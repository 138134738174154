import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import * as React from 'react';
import { Stack, Typography, TextField, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import i18next from 'i18next';
import { Auth } from 'aws-amplify';

// ----------------------------------------------------------------------
//  This is the example given within the documentation for using a multi select field drop for this library
//  Apparently you can't just embed and have to create a const, I'm sure you there is a way, but will investigate later
const accounts = [
  // {
  //   value: 'Seller',
  //   label: i18next.t('registerForm.account.seller')
  // },
  {
    value: 'Distributor',
    label: i18next.t('registerForm.account.distributor')
  }
  // {
  //   value: 'Manufacture',
  //   label: i18next.t('registerForm.account.manufacture')
  // }
];

async function verifyUser(usernameUuid, code) {
  try {
    await Auth.confirmSignUp(usernameUuid, code);
  } catch (error) {
    alert("Error confirming sign up", error);
  }
}

async function resendCode(usernameUuid) {
  try {
    await Auth.resendSignUp(usernameUuid);
  } catch (error) {
    alert(error.message);
  };
}

// Registration form set up with formik validation
export default function VerifyForm({ usernameUuid }) {
  const navigate = useNavigate();
  // const [showPassword, setShowPassword] = useState(false);
  //  React Hooks first param is the variable that houses the state and the second is the method that makes the state change
  // const [account, setAccount] = useState('');
  // const strongPasswordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

  // This is your yum validator to check if all the inputs have been filed in prior to allowing submission.
  const VerifySchema = Yup.object().shape({
    code: Yup.string().min(1, i18next.t('registerForm.message.verification1')).max(50, i18next.t('registerForm.message.verification2')).required(i18next.t('registerForm.message.verification3')),
  });
  //  This formik object contains the fields and their values that you can reference in the json payload to the lambda
  const formik = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: VerifySchema,

    // Following which data is sent through an axios call to be authenticated via google or whichever. 
    onSubmit: async (values, { setSubmitting }) => {
      // TODO: Add resend code button in case user doesn't get code
      try {
        verifyUser(usernameUuid, values.code);
        alert(i18next.t('verifyForm.alert.success'));
        setSubmitting(false);
        navigate('/auth/login', { replace: true });
      } catch (error) {
        alert(i18next.t('verifyForm.alert.error'));
        setSubmitting(false);
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  // TODO: Add resend code button in case user doesn't get code
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              // The label is the name of the field
              label={i18next.t('verifyForm.label.verificationCode')}
              //  FieldProps is the setter method that updates the firstname property upon value change in the field
              {...getFieldProps('code')}
              //  This is where the error validation messages come into play based on the scheme you defined earlier
              error={Boolean(touched.code && errors.code)}
              helperText={touched.code && errors.code}
            />
          </Stack>
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            {i18next.t('verifyForm.button.verify')}
          </LoadingButton>
        </Stack>
      </Form>
      <Typography variant="subtitle2" sx={{ mt: 3, textAlign: 'center' }}>
          {i18next.t('verifyForm.button.didNotReceiveCode')}&nbsp;
        <Button size="small" onClick={() => resendCode(usernameUuid)}>
          {i18next.t('verifyForm.button.resendCode')}
        </Button>
      </Typography>
    </FormikProvider>
  );
}
