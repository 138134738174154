import { Stack, Typography, Button, Divider } from '@mui/material';
import { Fragment } from 'react';
import { Icon } from '@iconify/react';
import { Auth } from 'aws-amplify';

// ---------------------------------------------------------------------

export default function AuthSocial() {
  const handleGoogleLogin = (event) => {
    Auth.federatedSignIn({ provider: 'Google' });
  };

  const handleKakaoLogin = (event) => {
    Auth.federatedSignIn({ provider: 'Kakao' });
  };
 
  return (
    <Fragment>
      <Stack direction="row" spacing={2} justifyContent="center">
        {/*<Button size="large" color="inherit" variant="outlined">
          <Icon icon="vs:naver" color="#47ad4f" height={23} />
        </Button>
        */}
        <Button size="large" color="inherit" variant="outlined" onClick={handleKakaoLogin}>
          <Icon icon="vs:kakaotalk" color="#fbc006" height={25} />
        </Button>
        <Button size="large" color="inherit" variant="outlined" onClick={handleGoogleLogin}>
          <Icon icon="flat-color-icons:google" height={27} />
        </Button>
      </Stack>
      <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          OR
        </Typography>
      </Divider>
    </Fragment>
  );
}
