import PropTypes from 'prop-types';
import { Container, Alert, AlertTitle } from '@mui/material';
import { getPermissionsInfo } from '../authentication/TempAuthService';
import { useState, useEffect, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import DashboardNavbar from '../../layouts/dashboard/DashboardNavbar';
import DashboardSidebar from '../../layouts/dashboard/DashboardSidebar';
import { useTranslation } from "react-i18next";
import ErrorIcon from '@mui/icons-material/Error';
import i18next from 'i18next'
import LoadingContainer from '../common/LoadingContainer';

// ----------------------------------------------------------------------
const APP_BAR_MOBILE = 64;

const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  children: PropTypes.node
};

const getPermissionKey = (pathName) => {
  if (pathName.includes("offline")) {
    return ['orderManagement', 'offlineOrders'];
  }
  if (pathName.includes("online")) {
    return ['orderManagement', 'onlineOrders'];
  }
  if (pathName.includes("orderstatus")) {
    return ['orderManagement', 'orderStatus'];
  }
  if (pathName.includes("buzzwords")) {
    return ['marketingTools', 'buzzwords'];
  }
  if (pathName.includes("hotproducts")) {
    return ['marketingTools', 'hotProducts'];
  }
  if (pathName.includes("pricematch")) {
    return ['marketingTools', 'priceMatch'];
  }
  if (pathName.includes("sellgpt")) {
    return ['marketingTools', 'sellgpt'];
  }
  if (pathName.includes("events")) {
    return ['marketingTools', 'events'];
  }
  if (pathName.includes("cashflow")) {
    return ['financials', 'cashflow'];
  }
  if (pathName.includes("invoice")) {
    return ['financials', 'invoicing'];
  }
  if (pathName.includes("payment")) {
    return ['financials', 'payment'];
  }
  if (pathName.includes("taxes")) {
    return ['financials', 'taxArchive'];
  }
  if (pathName.includes("commandcentral")) {
    return ['commandCentral', 'commandCentral'];
  }
  if (pathName.includes("performance")) {
    return ['commerce', 'performance'];
  }
  if (pathName.includes("catalogue") || pathName.includes("product")) {
    return ['commerce', 'inventoryCatalogue'];
  }
  if (pathName.includes("inventory")) {
    return ['commerce', 'inventoryManagement'];
  }
  if (pathName.includes("customer")) {
    return ['commerce', 'customerDirectory'];
  }
  if (pathName.includes("procurement")) {
    return ['commerce', 'procurement'];
  }
  if (pathName.includes("admin")) {
    return ['admin', 'userManagement'];
  }
  return null;
} 

const userHasPermissions = (permissions, pathName) => {
  // if(!permissions?.isAdmin) {
  //   return false;
  // }
  if(permissions['isAdmin'] == true) {
    return true;
  }
  let permissionKey = getPermissionKey(pathName)

  if (permissionKey) {
  console.log(permissions, permissions[permissionKey[0]], permissions[permissionKey[0]][permissionKey[1]])

    if (permissions[permissionKey[0]] && permissions[permissionKey[0]][permissionKey[1]] == false) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }

};

export default function RoleBasedGuard({ children }) {
  const userPermissions = JSON.parse(getPermissionsInfo());
  const pathName = window.location.pathname;

  const theme = useTheme();

  const [open, setOpen] = useState(false);
  var [title, setTitle] = useState("");
  var [subTitle, setSubTitle] = useState("");

  const [navbarHeight, setNavbarHeight] = useState(0);
  const navbarHeightRef = useRef(null)
  useEffect(() => {
    // setNavbarHeight(navbarHeightRef.current.childNodes[0].clientHeight);
  }, [title, subTitle, theme])

  if(!userPermissions) return <LoadingContainer isLoading={true} isSellpointLoader />
  // console.log("userPermissions", !userHasPermissions(userPermissions, pathName))

  if (!userHasPermissions(userPermissions, pathName)) {
    return (
      <RootStyle>
      <div ref={navbarHeightRef}>
        <DashboardNavbar
          headerDescription={{ title, subTitle }} onOpenSidebar={() => setOpen(true)}
        />
      </div>
      <DashboardSidebar
        isOpenSidebar={open} onCloseSidebar={() => setOpen(false)}
      />
      <MainStyle
        sx={{
          [theme.breakpoints.down('md')]: {
            marginTop: [(navbarHeight - 90) + "px"],
          },
          [theme.breakpoints.down('sm')]: {
            marginTop: [(navbarHeight - 70) + "px"],
          },
          [theme.breakpoints.up('md')]: {
            marginTop: 0,
          },
          transition: theme.transitions.create('margin', {
            duration: theme.transitions.duration.complex
          }),
        }}
      >
        <Container>
          <Alert severity="error" iconMapping={{ error: <ErrorIcon fontSize="inherit" /> }}>
            <AlertTitle>{i18next.t('authGuard.alertTitle')}</AlertTitle>
            {i18next.t('authGuard.messageBody')}
          </Alert>
        </Container>
      </MainStyle>
    </RootStyle>
    );
  }

  return <>{children}</>;
}
