import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  countedProductByCategory: {},
  countedProductByBrand: {},
};

const slice = createSlice({
  name: 'hotProducts',
  initialState,
  reducers: {
    setCountedProductByCategory(state, action) {
      state.countedProductByCategory = action.payload;
    },
    setCountedProductByBrand(state, action) {
      state.countedProductByBrand = action.payload;
    },
  },
});

export const { setCountedProductByCategory, setCountedProductByBrand } = slice.actions;
export default slice.reducer;

export const selectCountedProductByCategory = (state) => state.hotProducts.countedProductByCategory;
export const selectCountedProductByBrand = (state) => state.hotProducts.countedProductByBrand;
