export const formatCreationDate = (value) => {
  // YYYYMMDD
  const date = new Date(value);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;
  return `${year}${formattedMonth}${formattedDay}`;
};

// date1: '일자1\n(2자리, 작성년월 제외)',
export const formatDate1 = (value) => {
  // DD
  const date = new Date(value);
  const day = date.getDate();
  const formattedDay = day < 10 ? `0${day}` : day.toString();
  return formattedDay;
};

export const EXCEL_COLUMNS = [
  {
    index: 0,
    field: 'typeOfElectronicInvoice',
    name: '전자(세금)계산서 종류\n(01:일반, 02:영세율)',
    mandatory: true,
    format: (value) => {
      if (value !== '01' && value !== '02') throw new Error('Electronic invoice type must be 01 or 02');
      return value;
    },
  },
  {
    index: 1,
    field: 'creationDate',
    name: '작성일자',
    mandatory: true,
    format: (value) => {
      // YYYYMMDD
      const date = new Date(value);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const formattedMonth = month < 10 ? `0${month}` : month;
      const formattedDay = day < 10 ? `0${day}` : day;
      return `${year}${formattedMonth}${formattedDay}`;
    },
  },
  {
    index: 2,
    field: 'supplierRegistrationNumber',
    name: '공급자 등록번호\n("-" 없이 입력)',
    mandatory: true,
  },
  {
    index: 3,
    field: 'supplierBusinessEstablishmentNumber',
    name: '공급자\n 종사업장번호',
  },
  {
    index: 4,
    field: 'supplierName',
    name: '공급자 상호',
    mandatory: true,
  },
  {
    index: 5,
    field: 'supplierPersonalName',
    name: '공급자 성명',
    mandatory: true,
  },
  {
    index: 6,
    field: 'supplierBusinessAddress',
    name: '공급자 사업장주소',
  },
  {
    index: 7,
    field: 'supplierBusinessType',
    name: '공급자 업태',
  },
  {
    index: 8,
    field: 'supplierBusinessCategory',
    name: '공급자 종목',
  },
  {
    index: 9,
    field: 'supplierEmail',
    name: '공급자 이메일',
  },
  {
    index: 10,
    field: 'recipientRegistrationNumber',
    name: '공급받는자 등록번호\n("-" 없이 입력)',
    mandatory: true,
  },
  {
    index: 11,
    field: 'recipientBusinessEstablishmentNumber',
    name: '공급받는자 \n종사업장번호',
  },
  {
    index: 12,
    field: 'recipientName',
    name: '공급받는자 상호',
    mandatory: true,
  },
  {
    index: 13,
    field: 'recipientPersonalName',
    name: '공급받는자 성명',
    mandatory: true,
  },
  {
    index: 14,
    field: 'recipientBusinessAddress',
    name: '공급받는자 사업장주소',
  },
  {
    index: 15,
    field: 'recipientBusinessType',
    name: '공급받는자 업태',
  },
  {
    index: 16,
    field: 'recipientBusinessCategory',
    name: '공급받는자 종목',
  },
  {
    index: 17,
    field: 'recipientEmail1',
    name: '공급받는자 이메일1',
  },
  {
    index: 18,
    field: 'recipientEmail2',
    name: '공급받는자 이메일2',
  },
  {
    index: 19,
    field: 'totalSupplyAmount',
    name: '공급가액\n합계',
    mandatory: true,
  },
  {
    index: 20,
    field: 'totalTaxAmount',
    name: '세액\n합계',
    mandatory: true,
  },
  {
    index: 21,
    field: 'remarks',
    name: '비고',
  },
  {
    index: 22,
    field: 'date1',
    name: '일자1\n(2자리, 작성년월 제외)',
    mandatory: true,
  },
  {
    index: 23,
    field: 'item1',
    name: '품목1',
  },
  {
    index: 24,
    field: 'specification1',
    name: '규격1',
  },
  {
    index: 25,
    field: 'quantity1',
    name: '수량1',
    format: (value) => {
      const number = Number(value);
      if (isNaN(number)) throw new Error('Quantity must be a number');
      return number.toFixed(2);
    },
  },
  {
    index: 26,
    field: 'unitPrice1',
    name: '단가1',
  },
  {
    index: 27,
    field: 'supplyAmount1',
    name: '공급가액1',
    mandatory: true,
  },
  {
    index: 28,
    field: 'taxAmount1',
    name: '세액1',
    mandatory: true,
  },
  {
    index: 29,
    field: 'itemRemarks1',
    name: '품목비고1',
  },
  {
    index: 30,
    field: 'date2',
    name: '일자2\n(2자리, 작성년월 제외)',
  },
  {
    index: 31,
    field: 'item2',
    name: '품목2',
  },
  {
    index: 32,
    field: 'specification2',
    name: '규격2',
  },
  {
    index: 33,
    field: 'quantity2',
    name: '수량2',
  },
  {
    index: 34,
    field: 'unitPrice2',
    name: '단가2',
  },
  {
    index: 35,
    field: 'supplyAmount2',
    name: '공급가액2',
  },
  {
    index: 36,
    field: 'taxAmount2',
    name: '세액2',
  },
  {
    index: 37,
    field: 'itemRemarks2',
    name: '품목비고2',
  },
  {
    index: 38,
    field: 'date3',
    name: '일자3\n(2자리, 작성년월 제외)',
  },
  {
    index: 39,
    field: 'item3',
    name: '품목3',
  },
  {
    index: 40,
    field: 'specification3',
    name: '규격3',
  },
  {
    index: 41,
    field: 'quantity3',
    name: '수량3',
  },
  {
    index: 42,
    field: 'unitPrice3',
    name: '단가3',
  },
  {
    index: 43,
    field: 'supplyAmount3',
    name: '공급가액3',
  },
  {
    index: 44,
    field: 'taxAmount3',
    name: '세액3',
  },
  {
    index: 45,
    field: 'itemRemarks3',
    name: '품목비고3',
  },
  {
    index: 46,
    field: 'date4',
    name: '일자4\n(2자리, 작성년월 제외)',
  },
  {
    index: 47,
    field: 'item4',
    name: '품목4',
  },
  {
    index: 48,
    field: 'specification4',
    name: '규격4',
  },
  {
    index: 49,
    field: 'quantity4',
    name: '수량4',
  },
  {
    index: 50,
    field: 'unitPrice4',
    name: '단가4',
  },
  {
    index: 51,
    field: 'supplyAmount4',
    name: '공급가액4',
  },
  {
    index: 52,
    field: 'taxAmount4',
    name: '세액4',
  },
  {
    index: 53,
    field: 'itemRemarks4',
    name: '품목비고4',
  },
  {
    index: 54,
    field: 'cash',
    name: '현금',
  },
  {
    index: 55,
    field: 'check',
    name: '수표',
  },
  {
    index: 56,
    field: 'billOfExchange',
    name: '어음',
  },
  {
    index: 57,
    field: 'accountsReceivable',
    name: '외상미수금',
  },
  {
    index: 58,
    field: 'receiptType',
    name: '영수(01),\n청구(02)',
    mandatory: true,
  },
  // {
  //   index: 59,
  //   field: 'undefinedField',
  //   name: 'Undefined',
  // },
];

export const COLUMN_NAMES = [
  '전자(세금)계산서 종류\n(01:일반, 02:영세율)',
  '작성일자',
  '공급자 등록번호\n("-" 없이 입력)',
  '공급자\n 종사업장번호',
  '공급자 상호',
  '공급자 성명',
  '공급자 사업장주소',
  '공급자 업태',
  '공급자 종목',
  '공급자 이메일',
  '공급받는자 등록번호\n("-" 없이 입력)',
  '공급받는자 \n종사업장번호',
  '공급받는자 상호',
  '공급받는자 성명',
  '공급받는자 사업장주소',
  '공급받는자 업태',
  '공급받는자 종목',
  '공급받는자 이메일1',
  '공급받는자 이메일2',
  '공급가액\n합계',
  '세액\n합계',
  '비고',
  '일자1\n(2자리, 작성년월 제외)',
  '품목1',
  '규격1',
  '수량1',
  '단가1',
  '공급가액1',
  '세액1',
  '품목비고1',
  '일자2\n(2자리, 작성년월 제외)',
  '품목2',
  '규격2',
  '수량2',
  '단가2',
  '공급가액2',
  '세액2',
  '품목비고2',
  '일자3\n(2자리, 작성년월 제외)',
  '품목3',
  '규격3',
  '수량3',
  '단가3',
  '공급가액3',
  '세액3',
  '품목비고3',
  '일자4\n(2자리, 작성년월 제외)',
  '품목4',
  '규격4',
  '수량4',
  '단가4',
  '공급가액4',
  '세액4',
  '품목비고4',
  '현금',
  '수표',
  '어음',
  '외상미수금',
  '영수(01),\n청구(02)',
  undefined,
];

export const BATCH_EXCEL_COLUMN_NAMEs = [
  '전자세금계산서 종류\n(01:일반, 02:영세율)',
  '작성일자\n(8자리,\nYYYYMMDD 형식)',
  '공급받는자 등록번호\n("-" 없이 입력)',
  '공급받는자 \n종사업장번호',
  '공급받는자 상호',
  '공급받는자 성명',
  '공급받는자 사업장주소',
  '공급받는자 업태',
  '공급받는자 종목',
  '공급받는자 이메일1',
  '공급받는자 이메일2',
  '공급가액\n합계',
  '세액\n합계',
  '비고',
  '일자1\n(2자리, 작성년월 제외)',
  '품목1',
  '규격1',
  '수량1',
  '단가1',
  '공급가액1',
  '세액1',
  '품목비고1',
  '일자2\n(2자리, 작성년월 제외)',
  '품목2',
  '규격2',
  '수량2',
  '단가2',
  '공급가액2',
  '세액2',
  '품목비고2',
  '일자3\n(2자리, 작성년월 제외)',
  '품목3',
  '규격3',
  '수량3',
  '단가3',
  '공급가액3',
  '세액3',
  '품목비고3',
  '일자4\n(2자리, 작성년월 제외)',
  '품목4',
  '규격4',
  '수량4',
  '단가4',
  '공급가액4',
  '세액4',
  '품목비고4',
  '현금',
  '수표',
  '어음',
  '외상미수금',
  '영수(01),\n청구(02)',
];

export const BATCH_EXCEL_COLUMNS = [
  {
    index: 0,
    field: 'typeOfElectronicInvoice',
    name: '전자세금계산서 종류\n(01:일반, 02:영세율)',
    mandatory: true,
  },
  {
    index: 1,
    field: 'creationDate',
    name: '작성일자\n(8자리,\nYYYYMMDD 형식)',
    mandatory: true,
  },
  {
    index: 2,
    field: 'recipientRegistrationNumber',
    name: '공급받는자 등록번호\n("-" 없이 입력)',
    mandatory: true,
  },
  {
    index: 3,
    field: 'recipientBusinessEstablishmentNumber',
    name: '공급받는자 \n종사업장번호',
  },
  {
    index: 4,
    field: 'recipientCompanyName',
    name: '공급받는자 상호',
    mandatory: true,
  },
  {
    index: 5,
    field: 'recipientName',
    name: '공급받는자 성명',
    mandatory: true,
  },
  {
    index: 6,
    field: 'recipientBusinessAddress',
    name: '공급받는자 사업장주소',
  },
  {
    index: 7,
    field: 'recipientBusinessType',
    name: '공급받는자 업태',
  },
  {
    index: 8,
    field: 'recipientBusinessItem',
    name: '공급받는자 종목',
  },
  {
    index: 9,
    field: 'recipientEmail1',
    name: '공급받는자 이메일1',
  },
  {
    index: 10,
    field: 'recipientEmail2',
    name: '공급받는자 이메일2',
  },
  {
    index: 11,
    field: 'totalSupplyValue',
    name: '공급가액\n합계',
    mandatory: true,
  },
  {
    index: 12,
    field: 'totalTaxAmount',
    name: '세액\n합계',
    mandatory: true,
  },
  {
    index: 13,
    field: 'remarks',
    name: '비고',
  },
  {
    index: 14,
    field: 'date1',
    name: '일자1\n(2자리, 작성년월 제외)',
    mandatory: true,
  },
  {
    index: 15,
    field: 'item1',
    name: '품목1',
  },
  {
    index: 16,
    field: 'specification1',
    name: '규격1',
  },
  {
    index: 17,
    field: 'quantity1',
    name: '수량1',
  },
  {
    index: 18,
    field: 'unitPrice1',
    name: '단가1',
  },
  {
    index: 19,
    field: 'supplyAmount1',
    name: '공급가액1',
    mandatory: true,
  },
  {
    index: 20,
    field: 'taxAmount1',
    name: '세액1',
    mandatory: true,
  },
  {
    index: 21,
    field: 'itemRemark1',
    name: '품목비고1',
  },
  {
    index: 22,
    field: 'date2',
    name: '일자2\n(2자리, 작성년월 제외)',
  },
  {
    index: 23,
    field: 'item2',
    name: '품목2',
  },
  {
    index: 24,
    field: 'specification2',
    name: '규격2',
  },
  {
    index: 25,
    field: 'quantity2',
    name: '수량2',
  },
  {
    index: 26,
    field: 'unitPrice2',
    name: '단가2',
  },
  {
    index: 27,
    field: 'supplyAmount2',
    name: '공급가액2',
  },
  {
    index: 28,
    field: 'taxAmount2',
    name: '세액2',
  },
  {
    index: 29,
    field: 'itemRemark2',
    name: '품목비고2',
  },
  {
    index: 30,
    field: 'date3',
    name: '일자3\n(2자리, 작성년월 제외)',
  },
  {
    index: 31,
    field: 'item3',
    name: '품목3',
  },
  {
    index: 32,
    field: 'specification3',
    name: '규격3',
  },
  {
    index: 33,
    field: 'quantity3',
    name: '수량3',
  },
  {
    index: 34,
    field: 'unitPrice3',
    name: '단가3',
  },
  {
    index: 35,
    field: 'supplyAmount3',
    name: '공급가액3',
  },
  {
    index: 36,
    field: 'taxAmount3',
    name: '세액3',
  },
  {
    index: 37,
    field: 'itemRemark3',
    name: '품목비고3',
  },
  {
    index: 38,
    field: 'date4',
    name: '일자4\n(2자리, 작성년월 제외)',
  },
  {
    index: 39,
    field: 'item4',
    name: '품목4',
  },
  {
    index: 40,
    field: 'specification4',
    name: '규격4',
  },
  {
    index: 41,
    field: 'quantity4',
    name: '수량4',
  },
  {
    index: 42,
    field: 'unitPrice4',
    name: '단가4',
  },
  {
    index: 43,
    field: 'supplyAmount4',
    name: '공급가액4',
  },
  {
    index: 44,
    field: 'taxAmount4',
    name: '세액4',
  },
  {
    index: 45,
    field: 'itemRemark4',
    name: '품목비고4',
  },
  {
    index: 46,
    field: 'cash',
    name: '현금',
  },
  {
    index: 47,
    field: 'check',
    name: '수표',
  },
  {
    index: 48,
    field: 'billOfExchange',
    name: '어음',
  },
  {
    index: 49,
    field: 'accountsReceivable',
    name: '외상미수금',
  },
  {
    index: 50,
    field: 'receiptType',
    name: '영수(01),\n청구(02)',
    mandatory: true,
  },
];
