import { styled } from '@mui/material/styles';
import { Card, Stack, Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/authentication/login';
import AuthSocial from '../components/authentication/AuthSocial';
import { getCookieAgreementStatus } from 'src/components/authentication/TempAuthService.js';
import CookieDialog from '../components/legal-notifications/CookieNotification';
import i18next from 'i18next';
import {useEffect} from "react";
import ReactGA from "react-ga4";

// ----------------------------------------------------------------------
const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 900,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 385,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(0, 0)
}));

const Stacks = styled('div')(({ theme }) => ({
  flexDirection: 'column',
  marginLeft: '15%'
}));

// ----------------------------------------------------------------------
export default function Login() {
  const cookieAgreementStatus = getCookieAgreementStatus();
  //GA PAGE TRACKING
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <RootStyle title={i18next.t('login.pageTitle')}>
      {cookieAgreementStatus != 'true' && <CookieDialog />}
      <MHidden width="mdDown">
        <SectionStyle>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>
      <Container maxWidth="sm">
        <ContentStyle>
          <Stacks sx={{ mb: 2 }}>
            <Link to="/"><img style={{ width: '80%' }} src="/static/illustrations/logo.png" alt="login"/></Link>
          </Stacks>
          <Stack sx={{ mb: 4 }}>
            <Typography style={{ marginLeft: '32%' }}>{i18next.t('login.text')}</Typography>
          </Stack>
          <AuthSocial />
          <LoginForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
