import { DEFAULT_HEADERS } from '../../constants';

/**
 * Edits customers, given as a list, in the customer directory.
 *
 * @param {List<Object> updatedCustomers} list of customers to update
 */
export const editCustomersMutation = (body) => ({
  url: ('/' + body.storeId + '/customers'),
  headers: DEFAULT_HEADERS,
  method: 'PATCH',
  // Include the entire post object as the body of the request
  body: {'storeID': body.storeId, 'customers': body.updatedCustomers}
});