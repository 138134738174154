import { camelCaseKeysForObjects } from '../../util';
import { DEFAULT_HEADERS } from '../../constants';

export const getCustomersQuery = (body) => ({
  url: ('/' + body.storeId + '/customers'),
  headers: DEFAULT_HEADERS
});

export const getCustomersTransformResponse = (responseData) => {
  return camelCaseKeysForObjects(responseData.response);
};