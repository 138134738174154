import { Icon } from '@iconify/react';
import homeFill from '@iconify/icons-eva/home-fill';
import fileFill from '@iconify/icons-eva/file-fill';
// eva:book-open-fill
import bookOpenFill from '@iconify/icons-eva/book-open-fill';

// material-symbols:privacy-tip
// eslint-disable-next-line no-unused-vars
import i18n from "../../locales/i18n";
import i18next from 'i18next'

// ----------------------------------------------------------------------
const ICON_SIZE = {
  width: 22,
  height: 22
};

const menuConfig = [
  {
    title: i18next.t('landing.menu.button1'),
    path: '/',
    icon: <Icon icon={homeFill} {...ICON_SIZE} />
  },
  { title: i18next.t('landing.menu.button2'), path: '/about', icon: <Icon icon={fileFill} {...ICON_SIZE} /> },
  // { title: i18next.t('landing.mainFooter.links.name2'), path: '/termsconditions', icon: <Icon icon={bookOpenFill} {...ICON_SIZE} /> },
  // { title: i18next.t('landing.mainFooter.links.name3'), path: '/privacy', icon: <Icon icon="material-symbols:privacy-tip-rounded" {...ICON_SIZE} /> },
  { title: i18next.t('landing.mainFooter.links.name5'), path: '/pricing', icon: <Icon icon="ic:twotone-payment" {...ICON_SIZE} /> }
];
export default menuConfig;
