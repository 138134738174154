import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// auth
import { Auth } from 'aws-amplify';
import { getStoreId } from "src/components/authentication/TempAuthService";
import { useNavigate } from 'react-router';
// pages
import Login from '../../pages/Login';
import Setup from '../../pages/Setup';
// components
import LoadingScreen from '../LoadingScreen';

// ----------------------------------------------------------------------
AuthGuardRedirect.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuardRedirect({ children }) {
  const [hasStoreId, setHasStoreId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getStoreId() ? setHasStoreId(true) : setHasStoreId(false);
  }, []);

  if (hasStoreId === null) {
    return <LoadingScreen />;
  }

  if (!hasStoreId) {
    (async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        const attributes = currentUser["attributes"];
        const storeId = attributes["custom:store_id"];
        
        if (storeId) {
          navigate("/auth/login", { replace: true });
          return <Login />;
        } else {
          navigate("/auth/register/setup", { replace: true });
          return <Setup />;
        }
      } catch(error) {
        console.error(error);
        navigate("/auth/login", { replace: true });
        return <Login />;
      }
    })();
  }

  return <>{children}</>;
}
