import { Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function OrderCompleteIllustration({ ...other }) {

  return (
    <Box {...other}>
      <svg version="1.1" x="0px" y="0px" width="1000px" height="400px" viewBox="500 250 1000 700">
      <g id="Layer_2">
      </g>
      <g id="Layer_4">
        <g>
        </g>
        <g>
          <rect x="548.553" y="274.936" fill="#5EB4E7" width="17.809" height="17.809"/>
          <g>
            <g>
              <rect x="584.818" y="278.523" fill="#282D33" width="4" height="4"/>
            </g>
            <g>
              <path fill="#282D33" d="M580.135,282.523h-4.684v-4h4.684V282.523z M570.769,282.523h-4.683v-4h4.683V282.523z"/>
            </g>
            <g>
              <rect x="557.404" y="278.523" fill="#282D33" width="4" height="4"/>
            </g>
            <g>
              <path fill="#282D33" d="M561.404,273.841h-4v-4.683h4V273.841z M561.404,264.475h-4v-4.683h4V264.475z"/>
            </g>
            <g>
              <rect x="557.404" y="251.109" fill="#282D33" width="4" height="4"/>
            </g>
            <g>
              <path fill="#282D33" d="M580.134,255.109h-4.683v-4h4.683V255.109z M570.769,255.109h-4.683v-4h4.683V255.109z"/>
            </g>
            <g>
              <rect x="584.818" y="251.109" fill="#282D33" width="4" height="4"/>
            </g>
            <g>
              <path fill="#282D33" d="M588.818,273.84h-4v-4.682h4V273.84z M588.818,264.475h-4v-4.683h4V264.475z"/>
            </g>
          </g>
          <g>
            <g>
              <g>
                <path fill="#F8C44F" d="M631.156,468.984h-67.182c-53.169,0-96.425-43.266-96.425-96.445v-26.016h40v26.016
                  c0,31.124,25.312,56.445,56.426,56.445h67.182V468.984z"/>
              </g>
            </g>
          </g>
          <g>
            <path fill="#F8C44F" d="M680.875,599.999V440.682c0-8.617-9.01-15.6-20.127-15.6h-28.645c0.041-0.393,0.069-0.787,0.069-1.185
              v-33.948c0-7.32-6.774-13.248-15.13-13.248h-0.945c-8.357,0-15.131,5.928-15.131,13.248v33.948c0,0.397,0.029,0.792,0.068,1.185
              h-28.639c-11.117,0-20.131,6.982-20.131,15.6v159.317H680.875z"/>
            <path fill="#F8C44F" d="M635.339,431.35c0,1.828-1.483,3.312-3.312,3.312h-30.912c-1.83,0-3.312-1.484-3.312-3.312v-14.904
              c0-1.83,1.482-3.312,3.312-3.312h30.912c1.828,0,3.312,1.482,3.312,3.312V431.35z"/>
            <g>
              <path fill="#FFFFFF" d="M655.464,375.025c0,21.752-17.63,39.383-39.382,39.383l0,0c-21.752,0-39.383-17.631-39.383-39.383
                v-21.567c0-21.752,17.631-39.383,39.383-39.383l0,0c21.752,0,39.382,17.631,39.382,39.383V375.025z"/>
              <path fill="#282D33" d="M616.082,416.408c-22.819,0-41.383-18.564-41.383-41.383v-21.567c0-22.818,18.563-41.383,41.383-41.383
                c22.818,0,41.382,18.564,41.382,41.383v21.567C657.464,397.844,638.9,416.408,616.082,416.408z M616.082,316.075
                c-20.613,0-37.383,16.771-37.383,37.383v21.567c0,20.613,16.77,37.383,37.383,37.383c20.611,0,37.382-16.77,37.382-37.383
                v-21.567C653.464,332.846,636.693,316.075,616.082,316.075z"/>
            </g>
            <g>
              <path fill="#FFFFFF" d="M581.639,363.274c-4.182,0.437-11.004,3.933-11.004,8.231v9.934c0,4.302,6.822,7.798,11.004,8.231
                V363.274z"/>
              <path fill="#282D33" d="M583.639,391.889l-2.207-0.229c-4.593-0.477-12.797-4.299-12.797-10.221v-9.934
                c0-5.916,8.203-9.741,12.796-10.221l2.208-0.23V391.889z M579.639,365.731c-3.451,1.061-7.004,3.583-7.004,5.774v9.934
                c0,2.194,3.553,4.717,7.004,5.776V365.731z"/>
            </g>
            <g>
              <rect x="595.805" y="362.555" fill="#282D33" width="7.178" height="4"/>
            </g>
            <g>
              <rect x="625.613" y="362.555" fill="#282D33" width="7.178" height="4"/>
            </g>
            <g>
              <path fill="#FFFFFF" d="M607.607,395.651c1.889,3.279,4.981,5.427,8.484,5.427c3.506,0,6.598-2.147,8.486-5.427H607.607z"/>
              <path fill="#282D33" d="M616.092,403.078c-4.08,0-7.899-2.403-10.218-6.429l3.466-1.996c1.596,2.771,4.12,4.425,6.752,4.425
                c2.633,0,5.157-1.654,6.753-4.425l3.466,1.996C623.992,400.675,620.172,403.078,616.092,403.078z"/>
            </g>
            <g>
              <path fill="#5EB4E7" d="M653.764,323.982h-4.578c1.531-2.662,2.004-5.401,1.068-7.814c-2.316-5.968-12.256-7.679-22.206-3.82
                c-1.12,0.434-2.185,0.929-3.204,1.459c0.625-2.125,0.645-4.218-0.102-6.139c-2.748-7.084-14.549-9.115-26.355-4.535
                c-11.66,4.522-18.951,13.795-16.488,20.85h-3.484c-4.575,0-8.281,3.707-8.281,8.28v34.55c0.141,0.354,0.273,0.709,0.422,1.055
                h11.117v-30.142c0-0.424,0.049-0.835,0.064-1.255c10.394,2.187,22.049,3.416,34.352,3.416c12.301,0,23.955-1.229,34.35-3.416
                c0.018,0.42,0.066,0.831,0.066,1.255v30.142h11.115c0.15-0.35,0.281-0.705,0.424-1.058v-34.547
                C662.043,327.689,658.336,323.982,653.764,323.982z"/>
              <path fill="#282D33" d="M662.936,369.867h-14.432v-30.966c-10.236,1.981-21.128,2.985-32.416,2.985
                c-11.29,0-22.182-1.003-32.416-2.984v30.965h-14.436l-0.52-1.211c-0.112-0.26-0.216-0.525-0.32-0.791l-0.264-0.67v-34.933
                c0-5.669,4.611-10.28,10.281-10.28h1.04c-0.593-7.621,6.812-16.294,18.209-20.715c4.358-1.69,8.891-2.583,13.107-2.583
                c7.909,0,13.829,3.087,15.836,8.261c0.438,1.129,0.673,2.312,0.701,3.545c0.006-0.003,0.013-0.006,0.019-0.008
                c3.707-1.438,7.565-2.197,11.157-2.197c6.798,0,11.895,2.676,13.635,7.159c0.788,2.032,0.823,4.252,0.123,6.538h1.522
                c5.668,0,10.279,4.611,10.279,10.28v34.937l-0.293,0.731c-0.096,0.243-0.19,0.486-0.294,0.728L662.936,369.867z
                M652.504,365.867h7.539v-33.604c0-3.463-2.817-6.28-6.279-6.28h-8.036l1.724-2.997c0.899-1.563,1.792-3.89,0.938-6.095
                c-1.103-2.841-4.898-4.605-9.905-4.605c-3.102,0-6.46,0.666-9.712,1.928c-0.982,0.379-1.965,0.826-3.006,1.368l-4.166,2.167
                l1.325-4.506c0.524-1.785,0.509-3.418-0.048-4.85c-1.387-3.575-5.912-5.709-12.106-5.709c-3.728,0-7.76,0.801-11.661,2.314
                c-10.549,4.091-17.423,12.312-15.323,18.325l0.929,2.659h-6.301c-3.464,0-6.281,2.817-6.281,6.28v33.604h7.539v-28.142
                c0-0.3,0.019-0.593,0.039-0.888c0.01-0.146,0.021-0.294,0.026-0.441l0.088-2.371l2.322,0.488
                c10.64,2.238,22.06,3.373,33.94,3.373c11.879,0,23.297-1.135,33.938-3.373l2.311-0.486l0.1,2.359
                c0.006,0.145,0.017,0.287,0.026,0.431c0.021,0.301,0.042,0.602,0.042,0.908V365.867z"/>
            </g>
            <g>
              <path fill="#FFFFFF" d="M610.088,355.857v24.773c0,1.598,1.297,2.897,2.898,2.897h5.314c1.6,0,2.898-1.3,2.898-2.897v-1.453
                L610.088,355.857z"/>
              <path fill="#282D33" d="M618.301,385.528h-5.314c-2.701,0-4.898-2.196-4.898-4.897v-24.773h4v24.773
                c0,0.494,0.402,0.897,0.898,0.897h5.314c0.495,0,0.898-0.403,0.898-0.897v-1.453h4v1.453
                C623.199,383.332,621.001,385.528,618.301,385.528z"/>
            </g>
            <g>
              <rect x="607.607" y="420.86" fill="#282D33" width="4" height="12.695"/>
            </g>
            <g>
              <path fill="#FFFFFF" d="M650.447,371.05c0-4.292,3.48-7.775,7.777-7.775h0.648c4.293,0,7.777,3.483,7.777,7.775v9.444
                c0,4.296-3.484,7.776-7.777,7.776h-0.648c-3.18,0-5.91-1.905-7.117-4.636L650.447,371.05z"/>
              <path fill="#282D33" d="M658.873,390.271h-0.648c-3.87,0-7.382-2.287-8.947-5.827l3.659-1.617c0.925,2.092,3,3.444,5.288,3.444
                h0.648c3.186,0,5.777-2.591,5.777-5.776v-9.444c0-3.185-2.592-5.775-5.777-5.775h-0.648c-3.186,0-5.777,2.591-5.777,5.775h-4
                c0-5.39,4.386-9.775,9.777-9.775h0.648c5.391,0,9.777,4.386,9.777,9.775v9.444C668.65,385.885,664.264,390.271,658.873,390.271z
                "/>
            </g>
          </g>
          <g>
            <g>
              <path fill="#FFFFFF" d="M301.054,599.999V455.005c0-9.799,8.978-17.743,20.049-17.743h87.974
                c11.072,0,20.043,7.944,20.043,17.743v144.994H301.054z"/>
              <path fill="#282D33" d="M431.12,599.999h-4V455.005c0-8.681-8.094-15.743-18.043-15.743h-87.974
                c-9.952,0-18.049,7.062-18.049,15.743v144.994h-4V455.005c0-10.886,9.891-19.743,22.049-19.743h87.974
                c12.155,0,22.043,8.857,22.043,19.743V599.999z"/>
            </g>
            <g>
              <polygon fill="#FFFFFF" points="381.536,437.446 364.694,447.171 347.851,437.446 347.851,400.674 364.694,400.674 
                381.536,400.674 				"/>
              <path fill="#282D33" d="M364.694,449.48l-18.843-10.879v-39.928h37.685v39.928L364.694,449.48z M349.851,436.291l14.843,8.57
                l14.842-8.57v-33.617h-29.685V436.291z"/>
            </g>
            <polygon fill="#5EB4E7" points="344.961,462.988 364.623,449.992 346.512,439.535 			"/>
            <polygon fill="#5EB4E7" points="384.391,462.99 364.729,449.992 382.837,439.535 			"/>
            <g>
              <path fill="#FFFFFF" d="M413.594,388.306c0,4.295-3.481,7.778-7.775,7.778h-0.649c-4.297,0-7.774-3.483-7.774-7.778v-14.255
                c0-4.295,3.478-7.775,7.774-7.775h0.649c4.294,0,7.775,3.48,7.775,7.775V388.306z"/>
              <path fill="#282D33" d="M405.819,398.084h-0.649c-5.39,0-9.774-4.387-9.774-9.778v-14.255c0-5.391,4.385-9.775,9.774-9.775
                h0.649c5.39,0,9.775,4.385,9.775,9.775v14.255C415.594,393.697,411.209,398.084,405.819,398.084z M405.169,368.275
                c-3.185,0-5.774,2.591-5.774,5.775v14.255c0,3.187,2.59,5.778,5.774,5.778h0.649c3.185,0,5.775-2.592,5.775-5.778v-14.255
                c0-3.185-2.591-5.775-5.775-5.775H405.169z"/>
            </g>
            <g>
              <path fill="#FFFFFF" d="M404.47,389.615c0,21.752-17.633,39.383-39.382,39.383l0,0c-21.752,0-39.385-17.631-39.385-39.383
                v-31.503c0-21.749,17.633-39.38,39.385-39.38l0,0c21.749,0,39.382,17.631,39.382,39.38V389.615z"/>
              <path fill="#282D33" d="M365.088,430.998c-22.82,0-41.385-18.564-41.385-41.383v-31.503c0-22.817,18.565-41.38,41.385-41.38
                c22.818,0,41.382,18.562,41.382,41.38v31.503C406.47,412.434,387.906,430.998,365.088,430.998z M365.088,320.732
                c-20.614,0-37.385,16.769-37.385,37.38v31.503c0,20.613,16.771,37.383,37.385,37.383c20.612,0,37.382-16.77,37.382-37.383
                v-31.503C402.47,337.501,385.701,320.732,365.088,320.732z"/>
            </g>
            <g>
              <path fill="#5EB4E7" d="M351.026,316.595c-6.373,0-11.975,3.28-15.232,8.238h-6.255c-7.794,0-14.118,6.319-14.118,14.118v67.897
                h9.935v-47.197c0-5.488,4.451-9.937,9.938-9.937h5.795h48.854h9.107c10.063,0,18.217-8.156,18.217-18.216v-14.904H351.026z"/>
              <path fill="#282D33" d="M327.355,408.849H313.42v-69.897c0-8.888,7.23-16.118,16.118-16.118h5.211
                c3.813-5.179,9.818-8.238,16.276-8.238h68.24v16.904c0,11.147-9.069,20.216-20.217,20.216h-63.756
                c-4.377,0-7.938,3.561-7.938,7.937V408.849z M317.42,404.849h5.935v-45.197c0-6.582,5.355-11.937,11.938-11.937h63.756
                c8.942,0,16.217-7.274,16.217-16.216v-12.904h-64.24c-5.473,0-10.542,2.743-13.561,7.337l-0.593,0.901h-7.333
                c-6.682,0-12.118,5.437-12.118,12.118V404.849z"/>
            </g>
            <g>
              <path fill="#FFFFFF" d="M325.704,366.356c-3.908,0.406-10.283,3.675-10.283,7.694v14.255c0,4.021,6.375,7.29,10.283,7.696
                V366.356z"/>
              <path fill="#282D33" d="M327.704,398.221l-2.207-0.229c-4.334-0.45-12.077-4.072-12.077-9.686v-14.255
                c0-5.611,7.742-9.232,12.077-9.684l2.207-0.229V398.221z M323.704,368.838c-3.095,1.013-6.283,3.291-6.283,5.213v14.255
                c0,1.923,3.188,4.202,6.283,5.214V368.838z"/>
            </g>
            <g>
              <path fill="#FFFFFF" d="M356.76,404.633c1.893,3.28,4.984,5.428,8.486,5.428c3.504,0,6.596-2.147,8.486-5.428H356.76z"/>
              <path fill="#282D33" d="M365.247,412.061c-4.076,0-7.896-2.403-10.219-6.428l3.465-1.999c1.599,2.771,4.125,4.427,6.754,4.427
                c2.631,0,5.155-1.654,6.753-4.426l3.466,1.997C373.146,409.657,369.325,412.061,365.247,412.061z"/>
            </g>
            <g>
              <path fill="#282D33" d="M342.903,391.082c-10.089,0-18.298-8.209-18.298-18.299v-8.318h36.597v8.318
                C361.202,382.873,352.993,391.082,342.903,391.082z M328.605,368.465v4.318c0,7.884,6.414,14.299,14.298,14.299
                c7.884,0,14.299-6.415,14.299-14.299v-4.318H328.605z"/>
            </g>
            <g>
              <path fill="#282D33" d="M388.141,391.082c-10.089,0-18.297-8.209-18.297-18.299v-8.318h36.594v8.318
                C406.438,382.873,398.23,391.082,388.141,391.082z M373.844,368.465v4.318c0,7.884,6.414,14.299,14.297,14.299
                s14.297-6.415,14.297-14.299v-4.318H373.844z"/>
            </g>
            <g>
              <rect x="342.332" y="373.283" fill="#282D33" width="6.02" height="4"/>
            </g>
            <g>
              <rect x="382.009" y="373.283" fill="#282D33" width="5.744" height="4"/>
            </g>
            <g>
              <rect x="359.172" y="364.465" fill="#282D33" width="14.905" height="4"/>
            </g>
          </g>
          <g>
            <path fill="#5EB4E7" d="M418.998,599.999v-80.69c0-7.315,6.884-13.248,15.381-13.248h84.6c8.494,0,15.383,5.933,15.383,13.248
              v80.69H418.998z"/>
            <g>
              <path fill="#FFFFFF" d="M440.878,599.999v-74.868c0-6.4,4.273-11.586,9.544-11.586h52.511c5.273,0,9.547,5.186,9.547,11.586
                v74.868H440.878z"/>
              <path fill="#282D33" d="M514.48,599.999h-4v-74.868c0-5.285-3.386-9.586-7.547-9.586h-52.511c-4.16,0-7.544,4.301-7.544,9.586
                v74.868h-4v-74.868c0-7.491,5.179-13.586,11.544-13.586h52.511c6.366,0,11.547,6.095,11.547,13.586V599.999z"/>
            </g>
            <g>
              <path fill="#FFFFFF" d="M476.679,533.856c11.947,0,21.667-9.72,21.667-21.667v-6.129h-43.334v6.129
                C455.012,524.137,464.732,533.856,476.679,533.856z"/>
              <path fill="#282D33" d="M476.679,535.856c-13.05,0-23.667-10.617-23.667-23.667v-8.129h47.334v8.129
                C500.346,525.239,489.729,535.856,476.679,535.856z M457.012,508.061v4.129c0,10.845,8.823,19.667,19.667,19.667
                s19.667-8.822,19.667-19.667v-4.129H457.012z"/>
            </g>
            <g>
              <path fill="#FFFFFF" d="M488.346,512.189c0,6.443-5.224,11.667-11.667,11.667l0,0c-6.443,0-11.667-5.224-11.667-11.667v-37.333
                c0-6.443,5.224-11.667,11.667-11.667l0,0c6.443,0,11.667,5.224,11.667,11.667V512.189z"/>
              <path fill="#282D33" d="M476.679,525.856c-7.536,0-13.667-6.131-13.667-13.667v-37.333c0-7.536,6.131-13.667,13.667-13.667
                s13.667,6.131,13.667,13.667v37.333C490.346,519.726,484.215,525.856,476.679,525.856z M476.679,465.189
                c-5.331,0-9.667,4.336-9.667,9.667v37.333c0,5.33,4.336,9.667,9.667,9.667s9.667-4.337,9.667-9.667v-37.333
                C486.346,469.525,482.01,465.189,476.679,465.189z"/>
            </g>
            <g>
              <ellipse fill="#5EB4E7" cx="441.653" cy="418.84" rx="27.601" ry="28.703"/>
              <path fill="#282D33" d="M441.654,449.543c-16.322,0-29.602-13.773-29.602-30.703c0-16.931,13.279-30.705,29.602-30.705
                c16.321,0,29.6,13.774,29.6,30.705C471.253,435.77,457.975,449.543,441.654,449.543z M441.654,392.135
                c-14.117,0-25.602,11.98-25.602,26.705s11.485,26.703,25.602,26.703c14.116,0,25.6-11.979,25.6-26.703
                S455.77,392.135,441.654,392.135z"/>
            </g>
            <g>
              <ellipse fill="#5EB4E7" cx="435.029" cy="450.15" rx="17.664" ry="18.371"/>
              <path fill="#282D33" d="M435.029,470.521c-10.843,0-19.664-9.139-19.664-20.371c0-11.233,8.821-20.372,19.664-20.372
                s19.664,9.139,19.664,20.372C454.693,461.383,445.872,470.521,435.029,470.521z M435.029,433.778
                c-8.637,0-15.664,7.345-15.664,16.372s7.027,16.371,15.664,16.371s15.664-7.344,15.664-16.371S443.666,433.778,435.029,433.778z
                "/>
            </g>
            <g>
              <ellipse fill="#FFFFFF" cx="438.341" cy="435.799" rx="17.664" ry="18.37"/>
              <path fill="#282D33" d="M438.341,456.169c-10.843,0-19.664-9.138-19.664-20.37s8.821-20.371,19.664-20.371
                c10.842,0,19.664,9.139,19.664,20.371S449.184,456.169,438.341,456.169z M438.341,419.428c-8.637,0-15.664,7.344-15.664,16.371
                c0,9.026,7.027,16.37,15.664,16.37s15.664-7.344,15.664-16.37C454.005,426.771,446.979,419.428,438.341,419.428z"/>
            </g>
            <g>
              <ellipse fill="#5EB4E7" cx="507.895" cy="450.15" rx="17.664" ry="18.371"/>
              <path fill="#282D33" d="M507.895,470.521c-10.843,0-19.665-9.139-19.665-20.371c0-11.233,8.821-20.372,19.665-20.372
                c10.842,0,19.664,9.139,19.664,20.372C527.559,461.383,518.736,470.521,507.895,470.521z M507.895,433.778
                c-8.638,0-15.665,7.345-15.665,16.372s7.027,16.371,15.665,16.371c8.637,0,15.664-7.344,15.664-16.371
                S516.531,433.778,507.895,433.778z"/>
            </g>
            <g>
              <path fill="#FFFFFF" d="M510,452.909c0,21.749-17.631,39.383-39.381,39.383l0,0c-21.75,0-39.381-17.634-39.381-39.383v-31.507
                c0-21.748,17.631-39.379,39.381-39.379l0,0c21.75,0,39.381,17.631,39.381,39.379V452.909z"/>
              <path fill="#282D33" d="M470.619,494.292c-22.817,0-41.381-18.564-41.381-41.383v-31.507c0-22.816,18.563-41.379,41.381-41.379
                S512,398.586,512,421.402v31.507C512,475.728,493.436,494.292,470.619,494.292z M470.619,384.023
                c-20.612,0-37.381,16.768-37.381,37.379v31.507c0,20.612,16.769,37.383,37.381,37.383S508,473.521,508,452.909v-31.507
                C508,400.791,491.23,384.023,470.619,384.023z"/>
            </g>
            <g>
              <path fill="#5EB4E7" d="M506.211,392.577l28.057,28.056l-5.076,5.074c-15.523,15.522-40.687,15.522-56.206,0l-35.072-35.07
                C457.449,373.177,487.453,373.82,506.211,392.577z"/>
              <path fill="#282D33" d="M501.087,439.35c-11.15,0-21.632-4.344-29.516-12.229l-36.567-36.566l1.577-1.409
                c9.479-8.472,21.716-13.138,34.457-13.138c13.82,0,26.813,5.383,36.587,15.155l29.471,29.47l-6.49,6.488
                C522.72,435.006,512.236,439.35,501.087,439.35z M440.857,390.751l33.543,33.542c7.128,7.129,16.606,11.057,26.687,11.057
                s19.56-3.928,26.69-11.057l3.661-3.66l-26.643-26.642c-9.017-9.017-21.006-13.983-33.758-13.983
                C459.991,380.008,449.355,383.809,440.857,390.751z"/>
            </g>
            <g>
              <path fill="#282D33" d="M464.917,438.451h-4c0-4.231-3.442-7.674-7.672-7.674s-7.672,3.442-7.672,7.674h-4
                c0-6.437,5.236-11.674,11.672-11.674S464.917,432.015,464.917,438.451z"/>
            </g>
            <g>
              <rect x="462.078" y="464.312" fill="#282D33" width="14.904" height="4"/>
            </g>
          </g>
          <g>
            <g>
              <g>
                <path fill="#F8C44F" d="M220.342,538.146c-41.088,0-74.517-33.435-74.517-74.53v-67.858h40v67.858
                  c0,19.04,15.484,34.53,34.517,34.53c19.005,0,34.467-15.49,34.467-34.53v-1.165h40v1.165
                  C294.809,504.711,261.403,538.146,220.342,538.146z"/>
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <path fill="#F8C44F" d="M344.81,599.999h-40v-54.167c0-12.973-10.549-23.526-23.517-23.526v-40
                  c35.023,0,63.517,28.498,63.517,63.526V599.999z"/>
              </g>
            </g>
          </g>
          <g>
            <g>
              <path fill="#282D33" d="M181.089,391.322h-4v-3.9l1.525-0.372c1.284-0.313,2.473-0.953,3.444-1.851l9.992-11.624v-7.149
                l-0.276-0.109l0.276-1.447v-4.772c-1.897,0.077-3.416,1.645-3.416,3.56v9.319h-4v-9.319c0-4.17,3.393-7.562,7.563-7.562h3.723
                l0.012,0.153l0.117,0.009l0.019,1.705l0.125,1.867l-0.144,0.02v15.21l-11.17,12.979c-1.099,1.041-2.39,1.86-3.791,2.413V391.322
                z"/>
            </g>
            <g>
              <path fill="#FFFFFF" d="M208.725,364.723h2.8c0.346,0,0.623,0.282,0.623,0.636v4.517c0,0.354-0.277,0.639-0.623,0.639h-2.8
                V364.723z"/>
              <path fill="#282D33" d="M211.525,372.514h-2.8v-4h1.423v-1.791h-1.423v-4h2.8c1.446,0,2.623,1.183,2.623,2.636v4.517
                C214.148,371.33,212.971,372.514,211.525,372.514z"/>
            </g>
            <g>
              <polygon fill="#282D33" points="141.094,372.505 139.446,372.505 128.655,367.613 139.446,362.723 141.094,362.723 
                141.094,366.723 140.311,366.723 138.344,367.614 140.311,368.505 141.094,368.505 				"/>
            </g>
            <rect x="143.328" y="363.103" fill="#5EB4E7" width="13.9" height="9.031"/>
            <rect x="188.312" y="363.103" fill="#5EB4E7" width="18.15" height="9.031"/>
            <g>
              <path fill="#FFFFFF" d="M194.05,372.588c-0.009,0,0.014,0.002,0,0.006v1.723l-10.545,12.269
                c-1.218,1.154-2.732,1.996-4.416,2.407v2.33L194.05,372.588z"/>
              <path fill="#282D33" d="M181.089,391.322h-4v-3.9l1.525-0.372c1.284-0.313,2.473-0.953,3.444-1.851l9.805-11.407l0.187-1.356
                v-1.351l0.195-0.056l0.061-0.441h1.744v1.999l0.543-1.923l1.461,1.924l-0.004,0.006v2.464l-11.17,12.979
                c-1.099,1.041-2.39,1.86-3.791,2.413V391.322z"/>
            </g>
            <g>
              <path fill="#282D33" d="M162.876,391.322h-4v-3.047c-2.517-2.238-3.939-5.386-3.939-8.784v-16.997l0.186-3.852
                c-0.002-3.014,2.528-5.545,5.639-5.545c1.096,0,2.007,0.17,2.753,0.462c1.029-1.384,2.677-2.283,4.531-2.283
                c0.945,0,1.835,0.233,2.619,0.646c1.017-1.488,2.727-2.467,4.662-2.467c2.075,0,3.892,1.126,4.873,2.799
                c0.784-0.389,1.661-0.612,2.571-0.612c3.18,0,5.768,2.587,5.768,5.767v15.566h-4v-15.566c0-0.974-0.793-1.767-1.768-1.767
                c-0.994,0-1.801,0.911-1.801,1.506h-4v-2.051c0-0.905-0.737-1.642-1.643-1.642s-1.643,0.736-1.643,1.642v1.822h-4
                c0-0.906-0.735-1.644-1.639-1.644c-0.906,0-1.643,0.737-1.643,1.644v0.909l-4-0.003c0-0.273-0.005-0.729-1.642-0.729
                c-0.905,0-1.642,0.736-1.642,1.643l-0.186,3.852l0.002,16.899c0,2.472,1.144,4.744,3.137,6.235l0.802,0.6V391.322z"/>
            </g>
          </g>
          <g>
            <g>
              <path fill="#F8C44F" d="M308.721,476.849c0,15.761-12.776,28.539-28.539,28.539h-12.017c-15.76,0-28.538-12.778-28.538-28.539
                v-51.821c0-15.762,12.778-28.538,28.538-28.538h12.017c15.763,0,28.539,12.776,28.539,28.538V476.849z"/>
              <path fill="#F8C44F" d="M280.182,507.388h-12.017c-16.839,0-30.538-13.699-30.538-30.539v-51.821
                c0-16.839,13.699-30.538,30.538-30.538h12.017c16.839,0,30.539,13.699,30.539,30.538v51.821
                C310.721,493.688,297.021,507.388,280.182,507.388z M268.166,398.489c-14.633,0-26.538,11.905-26.538,26.538v51.821
                c0,14.634,11.905,26.539,26.538,26.539h12.017c14.634,0,26.539-11.905,26.539-26.539v-51.821
                c0-14.633-11.905-26.538-26.539-26.538H268.166z"/>
            </g>
            <g>
              <path fill="#FFFFFF" d="M313.845,599.999v-70.636c0-22.29-18.072-40.361-40.362-40.361c-22.291,0-40.362,18.071-40.362,40.361
                v70.636H313.845z"/>
              <path fill="#282D33" d="M315.845,599.999h-4v-70.636c0-21.152-17.209-38.361-38.362-38.361s-38.362,17.209-38.362,38.361v70.636
                h-4v-70.636c0-23.357,19.003-42.361,42.362-42.361s42.362,19.004,42.362,42.361V599.999z"/>
            </g>
            <g>
              <path fill="#FFFFFF" d="M319.113,449.061c0,6.75-5.475,12.222-12.227,12.222H241.46c-6.749,0-12.221-5.472-12.221-12.222l0,0
                c0-6.751,5.472-12.224,12.221-12.224h65.426C313.638,436.837,319.113,442.31,319.113,449.061L319.113,449.061z"/>
              <path fill="#282D33" d="M306.886,463.282H241.46c-7.841,0-14.221-6.38-14.221-14.222c0-7.843,6.379-14.224,14.221-14.224h65.426
                c7.844,0,14.227,6.381,14.227,14.224C321.113,456.902,314.73,463.282,306.886,463.282z M241.46,438.837
                c-5.636,0-10.221,4.587-10.221,10.224s4.585,10.222,10.221,10.222h65.426c5.639,0,10.227-4.585,10.227-10.222
                s-4.587-10.224-10.227-10.224H241.46z"/>
            </g>
            <g>
              <path fill="#FFFFFF" d="M273.309,506.513c-7.051,0-12.768-5.716-12.768-12.767v-68.344c0-7.05,5.717-12.767,12.768-12.767l0,0
                c7.05,0,12.767,5.717,12.767,12.767v68.344C286.076,500.797,280.359,506.513,273.309,506.513L273.309,506.513z"/>
              <path fill="#282D33" d="M273.309,508.513c-8.143,0-14.768-6.624-14.768-14.767v-68.344c0-8.143,6.625-14.767,14.768-14.767
                c8.142,0,14.767,6.624,14.767,14.767v68.344C288.076,501.889,281.451,508.513,273.309,508.513z M273.309,414.636
                c-5.937,0-10.768,4.83-10.768,10.767v68.344c0,5.937,4.831,10.767,10.768,10.767s10.767-4.83,10.767-10.767v-68.344
                C284.076,419.466,279.246,414.636,273.309,414.636z"/>
            </g>
            <g>
              <path fill="#FFFFFF" d="M308.721,402.496v51.821c0,15.761-12.776,28.539-28.539,28.539h-12.017
                c-15.76,0-28.538-12.778-28.538-28.539v-51.821H308.721z"/>
              <path fill="#282D33" d="M280.182,484.856h-12.017c-16.839,0-30.538-13.7-30.538-30.539v-51.821h4v51.821
                c0,14.634,11.905,26.539,26.538,26.539h12.017c14.634,0,26.539-11.905,26.539-26.539v-51.821h4v51.821
                C310.721,471.156,297.021,484.856,280.182,484.856z"/>
            </g>
            <g>
              <path fill="#F8C44F" d="M280.182,373.958h-12.017c-15.76,0-28.538,12.777-28.538,28.538v34.175
                c14.251-1.294,27.814-14.592,34.545-33.626c6.733,19.034,20.296,32.332,34.548,33.627v-34.176
                C308.721,386.735,295.945,373.958,280.182,373.958z"/>
              <path fill="#F8C44F" d="M310.721,438.861l-2.181-0.197c-13.755-1.25-26.868-12.9-34.367-30.157
                c-7.498,17.257-20.609,28.907-34.364,30.155l-2.181,0.199v-36.365c0-16.839,13.699-30.538,30.538-30.538h12.017
                c16.839,0,30.539,13.699,30.539,30.538V438.861z M274.173,397.047l1.886,5.331c6.156,17.404,18.021,29.689,30.663,32.004
                v-31.886c0-14.633-11.905-26.538-26.539-26.538h-12.017c-14.633,0-26.538,11.905-26.538,26.538v31.885
                c12.641-2.313,24.505-14.599,30.66-32.003L274.173,397.047z"/>
            </g>
            <g>
              <path fill="#282D33" d="M267.844,444.315h-4c0-1.546-2.26-3.269-5.503-3.269c-3.246,0-5.507,1.723-5.507,3.269h-4
                c0-4.075,4.176-7.269,9.507-7.269C263.67,437.047,267.844,440.24,267.844,444.315z"/>
            </g>
            <g>
              <path fill="#282D33" d="M296.253,444.315h-4c0-1.546-2.26-3.269-5.503-3.269c-3.244,0-5.505,1.723-5.505,3.269h-4
                c0-4.075,4.175-7.269,9.505-7.269C292.079,437.047,296.253,440.24,296.253,444.315z"/>
            </g>
            <g>
              <path fill="#FFFFFF" d="M261.928,455.068v5.257c0,4.148,3.362,7.511,7.511,7.511h5.258c4.147,0,7.51-3.362,7.51-7.511v-5.257
                H261.928z"/>
              <path fill="#282D33" d="M274.697,469.836h-5.258c-5.244,0-9.511-4.267-9.511-9.511v-7.257h24.278v7.257
                C284.207,465.569,279.94,469.836,274.697,469.836z M263.928,457.068v3.257c0,3.039,2.472,5.511,5.511,5.511h5.258
                c3.038,0,5.51-2.472,5.51-5.511v-3.257H263.928z"/>
            </g>
          </g>
          <g>
            <g>
              <rect x="361.404" y="457.523" fill="#282D33" width="4" height="2"/>
            </g>
            <g>
              <path fill="#282D33" d="M365.404,575.773h-4v-3.875h4V575.773z M365.404,564.148h-4v-3.875h4V564.148z M365.404,552.523h-4
                v-3.875h4V552.523z M365.404,540.898h-4v-3.875h4V540.898z M365.404,529.273h-4v-3.875h4V529.273z M365.404,517.648h-4v-3.875h4
                V517.648z M365.404,506.023h-4v-3.875h4V506.023z M365.404,494.398h-4v-3.875h4V494.398z M365.404,482.773h-4v-3.875h4V482.773z
                M365.404,471.148h-4v-3.875h4V471.148z"/>
            </g>
            <g>
              <rect x="361.404" y="583.523" fill="#282D33" width="4" height="2"/>
            </g>
          </g>
          <g>
            <rect x="396.404" y="505.523" fill="#282D33" width="4" height="94.476"/>
          </g>
          <g>
            <g>
              <path fill="#282D33" d="M478.731,350.111h-4v-10.23c-3.078-2.662-4.823-6.455-4.823-10.559v-27.207h4v27.207
                c0,3.175,1.465,6.09,4.021,8l0.803,0.6V350.111z"/>
            </g>
            <g>
              <path fill="#282D33" d="M501.048,350.111h-4v-10.719l1.527-0.371c1.657-0.403,3.19-1.229,4.438-2.39l12.37-14.393v-9.122
                l-0.106-0.033l0.106-1.543v-6.438h-0.27c-2.658,0-4.82,2.161-4.82,4.816v9.223l-0.093,2.281l-3.999-0.082l-0.023-19.225l4-0.004
                l0.001,0.503c1.409-0.954,3.107-1.513,4.935-1.513h4.156l0.012,0.167l0.102,0.009l0.025,1.709l0.109,1.884l-0.135,0.017v5.3
                l0.066,0.021l-0.066,1.622v11.891l-13.546,15.745c-1.379,1.309-3.013,2.32-4.789,2.976V350.111z"/>
            </g>
            <g>
              <path fill="#282D33" d="M482.853,302.273h-4v-16.197c0-1.363-1.11-2.473-2.475-2.473c-1.362,0-2.471,1.109-2.471,2.473v16.197
                h-4v-16.197c0-3.568,2.903-6.473,6.471-6.473c3.57,0,6.475,2.904,6.475,6.473V302.273z"/>
            </g>
            <g>
              <path fill="#282D33" d="M491.955,302.273h-4v-25.91c0-1.363-1.11-2.473-2.475-2.473c-1.362,0-2.471,1.109-2.471,2.473v25.91h-4
                v-25.91c0-3.569,2.903-6.473,6.471-6.473c3.57,0,6.475,2.903,6.475,6.473V302.273z"/>
            </g>
            <g>
              <path fill="#282D33" d="M501.055,302.273h-4v-30.49c0-1.363-1.109-2.473-2.472-2.473c-1.365,0-2.475,1.109-2.475,2.473v30.49h-4
                v-30.49c0-3.569,2.905-6.473,6.475-6.473c3.568,0,6.472,2.904,6.472,6.473V302.273z"/>
            </g>
            <g>
              <path fill="#282D33" d="M510.156,302.273h-4v-22.75c0-1.363-1.11-2.473-2.475-2.473c-1.363,0-2.471,1.109-2.471,2.473v22.75h-4
                v-22.75c0-3.568,2.903-6.473,6.471-6.473c3.569,0,6.475,2.904,6.475,6.473V302.273z"/>
            </g>
          </g>
          <g>
            <rect x="561.166" y="438.095" fill="#282D33" width="109.572" height="4"/>
          </g>
          <g>
            <rect x="561.166" y="461.428" fill="#282D33" width="109.572" height="4"/>
          </g>
          <g>
            <rect x="613.953" y="431.974" fill="#282D33" width="4" height="168.024"/>
          </g>
          <g>
            <path fill="#5EB4E7" d="M659.002,599.999V465.535c0-8.772,9.479-15.883,21.174-15.883h92.92c11.693,0,21.168,7.11,21.168,15.883
              v134.464H659.002z"/>
            <g>
              <g>
                <g>
                  <path fill="#5EB4E7" d="M807.314,593.199h-15.953v-40h15.953c17.55,0,31.828-14.271,31.828-31.814
                    c0-17.517-14.278-31.768-31.828-31.768h-63.168v-40h63.168c39.605,0,71.828,32.195,71.828,71.768
                    C879.143,560.983,846.92,593.199,807.314,593.199z"/>
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <path fill="#282D33" d="M805.145,555.383h-14.172v-4h14.172c16.541,0,29.998-13.452,29.998-29.988
                    c0-16.521-13.457-29.961-29.998-29.961h-38.998v-4h38.998c18.746,0,33.998,15.234,33.998,33.961
                    C839.143,540.136,823.891,555.383,805.145,555.383z"/>
                </g>
              </g>
            </g>
            <g>
              <g>
                <path fill="#FFFFFF" d="M677.562,379.86v18.279c0,2.969,2.379,5.376,5.312,5.376s5.312-2.407,5.312-5.376V379.86H677.562z"/>
                <path fill="#282D33" d="M682.875,405.516c-4.032,0-7.312-3.309-7.312-7.376V377.86h14.625v20.279
                  C690.188,402.207,686.906,405.516,682.875,405.516z M679.562,381.86v16.279c0,1.861,1.486,3.376,3.312,3.376
                  s3.312-1.515,3.312-3.376V381.86H679.562z"/>
              </g>
              <g>
                <path fill="#FFFFFF" d="M765.746,379.86v18.279c0,2.969,2.377,5.376,5.311,5.376s5.312-2.407,5.312-5.376V379.86H765.746z"/>
                <path fill="#282D33" d="M771.057,405.516c-4.031,0-7.311-3.309-7.311-7.376V377.86h14.623v20.279
                  C778.369,402.207,775.088,405.516,771.057,405.516z M767.746,381.86v16.279c0,1.861,1.484,3.376,3.311,3.376
                  s3.312-1.515,3.312-3.376V381.86H767.746z"/>
              </g>
              <g>
                <polygon fill="#FFFFFF" points="753.967,464.936 753.967,451.245 699.242,451.245 699.242,464.936 726.604,480.924 					"/>
                <path fill="#282D33" d="M726.604,483.24l-29.361-17.156v-16.839h58.725v16.839L726.604,483.24z M701.242,463.787l25.361,14.82
                  l25.363-14.82v-10.542h-50.725V463.787z"/>
              </g>
              <g>
                <polygon fill="#FFFFFF" points="742.809,458.417 726.604,467.888 710.398,458.417 710.398,422.602 726.604,422.602 
                  742.809,422.602 					"/>
                <path fill="#282D33" d="M726.604,470.204l-18.205-10.64v-38.963h36.41v38.963L726.604,470.204z M712.398,457.27l14.205,8.302
                  l14.205-8.302v-32.668h-28.41V457.27z"/>
              </g>
              <g>
                <path fill="#FFFFFF" d="M765.078,355.602c0-12.583-10.078-22.786-22.516-22.786h-32.271c-12.434,0-22.516,10.203-22.516,22.786
                  l-0.068,64.051l21.336,24.828h34.768l21.336-24.828L765.078,355.602z"/>
                <path fill="#282D33" d="M744.729,446.48h-36.604l-22.419-26.088l0.069-64.793c0-13.665,10.998-24.784,24.516-24.784h32.271
                  c13.518,0,24.516,11.119,24.516,24.786l0.068,64.791L744.729,446.48z M709.962,442.48h32.93l20.254-23.568l-0.067-63.309
                  c0-11.463-9.204-20.788-20.516-20.788h-32.271c-11.312,0-20.516,9.325-20.516,20.786l-0.068,63.311L709.962,442.48z"/>
              </g>
              <g>
                <path fill="#5EB4E7" d="M726.355,402.978c-14.76,0-27.572,8.868-34.039,21.885l16.859,19.618h34.768l16.594-19.313
                  C754.121,411.988,741.227,402.978,726.355,402.978z"/>
                <path fill="#282D33" d="M744.861,446.48h-36.604l-18.322-21.32l0.591-1.188c7.046-14.184,20.775-22.995,35.83-22.995
                  c15.191,0,28.979,8.934,35.979,23.313l0.575,1.182L744.861,446.48z M710.094,442.48h32.931l15.124-17.603
                  c-6.487-12.31-18.557-19.9-31.793-19.9c-13.108,0-25.121,7.478-31.644,19.604L710.094,442.48z"/>
              </g>
              <g>
                <path fill="#FFFFFF" d="M717.188,419.26c-0.035,0.323-0.058,0.649-0.058,0.98c0,4.875,3.901,8.821,8.716,8.821h1.159
                  c4.815,0,8.718-3.946,8.718-8.821c0-0.331-0.02-0.657-0.055-0.98H717.188z"/>
                <path fill="#282D33" d="M727.005,431.062h-1.159c-5.909,0-10.716-4.854-10.716-10.821c0-0.404,0.026-0.802,0.069-1.197
                  l0.193-1.783h22.069l0.194,1.783c0.043,0.396,0.066,0.793,0.066,1.197C737.723,426.207,732.914,431.062,727.005,431.062z
                  M719.205,421.26c0.485,3.279,3.278,5.802,6.641,5.802h1.159c3.362,0,6.156-2.522,6.643-5.802H719.205z"/>
              </g>
              <g>
                <g>
                  <rect x="702.255" y="378.396" fill="#282D33" width="4.737" height="4"/>
                </g>
                <g>
                  <rect x="742.002" y="378.396" fill="#282D33" width="4.736" height="4"/>
                </g>
              </g>
              <g>
                <polygon fill="#282D33" points="735.865,395.3 716.99,395.3 716.99,372.181 701.467,372.181 701.467,368.181 720.99,368.181 
                  720.99,391.3 731.865,391.3 731.865,370.181 735.865,370.181 					"/>
              </g>
            </g>
            <g>
              <rect x="685.738" y="505.523" fill="#282D33" width="4" height="94.476"/>
            </g>
          </g>
          <path fill="#F8C44F" d="M58.487,350.062l-11.237,4.679l12.476,29.985l29.982-12.478l-4.84-11.643
            c-0.056-0.056-0.108-0.103-0.169-0.146c-0.006-0.005-0.012-0.011-0.023-0.016c-0.056-0.041-0.115-0.082-0.176-0.112
            c-0.004-0.005-0.01-0.007-0.014-0.008c-0.114-0.063-0.235-0.105-0.358-0.135c-0.024-0.006-0.043-0.014-0.062-0.019
            c-0.043-0.009-0.086-0.012-0.131-0.017c-0.047-0.005-0.094-0.013-0.141-0.013c-0.04-0.003-0.076-0.001-0.113,0.004
            c-0.057,0.001-0.115,0.007-0.174,0.017c-0.03,0.005-0.059,0.008-0.09,0.015c-0.087,0.019-0.179,0.045-0.265,0.08
            c-0.465,0.194-0.777,0.581-0.906,1.029c-0.412,1.536-1.506,2.872-3.09,3.533c-2.715,1.13-5.832-0.158-6.963-2.871
            c-1.128-2.715,0.158-5.833,2.872-6.963c1.584-0.658,3.302-0.494,4.684,0.298c0.407,0.222,0.907,0.275,1.369,0.084
            c0.086-0.038,0.165-0.084,0.241-0.132c0.027-0.018,0.053-0.036,0.076-0.055c0.047-0.035,0.092-0.073,0.137-0.108
            c0.027-0.029,0.055-0.054,0.079-0.081c0.032-0.035,0.063-0.072,0.093-0.11c0.023-0.033,0.053-0.067,0.078-0.103
            c0.014-0.017,0.019-0.036,0.029-0.057c0.069-0.108,0.119-0.223,0.156-0.35c0.002-0.006,0.005-0.011,0.006-0.016
            c0.021-0.065,0.034-0.135,0.046-0.203c0.001-0.011,0.003-0.021,0.005-0.03c0.011-0.071,0.014-0.143,0.016-0.215l-4.847-11.646
            l-11.243,4.679c-0.762,0.225-1.591-0.147-1.902-0.9c-0.193-0.462-0.143-0.956,0.079-1.365c0.792-1.383,0.96-3.103,0.3-4.688
            c-1.13-2.714-4.248-3.999-6.961-2.868c-2.716,1.129-4.003,4.246-2.873,6.96c0.661,1.587,1.997,2.676,3.535,3.092
            c0.444,0.128,0.834,0.444,1.026,0.908C59.507,348.832,59.186,349.679,58.487,350.062z"/>
          <g>
            <g>
              <g>
                <rect x="759.279" y="510.75" fill="#282D33" width="4" height="6.734"/>
              </g>
              <g>
                
                  <rect x="767.265" y="513.383" transform="matrix(0.866 0.5 -0.5 0.866 361.4608 -315.4133)" fill="#282D33" width="4" height="6.732"/>
              </g>
              <g>
                
                  <rect x="772.865" y="519.655" transform="matrix(0.5001 0.866 -0.866 0.5001 840.2721 -409.5504)" fill="#282D33" width="4.001" height="6.733"/>
              </g>
              <g>
                <rect x="773.213" y="529.256" fill="#282D33" width="6.734" height="4"/>
              </g>
              <g>
                
                  <rect x="770.581" y="537.241" transform="matrix(0.8659 0.5003 -0.5003 0.8659 373.5933 -314.8623)" fill="#282D33" width="6.734" height="4.001"/>
              </g>
              <g>
                
                  <rect x="743.854" y="534.285" transform="matrix(0.4997 0.8662 -0.8662 0.4997 838.8252 -377.0751)" fill="#282D33" width="4" height="6.732"/>
              </g>
              <g>
                <rect x="740.773" y="527.42" fill="#282D33" width="6.734" height="4"/>
              </g>
              <g>
                
                  <rect x="743.406" y="519.432" transform="matrix(0.8659 0.5001 -0.5001 0.8659 360.8909 -303.5864)" fill="#282D33" width="6.733" height="4"/>
              </g>
              <g>
                
                  <rect x="749.678" y="513.831" transform="matrix(0.4996 0.8663 -0.8663 0.4996 823.7128 -394.2043)" fill="#282D33" width="6.735" height="4.001"/>
              </g>
            </g>
            <g>
              <path fill="#FFFFFF" d="M788.893,581.896h-13.527c-1.994,2.672-5.166,4.406-8.752,4.406h-7.305h-10.453h-0.537l-0.002-0.023
                h-4.354c-2.738,0-4.959-2.221-4.959-4.957c0-2.74,2.221-4.959,4.959-4.959h-3.967c-2.741,0-4.959-2.223-4.959-4.959
                c0-2.738,2.218-4.959,4.959-4.959h-1.323c-2.737,0-4.956-2.221-4.956-4.959c0-2.736,2.219-4.957,4.956-4.957h-2.644
                c-2.738,0-4.957-2.221-4.957-4.959s2.219-4.957,4.957-4.957h13.885c0.285,0,0.562,0.027,0.834,0.074h5.557l-1.746-6.164v-6.988
                c0-3.43,2.78-6.211,6.215-6.211h2.065c0.084,0,0.171,0.01,0.259,0.014v9.506l2.406,3.393l9.029,9.863
                c1.291,1.361,2.23,3.049,2.689,4.93h11.67V581.896z"/>
              <path fill="#282D33" d="M766.613,588.303l-22.65-0.023c-3.837,0-6.959-3.121-6.959-6.957c0-1.193,0.303-2.318,0.834-3.301
                c-2.784-0.91-4.801-3.533-4.801-6.617c0-1.631,0.563-3.133,1.507-4.32c-1.714-1.269-2.827-3.306-2.827-5.598
                c0-1.378,0.402-2.664,1.097-3.746c-2.222-1.163-3.741-3.492-3.741-6.17c0-3.836,3.12-6.957,6.957-6.957h13.885
                c0.328,0,0.654,0.024,0.992,0.074h2.753l-1.101-3.887v-7.266c0-4.527,3.686-8.211,8.215-8.211h2.065
                c0.098,0,0.197,0.006,0.299,0.012l1.859,0.132l0.101,1.87v8.868l1.966,2.771l8.944,9.771c1.162,1.226,2.077,2.688,2.683,4.28
                h10.202v4H775.65l-0.371-1.525c-0.367-1.507-1.129-2.9-2.197-4.027l-9.209-10.083l-2.775-3.912v-8.157h-0.324
                c-2.324,0-4.215,1.89-4.215,4.211v6.711l2.391,8.441l-8.54-0.029c-0.177-0.03-0.339-0.045-0.495-0.045h-13.885
                c-1.631,0-2.957,1.326-2.957,2.957c0,1.632,1.326,2.959,2.957,2.959h2.644v4c-1.63,0-2.956,1.326-2.956,2.957
                s1.326,2.959,2.956,2.959h1.323v4c-1.632,0-2.959,1.328-2.959,2.959s1.327,2.959,2.959,2.959h3.967v4
                c-1.631,0-2.959,1.328-2.959,2.959s1.328,2.957,2.959,2.957l22.65,0.023c2.835,0,5.44-1.312,7.148-3.602l0.6-0.805h14.531v4
                h-12.574C773.861,586.709,770.378,588.303,766.613,588.303z"/>
            </g>
          </g>
          <g>
            <g>
              <g>
                <rect x="817.174" y="410.612" fill="#282D33" width="18.039" height="4"/>
              </g>
              <g>
                <rect x="838.648" y="410.612" fill="#282D33" width="6.014" height="4"/>
              </g>
            </g>
            <g>
              <rect x="833.432" y="401.563" fill="#282D33" width="27.633" height="4"/>
            </g>
          </g>
          <g>
            <polygon fill="#F8C44F" points="691.918,280.327 695.223,287.021 702.609,288.095 697.264,293.306 698.525,300.665 
              691.918,297.19 685.311,300.665 686.572,293.306 681.225,288.095 688.613,287.021 			"/>
            <path fill="#F8C44F" d="M682.655,304.321l1.768-10.317l-7.496-7.306l10.358-1.506l4.632-9.383l4.633,9.383l10.357,1.506
              l-7.494,7.305l1.769,10.317l-9.264-4.871L682.655,304.321z M691.917,294.931l3.953,2.078l-0.755-4.401l3.197-3.117l-4.418-0.642
              l-1.977-4.003l-1.977,4.003l-4.418,0.642l3.197,3.117l-0.754,4.401L691.917,294.931z"/>
          </g>
          <g>
            <g>
              <g>
                <rect x="208.337" y="284.834" fill="#282D33" width="21.849" height="4"/>
              </g>
              <g>
                <rect x="216.661" y="276.786" fill="#282D33" width="21.848" height="4"/>
              </g>
              <g>
                <rect x="205.218" y="276.786" fill="#282D33" width="7.28" height="4"/>
              </g>
              <g>
                <rect x="196.894" y="284.834" fill="#282D33" width="7.282" height="4"/>
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <rect x="885.189" y="366.238" fill="#282D33" width="19.848" height="4"/>
              </g>
              <g>
                <rect x="871.746" y="366.238" fill="#282D33" width="7.283" height="4"/>
              </g>
            </g>
            <g>
              <g>
                <rect x="885.189" y="353.862" fill="#282D33" width="19.848" height="4"/>
              </g>
              <g>
                <rect x="871.746" y="353.862" fill="#282D33" width="7.283" height="4"/>
              </g>
            </g>
          </g>
          <g>
            <path fill="#282D33" d="M912.881,500.859c-2.817,0-4.086-2.338-5.013-4.046c-0.472-0.87-1.06-1.954-1.497-1.954
              c-0.437,0-1.024,1.084-1.496,1.954c-0.927,1.708-2.195,4.046-5.012,4.046c-2.819,0-4.089-2.339-5.016-4.046
              c-0.498-0.916-1.061-1.954-1.498-1.954s-1.024,1.083-1.496,1.954c-0.926,1.707-2.195,4.046-5.012,4.046
              c-2.819,0-4.089-2.338-5.016-4.046c-0.498-0.916-1.062-1.954-1.5-1.954c-0.439,0-1.003,1.038-1.501,1.954
              c-0.927,1.708-2.197,4.046-5.017,4.046c-2.82,0-4.09-2.338-5.018-4.045c-0.498-0.916-1.062-1.955-1.502-1.955v-4
              c2.818,0,4.089,2.338,5.017,4.045c0.498,0.916,1.062,1.955,1.503,1.955c0.439,0,1.004-1.039,1.502-1.955
              c0.928-1.707,2.197-4.045,5.016-4.045s4.088,2.339,5.015,4.045c0.497,0.916,1.062,1.955,1.501,1.955
              c0.436,0,1.023-1.083,1.495-1.953c0.927-1.708,2.194-4.047,5.013-4.047c2.817,0,4.086,2.338,5.013,4.045
              c0.497,0.916,1.062,1.955,1.501,1.955c0.436,0,1.023-1.083,1.495-1.953c0.927-1.708,2.195-4.047,5.013-4.047
              c2.818,0,4.087,2.339,5.014,4.047c0.472,0.87,1.06,1.953,1.496,1.953V500.859z"/>
          </g>
          <g>
            <path fill="#F8C44F" d="M453.894,274.711c0,1.018-0.824,1.842-1.843,1.842h-3.569v6.914l-8.747-6.914h-11.646
              c-1.02,0-1.843-0.824-1.843-1.842v-16.107c0-1.018,0.823-1.846,1.843-1.846h23.963c1.019,0,1.843,0.828,1.843,1.846V274.711z"/>
          </g>
          <g>
            <g>
              <path fill="#282D33" d="M414.324,297.931v-9.043h-1.57c-2.119,0-3.842-1.724-3.842-3.843v-16.107
                c0-2.12,1.723-3.845,3.842-3.845h23.964c2.119,0,3.843,1.725,3.843,3.845v16.107c0,2.119-1.724,3.843-3.843,3.843h-10.951
                L414.324,297.931z M412.912,284.888h5.412v4.784l6.053-4.784h12.183v-15.795h-23.648V284.888z"/>
            </g>
          </g>
          <g>
            <g>
              <path fill="#282D33" d="M797.377,318.534c-3.936,0-7.137-3.201-7.137-7.136c0-3.934,3.201-7.135,7.137-7.135
                c3.934,0,7.135,3.201,7.135,7.135C804.512,315.333,801.311,318.534,797.377,318.534z M797.377,308.264
                c-1.73,0-3.137,1.406-3.137,3.135s1.406,3.136,3.137,3.136c1.729,0,3.135-1.407,3.135-3.136S799.105,308.264,797.377,308.264z"
                />
            </g>
            <g>
              <g>
                <path fill="#282D33" d="M825.803,303.279c-3.934,0-7.135-3.201-7.135-7.135c0-3.935,3.201-7.135,7.135-7.135
                  s7.135,3.2,7.135,7.135C832.938,300.079,829.736,303.279,825.803,303.279z M825.803,293.01c-1.729,0-3.135,1.406-3.135,3.135
                  s1.406,3.135,3.135,3.135s3.135-1.406,3.135-3.135S827.531,293.01,825.803,293.01z"/>
              </g>
              <g>
                <path fill="#282D33" d="M825.803,333.791c-3.934,0-7.135-3.201-7.135-7.137c0-3.933,3.201-7.133,7.135-7.133
                  s7.135,3.2,7.135,7.133C832.938,330.59,829.736,333.791,825.803,333.791z M825.803,323.521c-1.729,0-3.135,1.405-3.135,3.133
                  c0,1.729,1.406,3.137,3.135,3.137s3.135-1.407,3.135-3.137C828.938,324.927,827.531,323.521,825.803,323.521z"/>
              </g>
            </g>
            <g>
              <g>
                
                  <rect x="800.879" y="316.678" transform="matrix(0.866 0.5 -0.5 0.866 268.0791 -363.0326)" fill="#282D33" width="21.049" height="4.001"/>
              </g>
              <g>
                
                  <rect x="809.404" y="293.592" transform="matrix(0.5001 0.8659 -0.8659 0.5001 668.9448 -550.6161)" fill="#282D33" width="3.999" height="21.05"/>
              </g>
            </g>
          </g>
          <path fill="#5EB4E7" d="M295.554,308.503h-2.311c-0.537,0-1.114-0.414-1.28-0.923l-0.433-1.043
            c-0.237-0.479-0.125-1.182,0.25-1.56l1.633-1.633c0.382-0.376,0.382-0.997,0-1.374l-2.52-2.527c-0.381-0.377-1-0.377-1.378,0
            l-1.633,1.632c-0.382,0.38-1.081,0.494-1.558,0.257l-1.044-0.432c-0.509-0.17-0.923-0.747-0.923-1.283v-2.308
            c0-0.536-0.438-0.976-0.975-0.976h-3.568c-0.538,0-0.977,0.439-0.977,0.976v2.308c0,0.536-0.414,1.113-0.921,1.283l-1.045,0.432
            c-0.476,0.237-1.181,0.123-1.558-0.257l-1.631-1.632c-0.379-0.377-0.997-0.377-1.375,0l-2.524,2.527
            c-0.382,0.377-0.382,0.998,0,1.374l1.633,1.633c0.374,0.378,0.488,1.08,0.251,1.56l-0.429,1.043
            c-0.17,0.509-0.748,0.923-1.281,0.923h-2.312c-0.534,0-0.974,0.438-0.974,0.975v3.571c0,0.531,0.44,0.971,0.974,0.971h2.312
            c0.533,0,1.111,0.414,1.281,0.922l0.429,1.045c0.237,0.48,0.123,1.182-0.251,1.56l-1.633,1.632c-0.382,0.377-0.382,0.998,0,1.375
            l2.524,2.527c0.378,0.375,0.996,0.375,1.375,0l1.631-1.633c0.377-0.379,1.082-0.494,1.558-0.254l1.045,0.43
            c0.508,0.173,0.921,0.746,0.921,1.283v2.309c0,0.535,0.439,0.973,0.977,0.973h3.568c0.536,0,0.975-0.438,0.975-0.973v-2.309
            c0-0.537,0.414-1.11,0.923-1.283l1.044-0.43c0.477-0.24,1.176-0.125,1.558,0.254l1.633,1.633c0.378,0.375,0.997,0.375,1.378,0
            l2.52-2.527c0.382-0.377,0.382-0.998,0-1.375l-1.633-1.632c-0.375-0.378-0.487-1.079-0.25-1.56l0.433-1.045
            c0.167-0.508,0.743-0.922,1.28-0.922h2.311c0.535,0,0.972-0.439,0.972-0.971v-3.571
            C296.526,308.941,296.089,308.503,295.554,308.503z M281.599,315.967c-2.598,0-4.703-2.109-4.703-4.706
            c0-2.599,2.105-4.704,4.703-4.704s4.706,2.105,4.706,4.704C286.305,313.857,284.197,315.967,281.599,315.967z"/>
          <g>
            <g>
              <path fill="#282D33" d="M919.741,363.716c-1.689,0-2.915-1.334-2.915-3.173v-15.498c0-1.842,1.226-3.178,2.914-3.178
                c0.585,0,1.168,0.166,1.732,0.492l13.422,7.752c1.065,0.613,1.68,1.59,1.681,2.678c0.001,1.089-0.611,2.068-1.68,2.686
                l-13.423,7.75C920.907,363.551,920.325,363.716,919.741,363.716z M920.826,346.605v12.374l10.714-6.186L920.826,346.605z"/>
            </g>
          </g>
          <g>
            <g>
              <g>
                <rect x="111.546" y="424.516" fill="#282D33" width="29.156" height="4"/>
              </g>
              <g>
                <rect x="146.255" y="424.516" fill="#282D33" width="9.719" height="4"/>
              </g>
            </g>
            <g>
              <rect x="161.056" y="424.516" fill="#282D33" width="5.076" height="4"/>
            </g>
          </g>
          <g>
            <g>
              <rect x="342.746" y="256.524" fill="#282D33" width="5.767" height="4"/>
            </g>
            <g>
              
                <rect x="338.66" y="247.713" transform="matrix(0.866 0.5 -0.5 0.866 170.9494 -136.7612)" fill="#282D33" width="4" height="5.766"/>
            </g>
            <g>
              
                <rect x="328.427" y="248.937" transform="matrix(0.4999 0.8661 -0.8661 0.4999 383.0241 -161.4483)" fill="#282D33" width="5.767" height="3.999"/>
            </g>
            <g>
              <rect x="324.044" y="257.204" fill="#282D33" width="5.769" height="4"/>
            </g>
            <g>
              
                <rect x="329.897" y="264.248" transform="matrix(0.866 0.5 -0.5 0.866 178.0428 -130.1642)" fill="#282D33" width="4" height="5.766"/>
            </g>
            <g>
              
                <rect x="338.363" y="264.792" transform="matrix(0.4999 0.8661 -0.8661 0.4999 401.7177 -162.1262)" fill="#282D33" width="5.769" height="4"/>
            </g>
          </g>
          <g>
            <g>
              <rect x="899.123" y="428.844" fill="#282D33" width="4" height="6.317"/>
            </g>
            <g>
              
                <rect x="890.402" y="434.368" transform="matrix(0.8659 0.5001 -0.5001 0.8659 338.0381 -388.4118)" fill="#282D33" width="6.317" height="4.001"/>
            </g>
            <g>
              
                <rect x="891.561" y="441.944" transform="matrix(0.5001 0.8659 -0.8659 0.5001 832.0987 -551.2861)" fill="#282D33" width="3.999" height="6.315"/>
            </g>
            <g>
              <rect x="899.123" y="446.309" fill="#282D33" width="4" height="6.316"/>
            </g>
            <g>
              
                <rect x="905.526" y="443.102" transform="matrix(0.866 0.5 -0.5 0.866 344.2559 -394.6861)" fill="#282D33" width="6.317" height="3.999"/>
            </g>
            <g>
              
                <rect x="906.685" y="433.21" transform="matrix(0.5002 0.8659 -0.8659 0.5002 832.0068 -568.7416)" fill="#282D33" width="4" height="6.318"/>
            </g>
          </g>
          <g>
            <path fill="#F8C44F" d="M955.164,455.046c-2.115,0.028-3.951,1.214-4.949,2.968c-1.045-1.729-2.908-2.871-5.025-2.848
              c-3.219,0.013-5.793,2.873-5.746,6.391c0.008,0.684,0.117,1.334,0.311,1.93c0.451,1.983,3.715,4.88,6.734,7.228
              c1.465,1.133,3.934,2.815,3.934,2.825c1.043-0.662,2.424-1.727,3.854-2.904c2.951-2.417,6.133-5.412,6.533-7.41
              c0.18-0.603,0.271-1.256,0.264-1.939C961.029,457.762,958.381,454.979,955.164,455.046z"/>
            <path fill="#F8C44F" d="M948.422,477.176v-2.594c-0.87-0.607-2.205-1.549-3.157-2.286c-5.862-4.56-7.083-6.821-7.44-8.275
              c-0.244-0.784-0.372-1.605-0.381-2.444c-0.03-2.305,0.847-4.54,2.406-6.126c1.439-1.466,3.333-2.276,5.332-2.284l0,0
              c1.882,0,3.623,0.641,5.001,1.782c1.37-1.184,3.122-1.878,4.955-1.902l0.142-0.001c4.241,0,7.737,3.686,7.792,8.216
              c0.011,0.841-0.097,1.665-0.321,2.45c-0.303,1.374-1.432,3.741-7.207,8.472c-1.668,1.374-3.032,2.4-4.051,3.046L948.422,477.176z
              M945.253,457.166c-0.979,0.003-1.865,0.39-2.551,1.088c-0.816,0.83-1.275,2.024-1.259,3.276c0.005,0.47,0.076,0.919,0.213,1.341
              l0.047,0.171c0.066,0.288,0.663,1.933,6.013,6.093c0.787,0.609,1.891,1.393,2.716,1.971c0.727-0.529,1.596-1.21,2.572-2.014
              c5.226-4.28,5.785-5.967,5.844-6.261l0.044-0.175c0.126-0.424,0.187-0.876,0.181-1.346c-0.029-2.353-1.73-4.266-3.793-4.266
              c-1.428,0.019-2.639,0.75-3.326,1.958l-1.685,2.962l-1.765-2.916C947.791,457.869,946.575,457.166,945.253,457.166z"/>
          </g>
          <g>
            <g>
              <rect x="96.789" y="529.169" fill="#282D33" width="19.383" height="4"/>
            </g>
            <g>
              <rect x="86.638" y="529.169" fill="#282D33" width="6.461" height="4"/>
            </g>
          </g>
          <g>
            <g>
              <rect x="35.082" y="518.161" fill="#282D33" width="2" height="4"/>
            </g>
            <g>
              <path fill="#282D33" d="M91.215,522.161h-3.866v-4h3.866V522.161z M83.482,522.161h-3.867v-4h3.867V522.161z M75.749,522.161
                h-3.867v-4h3.867V522.161z M68.016,522.161h-3.867v-4h3.867V522.161z M60.282,522.161h-3.867v-4h3.867V522.161z M52.549,522.161
                h-3.867v-4h3.867V522.161z M44.815,522.161h-3.867v-4h3.867V522.161z"/>
            </g>
            <g>
              <rect x="95.082" y="518.161" fill="#282D33" width="2" height="4"/>
            </g>
          </g>
          <polygon fill="#5EB4E7" points="176.273,566.007 166.513,571.644 156.751,577.278 156.751,566.007 156.751,554.736 
            166.513,560.37 		"/>
          <rect x="129.237" y="538.61" fill="#F8C44F" width="17.809" height="17.809"/>
          <g>
            <g>
              <rect x="165.501" y="542.198" fill="#282D33" width="4" height="4"/>
            </g>
            <g>
              <path fill="#282D33" d="M160.819,546.198h-4.683v-4h4.683V546.198z M151.453,546.198h-4.683v-4h4.683V546.198z"/>
            </g>
            <g>
              <rect x="138.088" y="542.198" fill="#282D33" width="4" height="4"/>
            </g>
            <g>
              <path fill="#282D33" d="M142.088,537.516h-4v-4.683h4V537.516z M142.088,528.15h-4v-4.684h4V528.15z"/>
            </g>
            <g>
              <rect x="138.088" y="514.784" fill="#282D33" width="4" height="4"/>
            </g>
            <g>
              <path fill="#282D33" d="M160.818,518.784h-4.682v-4h4.682V518.784z M151.453,518.784h-4.683v-4h4.683V518.784z"/>
            </g>
            <g>
              <rect x="165.501" y="514.784" fill="#282D33" width="4" height="4"/>
            </g>
            <g>
              <path fill="#282D33" d="M169.501,537.516h-4v-4.683h4V537.516z M169.501,528.149h-4v-4.683h4V528.149z"/>
            </g>
          </g>
          <circle fill="#F8C44F" cx="225.749" cy="329.019" r="3.75"/>
          <g>
            <g>
              
                <rect x="741.654" y="230.253" transform="matrix(0.7072 0.707 -0.707 0.7072 384.9144 -456.5148)" fill="#282D33" width="4" height="12.472"/>
            </g>
            <g>
              
                <rect x="737.418" y="234.488" transform="matrix(0.7071 0.7071 -0.7071 0.7071 385.0336 -456.5768)" fill="#282D33" width="12.471" height="4"/>
            </g>
          </g>
          <g>
            <g>
              
                <rect x="109.843" y="459.185" transform="matrix(0.7072 0.707 -0.707 0.7072 361.7899 57.1903)" fill="#282D33" width="4" height="12.471"/>
            </g>
            <g>
              
                <rect x="105.608" y="463.421" transform="matrix(0.7071 0.7071 -0.7071 0.7071 361.8375 57.2195)" fill="#282D33" width="12.47" height="4"/>
            </g>
          </g>
          <g>
            <g>
              
                <rect x="600.233" y="240.507" transform="matrix(0.7072 0.707 -0.707 0.7072 350.2714 -353.733)" fill="#282D33" width="4" height="11.089"/>
            </g>
            <g>
              
                <rect x="596.689" y="244.051" transform="matrix(0.7072 0.707 -0.707 0.7072 350.2996 -353.7454)" fill="#282D33" width="11.089" height="4"/>
            </g>
          </g>
          <g>
            <rect x="756.375" y="271.969" fill="#282D33" width="4.058" height="4"/>
          </g>
          <g>
            <rect x="81.753" y="487.511" fill="#282D33" width="4.058" height="4"/>
          </g>
          <g>
            <g>
              <path fill="#FFFFFF" d="M808.645,252.972l-10.502,10.503L808.645,252.972z"/>
              
                <rect x="801.393" y="250.797" transform="matrix(0.7072 0.707 -0.707 0.7072 417.7703 -492.3867)" fill="#282D33" width="4" height="14.852"/>
            </g>
            <g>
              <path fill="#FFFFFF" d="M808.646,263.475l-10.504-10.503L808.646,263.475z"/>
              
                <rect x="795.968" y="256.223" transform="matrix(0.7071 0.7071 -0.7071 0.7071 417.9002 -492.4539)" fill="#282D33" width="14.853" height="4"/>
            </g>
          </g>
          <g>
            <g>
              <path fill="#282D33" d="M926.212,566.406c-2.589,0-4.445-1.318-4.843-3.441c-0.63-3.365,2.803-6.955,6.352-8.624
                c1.571-0.74,3.154-1.133,4.577-1.133c2.588,0,4.444,1.317,4.845,3.438c0.625,3.369-2.807,6.957-6.353,8.627
                C929.217,566.015,927.633,566.406,926.212,566.406z M932.298,557.208c-0.825,0-1.845,0.267-2.873,0.751
                c-2.808,1.321-4.266,3.515-4.124,4.27c0.035,0.036,0.312,0.178,0.911,0.178c0.824,0,1.845-0.268,2.874-0.752
                c2.806-1.32,4.266-3.518,4.125-4.272C933.176,557.351,932.898,557.208,932.298,557.208z"/>
            </g>
            <g>
              <polygon fill="#282D33" points="937.215,557.008 933.215,557.008 933.217,522.227 945.967,527.243 945.967,536.581 
                941.967,536.581 941.967,529.968 937.217,528.099 				"/>
            </g>
            <g>
              <rect x="905.367" y="522.75" fill="#282D33" width="22.594" height="4"/>
            </g>
            <g>
              <rect x="905.367" y="544.129" fill="#282D33" width="20.875" height="4"/>
            </g>
            <g>
              <rect x="905.367" y="554.821" fill="#282D33" width="11.115" height="4"/>
            </g>
            <g>
              <rect x="905.367" y="533.441" fill="#282D33" width="22.594" height="4"/>
            </g>
          </g>
          
            <rect x="266.071" y="542.523" transform="matrix(0.7071 0.7071 -0.7071 0.7071 469.54 -32.5576)" fill="#F8C44F" width="16.001" height="16.001"/>
          <g>
            <path fill="#282D33" d="M286.738,564.667l-14.143-14.144l14.143-14.143l14.142,14.143L286.738,564.667z M278.251,550.523
              l8.487,8.486l8.485-8.486l-8.485-8.486L278.251,550.523z"/>
          </g>
          <g>
            <path fill="#282D33" d="M262.072,564.667l-14.146-14.144l14.146-14.143l14.14,14.143L262.072,564.667z M253.583,550.523
              l8.488,8.486l8.485-8.486l-8.485-8.486L253.583,550.523z"/>
          </g>
        </g>
      </g>
      </svg>
    </Box>
  );
}
