import { CURRENCY } from 'src/constants';

// const convertCurrency = (amount, from, to) => {
//   const convertedAmount = (amount * CURRENCY.RATES[to]) / CURRENCY.RATES[from];
//   return convertedAmount;
// };

export const getDetectedCurrency = (amount) => {
  try {
    const _amount = amount?.toUpperCase();
    switch (true) {
      case CURRENCY.CURRENCY_EXPRESSIONS.USD.some((el) => _amount?.includes(el)):
        return 'USD';
      case CURRENCY.CURRENCY_EXPRESSIONS.KRW.some((el) => _amount?.includes(el)):
        return 'KRW';
      default:
        return 'KRW';
    }
  } catch (error) {
    console.error(error);
    return 'KRW';
  }
};

export const getNumbers = (amount) => {
  try {
    if (!amount) return 0;
    // get numbers and leave out decimal point
    if (amount.includes('-') || Number(amount) < 0) return -1 * Number(amount.replace(/[^0-9.]/g, ''));
    return Number(amount.replace(/[^0-9.]/g, ''));
  } catch (error) {
    console.error(error);
    return 0;
  }
};

/**
 *
 * @param {Array<Object>} objArr
 * @param {string} key
 * @returns {number} total sum of the key's value
 * @example
 * const objArr = [
 * { amount: "1000" },
 * { amount: "2000" },
 * { amount: "3000" },
 * { amount: "4000" },
 * ];
 * const key = "amount";
 * const total = getTotalAmount(objArr, key);
 * console.log(total); // 1000 + 2000 + 3000 + 4000 = 10000
 */
export const sumObj = (objArr, key) => objArr.reduce((a, b) => a + Number(getNumbers(b[key] || 0)), 0);

const isCurrencySignContained = (amount) => {
  const _amount = amount?.toUpperCase();
  if (_amount) {
    return (
      CURRENCY.CURRENCY_EXPRESSIONS.USD.some((el) => _amount.includes(el)) ||
      CURRENCY.CURRENCY_EXPRESSIONS.KRW.some((el) => _amount.includes(el))
    );
  } else {
    return false
  }
};

/**
 *
 * @param {string} amount
 * @param {"USD" | "KRW"} to
 * @returns {string} converted amount
 */
export const getConvertedAmount = (amount, to = 'USD') => {
  if (typeof amount === 'number') amount = String(amount);

  const from = getDetectedCurrency(amount);
  if (from === to) return isCurrencySignContained(amount) ? amount : `${CURRENCY.SYMBOL[to]}${amount}`;
  let convertedAmount = getNumbers(amount);
  // const convertedAmount = convertCurrency(getNumbers(amount), from, to);
  // delete decimal places until the last one and replace
  // let decimalProcessed = convertedAmount;
  // let decimalProcessed = convertedAmount.toFixed(1).replace(/\.0$/, "")
  if (convertedAmount > 1) convertedAmount = Math.floor(convertedAmount);

  return `${CURRENCY.SYMBOL[to] ?? ''}${convertedAmount ?? '0'}`;
};

export const currencyUtil = {
  getConvertedAmount,
  getDetectedCurrency,
  getNumbers,
  sumObj,
};
