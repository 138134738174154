import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------
Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box sx={{ width: 150, height: 150, marginTop: 9, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 700 600">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
          <path
            fill="url(#BG1)"
            d="M204.592,188.065c0-1.717,0.857-3.435,2.574-4.723
            c1.289-1.287,3.436-2.146,6.44-2.146c2.575,0,5.151,0.858,7.298,1.717c2.576,0.858,5.152,2.146,7.728,4.293l5.151-7.299
            c-3.006-2.575-6.01-4.292-9.016-5.15c-3.434-1.288-6.868-2.147-11.161-2.147c-2.575,0-5.151,0.43-7.298,1.289
            c-2.576,0.858-4.293,1.717-6.011,3.434c-1.717,1.288-3.005,3.006-3.863,5.151c-0.858,1.718-1.288,3.864-1.288,6.439
            c0,2.576,0.43,4.723,1.288,6.439c0.429,1.718,1.718,3.435,3.435,4.723c1.287,1.288,3.434,2.146,5.58,3.005
            c2.576,0.858,5.152,1.717,8.157,2.146c2.575,0.858,4.293,1.287,6.01,2.146c1.718,0.43,3.005,0.858,3.864,1.718
            c0.858,0.429,1.717,1.287,1.717,2.146c0.43,0.858,0.859,1.718,0.859,2.576c0,2.146-0.859,3.863-3.006,5.151
            c-1.717,1.288-3.863,1.718-6.869,1.718c-3.434,0-6.439-0.43-9.444-1.718c-2.575-1.288-5.151-3.005-8.156-5.151L193,220.692
            c3.005,3.005,6.869,5.151,10.732,6.439c3.863,1.717,7.728,2.146,12.021,2.146c3.005,0,5.581,0,8.156-0.858
            c2.147-0.858,4.294-1.718,6.011-3.436c1.717-1.287,3.005-3.004,4.293-5.151c0.858-1.717,1.287-4.292,1.287-6.868
            c0-2.146-0.429-4.293-1.287-6.01c-0.43-1.718-1.717-3.435-3.436-4.723c-1.287-1.288-3.004-2.146-5.15-3.006l-7.728-2.575
            c-3.005-0.43-5.151-1.288-6.868-1.717c-1.718-0.43-3.006-1.288-3.865-1.718c-0.857-0.858-1.717-1.717-2.146-2.575
            C205.021,190.211,204.592,188.923,204.592,188.065 M291.739,173.469h-40.783v55.379h41.213v-9.016H260.4v-14.596h27.904v-8.586
            H260.4v-14.167h31.339V173.469z M307.623,228.848h38.638v-9.016h-29.192v-46.363h-9.445V228.848z M360.426,228.848h38.637v-9.016
            h-29.191v-46.363h-9.445V228.848z M479.341,191.928c0-2.575-0.429-5.15-1.287-7.298c-1.288-2.575-2.576-4.292-4.293-6.01
            c-1.718-1.718-4.293-3.005-6.869-3.863c-2.575-0.858-5.151-1.288-8.586-1.288h-21.464v55.379h9.443v-18.03h10.732
            c3.435,0,6.01-0.43,9.016-0.858c2.575-0.858,4.723-2.146,6.868-3.864c2.146-1.288,3.435-3.435,4.723-6.01
            C478.913,197.939,479.341,195.363,479.341,191.928 M469.468,192.358c0,3.005-0.859,5.151-3.005,7.299
            c-2.146,1.717-5.152,2.575-9.016,2.575h-11.162v-19.748h11.162c3.863,0,6.439,0.858,8.586,2.146
            C468.609,186.348,469.468,188.923,469.468,192.358 M548.458,200.944c0-3.863-0.858-7.298-2.146-10.732
            c-1.288-3.434-3.435-6.439-6.01-9.015c-2.576-2.576-5.581-4.723-9.016-6.439c-3.435-1.288-7.298-2.147-11.591-2.147
            s-8.156,0.859-11.592,2.147c-3.434,1.717-6.439,3.863-9.015,6.439c-2.575,2.575-4.723,5.581-6.01,9.015
            c-1.718,3.435-2.146,7.298-2.146,11.162c0,3.863,0.429,7.298,2.146,10.731c1.287,3.436,3.005,6.439,5.581,9.016
            c2.575,2.576,5.58,4.723,9.015,6.44c3.864,1.287,7.728,2.146,12.021,2.146c3.864,0,8.156-0.858,11.591-2.575
            c3.435-1.288,6.439-3.435,9.016-6.011c2.575-2.576,4.722-5.58,6.01-9.016C547.6,208.671,548.458,204.807,548.458,200.944
             M538.156,201.374c0,2.575-0.43,5.15-1.288,7.298c-0.859,2.575-2.146,4.723-3.863,6.439c-1.718,1.717-3.436,3.005-6.011,4.293
            c-2.146,0.858-4.723,1.288-7.298,1.288s-5.152-0.43-7.728-1.718c-2.146-0.858-4.293-2.146-5.581-3.863
            c-1.717-2.146-3.005-3.864-3.863-6.439c-1.288-2.576-1.718-4.723-1.718-7.728c0-2.576,0.43-5.151,1.718-7.298
            c0.858-2.576,2.146-4.723,3.435-6.439c1.717-1.718,3.863-3.005,6.01-4.293c2.146-0.858,4.722-1.288,7.728-1.288
            c2.575,0,5.151,0.43,7.298,1.288c2.575,1.288,4.293,2.575,6.011,4.293c1.717,2.146,3.004,3.863,3.863,6.439
            C537.726,196.221,538.156,198.368,538.156,201.374 M564.772,228.848h9.444v-55.379h-9.444V228.848z M631.313,211.676l-29.192-38.207
            h-9.015v55.379h9.443v-39.495l30.48,39.495h8.157v-55.379h-9.874V211.676z M682.399,182.484H700v-9.015h-44.647v9.015h17.602v46.364
            h9.444V182.484z"
          />
          <path
            fill="url(#BG2)"
            d="M131.094,27.401l18.331,16.726l3.319,2.918
            c3.154-2.39,5.891-4.87,7.879-7.952l-2.9-2.827l-13.277-11.674c9.812-2.698,18.805-1.622,21.996,3.904
            c1.879,3.483,1.203,8.608-1.568,13.277l-0.183,0.84c-3.886,5.745-8.937,10.797-14.975,14.316l-1.532,0.986
            c-6.456,3.428-13.15,5.927-19.479,6.747l-0.092,0.42c-0.365,1.679-1.148,3.265-2.262,4.341l-8.572,9.12l-3.593-11.766
            c-0.565-1.44-0.62-3.209-0.256-4.887c0.912-4.195,4.997-6.822,8.772-6.002c1.679,0.365,3.174,1.569,4.25,2.683
            c3.211-0.621,6.001-1.332,9.303-2.371c1.44-0.565,2.972-1.551,3.993-2.207l2.464-1.223l-2.992-2.407l-13.187-12.093L78.273,54.139
            l-0.547,2.517l-10.67,49.081l-0.273,1.259l65.696,59.532l-2.735,12.585l-73.74-66.992c-1.167-0.692-1.313-2.042-1.04-3.302
            l13.315-61.246c0.365-1.678,0.966-2.426,2.408-2.992l56.632-18.001C128.76,26.014,130.018,26.289,131.094,27.401 M84.438,78.324
            l28.617,25.991l40.729,36.971l4.395,4.03c0.748,0.603,1.312,2.044,1.039,3.302l-14.5,66.7c-0.456,2.097-2.498,3.411-4.596,2.955
            l-100.68-21.888c-1.678-0.364-2.991-2.408-2.535-4.505l12.129-55.793l-9.539-8.664l-13.588,62.506
            c-1.732,7.97,3.192,15.63,11.163,17.362l101.1,21.979c7.97,1.732,15.631-3.192,17.363-11.162l14.592-67.12
            c1.094-5.034-0.419-10.196-4.487-13.716l-9.119-8.572l-3.647-3.429l10.852-49.921l0.548-2.517l-11.034-9.868
            c3.064-1.969,5.708-4.03,8.354-6.091l14.682,13.296l-0.183,0.839l-10.944,50.34l4.396,4.031l5.144,4.633l0.364-1.679l12.038-55.373
            c0.912-4.195,0.056-8.336-2.662-12.002l-14.683-13.297l0.693-1.167c3.976-6.164,6.22-14.463,2.043-21.521
            c-4.176-7.059-12.477-9.302-19.972-9.175c-4.47,0.347-9.539,1.441-14.281,3.046l-0.328-0.511l-1.496-1.203
            c-2.572-2.316-6.347-3.138-10.067-2.188L67.239,34.167c-5.162,1.514-8.682,5.582-9.777,10.616l-13.406,61.665
            c-1.186,5.454,0.419,10.196,4.396,14.136l9.21,8.152l3.648,3.43L49.364,187.12l85.998,18.696l9.85-45.307l2.188-10.068
            L81.703,90.909l0.912-4.195L84.438,78.324z"
          />
        </g>
      </svg>
    </Box>
  );
}
