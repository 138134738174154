import { setCookieAgreementStatus, getCookieAgreementStatus } from 'src/components/authentication/TempAuthService.js';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import i18next from 'i18next';
import CancelIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useBoolean } from 'src/hooks/useBoolean';

// ---------------------------------------------------------------------- Cookie Notification based off Privacy Laws ---------------------------------------------------------------------- //
export default function CookieDialog() {
    const [dialog, setDialog] = useState(useBoolean(true));

    // If the user pressed reject all to the cookie agreement, then they are forced to leave the page
    const handleRejectAll = () => {
      // Set flag in local storage indicating rejection
      setCookieAgreementStatus(false);
      // Redirect to google.com
      window.location.href = 'https://www.google.com';
    };

    // This is when you just close out of the dialogue without accepting or rejecting the cookie agreement
    const handleCancelAll = () =>  {
      setDialog(false);
    };

    // If you accepted the cookie agreement
    const handleAcceptAll = () => {
        // Set flag in local storage indicating rejection
        setCookieAgreementStatus(true);
        setDialog(false);
    };
  
    return (
      <>
        {dialog.value && (
          <Dialog open={dialog.value} onClose={dialog.onFalse} sx={{width: 1}}>
            <div style={{ position: "absolute", top: 11, right: 11, zIndex: 1 }}>
              <CancelIcon onClick={handleCancelAll} sx={{ cursor: 'pointer' }} />
            </div>
            <DialogTitle>{i18next.t('landing.cookieAlertDialog.dialogTitle')}</DialogTitle>
            <DialogContent sx={{ color: 'text.secondary', mt: 3 }}>
                {i18next.t('landing.cookieAlertDialog.dialogContent')}
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" color="error" onClick={handleRejectAll}>
                {i18next.t('landing.cookieAlertDialog.rejectAllButton')}
              </Button>
              <Button variant="contained" onClick={handleAcceptAll} autoFocus>
                {i18next.t('landing.cookieAlertDialog.acceptAllButton')}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </>
    );
  }
  
