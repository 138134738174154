import PropTypes from 'prop-types';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, List, Collapse, ListItemText, ListItemIcon, ListSubheader, ListItemButton } from '@mui/material';
import useSidebarDrop from 'src/hooks/useSidebarDrop';
const { getStoreType } = require("src/components/authentication/TempAuthService");

// ----------------------------------------------------------------------
const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    display: 'flex',
    cursor: 'pointer',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);

const ListItemStyle = styled(ListItemButton)(({ theme }) => ({
  ...theme.typography.body2,
  height: 44,
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  '&:before': {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main
  }
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 27,
  height: 27,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

// ----------------------------------------------------------------------
NavItem.propTypes = {
  isShow: PropTypes.bool,
  item: PropTypes.object
};

function NavItem({ item, isShow, isCollapse, isDropStatus, onDropStatus }) {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { title, path, icon, info, children, dropKey } = item;
  const isActiveRoot = path ? !!matchPath({ path, end: true }, pathname) : false;

  const storeType = getStoreType();
  const [open, setOpen] = useState(dropKey ? isDropStatus[dropKey] : false);

  const handleOpen = () => {
    onDropStatus(dropKey);
    setOpen(prev=>!prev);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' }
  };

  if (children) {
    return (
      <>
        <ListSubheaderStyle onClick={handleOpen}
          sx={{
            ...(isCollapse && {
              opacity: 0,
            }),
          }}
        >
          {isShow && (
            <>
              <ListItemText disableTypography primary={title} sx={{ userSelect: 'none' }} />
              {info && info}
              <Box
                component={Icon}
                icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
                sx={{ width: 16, height: 16, mr: 4 }}
              />
            </>
          )}
        </ListSubheaderStyle>
        {isShow && (
          <Collapse in={open} timeout="auto">
            <List component="div" disablePadding>
              {children.map((item) => {
                const { title, path, icon, exclude } = item;
                const isActiveSub = path ? !!matchPath({ path, end: false }, pathname) : false;

                // Check and apply exclude values from SidebarConfig
                if (typeof exclude === 'object' && exclude.includes(storeType)) {
                  return null;
                }

                return (
                  <ListItemStyle
                    key={title}
                    component={RouterLink}
                    to={path}
                    sx={{
                      ...(isActiveSub && activeRootStyle),
                      ...(isCollapse && {
                        justifyContent: 'center',
                      }),
                    }}
                  >
                    <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
                    <ListItemText disableTypography primary={title}
                      sx={{
                        ...(isCollapse && {
                          opacity: 0,
                        }),
                      }}
                    />
                  </ListItemStyle>
                );
              })}
            </List>
          </Collapse>
        )}
      </>
    );
  }
  
  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        mt: 4,
        ...(isActiveRoot && activeRootStyle),
        ...(isCollapse && {
          justifyContent: 'center',
        }),
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      {isShow && (
        <>
          <ListItemText disableTypography primary={title}
            sx={{
              ...(isCollapse && {
                opacity: 0,
              }),
            }}
          />
            {info && info}
        </>
      )}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  isShow: PropTypes.bool,
  navConfig: PropTypes.array
};

export default function NavSection({ navConfig, isShow=true, isCollapse=false, ...other }) {

  const { isDropStatus, onDropStatus } = useSidebarDrop();

  return (
    <Box {...other}>
      {navConfig.map((list) => {
        const { subheader, items } = list;
        return (
          <List key={subheader} disablePadding>
            {items.map((item) => (
              <NavItem key={item.title} item={item} isShow={isShow}
                isCollapse={isCollapse}
                isDropStatus={isDropStatus}
                onDropStatus={onDropStatus}
              />
            ))}
          </List>
        );
      })}
    </Box>
  );
}
