const RATES = {
  USD: 1,
  KRW: 1400,
};

const SYMBOL = {
  USD: '$',
  KRW: '₩',
};

export const CURRENCY_EXPRESSIONS = {
  USD: ['$', 'USD', 'DOLLAR'],
  KRW: ['￦', '₩', 'KRW', 'WON'],
};

export const CURRENCY = {
  RATES,
  SYMBOL,
  CURRENCY_EXPRESSIONS,
};
