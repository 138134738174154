import { createSlice } from '@reduxjs/toolkit';
import { getStoreDetails } from 'src/server/awsClient';

const initialState = {
  taxFiles: {
    original: [],
    filtered: [],
    isLoading: false,
    selectedIds: [],
  },
  taxBillings: {
    original: [],
    filtered: [],
    isLoading: false,
    selectedIds: [],
  },
  modalConfig: {
    open: false,
    id: null,
  },
  taxFilters: {
    year: '',
    quarter: '',
  },
  popbillInfo: {
    info: {
      corpNum: '',
      id: '',
    },
    isLoading: false,
  }
};

const slice = createSlice({
  name: 'taxArchive',
  initialState,
  reducers: {
    setTaxFiles: (state, action) => {
      state.taxFiles.original = action.payload;
    },
    setTaxBillings: (state, action) => {
      state.taxBillings.original = action.payload;
    },
    setFilteredTaxFiles: (state, action) => {
      state.taxFiles.filtered = action.payload;
    },
    setFilteredTaxBillings: (state, action) => {
      state.taxBillings.filtered = action.payload;
    },

    setTaxFilesIsLoading: (state, action) => {
      state.taxFiles.isLoading = action.payload;
    },
    setTaxBillingsIsLoading: (state, action) => {
      state.taxBillings.isLoading = action.payload;
    },

    setTaxFilesSelectedIds: (state, action) => {
      state.taxFiles.selectedIds = action.payload;
    },
    setTaxBillingsSelectedIds: (state, action) => {
      state.taxBillings.selectedIds = action.payload;
    },
    setModalConfig: (state, action) => {
      state.modalConfig = action.payload;
    },
    setTaxFiltersYear: (state, action) => {
      state.taxFilters.year = action.payload;
    },
    setTaxFiltersQuarter: (state, action) => {
      state.taxFilters.quarter = action.payload;
    },
    setPopbillInfo: (state, action) => {
      state.popbillInfo.info = action.payload;
    },
    setPopbillInfoIsLoading: (state, action) => {
      state.popbillInfo.isLoading = action.payload;
    },
  },
});

export const {
  setTaxFiles,
  setTaxBillings,
  setModalConfig,
  setFilteredTaxFiles,
  setFilteredTaxBillings,
  setTaxFilesIsLoading,
  setTaxBillingsIsLoading,
  setTaxFilesSelectedIds,
  setTaxBillingsSelectedIds,
  setTaxFiltersYear,
  setTaxFiltersQuarter,
  setPopbillInfo,
  setPopbillInfoIsLoading,
} = slice.actions;
export default slice.reducer;

export const getTaxFiles = (state) => state.taxArchive.taxFiles.original;
export const getTaxBillings = (state) => state.taxArchive.taxBillings.original;
export const getFilteredTaxFiles = (state) => state.taxArchive.taxFiles.filtered;
export const getFilteredTaxBillings = (state) => state.taxArchive.taxBillings.filtered;
export const getModalConfig = (state) => state.taxArchive.modalConfig;
export const getTaxFilesIsLoading = (state) => state.taxArchive.taxFiles.isLoading;
export const getTaxBillingsIsLoading = (state) => state.taxArchive.taxBillings.isLoading;
export const getTaxFilesSelectedIds = (state) => state.taxArchive.taxFiles.selectedIds;
export const getTaxBillingsSelectedIds = (state) => state.taxArchive.taxBillings.selectedIds;
export const getTaxFilters = (state) => state.taxArchive.taxFilters;
export const getPopbillInfo= (state) => state.taxArchive.popbillInfo.info;
export const getPopbillInfoIsLoading= (state) => state.taxArchive.popbillInfo.isLoading;