export const MOCK_ROLES = [
  {
    roleName: 'FE Dev',
    authorization: [
      {
        key: 'commandCentral',
        selectedValues: ['commandCentral'],
      },
      {
        key: 'orderManagement',
        selectedValues: ['offlineOrders', 'onlineOrders', 'orderStatus'],
      },
      {
        key: 'financials',
        selectedValues: [],
      },
      {
        key: 'marketingTools',
        selectedValues: [],
      },
      {
        key: 'commerce',
        selectedValues: [],
      },
      {
        key: 'support',
        selectedValues: [],
      },
    ],
  },
  {
    roleName: 'BE Dev',
    authorization: [
      {
        key: 'commandCentral',
        selectedValues: [],
      },
      {
        key: 'orderManagement',
        selectedValues: [],
      },
      {
        key: 'financials',
        selectedValues: [],
      },
      {
        key: 'marketingTools',
        selectedValues: ['buzzwords', 'hotProducts', 'priceMatch'],
      },
      {
        key: 'commerce',
        selectedValues: [
          'events',
          'performance',
          'catalogue',
          'inventoryManagement',
          'customerDirectory',
        ],
      },
      {
        key: 'support',
        selectedValues: [],
      },
    ],
  },
];

export const SIDEBAR_CONFIG_BLACKLIST_DROPKEYS = ['support'];