import { useQueryClient } from 'react-query';
import { INVENTORY_CATALOGUE_QUERY_KEYS } from 'src/constants';

const useReactQueryManager = () => {
  const queryClient = useQueryClient();
  const getQueryData = (queryKey) => queryClient.getQueryData(queryKey);
  const getQueryState = (queryKey) => queryClient.getQueryState(queryKey) || {};
  const getQueryFetching = (queryKey) => queryClient.isFetching(queryKey, null);
  const getIsLoading = (queryKey) => getQueryFetching(queryKey) > 0;
  // const getIsLoading = (queryKey) => getQueryState(queryKey).status !== 'success';
  const removeQueryData = (queryKey) => {
    queryClient.removeQueries(queryKey);
  };
  const invalidateQueries = async (queryKey) =>
    await queryClient.invalidateQueries(queryKey, { refetchActive: true, refetchInactive: true });

  const updateQueryData = (queryKey, updater) => {
    const prev = getQueryData(queryKey);
    queryClient.setQueryData(queryKey, updater(prev));
  };

  const getQueryInfo = (queryKey) => {
    return {
      data: getQueryData(queryKey),
      isLoading: getIsLoading(queryKey),
    };
  };

  return {
    getQueryData,
    getIsLoading,
    getQueryState,
    getQueryInfo,
    updateQueryData,
    invalidateQueries,
    removeQueryData,
    getQueryFetching,
  };
};

export default useReactQueryManager;
