export function getStoreId() {
  return localStorage.getItem('storeID');
}

export function getStoreType() {
  return localStorage.getItem('storeType');
}

export function getEmail() {
  return localStorage.getItem('email');
}

export function getCookieAgreementStatus() {
  return localStorage.getItem('cookieAgreementStatus');
}

export function getUserName() {
  return localStorage.getItem('userName');
}

export function getGivenName() {
  return localStorage.getItem('givenName');
}

export function getFamilyName() {
  return localStorage.getItem('familyName');
}

export function getPermissionsInfo() {
  return localStorage.getItem('permissions');
}

export const getUserId = () => {
  return localStorage.getItem('userId');
}

export function getUserAccessTokenData() {
  return { 
    IdToken: localStorage.getItem('IdToken'),
    AccessToken: localStorage.getItem('AccessToken'),
    ExpiresIn: localStorage.getItem('ExpiresIn'),
    TokenType: localStorage.getItem('TokenType')
  }
}

export function setUserSession(storeId, storeType, email, givenName, familyName) {
  localStorage.setItem('storeID', storeId);
  localStorage.setItem('storeType', storeType);
  localStorage.setItem('email', email);
  localStorage.setItem('givenName', givenName);
  localStorage.setItem('familyName', familyName);
  localStorage.setItem('userName', givenName + ' ' + familyName);
}

export function setPermissionsInfo(permissions) {
  localStorage.setItem('permissions', permissions);
}

export function setCookieAgreementStatus(status) {
  localStorage.setItem('cookieAgreementStatus', status);
}

export function setUserId(userId) {
  localStorage.setItem('userId', userId);
}

export function setEmail(email) {
  localStorage.setItem('email', email);
}

export function setStoreId(storeId) {
  localStorage.setItem('storeID', storeId);
}

export function setStoreType(storeType) {
  localStorage.setItem('storeType', storeType);
}

export function setUserAccessTokenData(idToken, accessToken, expiresIn, tokenType) {
  localStorage.setItem('IdToken', idToken);
  localStorage.setItem('AccessToken', accessToken);
  localStorage.setItem('ExpiresIn', expiresIn);
  localStorage.setItem('TokenType', tokenType);
}

export function resetUserSession() {
  localStorage.removeItem('storeID');
  localStorage.removeItem('storeType');
  localStorage.removeItem('email');
  localStorage.removeItem('IdToken');
  localStorage.removeItem('AccessToken');
  localStorage.removeItem('ExpiresIn');
  localStorage.removeItem('TokenType');
  localStorage.removeItem('permissions');
  localStorage.removeItem('userId');
  localStorage.removeItem('userName');
  localStorage.removeItem('givenName');
  localStorage.removeItem('familyName');
}