import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';
import { rootPersistConfig, rootReducer } from './rootReducer';
import { apiSlice } from './rtkq/apiSlice';

// ----------------------------------------------------------------------

const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // Needed for RTK query compatability with redux-persist
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          'adminPanel/setFilterdSidebarConfig',
          'accountSetting/setFilterdPaymentHistory',
          'accountSetting/setPaymentHistory',
          'offlineOrder/setFilterdMultiLineOrders',
          'sellGpt/hasError'
        ],
        // ignoredPaths: ['adminPanel.roleCreationManagement', 'accountSetting.paymentHistory'],
        //   'offlineOrder/setFilterdMultiLineOrders',
        // ],
        ignoredPaths: [
          'adminPanel.roleCreationManagement',
          'offlineOrder.multiLineOrders',
          'accountSetting.paymentHistory',
          'offlineOrder.filterdMultiLineOrders',
          'sellGpt.error'
        ],
      },
      immutableCheck: false,
      // Needed for RTK query integration
    }).concat(apiSlice.middleware),
});

const persistor = persistStore(store);

const { dispatch } = store;

const useSelector = useAppSelector;

const useDispatch = () => useAppDispatch();

export { store, persistor, dispatch, useSelector, useDispatch };
