export const EXPENSECATEGORIES = [
  "FoodDrink",
  "LaborCharges",
  "ProfessionalServices",
  "Travel",
  "Electronics",
  "MedicalExpenses",
  "Entertainment",
  "Education",
  "3PL",
  "RawMaterials",
  "MISC"
]

export const PRODUCTCATEGORIES = [
  "appliances",
  "food",
  "living",
  "fashion",
  "cosmetics",
  "kids",
  "sports",
  "stationary",
  "services"
]
