import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Drawer } from '@mui/material';
import LogoDashboard from '../../components/LogoDashboard';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
import sidebarConfig from './SidebarConfig';
import useCollapseDrawer from 'src/hooks/useCollapseDrawer';
import { useTheme } from '@mui/styles';
import cssStyles from '../../utils/cssStyles';


// ----------------------------------------------------------------------
const DRAWER_WIDTH = 300;
const DASHBOARD_COLLAPSE_WIDTH = 90;

const RootStyle = styled('div', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    ...(collapseClick ? { width: DASHBOARD_COLLAPSE_WIDTH, } : { width: DRAWER_WIDTH, }),
  },
}));

// ----------------------------------------------------------------------
DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();

  const theme = useTheme();
  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const RenderContent = useCallback(({ isMobile }) => {
    return (
      <Scrollbar
        sx={{
          height: '100%',
          '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
        }}
      >
        <Box sx={{ ...( isCollapse ? { pl: 2.5, } : { pl: 4, } ), pr: 2.5, py: 1, display: 'inline-flex' }}>
          <LogoDashboard isMobile={isMobile} isCollapse={isCollapse} onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
        </Box>
        <NavSection navConfig={sidebarConfig} isCollapse={isCollapse} />
        <Box sx={{ flexGrow: 10 }} />
      </Scrollbar>
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCollapse, collapseClick])

  return (
    <RootStyle collapseClick={collapseClick}>
      {/* Sidebar for Mobile */}
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <RenderContent isMobile={true} />
        </Drawer>
      </MHidden>
      {/* Sidebar for PC */}
      <MHidden width="lgDown">
        <Drawer
          open
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          variant="persistent"
          PaperProps={{
            sx: {
              ...(isCollapse ? { width: DASHBOARD_COLLAPSE_WIDTH, } : { width: DRAWER_WIDTH, }),
              bgcolor: 'background.default',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            }
          }}
        >
          <RenderContent isMobile={false} />
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
