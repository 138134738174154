import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Typography, CircularProgress, Skeleton } from '@mui/material';
import { MHidden } from '../../components/@material-extend';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import useCollapseDrawer from 'src/hooks/useCollapseDrawer';
import { useAccountSetting } from 'src/contexts/AccountSettingContext';
// import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------
const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;
const DASHBOARD_COLLAPSE_WIDTH = 90;

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    ...(collapseClick ? { width: `calc(100% - ${DASHBOARD_COLLAPSE_WIDTH + 1}px)`, } : { width: `calc(100% - ${DRAWER_WIDTH + 1}px)`, }),
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

const HeaderStackStyle = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  marginBottom: 0,
  marginTop: '16px',
  [theme.breakpoints.down('lg')]: {
    marginLeft: '8px',
  },
  [theme.breakpoints.up('lg')]: {
    ...(collapseClick ? { marginLeft: '8px', } : { marginLeft: '24px', }),
  },
}));

// ----------------------------------------------------------------------
DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
  headerDescription: PropTypes.object
};

export default function DashboardNavbar({ onOpenSidebar, headerDescription }) {

  const { collapseClick } = useCollapseDrawer();
  const { storeInfo,  loading } = useAccountSetting();

  return (
    <RootStyle collapseClick={collapseClick}>
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>
        <HeaderStackStyle collapseClick={collapseClick} >
          <Typography variant="h4" component="h4" sx={{ color: 'primary' }}>
            {headerDescription.title}
          </Typography>
          <Typography variant="body2" sx={{ paddingTop: 0, color: 'text.secondary'}}>
            {headerDescription.subTitle}
          </Typography>
        </HeaderStackStyle>
        <Box sx={{ flexGrow: 1}} />
        <Stack direction="row" alignItems="center" sx={{ marginTop: "0%" }} spacing={{ xs: 0.5, sm: 1.5, md: 3 }}>
          <LanguagePopover/>
          {/* <Typography
            variant="body2"
            sx={{
              paddingTop: "1rem",
              color: 'text.secondary',
              textAlign: 'center',
              display: {
                xs: 'none',
                sm: 'none',
                md: 'block'
              }
            }}
          >{loading ? <CircularProgress size={20} /> : storeInfo.storeName}</Typography> */}
          <AccountPopover/>
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
