import { camelCaseKeysForObjects } from '../../util';
import { DEFAULT_HEADERS } from '../../constants';

export const getProductQuery = (body) => ({
  url: ('/' + body.storeId + '/products/'+ body.productId + '/single'),
  headers: DEFAULT_HEADERS
});

export const getProductsTransformResponse = (responseData) => {
  return camelCaseKeysForObjects(responseData.response);
};