// ----------------------------------------------------------------------

const shape = {
  borderRadiusFlatten: 4,
  borderRadius: 8,
  borderRadiusSm: 12,
  borderRadiusMd: 16
};

export default shape;
