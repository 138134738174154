import { Box, CircularProgress } from '@mui/material';
import LoadingScreen from '../LoadingScreen';
const LoadingContainer = ({ isLoading = false, children, isSellpointLoader = false, sx = {}, size = 40 }) => {
  if (isLoading) {
    return (
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          ...sx,
        }}
      >
        {isSellpointLoader ? <LoadingScreen /> : <CircularProgress size={size} />}
      </Box>
    );
  }
  return children;
};

export default LoadingContainer;
