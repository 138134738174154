export const HOUR = 60;
export const MINUTE = 60;
export const SECOND = 1000;
export const DAY = 24;

export const TIME_ZONE = Object.freeze({
  kst: { utcDiff: 9 },
  pst: { utcDiff: -8 },
  est: { utcDiff: -5 },
  cwt: { utcDiff: 8 },
});
