import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  paymentHistory: {
    paymentHistory: [],
    filterdPaymentHistory: [],
    isLoading: false,
    year: null,
  },
};

const slice = createSlice({
  name: 'accountSetting',
  initialState,
  reducers: {
    setFilterdPaymentHistory(state, action) {
      state.paymentHistory.filterdPaymentHistory = action.payload;
    },
    setPaymentHistoryIsLoading(state, action) {
      state.paymentHistory.isLoading = action.payload;
    },
    setPaymentHistory(state, action) {
      state.paymentHistory.paymentHistory = action.payload;
    },
    setPaymentHistoryYear(state, action) {
      state.paymentHistory.year = action.payload;
    },
  },
});

export const { setFilterdPaymentHistory, setPaymentHistory, setPaymentHistoryIsLoading, setPaymentHistoryYear } =
  slice.actions;

export default slice.reducer;

export const selectFilterdPaymentHistory = (state) => state.accountSetting.paymentHistory.filterdPaymentHistory;
/**
 * @returns {Array<{ id: number, date: Date, amount: string, description: string, pays: string }>}
 */
export const selectPaymentHistory = (state) => state.accountSetting.paymentHistory.paymentHistory;
export const selectPaymentHistoryIsLoading = (state) => state.accountSetting.paymentHistory.isLoading;
export const selectPaymentHistoryYear = (state) => state.accountSetting.paymentHistory.year;
