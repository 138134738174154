import { createSlice } from '@reduxjs/toolkit';
import {
  getProcurementOrdersFromAWS,
  postProcurementOrderToAWS,
  updateProcurementOrdersToAWS,
} from '../../../server/awsClient';
import { dispatch } from '../store';

//This is the redux shared state that you will be updating. Source of truth via your reducers
const initialState = {
  error: {},
  isLoading: false,
  procurementOrders: { ProcurementManufacture: [], ProcurementVendor: [] },
  manufactureOrder: {},
  vendorOrder: {},
  editType: 'manufacture',
  suppliers: [],
  selectedPrefSupplier: "all",
  modalConfig: {
    open: false,
    type: null,
    data: {},
    isLoading: false,
    getOrderIsIsLoading: false,
    newComponentId: null,
    newCustomerId: null,
    cellId: null,
  },
  inventory: {},
  products: [],
  // newComponentId: null,
};

//Creating a slice with action reducers that handle each slice of a state
const slice = createSlice({
  name: 'procurement',
  initialState,
  reducers: {
    setInventory(state, action) {
      state.inventory = action.payload;
    },
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.error = action.payload;
    },
    getProcurementOrdersSuccess(state, action) {
      state.isLoading = false;
      state.procurementOrders = action.payload;
    },
    getProcurementOrderSuccess(state, action) {
      state.isLoading = false;
      if (action.payload.type === 'manufacture') {
        state.manufactureOrder = action.payload.order;
        state.editType = 'manufacture';
      }
      if (action.payload.type === 'vendor') {
        state.vendorOrder = action.payload.order;
        state.editType = 'vendor';
      }
    },
    postProcurementOrderSuccess(state, action) {
      state.isLoading = false;
      if (action.payload.response.statusCode === 200) {
        const addedOrder = JSON.parse(action.payload.response.body);
        if (action.payload.procurementType === 'manufacture') {
          state.procurementOrders.ProcurementManufacture.push(addedOrder);
        }
        if (action.payload.procurementType === 'vendor') {
          state.procurementOrders.ProcurementVendor.push(addedOrder);
        }
      }
    },
    updateProcurementOrdersSuccess(state, action) {
      const updatedOrders = action.payload.data.response;
      if (state.procurementOrders.ProcurementManufacture) {
        state.procurementOrders.ProcurementManufacture = state.procurementOrders.ProcurementManufacture.map((order) => {
          if (updatedOrders[order.orderId]) return updatedOrders[order.orderId];
          else return order;
        });
      }
      if (state.procurementOrders.ProcurementVendor) {
        state.procurementOrders.ProcurementVendor = state.procurementOrders.ProcurementVendor.map((order) => {
          if (updatedOrders[order.orderId]) return updatedOrders[order.orderId];
          else return order;
        });
      }
    },
    setSuppliers(state, action) {
      state.suppliers = action.payload;
    },
    setSelectedPrefSupplier(state, action) {
      state.selectedPrefSupplier = action.payload
    },
    setModalConfig(state, action) {
      state.modalConfig = action.payload
    },
    setGetOrderIsIsLoading(state, action) {
      state.modalConfig.getOrderIsIsLoading = action.payload
    },
    setOrderId(state, action) {
      state.modalConfig.data.orderId = action.payload
    },
    setNewComponentId(state, action) {
      // state.newComponentId = action.payload
      state.modalConfig.newComponentId = action.payload
    },
    setNewCustomerId(state, action) {
      state.modalConfig.newCustomerId = action.payload
    },
    setCellId(state, action) {
      state.modalConfig.cellId = action.payload
    },
    setModalLoading(state, action) {
      state.modalConfig.isLoading = action.payload
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload
    },
    setProducts(state, action) {
      state.products = action.payload
    }
  },
});

// Exporting the reducer
export default slice.reducer;


// Actions
export const {
  setSuppliers,
  setSelectedPrefSupplier: setSelectedPrefSupplierAction,
  setModalConfig,
  setGetOrderIsIsLoading: setGetOrderIsIsLoadingAction,
  setOrderId: setOrderIdAction,
  setNewComponentId: setNewComponentIdAction,
  setNewCustomerId: setNewCustomerIdAction,
  setModalLoading: setModalLoadingAction,
  setInventory: setInventoryAction,
  setIsLoading,
  setProducts
} = slice.actions;

// Exporting constant to access state values within external components
export const procurementOrdersConstant = (state) => state.procurement.procurementOrders;
export const manufactureOrderConstant = (state) => state.procurement.manufactureOrder;
export const vendorOrderConstant = (state) => state.procurement.vendorOrder;
export const editTypeConstant = (state) => state.procurement.editType;
export const selectSuppliers = (state) => state.procurement.suppliers;
export const selectSelectedPrefSupplier = (state) => state.procurement.selectedPrefSupplier;
export const selectIsLoading = (state) => state.procurement.isLoading;
export const selectModalConfig = (state) => state.procurement.modalConfig;
export const selectNewComponentId = (state) => state.procurement.modalConfig.newComponentId;
export const selectInventory = (state) => state.procurement.inventory;
export const selectProducts = (state) => state.procurement.products;

export function fetchProcurementOrdersFromStore(controller, procurementOrderId = '') {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getProcurementOrdersFromAWS(controller, procurementOrderId);

      if (response.data.query === 'multiple') {
        // GET multiple procurement orders
        dispatch(slice.actions.getProcurementOrdersSuccess(response.data.item));
      }

      if (response.data.query === 'single') {
        // GET single procurement order
        dispatch(slice.actions.getProcurementOrderSuccess({ order: response.data.item, type: response.data.type }));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function postProcurementOrderToStore(procurementType, procurementOrder) {
  // return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await postProcurementOrderToAWS(procurementType, procurementOrder);
      dispatch(slice.actions.postProcurementOrderSuccess({ response: response.data, procurementType }));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  // };
}

export async function updateProcurementOrderToStore(abortController, procurementType, procurementId, updateValues) {
  // return async () => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await updateProcurementOrdersToAWS(abortController, procurementType, procurementId, updateValues);
    dispatch(slice.actions.updateProcurementOrdersSuccess(response));
    return response;
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return error;
  }
  // };
}