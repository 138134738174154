import i18next from "i18next";

export const SHIPPING = 'shipping';
export const PICKUP = 'pickup';
export const DELIVERY = 'delivery';
export const DELIVERY_OPTIONS = [SHIPPING, PICKUP, DELIVERY];

export const PAID = 'paid';
export const UNPAID = 'unpaid';
export const STATUS_OPTIONS = [UNPAID, PAID];

// const IN_STORE_PICK_UP = 'inStorePickUp';
// const PRE_ORDER = 'preOrder';
export const DELIVERY_OPTION = [
  { value: 'shipping', label: 'offlineOrderStatusDate.label.shipping' },
  { value: 'inStorePickUp', label: 'offlineOrderStatusDate.label.pickup' },
  { value: 'preOrder', label: 'offlineOrderStatusDate.label.delivery' },
];

export const ORDER_STATUS_OPTION = ["awaiting","fulfilled","cancelled"];

// { value: 'awaiting', label: i18next.t(`orderStatus.label.awaiting`) },
// { value: 'fulfilled', label: i18next.t(`orderStatus.label.fulfilled`) },
// { value: 'cancelled', label: i18next.t(`orderStatus.label.cancelled`) },


export const unitOptions = [
  { key: 'count', value: i18next.t('productNewForm.unit.pscs') },
  { key: 'count', value: i18next.t('productNewForm.unit.units') },
  { key: 'count', value: i18next.t('productNewForm.unit.pairs') },
  { key: 'count', value: i18next.t('productNewForm.unit.dozens') },
  { key: 'count', value: i18next.t('productNewForm.unit.packs') },
  { key: 'count', value: i18next.t('productNewForm.unit.boxes') },
  { key: 'count', value: i18next.t('productNewForm.unit.cases') },
  { key: 'count', value: i18next.t('productNewForm.unit.bundle') },
  { key: 'weight', value: i18next.t('productNewForm.unit.tons') },
  { key: 'weight', value: i18next.t('productNewForm.unit.lb') },
  { key: 'weight', value: i18next.t('productNewForm.unit.kg') },
  { key: 'weight', value: i18next.t('productNewForm.unit.oz') },
  { key: 'weight', value: i18next.t('productNewForm.unit.g') },
  { key: 'weight', value: i18next.t('productNewForm.unit.t') },
  { key: 'volume', value: i18next.t('productNewForm.unit.gal') },
  { key: 'volume', value: i18next.t('productNewForm.unit.l') },
  { key: 'volume', value: i18next.t('productNewForm.unit.ml') },
  { key: 'volume', value: i18next.t('productNewForm.unit.ft3') },
  { key: 'volume', value: i18next.t('productNewForm.unit.m3') },
  { key: 'length', value: i18next.t('productNewForm.unit.in') },
  { key: 'length', value: i18next.t('productNewForm.unit.ft') },
  { key: 'length', value: i18next.t('productNewForm.unit.yd') },
  { key: 'length', value: i18next.t('productNewForm.unit.m') },
  { key: 'length', value: i18next.t('productNewForm.unit.cm') },
  { key: 'length', value: i18next.t('productNewForm.unit.mm') },
];


//   paymentStatusOption: [
//     { value: 'unpaid', label: 'offlineOrderStatusDate.label.unpaid' },
//     { value: 'paid', label: 'offlineOrderStatusDate.label.paid' },
//     { value: 'returned', label: 'offlineOrderStatusDate.label.returned' },
//     { value: 'cancelled', label: 'offlineOrderStatusDate.label.cancelled' },
//   ],
// };
