import { createSlice } from '@reduxjs/toolkit';
// utils
// utils
import {
  getInvoicesFromAWS,
  getInvoiceFromAWS,
  deleteInvoiceFromAWS,
  getBatchInvoicesFromAWS,
} from '../../../server/awsClient';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isInvoicesLoading: false,
  invoiceData: {},
  orderType: '',
  error: null,
  invoices: [],
  batchInvoices: [],
  invoice: null,
  sortBy: null,
  filters: {
    status: 'All',
  },
  checkout: {
    activeStep: 0,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: null,
  },
};

const slice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setInvoices(state, action) {
      state.invoices = action.payload;
    },

    // GET INVOICES
    getInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.invoices = action.payload;
    },

    // GET BATCH INVOICES
    getBatchInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.batchInvoices = action.payload;
    },

    // GET INVOICE
    getInvoiceSuccess(state, action) {
      state.isLoading = false;
      state.invoice = action.payload;
    },

    // SORT & FILTER INVOICES
    sortByInvoices(state, action) {
      state.sortBy = action.payload;
    },

    filterInvoices(state, action) {
      state.filters.status = action.payload.status;
    },

    // DELETE invoice
    deleteInvoiceSuccess(state, action) {
      state.isLoading = false;
      state.invoices = action.payload;
    },

    setInvoiceIsLoading(state, action) {
      state.isInvoicesLoading = action.payload;
    },

    setInvoiceDataAction(state, action) {
      state.invoiceData = action.payload;
    },
    setOrderType(state, action) {
      state.orderType = action.payload;
    },
    setInvoice(state, action) {
      state.invoice = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  sortByInvoices,
  filterInvoices,
  setInvoiceIsLoading,
  setInvoices,
  setInvoiceDataAction,
  setOrderType,
  setInvoice,
} = slice.actions;

// ----------------------------------------------------------------------
export function getBatchInvoicesFromStore(abortController, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getBatchInvoicesFromAWS(abortController, id);
      dispatch(slice.actions.getBatchInvoicesSuccess(response.Invoices));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getInvoicesFromStore(abortController) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getInvoicesFromAWS(abortController);
      dispatch(slice.actions.getInvoicesSuccess(response));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getInvoiceFromStore(abortController, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getInvoiceFromAWS(abortController, id);
      dispatch(slice.actions.getInvoiceSuccess(response));
      return response
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function getInvoiceFromStoreAsync(abortController, id) {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getInvoiceFromAWS(abortController, id);
      dispatch(slice.actions.getInvoiceSuccess(response));
      return response
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
}


export function deleteInvoiceInStore(abortController, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      deleteInvoiceFromAWS(abortController, id)
        .then((response) => {
          dispatch(slice.actions.deleteInvoiceSuccess(response.data));
        })
        .catch((err) => {
          // console.log(err);
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export const selectInvoiceIsLoading = (state) => state.invoice.isLoading;
export const selectInvoiceData = (state) => state.invoice.invoiceData;
export const selectIsLoading = (state) => state.invoice.isLoading;
export const selectOrderType = (state) => state.invoice.orderType;
