// import i18next from 'i18next';

export const MONTH = {
  1: 'annualSales.month.jan',
  2: 'annualSales.month.feb',
  3: 'annualSales.month.mar',
  4: 'annualSales.month.apr',
  5: 'annualSales.month.may',
  6: 'annualSales.month.jun',
  7: 'annualSales.month.jul',
  8: 'annualSales.month.aug',
  9: 'annualSales.month.sep',
  10: 'annualSales.month.oct',
  11: 'annualSales.month.nov',
  12: 'annualSales.month.dec',
};

export const WEEK_AGO = {
  1: 'common.weekAgo.1',
  2: 'common.weekAgo.2',
  3: 'common.weekAgo.3',
  4: 'common.weekAgo.4',
};

export const DATE_AGO = {
  1: 'common.dateAgo.1',
  2: 'common.dateAgo.2',
  3: 'common.dateAgo.3',
  4: 'common.dateAgo.4',
  5: 'common.dateAgo.5',
  6: 'common.dateAgo.6',
  7: 'common.dateAgo.7',
  8: 'common.dateAgo.8',
  9: 'common.dateAgo.9',
  10: 'common.dateAgo.10',
  11: 'common.dateAgo.11',
  12: 'common.dateAgo.12',
  13: 'common.dateAgo.13',
  14: 'common.dateAgo.14',
};
