import { isString } from 'lodash';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { useDropzone } from 'react-dropzone';
import fileFill from '@iconify/icons-eva/file-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import { motion, AnimatePresence } from 'framer-motion';
// material
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  List,
  Stack,
  Paper,
  Button,
  ListItem,
  Typography,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Snackbar,
  Alert,
} from '@mui/material';
// utils
import { fData } from '../../utils/formatNumber';
import { useEffect, useMemo, useState } from 'react';
import { MIconButton } from '../@material-extend';
import { varFadeInRight } from '../animate';
import { UploadIllustration } from '../../assets';
import Papa from 'papaparse';
import { streamlineObjects } from 'src/utils/orderProcessing.js';
import { updateOnlineOrders } from 'src/components/redux/slices/online-order.js';
import { useDispatch } from 'src/components/redux/store.js';
import i18next from 'i18next';
import * as XLSX from 'xlsx';
import PasswordModal from './PasswordModal';
// ----------------------------------------------------------------------
import InfoIcon from '@mui/icons-material/Info';
import Showable from '../common/Showable';

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
  [theme.breakpoints.up('md')]: { textAlign: 'left', flexDirection: 'row' },
}));
// ----------------------------------------------------------------------
UploadMultiGeneric.propTypes = {
  error: PropTypes.bool,
  showPreview: PropTypes.bool,
  files: PropTypes.array,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  sx: PropTypes.object,
};

export default function UploadMultiGeneric({
  error,
  showPreview = false,
  files,
  onRemove,
  onRemoveAll,
  sx,
  setUploadState,
  grabFiles,
  streamline,
  isAlertOff = false,
  mappingFields,
  fromCustomerTemplate = false,
  ...other

}) {
  const hasFile = 1;
  const isFileSelected = useMemo(() => files.length > 0, [files]);
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    ...other,
    accept: 'text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const dispatch = useDispatch();
  const [status, setStatus] = useState('');

  const [csvPrep, setCsvPrep] = useState({
    csv: [], // csv files
    passwordExcel: [], // excel files with password
    passwordExcelIndex: [],
  });
  const [modal, setModal] = useState([false, []]);

  const checkProtectedExcel = () => {
    let _csvPrep = {
      csv: [],
      passwordExcel: [],
      passwordExcelIndex: [],
    };

    return new Promise((resolve, reject) => {
      let completed = 0;
      const total = files.length;
      files.forEach((file, i) => {
        if (file.type !== 'text/csv') {
          // console.log(file.name)
          const fileReader = new FileReader();
          fileReader.readAsArrayBuffer(file);
          fileReader.onload = (event) => {
            try {
              const workbook = XLSX.read(event.target.result, { type: 'array' });
              const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
              const csvFile = XLSX.utils.sheet_to_csv(firstSheet);
              _csvPrep.csv.push(csvFile);
            } catch (e) {
              if (e.message === 'File is password-protected') {
                _csvPrep.passwordExcel.push(file);
                _csvPrep.passwordExcelIndex.push(i);
              }
            }
            completed++;
            if (completed === total) resolve(_csvPrep);
          };
        } else {
          _csvPrep.csv.push(file);
          completed++;
          if (completed === total) resolve(_csvPrep);
        }
      });
    });
  };

  useEffect(() => {
    if (files.length > 0) {
      checkProtectedExcel().then((prep) => {
        setCsvPrep(prep);
      });
    }
  }, [files]);

  const parseFiles = () => {
    //Method to asynchronously parse CSV/Excel files to JSON objects
    let filesData = [];
    Promise.all(
      [...csvPrep.csv].map(async (file) => {
        return new Promise((resolve, reject) =>
          Papa.parse(file, {
            header: true,
            complete: resolve,
            error: reject,
            // korean encoding
            // encoding: "EUC-KR",
          }),
        );
      }),
    )
      .then((results) => {
        if (fromCustomerTemplate) {
          filterCustomertemplateFiles(results[0].meta.fields);
        }
        else {
          results.forEach((result) => {
            result.data = result.data.filter((row) => Object.values(row).some((value) => value.trim() !== '')); // remove empty rows
            filesData = [...filesData, ...result.data];
          });
          filterFiles(filesData);
        }
      })
      .catch((err) => {
        // console.log('Something went wrong:', err);
        setStatus('error');
      });
  };

  const filterCustomertemplateFiles = async (fields) => {
    const columns = streamline(fields);
    
    await mappingFields(columns.fieldIds);
    const controller = new AbortController();

    //Creates a dispatch to update state data in redux.
    if (dispatch(updateOnlineOrders(columns)).type === 'onlineOrder/updateOnlineOrders') {
      setStatus('success');
      setUploadState(false);
    } else {
      setStatus('error');
    }
    return () => {
      controller.abort();
    };
  }

  const filterFiles = async (csvData) => {
    //Method to obtain the filtered JSON objects, which calls an external module.
    // const filtered = streamlineObjects(csvData)
    let filteredCsvData = csvData.map((data) => {
      const filterData = Object.fromEntries(Object.entries(data).filter(([key]) => key !== ''));
      return filterData;
    });
    const filtered = streamline ? streamline(filteredCsvData) : streamlineObjects(filteredCsvData);
    // console.log(filtered);
    // const filtered = streamlineObjects(csvData)
    const controller = new AbortController();

    // If the grabFiles prop is true, it will return the filtered data to the parent component.
    if (grabFiles) {
      grabFiles(filtered);
      setUploadState();
      return;
    }

    //Creates a dispatch to update state data in redux.
    if (dispatch(updateOnlineOrders(filtered)).type === 'onlineOrder/updateOnlineOrders') {
      setStatus('success');
      setUploadState(false);
    } else {
      setStatus('error');
    }
    return () => {
      controller.abort();
    };
  };

  const ShowRejectionItems = () => (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: 'error.light',
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
      }}
    >
      {fileRejections.map(({ file, errors }) => {
        const { path, size } = file;
        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {path} - {fData(size)}
            </Typography>
            {errors.map((e) => (
              <Typography key={e.code} variant="caption" component="p">
                - {e.message}
              </Typography>
            ))}
          </Box>
        );
      })}
    </Paper>
  );

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <Showable show={!isAlertOff}>
        <Stack sx={{ width: '100%', pb: 3 }} spacing={10}>
          <Alert
            iconMapping={{ info: <InfoIcon fontSize="inherit" /> }}
            sx={{ bgcolor: 'background.paper' }}
            variant="outlined"
            severity="info"
          >
            {i18next.t('uploadMultiGeneric.alert1')}
          </Alert>
        </Stack>
      </Showable>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',
          }),
        }}
      >
        <input {...getInputProps()} />
        <UploadIllustration sx={{ width: 220 }} />
        <Box sx={{ p: 3, ml: { md: 2 } }}>
          <Typography gutterBottom variant="h5">
            {i18next.t('uploadMultiGeneric.text1')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {i18next.t('uploadMultiGeneric.text2')}&nbsp;
            <Typography variant="body2" component="span" sx={{ color: 'primary.main', textDecoration: 'underline' }}>
              {i18next.t('uploadMultiGeneric.text3')}
            </Typography>
            &nbsp;{i18next.t('uploadMultiGeneric.text4')}
          </Typography>
        </Box>
      </DropZoneStyle>

      {fileRejections.length > 0 && <ShowRejectionItems />}

      <List disablePadding sx={{ ...(hasFile && { my: 3 }) }}>
        <AnimatePresence>
          {files.map((file, i) => {
            const { name, size } = file;
            const key = isString(file) ? file : name;

            return (
              <ListItem
                key={key}
                component={motion.div}
                {...varFadeInRight}
                sx={{
                  my: 1,
                  py: 0.75,
                  px: 2,
                  borderRadius: 1,
                  border: (theme) => `solid 1px ${theme.palette.divider}`,
                  bgcolor: 'background.paper',
                }}
              >
                <ListItemIcon>
                  <Icon icon={fileFill} width={28} height={28} />
                </ListItemIcon>
                <ListItemText
                  primary={isString(file) ? file : name}
                  secondary={isString(file) ? '' : fData(size)}
                  primaryTypographyProps={{ variant: 'subtitle2' }}
                  secondaryTypographyProps={{ variant: 'caption' }}
                />
                <ListItemSecondaryAction>
                  {csvPrep.passwordExcelIndex.includes(i) && (
                    <MIconButton edge="end" size="small" onClick={() => setModal([true, file, i])}>
                      <Icon icon={lockFill} />
                    </MIconButton>
                  )}
                  <MIconButton edge="end" size="small" onClick={() => onRemove(file)}>
                    <Icon icon={closeFill} />
                  </MIconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </AnimatePresence>
      </List>

      {hasFile && (
        <Stack direction="row" justifyContent="flex-end">
          <Button onClick={onRemoveAll} sx={{ mr: 1.5 }} disabled={!isFileSelected}>
            {i18next.t('uploadMultiGeneric.button.removeAll')}
          </Button>
          <Button
            onClick={parseFiles}
            variant="contained"
            disabled={!isFileSelected || csvPrep.passwordExcel.length > 0}
          >
            {i18next.t('uploadMultiGeneric.button.upload')}
          </Button>
          {status === 'success' && (
            <Snackbar open={status === 'success'} autoHideDuration={4000}>
              <Alert severity="success" sx={{ width: '100%' }}>
                {i18next.t('onlineOrderGrid.alert.ordersProcessed')}
              </Alert>
            </Snackbar>
          )}
          {status === 'error' && (
            <Snackbar open={status === 'error'} autoHideDuration={4000}>
              <Alert severity="success" sx={{ width: '100%' }}>
                {i18next.t('onlineOrderGrid.alert.unexpectedError')}
              </Alert>
            </Snackbar>
          )}
        </Stack>
      )}

      <PasswordModal modal={modal} setModal={setModal} csvPrep={csvPrep} setCsvPrep={setCsvPrep} />
    </Box>
  );
}
