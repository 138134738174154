import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import { m, AnimatePresence } from 'framer-motion';
import { alpha } from '@mui/material/styles';
import { List, Stack, Button, IconButton, ListItemText, ListItem } from '@mui/material';
import { fData } from '../../utils/formatNumber';
import Iconify from '../Iconify';
import Image from '../Image';
import i18next from 'i18next';

// ----------------------------------------------------------------------
const getFileData = (file) => {
  if (typeof file === 'string') {
    return {
      key: file,
    };
  }
  return {
    key: file.name,
    name: file.name,
    size: file.size,
    preview: file.preview,
  };
};

// ----------------------------------------------------------------------
MultiFilePreview.propTypes = {
  files: PropTypes.array,
  showPreview: PropTypes.bool,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
};

export default function MultiFilePreview({ showPreview = false, files, onRemove, onRemoveAll, hideRemove = false, hideRemoveAll = false }) {
  const hasFile = files?.length > 0;
  const handleRemove = (isS3, file, fileData, index) => {
    onRemove(isS3 ? file?.id : fileData, index)
  }
  const handleClick = (fileData, preview) => {
    window.open(preview ? preview : base64ToBlobURL(fileData), '_blank')
  }
  const base64ToBlobURL = (base64Data) => {
    const contentType = base64Data.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
    const base64Payload = base64Data.split(',')[1];
    const byteArray = Uint8Array.from(atob(base64Payload), (c) => c.charCodeAt(0));
    const blob = new Blob([byteArray], { type: contentType });
    const blobURL = URL.createObjectURL(blob);
    return blobURL;
  }

  const imageFiles = Array.isArray(files) ? files.filter((image) => image) : [];
  
  return (
    <>
      <List disablePadding sx={{ ...(hasFile && { my: 3 }) }}>
        <AnimatePresence>
          {imageFiles?.map((file, index) => {
            try {
              
            // Database returns image in {id: ..., payloadS3: ...} format
            let fileData = file;
            let isS3 = false;
            // console.log(file)
            if ("payloadS3" in file) {
              fileData = file.payloadS3;
              isS3 = true;
            }
            const { key, name, size, preview } = getFileData(fileData);
            if (showPreview) {
              return (
                <ListItem
                  key={key}
                  component={m.div}
                  sx={{
                    p: 0,
                    m: 0.5,
                    width: 80,
                    height: 80,
                    borderRadius: 1.25,
                    overflow: 'hidden',
                    position: 'relative',
                    display: 'inline-flex',
                    border: (theme) => `solid 1px ${theme.palette.divider}`,
                    ".MuiBox-root":{
                      width: '100%',
                      height: '100%',
                    }
                  }}
                >
                  <Image alt="preview" ratio="1/1"
                    src={isString(fileData) ? fileData : preview}
                    onClick={(event) => event.target === event.currentTarget && handleClick(fileData, preview)} />
                  {!hideRemove && <IconButton
                    size="small"
                    onClick={()=>{handleRemove(isS3, file, fileData, index)}}
                    sx={{
                      top: 6,
                      p: '2px',
                      right: 6,
                      position: 'absolute',
                      color: 'common.white',
                      bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                      '&:hover': {
                        bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                      },
                    }}
                  >
                    <Iconify icon={'eva:close-fill'} />
                  </IconButton>}
                </ListItem>
              );
            }
            return (
              <ListItem
                key={key}
                component={m.div}
                sx={{
                  my: 1,
                  px: 2,
                  py: 0.75,
                  borderRadius: 0.75,
                  border: (theme) => `solid 1px ${theme.palette.divider}`,
                }}
              >
                <Iconify icon={'eva:file-fill'} sx={{ width: 28, height: 28, color: 'text.secondary', mr: 2 }} />

                <ListItemText
                  primary={isString(fileData) ? fileData : name}
                  secondary={isString(fileData) ? '' : fData(size || 0)}
                  primaryTypographyProps={{ variant: 'subtitle2' }}
                  secondaryTypographyProps={{ variant: 'caption' }}
                />

                {!hideRemove && <IconButton edge="end" size="small" onClick={() => onRemove(fileData)}>
                  <Iconify icon={'eva:close-fill'} />
                </IconButton>}
              </ListItem>
            );
                
            } catch (error) {
              console.log("ERROR",error.message || error)
              return null; 
            }
          })}
        </AnimatePresence>
      </List>
      {(hasFile && !hideRemoveAll) && (
        <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
          <Button color="inherit" size="small" onClick={onRemoveAll}>
            {i18next.t('multiFilePreview.button.removeAll')}
          </Button>
          {/* <Button size="small" variant="contained">
            {i18next.t('multiFilePreview.button.uploadFiles')}
          </Button> */}
        </Stack>
      )}
    </>
  );
}
