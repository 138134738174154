import i18next from 'i18next';

export const UNIT_OPTIONS = [
  { key: 'count', value: 'productNewForm.unit.pcs' },
  { key: 'count', value: 'productNewForm.unit.units' },
  { key: 'count', value: 'productNewForm.unit.pairs' },
  { key: 'count', value: 'productNewForm.unit.dozens' },
  { key: 'count', value: 'productNewForm.unit.packs' },
  { key: 'count', value: 'productNewForm.unit.boxes' },
  { key: 'count', value: 'productNewForm.unit.cases' },
  { key: 'count', value: 'productNewForm.unit.bundle' },
  { key: 'weight', value: 'productNewForm.unit.tons' },
  { key: 'weight', value: 'productNewForm.unit.lb' },
  { key: 'weight', value: 'productNewForm.unit.kg' },
  { key: 'weight', value: 'productNewForm.unit.oz' },
  { key: 'weight', value: 'productNewForm.unit.g' },
  { key: 'weight', value: 'productNewForm.unit.t' },
  { key: 'volume', value: 'productNewForm.unit.gal' },
  { key: 'volume', value: 'productNewForm.unit.l' },
  { key: 'volume', value: 'productNewForm.unit.ml' },
  { key: 'volume', value: 'productNewForm.unit.ft3' },
  { key: 'volume', value: 'productNewForm.unit.m3' },
  { key: 'length', value: 'productNewForm.unit.in' },
  { key: 'length', value: 'productNewForm.unit.ft' },
  { key: 'length', value: 'productNewForm.unit.yd' },
  { key: 'length', value: 'productNewForm.unit.m' },
  { key: 'length', value: 'productNewForm.unit.cm' },
  { key: 'length', value: 'productNewForm.unit.mm' },
];

export const INVENTORY_CATALOGUE_QUERY_KEYS = {
  rawMaterialsConfig: ['create/raw-material/config'],
  rawMaterial: (id) => ['create/raw-material/material', id],
  deleteRawMaterial: ['create/raw-material/delete'],
  rawMaterials: ['tab/raw-materials'],
  manufacturedProduct: (id) => ['create/manufactured-product/product', id],

  manufacturedProductsConfig: ['create/manufactured-product/config'],

  customers: ['create/raw-material/customers'],
  product: (id) => ['create/raw-material/product', id],
};
