import { DEFAULT_HEADERS } from '../../constants';

// Provide arguments for query body in object form to keep it order-agnostic.
// New arguments can be added, e.g. productType, helping reduce mental load
export const createProductMutation = (body) => ({
  url: ('/' + body.storeId + '/products'),
  headers: DEFAULT_HEADERS,
  method: 'POST',
  // DB generate dateCreated and productId
  body: JSON.stringify({
    'storeID': body.storeId,
    'product': body.newProduct,
    'productType': body.productType,
  })
});