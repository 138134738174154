import { useState } from 'react';
// @mui
import { MenuItem, Stack } from '@mui/material';
// hooks
import useLocales from '../../hooks/useLocales';
// components
import Image from '../../components/Image';
import MenuPopover from '../../components/MenuPopover';
import { IconButtonAnimate } from '../../components/animate';

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const { allLang, currentLang, onChangeLang } = useLocales();

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          marginTop: "17px", 
          width: "40px !important" ,
          height: "40px !important",
          ...(open && { bgcolor: 'action.selected' }),
          display: "block !important"
        }}
      >
        <Image disabledEffect type="image/svg+xml" src={currentLang.icon} alt={currentLang.label} 
          sx={{
            width: 24, 
            height: 17
          }}
        />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          {allLang.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang.value}
              onClick={() => {
                onChangeLang(option.value);
                handleClose();
                window.location.reload(false);
              }}
            >
              <Image disabledEffect type="image/svg+xml" alt={option.label} src={option.icon} 
                sx={{ 
                  width: 28,
                  height: 20,
                  mr: 2 }} 
              />
              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
