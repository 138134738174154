export const EXPORT_TEMPLATE_COLUMNS_ID = [
  'manufacturerProductName',
  'option',
  'quantity',
  'receiverName',
  'receiverAddress',
  'receiverNumber',
  'zipcode',
  'customsNumber',
  'memo',
  'courier',
  'trackingNumber',
  'deliveryCost',
  'orderDate',
  'companyNameOrdering',
  'unitPrice',
];

export const NAVER_EXPORT_TEMPLATE_COLUMNS_ID = {
  manufacturerProductName: '상품명',
  option: '옵션정보',
  quantity: '수량',
  receiverName: '수취인명',
  receiverAddress: '통합배송지',
  receiverNumber: '수취인연락처1',
  zipcode: '우편번호',
  customsNumber: '개인통관고유부호',
  memo: '배송메세지',
  courier: '택배사',
  trackingNumber: '송장번호',
  deliveryCost: '배송비 합계',
  orderDate: '발송일',
  companyNameOrdering: '구매자명',
  unitPrice: '상품가격',
};
 
export const DOMESTIC = 'domestic';
export const FOREIGN = 'foreign';