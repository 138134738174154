import { createSlice } from '@reduxjs/toolkit';
import {
  getRolesFromAWS,
  deleteRoleFromAWS,
  getPostRoleToAWS,
  updateRoleToAWS,
  getUsersFromAWS,
  getUserFromAWS,
  deleteUserFromAWS,
  getPostUserToAWS,
  updateUserToAWS,
} from '../../../server/awsClient';
import { dispatch } from '../store';

const initialState = {
  error: null,
  isLoading: false,
  userManagement: {
    users: [],
    user: {},
    filterdUsers: [],
    isSearching: false,
    usersIsLoading: false,
    alertConfig: {
      open: false,
      message: '',
      severity: 'success',
    },
  },

  roleManagement: {
    roles: [],
    filterdRoles: [],
    isSearching: false,
  },

  roleCreationManagement: {
    roleName: '',
    permissions: {},
    filterdSidebarConfig: [],
    isSearching: false,
    alertConfig: {
      open: false,
      message: '',
      severity: 'success',
    },
  },
};

const slice = createSlice({
  name: 'adminPanel',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // #1 User Management Page
    setUsers(state, action) {
      state.isLoading = false;
      state.userManagement.usersIsLoading = false;
      state.userManagement.users = action.payload;
    },

    setUser(state, action) {
      state.isLoading = false;
      state.userManagement.user = action.payload;
    },

    deleteUserSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
    },

    createUserSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      state.userManagement.users.unshift(action.payload);
    },

    updateUserSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      const id = action.payload.id;
      const updatedUser = action.payload.user;
      state.userManagement.users = state.userManagement.users.filter((user) => user.userId !== id);
      state.userManagement.users.unshift(updatedUser);
    },

    addUser(state, action) {
      state.userManagement.users.unshift(action.payload);
    },

    deleteUser(state, action) {
      state.isLoading = false;
      const id = action.payload;
      state.userManagement.users = state.userManagement.users.filter((user) => user.userId !== id);
    },

    bulkDeleteUsers(state, action) {
      state.isLoading = false;
      const ids = action.payload;
      state.userManagement.users = state.userManagement.users.filter((user) => !ids.includes(user.id));
    },

    setFilterdUsers(state, action) {
      state.isLoading = false;
      state.userManagement.filterdUsers = action.payload;
    },

    setIsSearching(state, action) {
      state.userManagement.isSearching = action.payload;
    },

    setUsersIsLoading(state, action) {
      state.userManagement.usersIsLoading = action.payload;
    },

    setUserManagementAlertConfig(state, action) {
      state.userManagement.alertConfig = action.payload;
    },
    resetUserManagement(state) {
      state.userManagement = initialState.userManagement;
    },
    // #2 Role Management Page
    setRoles(state, action) {
      state.isLoading = false;
      state.roleManagement.roles = action.payload;
    },

    deleteRoleSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
    },

    createRoleSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
    },

    updateRoleSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
    },

    setRolesIsLoading(state, action) {
      state.roleManagement.rolesIsLoading = action.payload;
    },

    setFilterdRoles(state, action) {
      state.roleManagement.filterdRoles = action.payload;
    },

    setIsSearchingRole(state, action) {
      state.roleManagement.isSearching = action.payload;
    },

    // #3 Role Creation Management Page
    setRoleName(state, action) {
      state.roleCreationManagement.roleName = action.payload;
    },

    setPermissions(state, action) {
      state.roleCreationManagement.permissions = action.payload;
    },

    togglePermissionsItem(state, action) {
      const { key, value } = action.payload;
      const permissions = state.roleCreationManagement.permissions[key];
      if (!permissions) return state;
      permissions[value] = !permissions[value];
    },

    togglePermissionsAllItem(state, action) {
      const { key, value } = action.payload;
      const permissions = state.roleCreationManagement.permissions[key];
      if (!permissions) return state;
      let allChecked = true;
      for (let i in permissions) {
        if (!permissions[i]) {
          allChecked = false;
        }
      }
      if (allChecked) {
        for (let i in permissions) {
          permissions[i] = false;
        }
      } else {
        for (let i in permissions) {
          permissions[i] = true;
        }
      }
    },

    setFilterdSidebarConfig(state, action) {
      const items = action.payload;
      state.roleCreationManagement.filterdSidebarConfig = [
        {
          subheader: 'Sellpoint',
          items,
        },
      ];
    },

    setIsSearchingSidebarConfig(state, action) {
      state.roleCreationManagement.isSearching = action.payload;
    },

    setRoleCreationAlertConfig(state, action) {
      state.roleCreationManagement.alertConfig = action.payload;
    },
  },
});

export const {
  resetUserManagement,
  setUserManagementAlertConfig,
  setUsers,
  setUser,
  deleteUser,
  bulkDeleteUsers,
  setFilterdUsers,
  setUsersIsLoading,
  setIsSearching,
  addUser,
  setRoles,
  setFilterdRoles,
  setIsSearchingRole,
  setRolesIsLoading,
  setRoleName,
  setPermissions,
  togglePermissionsItem,
  togglePermissionsAllItem,
  setFilterdSidebarConfig,
  setIsSearchingSidebarConfig,
  setRoleCreationAlertConfig,
} = slice.actions;

export default slice.reducer;

export const selectUsers = (state) => state.adminPanel.userManagement.users;
export const selectUser = (state) => state.adminPanel.userManagement.user;
export const selectFilterdUsers = (state) => state.adminPanel.userManagement.filterdUsers;
export const selectIsSearching = (state) => state.adminPanel.userManagement.isSearching;
export const selectUsersIsLoading = (state) => state.adminPanel.userManagement.usersIsLoading;
export const selectAlertConfig = (state) => state.adminPanel.userManagement.alertConfig;
export const selectRoles = (state) => state.adminPanel.roleManagement.roles;
export const selectFilterdRoles = (state) => state.adminPanel.roleManagement.filterdRoles;
export const selectIsRolesLoading = (state) => state.adminPanel.roleManagement.rolesIsLoading;
export const selectIsSearchingRole = (state) => state.adminPanel.roleManagement.isSearching;
export const selectRoleName = (state) => state.adminPanel.roleCreationManagement.roleName;
export const selectPermissions = (state) => state.adminPanel.roleCreationManagement.permissions;
export const selectFilterdSidebarConfig = (state) => state.adminPanel.roleCreationManagement.filterdSidebarConfig;
export const selectIsSearchingSidebarConfig = (state) => state.adminPanel.roleCreationManagement.isSearching;

export const selectRoleCreationAlertConfig = (state) => state.adminPanel.roleCreationManagement.alertConfig;
export const selectError = (state) => state.adminPanel.error;
export const selectAdminPanelLoading = (state) => state.adminPanel.isLoading;

// ----------------------------------------------------------------------

export function getRolesFromStore(abortController) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getRolesFromAWS(abortController);
      dispatch(slice.actions.setRoles(response));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteRoleInStore(abortController, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      deleteRoleFromAWS(abortController, id)
        .then((response) => {
          dispatch(slice.actions.deleteRoleSuccess(response.data));
        })
        .catch((error) => {
          console.error(error);
          dispatch(slice.actions.hasError(error));
        });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function postRoleInStore(role) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      getPostRoleToAWS(role)
        .then((response) => {
          dispatch(slice.actions.createRoleSuccess(response.data));
        })
        .catch((error) => {
          console.error(error);
          dispatch(slice.actions.hasError(error));
        });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateRoleInStore(role) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      updateRoleToAWS(role)
        .then((response) => {
          dispatch(slice.actions.updateRoleSuccess(response.data));
        })
        .catch((error) => {
          console.error(error);
          dispatch(slice.actions.hasError(error));
        });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUsersFromStore(abortController) {
  return async () => {
    dispatch(slice.actions.startLoading());
    // userManagement.usersIsLoading
    dispatch(slice.actions.setUsersIsLoading(true));
    try {
      const response = await getUsersFromAWS(abortController);
      dispatch(slice.actions.setUsers(response));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserFromStore(inviteId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getUserFromAWS(inviteId);
      dispatch(slice.actions.setUser(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteUserInStore(abortController, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      deleteUserFromAWS(abortController, id)
        .then((response) => {
          dispatch(slice.actions.deleteUserSuccess(response.data));
        })
        .catch((error) => {
          console.error(error);
          dispatch(slice.actions.hasError(error));
        });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function postUserInStore(user) {
  // return async () => {
  dispatch(slice.actions.startLoading());
  try {
    return await getPostUserToAWS(user)
      .then((response) => {
        // console.log(response);
        dispatch(slice.actions.createUserSuccess(response.data));
        return response;
      })
      .catch((error) => {
        console.error(error);
        dispatch(slice.actions.hasError(error));
        return error;
      });
  } catch (error) {
    console.error(error);
    dispatch(slice.actions.hasError(error));
  }
  // };
}

export async function updateUserInStore(user) {
  // return async () => {
  dispatch(slice.actions.startLoading());
  try {
    await updateUserToAWS(user)
      .then((response) => {
        dispatch(slice.actions.updateUserSuccess(response.data));
      })
      .catch((error) => {
        console.error(error);
        dispatch(slice.actions.hasError(error));
      });
  } catch (error) {
    console.error(error);
    dispatch(slice.actions.hasError(error));
  }
  // };
}
