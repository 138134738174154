export const EXPORT_FIELD_NAME = [
  'id',
  ['buyer', 'company'], // [0] headerName | [1] field
  ['listedProductCode', 'productCode'],
  ['listedProductName', 'productName'],
  'option',
  'quantity',
  'receiverName',
  'shippingAddress',
  'zipcode',
  'receiverNumber',
  'productDescription',
  'customsNumber',
  'memo',
];

export const EXPORT_FIELD_NAME_WITH_DELIVERY = [
  EXPORT_FIELD_NAME[0],
  'courier',
  'trackingNumber',
  ...EXPORT_FIELD_NAME.slice(1),
];

export const EXPORT_ONLINE_PLATFORM_FIELD = {
  Naver:[
    "상품주문번호",
    "배송방법",
    "택배사",
    "송장번호"
  ],
  // 'Naver': [
  //   '상품주문번호',
  //   '주문번호',
  //   '배송속성',
  //   '풀필먼트사(주문 기준)',
  //   '배송방법(구매자 요청)',
  //   '배송방법',
  //   '택배사',
  //   '송장번호',
  //   '발송일',
  //   '판매채널',
  //   '구매자명',
  //   '구매자ID',
  //   '수취인명',
  //   '주문상태',
  //   '주문세부상태',
  //   '결제위치',
  //   '결제일',
  //   '상품번호',
  //   '상품명',
  //   '상품종류',
  //   '반품안심케어',
  //   '옵션정보',
  //   '옵션관리코드',
  //   '수량',
  //   '옵션가격',
  //   '상품가격',
  //   '상품별 할인액',
  //   '판매자 부담 할인액',
  //   '상품별 총 주문금액',
  //   '사은품',
  //   '발주확인일',
  //   '발송기한',
  //   '발송처리일',
  //   '송장출력일',
  //   '배송비 형태',
  //   '배송비 묶음번호',
  //   '배송비 유형',
  //   '배송비 합계',
  //   '제주/도서 추가배송비',
  //   '배송비 할인액',
  //   '판매자 상품코드',
  //   '판매자 내부코드1',
  //   '판매자 내부코드2',
  //   '수취인연락처1',
  //   '수취인연락처2',
  //   '통합배송지',
  //   '기본배송지',
  //   '상세배송지',
  //   '구매자연락처',
  //   '우편번호',
  //   '배송메세지',
  //   '출고지',
  //   '결제수단',
  //   '수수료 과금구분',
  //   '수수료결제방식',
  //   '네이버페이 주문관리 수수료',
  //   '매출연동 수수료',
  //   '정산예정금액',
  //   '매출연동수수료 유입경로',
  //   '개인통관고유부호',
  //   '주문일시',
  //   '구독신청회차',
  //   '구독진행회차',
  //   '배송희망일'
  // ],
  'Kakao': [
    '배송지/수신자정보 입력일',
    '주문상태',
    '주문번호',
    '채널상품번호',
    '상품명',
    '옵션',
    '수량',
    '배송방법',
    '택배사코드',
    '송장번호',
    '수령인명',
    '수령인연락처1',
    '하이픈포함 수령인연락처1',
    '수령인연락처2',
    '하이픈포함 수령인연락처2',
    '배송지주소',
    '우편번호',
    '배송메세지',
    '배송예정일',
    '주문일',
    '상품금액',
    '옵션금액',
    '판매자할인금액',
    '판매자쿠폰할인금액',
    '정산기준금액',
    '기본수수료',
    '노출추가수수료',
    '채널',
    '브랜드',
    '모델명',
    '판매자상품번호',
    '옵션코드',
    '최초배송비번호',
    '배송비지불방법',
    '배송비유형',
    '배송비금액',
    '유입경로',
    '톡딜그룹번호',
    '판매자할인쿠폰번호',
    '할인쿠폰명',
    '상품유형',
    'biz판매여부'
  ],
  'Tmon': [
    '배송번호',
    '딜번호',
    '주문번호',
    '택배사',
    '운송장번호',
    '발송예정일',
    '출고마감일',
    '지연발송예정일',
    '지연사유',
    '지연사유상세',
    '발송지연 최종 안내일',
    '옵션번호',
    '주문자명',
    '아이디',
    '주문자 연락처',
    '딜명',
    '옵션명',
    '옵션가',
    '구매수량',
    '총 주문금액',
    '결제완료일',
    '수취인명',
    '수취인연락처',
    '수취인주소',
    '수취인우편번호',
    '배송요청메모',
    '배송상태',
    '운송장등록시점',
    '파트너 CODE1',
    '파트너 CODE2',
    '파트너 CODE3',
    '파트너 CODE4',
    '파트너 CODE5',
    '추가송장',
    '추가문구',
    '개인통관고유부호',
    '도서산간배송비',
    '도서산간선결제',
    '배송권역구분',
    '배송비조건',
    '고객부담배송비',
    '출고일',
    '배송완료일',
    '배송유형',
    '상품관리코드',
    '주문시 배송방법'
  ],
  'Todayshouse': [
    '주문번호',
    '상품주문번호',
    '묶음배송그룹ID',
    '상품주문옵션번호',
    '주문일자',
    '판매자상품코드',
    '판매자옵션코드',
    '택배사코드',
    '운송장번호',
    '상품명',
    '오늘의집상품코드',
    '선택옵션',
    '수량',
    '주문메모',
    '오늘출발',
    '출고예정일',
    '배송예정일',
    '희망배송일',
    '배송유형',
    '주문자',
    '수취인명',
    '수취인연락처',
    '수취인우편번호',
    '수취인주소',
    '수취인주소상세',
    '배송메세지',
    '조립여부',
    '조립비',
    '선불배송비',
    '판매가',
    '공급가',
    '결제가(판매비/조립비/선불)',
    '통관부호',
    '배송메모'
  ],
  'TodaysHouse': [
    '주문번호',
    '상품주문번호',
    '묶음배송그룹ID',
    '상품주문옵션번호',
    '주문일자',
    '판매자상품코드',
    '판매자옵션코드',
    '택배사코드',
    '운송장번호',
    '상품명',
    '오늘의집상품코드',
    '선택옵션',
    '수량',
    '주문메모',
    '오늘출발',
    '출고예정일',
    '배송예정일',
    '희망배송일',
    '배송유형',
    '주문자',
    '수취인명',
    '수취인연락처',
    '수취인우편번호',
    '수취인주소',
    '수취인주소상세',
    '배송메세지',
    '조립여부',
    '조립비',
    '선불배송비',
    '판매가',
    '공급가',
    '결제가(판매비/조립비/선불)',
    '통관부호',
    '배송메모'
  ],
  'Coupang': [
    '번호',
    '묶음배송번호',
    '주문번호',
    '택배사',
    '운송장번호',
    '분리배송 Y/N',
    '분리배송 출고예정일',
    '주문시 출고예정일',
    '출고일(발송일)',
    '주문일',
    '등록상품명',
    '등록옵션명',
    '노출상품명(옵션명)',
    '노출상품ID',
    '옵션ID',
    '최초등록등록상품명/옵션명',
    '업체상품코드',
    '바코드',
    '결제액',
    '배송비구분',
    '배송비',
    '도서산간 추가배송비',
    '구매수(수량)',
    '옵션판매가(판매단가)',
    '구매자',
    '구매자전화번호',
    '수취인이름',
    '수취인전화번호',
    '우편번호',
    '수취인 주소',
    '배송메세지',
    '상품별 추가메시지',
    '주문자 추가메시지',
    '배송완료일',
    '구매확정일자',
    '개인통관번호(PCCC)',
    '통관용수취인전화번호',
    '기타',
    '결제위치',
    '배송유형'
  ],
  'Gmarket': [
    '아이디',
    '주문일자(결제확인전)',
    '주문번호',
    '수령인명',
    '구매자명',
    '발송정책',
    '주문상태',
    '발송마감일',
    '택배사명(발송방법)',
    '송장번호',
    '상품번호',
    '상품명',
    '수량',
    '주문옵션',
    '추가구성',
    '사은품',
    '사은품 관리코드',
    '덤',
    '덤 관리코드',
    '판매단가',
    '판매금액',
    '판매자 관리코드',
    '판매자 상세관리코드',
    '수령인 휴대폰',
    '수령인 전화번호',
    '우편번호',
    '주소',
    '배송시 요구사항',
    '배송비',
    '배송비 금액',
    '배송번호',
    '배송지연사유',
    '수령인 통관정보',
    'SKU번호 및 수량',
    '구매자ID',
    '구매자 휴대폰',
    '구매자 전화번호',
    '판매방식',
    '주문종류',
    '장바구니번호(결제번호)',
    '결제완료일',
    '주문확인일자',
    '발송예정일',
    '정산예정금액',
    '서비스이용료',
    '판매자쿠폰할인',
    '구매쿠폰적용금액',
    '(옥션)우수회원할인',
    '복수구매할인',
    '스마일캐시적립',
    '제휴사명'
  ],
  'Cafe24': [
    '결제일시(입금확인일)',
    '주문번호(원룸만들기)',
    '주문상품고유번호',
    '품목별주문번호',
    '배송번호',
    '주문상품명',
    '상품옵션(기본)',
    '자체품목코드',
    '주문수량',
    '상품구매금액',
    '주문자명',
    '수령인',
    '수령인전화번호',
    '수령인연락처',
    '수령인우편번호',
    '수령인주소(전체)',
    '결제구분',
    '배송메시지',
    '결제금액',
    '공급사배송비',
    '발주처',
    '택배사**',
    '주문번호',
    '상품코드',
    '추가구성상품'
  ]
}

export const EXPORT_ONLINE_PLATFORM_DELIVERY = {
  'Naver': {
    '택배사': 'courier',
    '송장번호': 'trackingNumber',
  },
  'Kakao': {
    '택배사코드': 'courier',
    '송장번호': 'trackingNumber',
  },
  'Tmon': {
    '택배사': 'courier',
    '운송장번호': 'trackingNumber',
  },
  'Todayshouse': {
    '택배사코드': 'courier',
    '운송장번호': 'trackingNumber',
  },
  'TodaysHouse': {
    '택배사코드': 'courier',
    '운송장번호': 'trackingNumber',
  },
  'Coupang': {
    '택배사': 'courier',
    '운송장번호': 'trackingNumber',
  },
  'Gmarket': {
    '택배사명(발송방법)': 'courier',
    '송장번호': 'trackingNumber',
  },
  'Cafe24': {
    '택배사**': 'courier',
    '배송번호': 'trackingNumber',
  },
}
