import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Link, Stack, Checkbox, TextField, IconButton, InputAdornment, FormControlLabel, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { getStoreId, getStoreType, getEmail, setUserSession, getGivenName, getFamilyName } from '../TempAuthService';
import i18next from 'i18next';
import InfoIcon from '@mui/icons-material/Info';
import { Auth } from 'aws-amplify';
import {
  Alert,
  Button
} from '@mui/material';
import { useAccountSetting } from 'src/contexts/AccountSettingContext';
import { SettingsAccessibility } from '@mui/icons-material';

async function loginUser(username, password) {
  try {
    const user = await Auth.signIn(username, password);
    return user;
  } catch (error) {
    throw error;
  }
}

export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState(null);
  const { fetchData } = useAccountSetting();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email(i18next.t('loginForm.message.verification1')).required(i18next.t('loginForm.message.verification2')),
    password: Yup.string().required(i18next.t('loginForm.message.verification3'))
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: (values, { setSubmitting }) => {
      loginUser(values.email.toLowerCase(), values.password)
        .then(async (user) => {
          var accountType = "";
          if (user.attributes["custom:account_type"] === "Distributor") {
            accountType = "mm";
          }
          var givenName = user.attributes['given_name'];
          var familyName = user.attributes['family_name'];
          var storeId = user.attributes["custom:store_id"];
          var email = user.attributes["email"];

          await setSession(storeId, accountType, email, givenName, familyName);
          navigateToDashboard();
          setSubmitting(false);
        })
        .catch((error) => {
          alert(error.message);
          setSubmitting(false);
        }
      );
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };


  const setSession = async (storeId, storeType, email, givenName, familyName) => {
    setUserSession(storeId, storeType, email, givenName, familyName);
    localStorage.removeItem('previousPath');
    await fetchData();
  }

  const navigateToDashboard = () => {
    navigate('/dashboard/commandcentral', { replace: true });
  }

  async function checkUserSession() {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      setUser(currentUser);
    } catch (error) {
    }
  }

  useEffect(()=> {
    checkUserSession();
  },[])

  useEffect(() => {
    const storeId = getStoreId();
    const storeType = getStoreType();
    const storeEmail = getEmail();
    const givenName = getGivenName();
    const familyName = getFamilyName();
    const settingSession = async () => {
      await setSession(storeId, storeType, storeEmail, givenName, familyName);
    };
    const settingSessionCurrentUser = async (storeId, storeType, storeEmail) => {
      await setSession(storeId, storeType, storeEmail, givenName, familyName)
    }
    //Checks if user has clicked 'Remember me'.
    //If it was clicked, it fetches user data, populates the current session and navigates
    if (storeId && storeType && storeEmail) {
      settingSession();
      navigateToDashboard();
    }
    else if (user) {
      const attributes = user["attributes"];
      const storeId = attributes["custom:store_id"];
      const storeType = attributes["custom:account_type"];
      const email = attributes["email"];
      settingSessionCurrentUser(storeId, storeType, email);
      navigateToDashboard();
    }
  }, [user]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Alert
          iconMapping={{ info: <InfoIcon fontSize="inherit" /> }}
          variant="standard"
          severity="info"
        >
          {i18next.t('login.demoAccount')} <span style={{ fontWeight: 'bold' }}>demo@sellpoint.ai</span> / {i18next.t('login.password')} <span style={{ fontWeight: 'bold' }}>Demo123!</span>
        </Alert>
        <Stack spacing={3} sx={{mt: 4}}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label={i18next.t('loginForm.label.emailAddress')}
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label={i18next.t('loginForm.label.password')}
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 1 }}>
          {/* <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label={i18next.t('loginForm.label.rememberMe')}
          /> */}
          <Link component={RouterLink} variant="subtitle2" to="/auth/reset">
            {i18next.t('loginForm.text1')}
          </Link>
        </Stack>
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          {i18next.t('loginForm.button1')}
        </LoadingButton>
      </Form>
      <Box sx={{ marginTop: '25px', textAlign: 'right' }}>
        {i18next.t('loginForm.text2')} &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/auth/register">
          {i18next.t('loginForm.text3')}
        </Link>
      </Box>
    </FormikProvider>
  );
}