export const USER_MESSAGE_ENTRY = (message) => ({
  message: [
    {
      content: message,
      role: 'user',
    },
    {
      content: 'loading....',
      isLoading: true,
      role: 'assistant',
    },
  ],
  created_at: new Date().toISOString(),
});

export const GPT_MESSAGE_ENTRY = (message) => ({
  message: [
    {
      content: message,
      role: 'assistant',
    },
  ],
  created_at: new Date().toISOString(),
});
