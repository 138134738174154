import { DEFAULT_HEADERS } from '../../constants';

// Provide arguments for query body in object form to keep it order-agnostic.
// New arguments can be added, e.g. customerType, helping reduce mental load
export const createCustomerMutation = (body) => ({
  url: ('/' + body.storeId + '/customers'),
  headers: DEFAULT_HEADERS,
  method: 'POST',
  body: JSON.stringify({
    'storeID': body.storeId,
    'customer': body.newCustomer,
  })
});