import { useTranslation } from 'react-i18next'; // needed
// import i18n from "../locales/i18n"; // need this 
import { enUS, koKR, zhCN, jaJP, viVN } from '@mui/material/locale';

// ----------------------------------------------------------------------

const LANGS = [
  {
    label: '한글',
    value: 'kr',
    systemValue: koKR,
    icon: "/static/icons/ic_flag_kr.svg"
  },{
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: "/static/icons/ic_flag_us.svg"
  },
  {
    label: '中文',
    value: 'cn',
    systemValue: zhCN,
    icon: "/static/icons/ic_flag_cn.svg"
  },
  {
    label: '日本語',
    value: 'ja',
    systemValue: jaJP,
    icon: "/static/icons/ic_flag_jp.svg"
  },
  {
    label: 'Tiếng Việt',
    value: 'vi',
    systemValue: viVN,
    icon: "/static/icons/ic_flag_vn.svg"
  },
];

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang = LANGS.find((_lang) => _lang.value === langStorage) || LANGS[1];

  const handleChangeLanguage = (newlang) => {
    i18n.changeLanguage(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS,
  };

}


