import { useLocation, Outlet } from "react-router-dom";
import MainNavbar from "./MainNavbar";
import MainFooter from "./MainFooter";

// ----------------------------------------------------------------------
export default function MainLayout() {
  const { pathname } = useLocation();
  const isLanding = pathname === '/';
  const isAbout = pathname === '/about';
  // const isLogin = pathname === "/login";
  // const isRegister = pathname === "/register";

  return (
    <>
      <div>
        <Outlet />
      </div>
      { isLanding && <MainNavbar isHome={true} /> }
      { isAbout && <MainNavbar /> }
      { (isLanding || isAbout) && <MainFooter /> }
    </>
  );
}
