import * as XLSX from 'xlsx';

import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { getFullKoreanName, getOnlyNumbers, getTodayInfo } from './common';
// import * as path from 'path'; // Assuming you are using Node.js
import { BATCH_EXCEL_COLUMNS, EXCEL_COLUMNS, formatCreationDate, formatDate1 } from 'src/constants/tax-archive';
// import { LegendToggleTwoTone } from '@mui/icons-material';
import _ from 'lodash';

export const getSplitInvoicesByMonth = (invoices) => {
  if (!invoices) return new Map();
  const monthMap = new Map();
  invoices?.forEach((invoice) => {
    const month = Number(invoice.dateIssued.split('-')[1]);
    if (!monthMap.has(month)) {
      monthMap.set(month, []);
    }
    monthMap.get(month).push(invoice);
  });
  return monthMap;
};

export const getFileSizeByByte = (byte) => {
  try {
    if (byte < 1024) return `${byte}B`;
    else if (byte < 1024 * 1024) return `${(byte / 1024).toFixed(2)}KB`;
    else if (byte < 1024 * 1024 * 1024) return `${(byte / (1024 * 1024)).toFixed(2)}MB`;
    else return `${(byte / (1024 * 1024 * 1024)).toFixed(2)}GB`;
  } catch (error) {
    return '0MB';
  }
};

export function downloadXLSXFile({ objArr, title }) {
  // Create a new workbook
  const wb = XLSX.utils.book_new();

  // Add a new worksheet
  const ws = XLSX.utils.json_to_sheet(objArr);

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  // Convert the workbook to a binary string
  const wbBinary = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

  // Convert the binary string to a Blob object
  const blob = new Blob([s2ab(wbBinary)], { type: 'application/octet-stream' });

  // Use FileSaver.js to save the file

  saveAs(blob, `${title}.xlsx`);
}

const getExcelColumns = (invoices) => {
  if (invoices.length > 101) {
    console.log(invoices.length, 'getExcelColumns batch');
    return BATCH_EXCEL_COLUMNS.map((column) => column.name);
  }
  return EXCEL_COLUMNS.map((column) => column.name);
};

export const downloadTaxInvoiceExcel = ({invoices, storeInfo, type}) => {
  const zip = new JSZip();
  const splittedInvoices = invoices?.reduce((acc, invoice) => {
    const { year, month } = getTodayInfo(new Date(invoice.dateIssued));
    const key = `${year}-${month}`;

    if (!acc[key]) acc[key] = [];

    // Add invoice to the appropriate category (payment or expense)
    acc[key].push(invoice);
    return acc;
  }, {});

  Object.keys(splittedInvoices).forEach((month) => {
    const invoices = splittedInvoices[month]; 
    const chunkedinvoices = invoices.length > 100 ? chunkArray(invoices, 100) : [invoices];

    chunkedinvoices.forEach((chunkInvoice, index) => {
      const baseRows = getBaseRows();
      const rows = [...baseRows, ...formatToExcelData({ invoices: chunkInvoice, storeInfo, type })];
      const columns = EXCEL_COLUMNS.map((column) => column.name);
      const wb = XLSX.utils.book_new();
      const sheet = XLSX.utils.json_to_sheet(rows, { header: columns, skipHeader: true });
      // const sheet = XLSX.utils.json_to_sheet(rows, { header: columns, skipHeader: true });
      XLSX.utils.book_append_sheet(wb, sheet, type);
      const wbBinary = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
      const blob = new Blob([s2ab(wbBinary)], { type: 'application/octet-stream' });
      const folderPath = `Tax Archive(${type})/${month}`;
      const fileName = `${type}${chunkedinvoices.length > 1 ? `_${index + 1}` : ''}.xlsx`;
      zip.folder(folderPath).file(fileName, blob);
    });
  })


  zip.generateAsync({ type: 'blob' }).then((content) => {
    saveAs(content, `Tax Archive(${type}).zip`);
  });
}

export const issueTaxInvoice = ({invoices, storeInfo, type="" }) => {
  const chunkedinvoices = invoices.length > 100 ? chunkArray(invoices, 100) : [invoices];
  const taxInvoices = chunkedinvoices.map((chunkInvoice, index) => {
    const rows = [...formatToRegisterPopbill({ invoices: chunkInvoice, storeInfo, index, type })];
    return rows;
  })
  return taxInvoices;
}

export const issueUploadedTaxInvoice = ({invoices, storeInfo}) => {
  const taxInvoices = invoices.map((invoiceList, i) => {
    return invoiceList.data.map((invoice, index)=>{
      return formatToRegisterPopbillFromFiles({ fields: invoiceList.matchfields, invoice, storeInfo, index, i });
    })
  })
  return taxInvoices;
}

export const downloadBatchExcel = ({ invoices, storeInfo, id }) => {
  const zip = new JSZip();
  // console.log(invoices);
  const splittedInvoices = invoices?.reduce((acc, invoice) => {
    const { year, month } = getTodayInfo(new Date(invoice.dateIssued));
    const key = `${year} Q${Math.ceil(month / 3)}`;
    const subKey = month.toString().padStart(2, '0');

    if (!acc[key]) acc[key] = {};

    if (id === "payment") {
      if (!acc[key][subKey]) acc[key][subKey] = { payment: []};
      acc[key][subKey].payment.push(invoice);
    }
    else {
      if (!acc[key][subKey]) acc[key][subKey] = { expense: []};
      acc[key][subKey].expense.push(invoice);
    }
    // Add invoice to the appropriate category (payment or expense)

    return acc;
  }, {});

  // iterate by quarter
  Object.keys(splittedInvoices).forEach((quarter) => {
    // iterate by month
    Object.keys(splittedInvoices[quarter]).forEach((month) => {
      // iterate by type
      const type = id;
        const invoices = splittedInvoices[quarter][month][type];
        const chunkedinvoices = invoices.length > 1000 ? chunkArray(invoices, 1000) : [invoices];
        // iterate by chunk

        // FIXME: ORDER BY INDEX
        chunkedinvoices.forEach((chunkInvoice, index) => {
          const rows = getExcelRows({ invoices: chunkInvoice, storeInfo });
          const columns = getExcelColumns(chunkInvoice);
          const wb = XLSX.utils.book_new();
          const sheet = XLSX.utils.json_to_sheet(rows, { header: columns, skipHeader: true });
          // const sheet = XLSX.utils.json_to_sheet(rows, { header: columns, skipHeader: true });
          XLSX.utils.book_append_sheet(wb, sheet, type);
          const wbBinary = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
          const blob = new Blob([s2ab(wbBinary)], { type: 'application/octet-stream' });
          const folderPath = `Tax Archive/${quarter}-${month}`;
          const fileName = `${type}${chunkedinvoices.length > 1 ? `_${index + 1}` : ''}.xlsx`;
          zip.folder(folderPath).file(fileName, blob);
        });
    });
  });

  zip.generateAsync({ type: 'blob' }).then((content) => {
    saveAs(content, 'Tax Archive.zip');
  });
};

function chunkArray(array, size) {
  const chunkedArr = [];
  for (let i = 0; i < array.length; i += size) {
    chunkedArr.push(array.slice(i, i + size));
  }
  return chunkedArr;
}

export function estimateXLSXSize(objArr) {
  // Create a new workbook
  const wb = XLSX.utils.book_new();

  // Add a new worksheet
  const ws = XLSX.utils.json_to_sheet(objArr);

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  // Convert the workbook to a binary string
  const wbBinary = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

  // Calculate the size of the binary data
  const binarySize = wbBinary.length; // in bytes

  // Return the size in bytes
  return getFileSizeByByte(binarySize);
}

// Call the function to download the XLSX file
// downloadXLSXFile();
function s2ab(s) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xff;
  }
  return buf;
}

// const getColumnName = (field) => {
//   const column = EXCEL_COLUMNS.find((column) => column.field === field);
//   if (!column) return field;
//   return column.name;
// };

const getColumnRowSchema = (columns) => {
  // FIXME: should be sorted by index
  const sortedColumns = columns.sort((a, b) => a.index - b.index);
  const row = sortedColumns.reduce((acc, column) => {
    acc[column.name] = column.name;
    return acc;
  }, {});
  return row;
};

const getColumnRow = () => {
  return getColumnRowSchema(EXCEL_COLUMNS);
};

const getBatchColumnRow = () => {
  // console.log('getBatchColumnRow');
  return getColumnRowSchema(BATCH_EXCEL_COLUMNS);
};

const convertKeyToColumnNameSchema = ({ obj, columns }) => {
  const newObj = {};
  const getColumnName = (field) => {
    const column = columns.find((column) => column.field === field);
    if (!column) return field;
    return column.name;
  };
  Object.keys(obj).forEach((key) => {
    const columnName = getColumnName(key);
    newObj[columnName] = obj[key];
  });
  return newObj;
};

const convertKeyToColumnName = (obj) => {
  return convertKeyToColumnNameSchema({ obj, columns: EXCEL_COLUMNS });
};

const convertKeyToBatchColumnName = (obj) => {
  return convertKeyToColumnNameSchema({ obj, columns: BATCH_EXCEL_COLUMNS });
};

const getBaseRows = () => {
  const columnRow = getColumnRow();
  return [{}, {}, {}, {}, {}, columnRow];
};

const getBatchBaseRows = () => {
  const columnRow = getBatchColumnRow();
  console.log(columnRow);
  return [{}, {}, {}, {}, {}, columnRow];
};

const getExcelRows = ({ invoices, storeInfo }) => {
  if (invoices.length > 100) {
    const baseRows = getBatchBaseRows();
    console.log(baseRows);
    const excelRows = [...baseRows, ...formatToBatchExcelData({ invoices, storeInfo })];
    return excelRows;
    // return getBatchExcelRows({ invoices });
  }
  const baseRows = getBaseRows();
  const excelRows = [...baseRows, ...formatToExcelData({ invoices, storeInfo })];
  return excelRows;
};
// const expenseRows = [...baseRows, ...formatToExcelData({ invoices: expenseInvoices, storeName })];

const getInformationByType = ({ invoice, storeInfo }) => {
  return {
    expense: {
      supplierName: invoice?.fromComp,
      recipientName: storeInfo.storeName,
      receiptType: '02',
      supplierRegistrationNumber: getOnlyNumbers(invoice?.businessRegistrationNumber) || '-',
      supplierPersonalName: invoice?.businessOwner || '-',
      recipientRegistrationNumber: getOnlyNumbers(storeInfo?.businessRegistrationNumber) || '-',
      // recipientRegistrationNumber: 'Me',
      recipientPersonalName: getFullKoreanName(storeInfo),
      // recipientPersonalName: 'Me',
    },
    payment: {
      supplierName: storeInfo.storeName,
      recipientName: invoice?.fromComp,
      receiptType: '01',
      // supplierRegistrationNumber: 'me',
      supplierRegistrationNumber: getOnlyNumbers(storeInfo.businessRegistrationNumber) || '-',
      // supplierRegistrationNumber: 'Me',
      supplierPersonalName: getFullKoreanName(storeInfo),
      // supplierPersonalName: 'Me',
      recipientRegistrationNumber: getOnlyNumbers(invoice?.businessRegistrationNumber) || '-',
      recipientPersonalName: invoice.businessOwner,
    },
  }[invoice.type];
};

const getInformationByInvoiceType = ({ invoice, storeInfo, type }) => {
  console.log(invoice, type)
  return {
    expense: {
      supplierName: invoice?.customerCompany || invoice?.client || invoice?.companyName,
      recipientName: storeInfo.storeName,
      receiptType: '청구',
      supplierRegistrationNumber: getOnlyNumbers(invoice?.businessRegistrationNumber) || '-',
      supplierPersonalName: invoice?.businessOwner || '-',
      recipientRegistrationNumber: getOnlyNumbers(storeInfo?.businessRegistrationNumber) || '-',
      // recipientRegistrationNumber: 'Me',
      recipientPersonalName: getFullKoreanName(storeInfo),
      // recipientPersonalName: 'Me',
    },
    payment: {
      supplierName: storeInfo.storeName,
      recipientName: invoice?.customerCompany || invoice?.client || invoice?.companyName,
      receiptType: '영수',
      // supplierRegistrationNumber: 'me',
      supplierRegistrationNumber: getOnlyNumbers(storeInfo.businessRegistrationNumber) || '-',
      // supplierRegistrationNumber: 'Me',
      supplierPersonalName: getFullKoreanName(storeInfo),
      // supplierPersonalName: 'Me',
      recipientRegistrationNumber: getOnlyNumbers(invoice?.businessRegistrationNumber) || '-',
      recipientPersonalName: invoice.businessOwner,
    },
  }[type];
};

const formatToBatchExcelData = ({ invoices, storeInfo }) => {
  const formattedInvoices = invoices
    .map((invoice) => {
      const {
        supplierName,
        recipientName,
        receiptType,
        supplierRegistrationNumber,
        recipientRegistrationNumber,
        supplierPersonalName,
        recipientPersonalName,
      } = getInformationByType({ invoice, storeInfo });

      return {
        typeOfElectronicInvoice: '01',
        creationDate: formatCreationDate(invoice.dateIssued),
        recipientRegistrationNumber: recipientRegistrationNumber,
        // recipientRegistrationNumber: '공급받는자 등록번호\n("-" 없이 입력)',
        // businessRegistrationNumber
        recipientCompanyName: supplierName,
        // recipientCompanyName: '공급받는자 상호',
        recipientName: recipientName,
        // totalSupplyValue: '공급가액\n합계',
        totalSupplyValue: invoice.total,
        totalTaxAmount: invoice.taxes || 0,
        date1: formatDate1(invoice.dateIssued),
        // date1: '일자1\n(2자리, 작성년월 제외)',
        supplyAmount1: invoice.total,
        taxAmount1: invoice.taxes || 0,
        receiptType: receiptType,
      };
    })
    .map(convertKeyToBatchColumnName);

  return formattedInvoices;
};

const formatToExcelData = ({ invoices, storeInfo, type = "" }) => {

  const formattedInvoices = invoices
    .map((invoice) => {
      const {
        supplierName,
        recipientName,
        receiptType,
        supplierRegistrationNumber,
        recipientRegistrationNumber,
        supplierPersonalName,
        recipientPersonalName,
      } = type === "" ? getInformationByType({ invoice, storeInfo }): getInformationByInvoiceType({ invoice, storeInfo, type});
      return {
        typeOfElectronicInvoice: '01',
        creationDate: formatCreationDate(invoice.dateIssued),
        supplierRegistrationNumber: supplierRegistrationNumber,
        // supplierRegistrationNumber: '공급자 등록번호\n("-" 없이 입력)', // idk V 1
        supplierName: supplierName,
        // supplierName: '공급자 상호',
        supplierPersonalName: supplierPersonalName,
        recipientRegistrationNumber: recipientRegistrationNumber,
        // recipientRegistrationNumber: '공급받는자 등록번호\n("-" 없이 입력)', // V 3
        recipientName: recipientName,
        // recipientName: '공급받는자 상호',
        recipientPersonalName: recipientPersonalName,
        // recipientPersonalName: invoice.toComp,
        // recipientPersonalName: '공급받는자 성명',
        totalSupplyAmount: invoice.total,
        // totalSupplyAmount: '공급가액\n합계',
        totalTaxAmount: invoice.taxes || 0,
        date1: formatDate1(invoice.dateIssued),
        // date1: '일자1\n(2자리, 작성년월 제외)',
        supplyAmount1: invoice.total,
        // supplyAmount1: '공급가액1',
        taxAmount1: invoice.taxes || 0,
        // taxAmount1: '세액1', // V
        receiptType: receiptType === "영수" ? '01' : (receiptType === "청구" ? '02' : receiptType),
        // receiptType: '01',
        // receiptType: '영수(01),\n청구(02)',
      };
    })
    .map(convertKeyToColumnName);

  return formattedInvoices;
};


const formatToRegisterPopbill = ({ invoices, storeInfo, type = "", index }) => {
  const formattedInvoices = invoices
    .map((invoice, lindex) => {
      let invoiceType = type || invoice.type;
      const {
        supplierName,
        recipientName,
        receiptType,
        supplierRegistrationNumber,
        recipientRegistrationNumber,
        supplierPersonalName,
        recipientPersonalName,
      } = getInformationByInvoiceType({ invoice, storeInfo, type: invoiceType});
      return {
        issueType: invoiceType === 'payment' ? '정발행' : '역발행',
        taxType: '과세',
        chargeDirection: invoiceType === 'payment' ? '정과금' : '역과금',
        writeDate: formatCreationDate(invoice.dateIssued),
        purposeType: receiptType,
        supplyCostTotal: invoice.batchPayment? invoice.batchPayment.subtotal : invoice.total, 
        taxTotal: invoice.batchPayment? invoice.batchPayment.taxes : invoice.taxes || 0, 
        totalAmount: invoice.batchPayment ? invoice.batchPayment.total : (invoice.total + (invoice.taxes || 0)),
        invoicerMgtKey: new Date().getTime() + index + '-' + lindex, //정발행시 필수
        invoicerCorpNum: supplierRegistrationNumber,
        invoicerCorpName: supplierName,
        invoicerCEOName: supplierPersonalName,
        invoiceeMgtKey: new Date().getTime() + index + '-' + lindex, //역발행시 필수
        invoiceeType: recipientName === "In Person Customer" ? "개인" : "사업자",
        invoiceeCorpNum: recipientRegistrationNumber,
        invoiceeCorpName: recipientName,
        invoiceeCEOName: recipientPersonalName,

      }
    })
    .map(convertKeyToColumnName);

  return formattedInvoices;
};


const formatToRegisterPopbillFromFiles = ({fields, invoice, storeInfo, index, i}) => {
  let type = undefined;
  let paymentRegNumIndex = fields.find((field)=>field.name === 'supplierRegistrationNumber').index;
  let expenseRegNumIndex = fields.find((field)=>field.name === 'recipientRegistrationNumber').index;

  if ( paymentRegNumIndex !== -1 && invoice[paymentRegNumIndex] === storeInfo?.popbillCorpNum) {
    type = 'payment';
  }
  else if (expenseRegNumIndex !== -1 && invoice[expenseRegNumIndex] === storeInfo?.popbillCorpNum) { 
    type = 'expense';
  }
  else if (paymentRegNumIndex === -1 || expenseRegNumIndex === -1) {
    alert("파일 내에 사업자등록번호를 찾을 수 없습니다.")
    return;
  }
  else if (!storeInfo?.popbillCorpNum){
    alert("팝빌 회원 등록을 먼저 진행해주세요");
    return;
  }

  if (type === undefined) {
    alert("사업자등록번호가 정확하지 않습니다.")
    return;
  }

  return {
    issueType: type === 'payment' ? '정발행' : '역발행',
    taxType: '과세',
    chargeDirection: type === 'payment' ? '정과금' : '역과금',
    writeDate: invoice[fields.find((field)=>field.name === 'creationDate').index],
    purposeType: invoice[fields.find((field)=>field.name === 'receiptType').index],
    supplyCostTotal: invoice[fields.find((field)=>field.name === 'totalSupplyAmount').index], 
    taxTotal: invoice[fields.find((field)=>field.name === 'totalTaxAmount').index] || 0,
    totalAmount: (Number(invoice[fields.find((field)=>field.name === 'totalSupplyAmount').index])
      + Number((invoice[fields.find((field)=>field.name === 'totalTaxAmount').index] || 0))).toString(),
    invoicerMgtKey: type === 'payment' ? new Date().getTime() + '-' + i + index : '', //정발행시 필수
    invoicerCorpNum: invoice[fields.find((field)=>field.name === 'supplierRegistrationNumber').index],
    invoicerCorpName: invoice[fields.find((field)=>field.name === 'supplierName').index],
    invoicerCEOName: invoice[fields.find((field)=>field.name === 'supplierPersonalName').index],
    invoiceeMgtKey: type === 'expense' ? new Date().getTime() + '-' + i + index : '', //역발행시 필수
    invoiceeType: invoice[fields.find((field)=>field.name === 'recipientName').index] === "In Person Customer" ? "개인" : "사업자",
    invoiceeCorpNum: invoice[fields.find((field)=>field.name === 'recipientRegistrationNumber').index],
    invoiceeCorpName: invoice[fields.find((field)=>field.name === 'recipientName').index],
    invoiceeCEOName: invoice[fields.find((field)=>field.name === 'recipientPersonalName').index],
  }
}

export const matchFieldNames = (fields) => {
  let mappedFields = [];

  if (!fields) return;

  const trimAll = (str) => {
    return str.replace(/[\s]/g, '');
  }
  EXCEL_COLUMNS.forEach((column)=>{
    let matchIndex = fields.findIndex((field) => {
      const cutString = trimAll(field);
      return cutString === trimAll(column.name) || trimAll(column.name).includes(cutString)});
    if (matchIndex !== -1) {
      let matchField = {
        index: matchIndex,
        name: column.field,
        value: fields[matchIndex]
      }
      mappedFields.push(matchField);
    }
  })

  const fieldsToCheck = ['supplierRegistrationNumber', 'creationDate', 'receiptType', 'totalSupplyAmount', 'totalTaxAmount', 'supplierName', 'supplierPersonalName', 'recipientName', 'recipientRegistrationNumber', 'recipientPersonalName']
  const allFieldsExist = fieldsToCheck.every(name => mappedFields.some(item => item.name === name));

  if (!allFieldsExist) {
    alert("업로드 된 파일에 필수 항목이 존재하지 않습니다.");
    return;
  }
  return mappedFields;
}