import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  tutorialStatus: true,
  createTemplate: {
    // raw material
    rawMaterials: {
      dynamicFormValues: {
        options: [],
      },
      alertConfig: { open: false, message: '', severity: 'success' },
      buttonIsLoading: false,
      // isLoading: false,
      isMutating: false,
    },
    // vendor products
    vendorProducts: {
      dynamicFormValues: {},
      alertConfig: { open: false, message: '', severity: 'success' },
    },
    // manufactured product
    manufacturedProducts: {
      dynamicFormValues: {
        options: {},
      },
      alertConfig: { open: false, message: '', severity: 'success' },
      buttonIsLoading: false,
      // isLoading: false,
      isMutating: false,
      currentProduct: {},
      ingredients: [],
      packagings: [],
      operations: []
    },
  },
  tab: {
    rawMaterials: {
      filterdData: [],
      originalData: [],
      stockHistory: [],
      isLoading: false,
      updatedStockHistory: [],
      materialDetails: {}
    },
    manufacturedProducts: {
      filteredData: [],
      originalData: [],
      isLoading: false,
    },
    vendorProducts: {
      stockHistory: []
    },
  },
};
const slice = createSlice({
  name: 'inventoryCatalogue',
  initialState,
  reducers: {
    // ✅ vendor product
    setVendorProductDynamicFormValues(state, action) {
      state.createTemplate.vendorProducts.dynamicFormValues = action.payload;
    },
    setVendorProductStockHistory(state, action) {
      state.createTemplate.vendorProducts.stockHistory = action.payload;
    },
    setVendorProductAlertConfig(state, action) {
      state.createTemplate.vendorProducts.alertConfig = action.payload;
    },

    // ✅ raw material
    // create raw material template
    setCreateTemplateOptions(state, action) {
      state.createTemplate.rawMaterials.dynamicFormValues.options = action.payload;
    },
    addCreateTemplateOptions(state, action) {
      state.createTemplate.rawMaterials.dynamicFormValues.options.push(action.payload);
    },
    deleteCreateTemplateOptions(state, action) {
      state.createTemplate.rawMaterials.dynamicFormValues.options =
        state.createTemplate.rawMaterials.dynamicFormValues.options.filter((item) => item.id !== action.payload);
    },
    updateCreateTemplateOptions(state, action) {
      const row = action.payload;
      const index = state.createTemplate.rawMaterials.dynamicFormValues.options.findIndex((item) => item.id === row.id);
      state.createTemplate.rawMaterials.dynamicFormValues.options[index] = row;
    },
    setRawMaterialDynamicFormValues(state, action) {
      state.createTemplate.rawMaterials.dynamicFormValues = action.payload;
    },

    setCreateTemplateAlertConfig(state, action) {
      state.createTemplate.rawMaterials.alertConfig = action.payload;
    },

    setRawMaterialCreateTemplateButtonIsLoading(state, action) {
      state.createTemplate.rawMaterials.buttonIsLoading = action.payload;
    },

    setRawMaterialCreateTemplateIsMutating(state, action) {
      state.createTemplate.rawMaterials.isMutating = action.payload;
    },

    resetRawMaterialCreateTemplate(state, action) {
      state.createTemplate.rawMaterials = {
        dynamicFormValues: {
          options: {},
        },
      };
    },

    setRawMaterialDetails(state, action) {
      state.createTemplate.rawMaterials.materialDetails = action.payload;
    },

    // ✅ manufactured product
    // create manufatured product template
    setManufacturedProductDynamicFormValues(state, action) {
      state.createTemplate.manufacturedProducts.dynamicFormValues = action.payload;
    },
    setManufacturedProductIngredients(state, action) {
      state.createTemplate.manufacturedProducts.ingredients = action.payload;
    },
    setManufacturedProductPackagings(state, action) {
      state.createTemplate.manufacturedProducts.packagings = action.payload;
    },
    setManufacturedProductOperations(state, action) {
      state.createTemplate.manufacturedProducts.operations = action.payload;
    },
    setManufacturedProductCurrentProduct(state, action) {
      state.createTemplate.manufacturedProducts.currentProduct = action.payload;
    },
    setManufacturedProductCreateTemplateOptions(state, action) {
      state.createTemplate.manufacturedProducts.dynamicFormValues.options = action.payload;
    },
    addManufacturedProductCreateTemplateOptions(state, action) {
      state.createTemplate.manufacturedProducts.dynamicFormValues.options.push(action.payload);
    },
    deleteManufacturedProductCreateTemplateOptions(state, action) {
      state.createTemplate.manufacturedProducts.dynamicFormValues.options =
        state.createTemplate.manufacturedProducts.dynamicFormValues.options.filter((item) => item.id !== action.payload);
    },
    updateManufacturedProductCreateTemplateOptions(state, action) {
      const row = action.payload;
      const index = state.createTemplate.manufacturedProducts.dynamicFormValues.options.findIndex((item) => item.id === row.id);
      state.createTemplate.manufacturedProducts.dynamicFormValues.options[index] = row;
    },
    setManufacturedProductCreateTemplateDynamicFormValues(state, action) {
      state.createTemplate.manufacturedProducts.dynamicFormValues = action.payload;
    },

    setManufacturedProductCreateTemplateAlertConfig(state, action) {
      state.createTemplate.manufacturedProducts.alertConfig = action.payload;
    },

    setManufacturedProductCreateTemplateButtonIsLoading(state, action) {
      state.createTemplate.manufacturedProducts.buttonIsLoading = action.payload;
    },

    setManufacturedProductCreateTemplateIsMutating(state, action) {
      state.createTemplate.manufacturedProducts.isMutating = action.payload;
    },

    setManufacturedProductStockHistory(state, action) {
      state.createTemplate.manufacturedProducts.stockHistory = action.payload;
    },

    setManufacturedProductDetails(state, action) {
      state.createTemplate.manufacturedProducts.productDetails = action.payload;
    },

    resetManufacturedProductCreateTemplate(state, action) {
      state.createTemplate.manufacturedProducts = {
        dynamicFormValues: {
          options: {},
        },
      };
    },

    // raw material tab
    setRawMaterials(state, action) {
      state.tab.rawMaterials.originalData = action.payload;
    },
    /** @param {{payload: string}} action */
    deleteRawMaterial(state, action) {
      state.tab.rawMaterials.originalData = state.tab.rawMaterials.originalData.filter(
        (item) => item.materialId !== action.payload,
      );
    },
    deleteBatchRawMaterials(state, action) {
      state.tab.rawMaterials.originalData = state.tab.rawMaterials.originalData.filter(
        (item) => !action.payload.includes(item.materialId),
      );
    },
    setRawMaterialsFilterdData(state, action) {
      state.tab.rawMaterials.filterdData = action.payload;
    },
    setRawMaterialIsLoading(state, action) {
      state.tab.rawMaterials.isLoading = action.payload;
    },
    setRawMaterialStockHistory(state, action)  {
      state.tab.rawMaterials.stockHistory = action.payload;
    },
    setRawMaterialUpdatedStockHistory(state, action) {
      state.tab.rawMaterials.updatedStockHistory = action.payload;
    },
    resetRawMaterialTab(state, action) {
      state.tab.rawMaterials = {
        filterdData: [],
        originalData: [],
        stockHistory: [],
        isLoading: false,
        materialDetails: {},
      };
    },
     // manufactured products tab
    setManufacturedProducts(state, action) {
      state.tab.manufacturedProducts.originalData = action.payload;
    },
    /** @param {{payload: string}} action */
    deleteManufacturedProduct(state, action) {
      state.tab.manufacturedProducts.originalData = state.tab.manufacturedProducts.originalData.filter(
        (item) => item.productId !== action.payload,
      );
    },
    deleteBatchManufacturedProducts(state, action) {
      state.tab.manufacturedProducts.originalData = state.tab.manufacturedProducts.originalData.filter(
        (item) => !action.payload.includes(item.productId),
      );
    },
    setManufacturedProductsFilteredData(state, action) {
      state.tab.manufacturedProducts.filteredData = action.payload;
    },
    setManufacturedProductIsLoading(state, action) {
      state.tab.manufacturedProducts.isLoading = action.payload;
    },
    resetManufacturedProductTab(state, action) {
      state.tab.manufacturedProducts = {
        filteredData: [],
        originalData: [],
        isLoading: false,
      };
    },
    resetManufacturedProductCreateTemplate(state, action) {
      state.createTemplate.manufacturedProducts = {
        dynamicFormValues: {
          options: {},
        },
      };
    }
  },
});

export const {
  setCreateTemplateOptions,
  addCreateTemplateOptions,
  deleteCreateTemplateOptions,
  updateCreateTemplateOptions,
  setRawMaterialDynamicFormValues,
  setRawMaterialStockHistory,
  setRawMaterialUpdatedStockHistory,
  setRawMaterialDetails,
  setRawMaterials,
  setManufacturedProducts,
  setManufacturedProductCurrentProduct,
  setRawMaterialsFilterdData,
  setManufacturedProductsFilteredData,
  setRawMaterialIsLoading,
  setManufacturedProductIsLoading,
  deleteRawMaterial,
  deleteManufacturedProduct,
  deleteBatchRawMaterials,
  deleteBatchManufacturedProducts,
  resetRawMaterialCreateTemplate,
  resetRawMaterialTab,
  resetManufacturedProductTab,
  setVendorProductDynamicFormValues,
  setVendorProductStockHistory,
  setVendorProductAlertConfig,
  setCreateTemplateAlertConfig,
  setManufacturedProductCreateTemplateAlertConfig,
  setRawMaterialCreateTemplateButtonIsLoading,
  setRawMaterialCreateTemplateIsMutating,
  setManufacturedProductDynamicFormValues,
  setManufacturedProductIngredients,
  setManufacturedProductPackagings,
  setManufacturedProductOperations,
  setManufacturedProductCreateTemplateButtonIsLoading,
  setManufacturedProductCreateTemplateIsMutating,
  setManufacturedProductStockHistory,
  setManufacturedProductDetails,
  resetManufacturedProductCreateTemplate: resetManufacturedProductCreateTemplateAction,
} = slice.actions;

export default slice.reducer;

export const selectCreateRawMaterialTemplate = (state) => state.inventoryCatalogue.createTemplate;

export const selectCreateRawMaterialTemplateOptions = (state) =>
  state.inventoryCatalogue.createTemplate.rawMaterials.dynamicFormValues.options;

export const selectRawMaterialDynamicFormValues = (state) =>
  state.inventoryCatalogue.createTemplate.rawMaterials.dynamicFormValues;

export const selectCreateRawMaterialTemplateIsMutating = (state) =>
  state.inventoryCatalogue.createTemplate.rawMaterials.isMutating;

export const selectRawMaterialAlertConfig = (state) => state.inventoryCatalogue.createTemplate.rawMaterials.alertConfig;

export const selectRawMaterialStockHistory = (state) => {
  return state.inventoryCatalogue.tab.rawMaterials.stockHistory;
}

export const selectRawMaterialDetails = (state) => {
  return state.inventoryCatalogue.createTemplate.rawMaterials.materialDetails;
}

export const selectRawMaterialUpdatedStockHistory = (state) => {
  return state.inventoryCatalogue.tab.rawMaterials.updatedStockHistory;
}

// export const selectRawMaterialCreateTemplateIsButtonLoading = (state) =>
//   state.inventoryCatalogue.createTemplate.rawMaterials.buttonIsLoading;

export const selectRawMaterialCreateTemplateButtonIsLoading = (state) =>
  state.inventoryCatalogue.createTemplate.rawMaterials.buttonIsLoading;

export const selectCreateManufacturedProductTemplateIsMutating = (state) =>
  state.inventoryCatalogue.createTemplate.manufacturedProducts.isMutating;

export const selectManufacturedProductAlertConfig = (state) => state.inventoryCatalogue.createTemplate.manufacturedProducts.alertConfig;

export const selectManufacturedProductCreateTemplateButtonIsLoading = (state) => state.inventoryCatalogue.createTemplate.manufacturedProducts.buttonIsLoading;

export const selectRawMaterials = (state) => state.inventoryCatalogue.tab.rawMaterials.originalData;
export const selectRawMaterialsFilterdData = (state) => state.inventoryCatalogue.tab.rawMaterials.filterdData;
export const selectRawMaterialsLoading = (state) => state.inventoryCatalogue.tab.rawMaterials.isLoading;
export const selectCreateManufacturedProductTemplate = (state) => state.inventoryCatalogue.createTemplate;
export const selectCreateManufacturedProductTemplateOptions = (state) =>
  state.inventoryCatalogue.createTemplate.dynamicFormValues.options;
export const selectManufacturedProductDynamicFormValues = (state) =>
  state.inventoryCatalogue.createTemplate.manufacturedProducts.dynamicFormValues;
export const selectManufacturedProductIngredients = (state) =>
  state.inventoryCatalogue.createTemplate.manufacturedProducts.ingredients;
export const selectManufacturedProductPackagings = (state) =>
  state.inventoryCatalogue.createTemplate.manufacturedProducts.packagings;
export const selectManufacturedProductOperations = (state) =>
  state.inventoryCatalogue.createTemplate.manufacturedProducts.operations;

export const selectManufacturedProducts = (state) => state.inventoryCatalogue.tab.manufacturedProducts.originalData;
export const selectManufacturedProductsFilteredData = (state) => state.inventoryCatalogue.tab.manufacturedProducts.filteredData;
export const selectManufacturedProductsLoading = (state) => state.inventoryCatalogue.tab.manufacturedProducts.isLoading;
export const selectManufacturedProductCurrentProduct = (state) => state.inventoryCatalogue.createTemplate.manufacturedProducts.currentProduct;
export const selectManufacturedProductStockHistory = (state) => state.inventoryCatalogue.createTemplate.manufacturedProducts.stockHistory;
export const selectManufacturedProductDetails = (state) => state.inventoryCatalogue.createTemplate.manufacturedProducts.productDetails;

export const selectVendorProductDynamicFormValues = (state) =>
  state.inventoryCatalogue.createTemplate.vendorProducts.dynamicFormValues;

export const selectVendorProductStockHistory = (state) =>
  state.inventoryCatalogue.createTemplate.vendorProducts.stockHistory;

export const selectVendorProductAlertConfig = (state) =>
  state.inventoryCatalogue.createTemplate.vendorProducts.alertConfig;
