import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getStoreId } from 'src/components/authentication/TempAuthService';
import {
  closeModal as closeModalAction,
  openModal as openModalAction,
  openNumberLimitModalAction,
  openPageLimitModalAction,
  selectModalConfig,
  setSelectPermission as setSelectPermissionAction,
} from 'src/components/redux/slices/subscription';
import { getSubscription } from 'src/server/awsClient';

const useSubscription = () => {
  const storeId = getStoreId();

  const { data, isLoading, refetch } = useQuery(['subscription', 'getPaymentInformation', storeId],
  getSubscription,
  {
    refetchOnWindowFocus: false,
    retry: 1
  });
  const subscription = data?.data;
  const dispatch = useDispatch();

  /** @type {{ isOpen: boolean, selectedPermission:string, type: "pageLimit" | "numberLimit" , usedAmount:number, limit:number  }} */
  const modalConfig = useSelector(selectModalConfig);
  const closeModal = () => dispatch(closeModalAction());
  const openModal = () => dispatch(openModalAction());

  /**
   * @param selectedPermission {TSelectedPermission}
   * @typedef { 'onlineOrders' | 'payment' | 'marketingTools' | 'procurement' | 'manufacturedGoods' } TSelectedPermission
   */
  const openPageLimitModal = (selectedPermission) => {
    dispatch(openPageLimitModalAction(selectedPermission));
  };

  /**
   * @typedef { 'user' | 'transaction' | 'warehouse' | 'sellgpt' | 'syncedApiAccount' } TSelectedNumberPermission
   * @param {{ selectedPermission: TSelectedNumberPermission, usedAmount: number }} param0
   */
  const openNumberLimitModal = ({ selectedPermission, usedAmount }) => {
    // const openNumberLimitModal = (selectedPermission) => {
    dispatch(openNumberLimitModalAction({ selectedPermission, usedAmount }));
  };

  /** @param {"user" | "transaction" | "warehouse" | "sellgpt" | "syncedApiAccount"} permission */
  const setSelectPermission = (permission) => dispatch(setSelectPermissionAction(permission));

  const convertPermissionToNumber = (permission) => {
    if (!permission) return permission;
    return {
      maxSellGptQueriesPerMonth: Number(subscription.permission.maxSellGptQueriesPerMonth),
      maxAdditionalUsers: Number(subscription.permission.maxAdditionalUsers),
      maxWarehouses: Number(subscription.permission.maxWarehouses),
      maxSyncedApiAccounts: Number(subscription.permission.maxSyncedApiAccounts),
      maxSalesTransactionsPerMonth: Number(subscription.permission.maxSalesTransactionsPerMonth),
      salesTransactionsUsage: Number(subscription.permission.salesTransactionsUsage),
      sellGptUsage: Number(subscription.permission.sellGptUsage),
    };
  };

  // useEffect(() => {
  //   console.log('SUBSCRIPTION', subscription);
  // }, [subscription]);

  /** @type {{ maxSellGptQueriesPerMonth: number, maxAdditionalUsers: number, maxWarehouses: number, maxSyncedApiAccounts: number, maxSalesTransactionsPerMonth: number, salesTransactionsUsage: number, sellGptUsage: number }} */
  const permission = useMemo(() => convertPermissionToNumber(subscription?.permission), [subscription]);

  useEffect(() => {
    return () => {
      closeModal();
      setSelectPermission('');
    };
  }, []);

  return {
    subscription,
    isLoading,
    refetch,
    modalConfig,
    closeModal,
    openModal,
    permission,
    setSelectPermission,
    openPageLimitModal,
    openNumberLimitModal,
  };
};

export default useSubscription;
