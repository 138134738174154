import { DEFAULT_HEADERS } from '../../constants';

// If image is new and wasn't loaded from DB, it'll be a Object. Otherwise, the
// payload data is fetched from the DB directly, so we return that instead.
function getImagePayload(image) {
  return typeof image === "string" ? image : image.payload;
}

// TODO: use productId for uploadImage by generating in FE instead of BE
function createUniqueImageId(storeId, productCode, image) {
  const imagePayload = getImagePayload(image);
  
  const getAlphanum = (text) => {
    if (typeof text !== 'string') {
      return '';
    }
    return text.replace(/[^A-Za-z0-9]/g, '');
  };
  
  const getAlphanumSubstrFrom = (idx) => {
    let substr = idx < 0
      ? imagePayload.substr(idx)
      : imagePayload.substr(idx, 16);
    return getAlphanum(substr);
  };
  
  // Image payload comes after metadata delimited by a comma
  const payloadStart = imagePayload.indexOf(",") + 1;
  const payloadStartSubstr = getAlphanumSubstrFrom(payloadStart);
  
  const payloadMid = parseInt(imagePayload.length / 2);
  const payloadMidSubstr = getAlphanumSubstrFrom(payloadMid);
  
  const payloadEndSubstr = getAlphanumSubstrFrom(-16);
  
  const payloadId = payloadStartSubstr + payloadMidSubstr + payloadEndSubstr;
  
  // TODO: use productId for uploadImage by generating in FE instead of BE
  const alphaNumProdCode = getAlphanum(productCode);
  
  // Everything in this string needs to be a safe character for AWS files, see:
  // https://docs.aws.amazon.com/AmazonS3/latest/userguide/object-keys.html
  // TODO: use productId for uploadImage by generating in FE instead of BE
  return storeId + "-" + alphaNumProdCode + "-" + payloadId;
}

// TODO: use productId for uploadImage by generating in FE instead of BE
export const uploadImageMutation = (body) => {
  const imgId = createUniqueImageId(body.storeId, body.productCode, body.image);
  return {
    // TODO: use productId for uploadImage by generating in FE instead of BE
    url: ('/images/' + imgId),
    headers: {
      ...DEFAULT_HEADERS,
      'Content-Type': body.image.type
    },
    method: 'PUT',
    // Include the entire post object as the body of the request
    body: JSON.stringify({ "image": body.image.payload })
  };
};

export const uploadImageTransformResponse = (response, meta, arg) => {
  // Image name is stored at the end of the url
  const imageId = meta.request.url.split("/images/")[1];
  return imageId;
};
