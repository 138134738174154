import { useRef, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import DashboardNavbar from 'src/layouts/dashboard/DashboardNavbar';
import DashboardSidebar from 'src/layouts/dashboard/DashboardSidebar';
import { Container } from '@mui/material';

// ----------------------------------------------------------------------
const APP_BAR_MOBILE = 64;

const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const DashboardContainer = ({ children }) => {
  // if(pathName.includes())
  const theme = useTheme();

  const [title, setTitle] = useState('');
  const [subTitle, setSubTitle] = useState('');
  const [open, setOpen] = useState(false);

  const [navbarHeight, setNavbarHeight] = useState(0);
  const navbarHeightRef = useRef(null);

  return (
    <RootStyle>
      <div ref={navbarHeightRef}>
        <DashboardNavbar headerDescription={{ title, subTitle }} onOpenSidebar={() => setOpen(true)} />
      </div>
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle
        sx={{
          [theme.breakpoints.down('md')]: {
            marginTop: [navbarHeight - 90 + 'px'],
          },
          [theme.breakpoints.down('sm')]: {
            marginTop: [navbarHeight - 70 + 'px'],
          },
          [theme.breakpoints.up('md')]: {
            marginTop: 0,
          },
          transition: theme.transitions.create('margin', {
            duration: theme.transitions.duration.complex,
          }),
        }}
      >
        <Container sx={{ height: '100%' }}>{children}</Container>
      </MainStyle>
    </RootStyle>
  );
};

export default DashboardContainer;
