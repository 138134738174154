import { camelCaseKeysForObjects } from '../../util';
import { DEFAULT_HEADERS } from '../../constants';

export const getProductsQuery = (body) => ({
  url: ('/' + body.storeId + '/products'),
  headers: DEFAULT_HEADERS
});

export const getProductsTransformResponse = (responseData) => {
  return camelCaseKeysForObjects(responseData.response);
};