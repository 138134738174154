import { styled } from '@mui/material/styles';
import { Stack, Card, Link, Container, Typography } from '@mui/material';
import Page from '../components/Page';
import { Link as Linkage, useNavigate } from 'react-router-dom';
import { MHidden } from '../components/@material-extend';
import { SetupForm } from '../components/authentication/register';
import AuthSocial from '../components/authentication/AuthSocial';
import i18next from 'i18next';
import { useEffect } from "react";
import ReactGA from "react-ga4";

// ----------------------------------------------------------------------
const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 900,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 385,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(0, 0)
}));

const Stacks = styled('div')(({ theme }) => ({
  flexDirection: 'column',
  marginLeft: '15%'
}));

// ----------------------------------------------------------------------
export default function Setup() {
  //GA PAGE TRACKING
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  const navigate = useNavigate();

  return (
    <RootStyle title={i18next.t('register.pageTitle')}>
      <MHidden width="mdDown">
        <SectionStyle>
          <img alt="register" src="/static/illustrations/illustration_register.png" />
        </SectionStyle>
      </MHidden>
      <Container>
        <ContentStyle>
          <Stacks sx={{ mb: 2 }}>
            <Linkage to="/"><img style={{ width: '80%' }} src="/static/illustrations/logo.png" alt="login"/></Linkage>
          </Stacks>
          <Stack sx={{ mb: 4 }}>
            <Typography style={{ width: '100%', textAlign:'center' }}>{i18next.t('register.text1')}</Typography>
          </Stack>
          <SetupForm />
          <Typography variant="caption" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
            {i18next.t('register.text2')}&nbsp;
            <Link underline="always" sx={{ color: 'text.primary' }} onClick={() => navigate("/termsconditions")}>
              {i18next.t('register.text3')}
            </Link>
            &nbsp;{i18next.t('register.text4')}&nbsp;
            <Link underline="always" sx={{ color: 'text.primary' }} onClick={() => navigate("/privacy")}>
              {i18next.t('register.text5')}
            </Link>
          </Typography>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
