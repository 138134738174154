const redirectSignInUrl = process.env.REACT_APP_REDIRECT_SIGN_IN_URL || "https://www.sellpoint.ai/auth/signin";
const redirectSignOutUrl = process.env.REACT_APP_REDIRECT_SIGN_OUT_URL || "https://www.sellpoint.ai/";

const AMPLIFY_CONFIG = {
    "Auth": {
      "identityPoolId": "us-east-1:8ac614ef-d0a5-431c-810b-b8ea7a70f216",
      "region": "us-east-1",
      "userPoolId": "us-east-1_nMsuFLGdL",
      "userPoolWebClientId": "2pfotrpve6dr5io6aufgkvgqlm",
      "oauth": {
        "domain": "sellpoint.auth.us-east-1.amazoncognito.com",
        "scope": [
          "aws.cognito.signin.user.admin",
          "email",
          "openid",
          "profile"
        ],
        "redirectSignIn": redirectSignInUrl,
        "redirectSignOut": redirectSignOutUrl,
        "responseType": "code"
      }
    }
};

export default AMPLIFY_CONFIG;
