import { createSlice } from '@reduxjs/toolkit';
// utils
// utils
import {
  getCustomersFromAWS,
  updateCustomersToAWS,
  postCustomerToAWS,
  deleteCustomersFromAWS,
} from '../../../server/awsClient';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  customers: [],
  customer: null,
  filteredCustomers: [],
  sortBy: null,
  filters: {
    category: 'all',
    status: 'all',
    search: '',
  },
};

const slice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    setCustomers(state, action) {
      state.customers = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CUSTOMERS FROM DYNAMO AND SET STATE
    getCustomersSuccess(state, action) {
      state.isLoading = false;
      state.customers = action.payload;
      state.filteredCustomers = action.payload;
    },

    // GET CUSTOMERS FROM DYNAMO AND SET STATE
    getCustomersFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CUSTOMER
    getCustomerSuccess(state, action) {
      state.isLoading = false;
      state.customer = action.payload;
    },

    //  SORT CUSTOMERS
    sortByCustomers(state, action) {
      state.sortBy = action.payload;
    },

    // FILTER CUSTOMERS BY CATEGORY/STATUS/SEARCHTEXT
    filterCustomers(state, action) {
      if (action.payload.filter === 'category') {
        state.filters.category = action.payload.value;
      } else if (action.payload.filter === 'status') {
        state.filters.status = action.payload.value;
      } else if (action.payload.filter === 'search') {
        state.filters.search = action.payload.value;
      }
    },

    // UPDATE EXISTING CUSTOMER IN STATE
    updateCustomersSuccess(state, action) {
      var customers = action.payload.data.response;
      var newCustomers = [];
      var newFilteredCustomers = [];
      state.customers.forEach((customer) => {
        if (customers[customer.customerId]) {
          newCustomers.push(customers[customer.customerId]);
        } else {
          newCustomers.push(customer);
        }
      });
      state.filteredCustomers.forEach((customer) => {
        if (customers[customer.customerId]) {
          newFilteredCustomers.push(customers[customer.customerId]);
        } else {
          newFilteredCustomers.push(customer);
        }
      });
      state.customers = newCustomers;
      state.filteredCustomers = newFilteredCustomers;
    },

    // DELETE CUSTOMER IN STATE
    deleteCustomersSuccess(state, action) {
      var deletedCustomers = action.payload.data.response;
      var newCustomers = [];
      var newFilteredCustomers = [];
      state.customers.forEach((customer) => {
        if (!deletedCustomers[customer.customerId]) {
          newCustomers.push(customer);
        }
      });
      state.filteredCustomers.forEach((customer) => {
        if (!deletedCustomers[customer.customerId]) {
          newFilteredCustomers.push(customer);
        }
      });
      state.customers = newCustomers;
      state.filteredCustomers = newFilteredCustomers;
    },
    // POST CUSTOMER IN STATE
    postCustomerSuccess(state, action) {
      var addedCustomer = action.payload.data.response;
      state.customers.push(addedCustomer);
      state.filteredCustomers.push(addedCustomer);
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { sortByCustomers, filterCustomers, setCustomers } = slice.actions;

// ----------------------------------------------------------------------

// GETS ALL CUSTOMERS FROM STORE
export function getCustomersFromStore(abortController) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getCustomersFromAWS(abortController);
      if (response.statusCode === 200) {
        dispatch(slice.actions.getCustomersSuccess(response.response));
      } else {
        dispatch(slice.actions.getCustomersFailure(response.response));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// GETS A SINGLE CUSTOMER FROM STORE GIVEN CUSTOMER ID
export function getCustomerFromStore(abortController, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getCustomersFromAWS(abortController, id);
      const data = response.response;
      dispatch(slice.actions.getCustomerSuccess(data.filter((customer) => customer.customerId === id)[0]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// UPDATES A LIST OF CUSTOMERS
export function updateCustomersToStore(customers) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await updateCustomersToAWS(customers);
      dispatch(slice.actions.updateCustomersSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// POST A NEW CUSTOMER TO STORE
export function postCustomerToStore(customer) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await postCustomerToAWS(customer);
      dispatch(slice.actions.postCustomerSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// DELETES A LIST OF CUSTOMERS FROM STORE
export function deleteCustomersFromStore(customers) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await deleteCustomersFromAWS(customers);
      dispatch(slice.actions.deleteCustomersSuccess(response));
    } catch (error) {
      // console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ---------------------------------------- Helper Functions ------------------------------------------------------------

// function filterData(state, action) {
//   var result = [];
//   const searchRegex = new RegExp(escapeRegExp(state.filters.search), 'i');
//   state.customers.forEach((customer) => {
//     var isValid = true;
//     if (customer.BusinessCategory !== state.filters.category && state.filters.category !== "all") {
//       isValid = false;
//     }
//     if (customer.AccountStatus !== state.filters.status && state.filters.status !== "any") {
//       isValid = false;
//     }
//     if (Object.keys(customer).every((field) => {
//       return !searchRegex.test(customer[field].toString());
//     })) {
//       isValid = false;
//     }
//     if (isValid) {
//       result.push(customer);
//     }
//   })
//   state.filteredCustomers = result;
// }

// Regex pattern matching.
// function escapeRegExp(value) {
//   return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
// }

export const selectCustomerIsLoading = (state) => state.customer.isLoading;
