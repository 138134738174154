import i18next from 'i18next';

const OUT_OF_STOCK = 'out_of_stock';
const LOW_STOCK = 'low_stock';
const MEDIUM_STOCK = 'medium_stock';
const IN_STOCK = 'in_stock';

export const STOCK_STATUS_WEIGHT = {
  [OUT_OF_STOCK]: 0,
  [LOW_STOCK]: 1,
  [MEDIUM_STOCK]: 2,
  [IN_STOCK]: 3,
};
export const STOCK_STATUS = { OUT_OF_STOCK, LOW_STOCK, MEDIUM_STOCK, IN_STOCK };

export const INVENTORY_TABLE_HEAD_NAMES = Object.freeze([
  { key: 'productName', label: 'inventory.label.productName' },
  { key: 'optionName', label: 'inventory.label.optionName' },
  { key: 'productType', label: 'inventory.label.productType' },
  { key: 'productCode', label: 'inventory.label.productCode' },
  { key: 'description', label: 'inventory.label.productDescription' },
  { key: 'status', label: 'inventory.label.status' },
  { key: 'quantity', label: 'inventory.label.quantity' },
  { key: 'lowStockThreshold', label: 'inventory.label.lowStockThreshold' },
]);

export const SECONDARY_INVENTORY_TABLE_HEAD_NAMES = Object.freeze([
  { key: 'productName', label: 'inventory.label.productName' },
  { key: 'optionName', label: 'inventory.label.optionName' },
  { key: 'productType', label: 'inventory.label.productType' },
  { key: 'productCode', label: 'inventory.label.productCode' },
  { key: 'description', label: 'inventory.label.productDescription' },
  { key: 'status', label: 'inventory.label.status' },
  { key: 'quantity', label: 'inventory.label.quantity' },
]);

export const WAREHOUSE_ID_LENGTH = 36;
// console.log(i18next.t('landing.aboutHero.title') );
export const ESSENTIAL_INVENTORY_CONFIG = Object.freeze({
  fields: {
    NewField1: {
      defaultField: true,
      id: 'NewField1',
      fieldName: 'newProductDynamic.dummy.productName',
      isMandatory: true,
    },
    NewField2: {
      defaultField: true,
      id: 'NewField2',
      fieldName: 'newProductDynamic.dummy.productCode',
      isMandatory: true,
    },
    NewField3: {
      defaultField: true,
      id: 'NewField3',
      fieldName: 'newProductDynamic.dummy.listPrice',
      isMandatory: true,
    },
    NewField4: {
      defaultField: true,
      id: 'NewField4',
      fieldName: 'newProductDynamic.dummy.menufacturerPrice',
      isMandatory: true,
    },
    NewField5: {
      defaultField: true,
      id: 'NewField5',
      fieldName: 'newProductDynamic.dummy.deliveryCost',
      isMandatory: true,
    },
    NewField6: { defaultField: true, id: 'NewField6', fieldName: 'newProductDynamic.dummy.gender', isMandatory: true },
    NewField7: {
      defaultField: true,
      id: 'NewField7',
      fieldName: 'newProductDynamic.dummy.description',
      isMandatory: true,
    },
  },
  column: {
    title: 'essential fields',
    fieldIds: ['NewField1', 'NewField2', 'NewField3', 'NewField4', 'NewField5', 'NewField6'],
    id: 'column-1',
  },
});

export const DEFAULT_INVENTORY_CONFIG = {
  fields: {
    NewField1: { defaultField: false, id: 'NewField1', fieldName: 'Basic Color', isMandatory: false },
    NewField2: { defaultField: false, id: 'NewField2', fieldName: 'Color Class', isMandatory: false },
    NewField3: { defaultField: false, id: 'NewField3', fieldName: 'Material', isMandatory: false },
    NewField4: { defaultField: false, id: 'NewField4', fieldName: 'Density', isMandatory: false },
    NewField5: { defaultField: false, id: 'NewField5', fieldName: 'Standard', isMandatory: false },
    NewField6: { defaultField: false, id: 'NewField6', fieldName: 'Weight', isMandatory: false },
    NewField7: { defaultField: false, id: 'NewField7', fieldName: 'Grade', isMandatory: false },
    NewField8: { defaultField: false, id: 'NewField8', fieldName: 'Fabric Class', isMandatory: false },
    NewField9: { defaultField: false, id: 'NewField9', fieldName: 'Yarn (Denier)', isMandatory: false },
    NewField10: { defaultField: false, id: 'NewField10', fieldName: 'Yarn (Bale)', isMandatory: false },
  },
  column: {
    title: 'Add Product',
    fieldIds: [
      'NewField1',
      'NewField2',
      'NewField3',
      'NewField4',
      'NewField5',
      'NewField6',
      'NewField7',
      'NewField8',
      'NewField9',
      'NewField10',
    ],
    id: 'column-1',
  },
};

export const PLATFORMS = ['naver', 'tmon', 'kakao', 'gmarket', 'cafe24', 'coupang', 'todaysHouse'];

export const UNIT_OPTIONS = [
  { key: 'count', value: 'productNewForm.unit.pcs' },
  { key: 'count', value: 'productNewForm.unit.units' },
  { key: 'count', value: 'productNewForm.unit.pairs' },
  { key: 'count', value: 'productNewForm.unit.dozens' },
  { key: 'count', value: 'productNewForm.unit.packs' },
  { key: 'count', value: 'productNewForm.unit.boxes' },
  { key: 'count', value: 'productNewForm.unit.cases' },
  { key: 'count', value: 'productNewForm.unit.bundle' },
  // { key: 'weight', value: 'productNewForm.unit.tons' },
  { key: 'weight', value: 'productNewForm.unit.lb' },
  { key: 'weight', value: 'productNewForm.unit.kg' },
  { key: 'weight', value: 'productNewForm.unit.oz' },
  { key: 'weight', value: 'productNewForm.unit.g' },
  { key: 'weight', value: 'productNewForm.unit.t' },
  { key: 'volume', value: 'productNewForm.unit.gal' },
  { key: 'volume', value: 'productNewForm.unit.l' },
  { key: 'volume', value: 'productNewForm.unit.ml' },
  { key: 'volume', value: 'productNewForm.unit.ft3' },
  { key: 'volume', value: 'productNewForm.unit.m3' },
  { key: 'length', value: 'productNewForm.unit.in' },
  { key: 'length', value: 'productNewForm.unit.ft' },
  { key: 'length', value: 'productNewForm.unit.yd' },
  { key: 'length', value: 'productNewForm.unit.m' },
  { key: 'length', value: 'productNewForm.unit.cm' },
  { key: 'length', value: 'productNewForm.unit.mm' },
];

export const UNIT_OPTION_VALUES = [
  "weight",
  "volume",
  "length",
  "count",
  "lb",
  "kg",
  "oz",
  "g",
  "t",
  "gal",
  "l",
  "ml",
  "ft3",
  "m3",
  "in",
  "ft",
  "yd",
  "m",
  "cm",
  "mm",
  "pcs",
  "units",
  "pairs",
  "dozens",
  "packs",
  "boxes",
  "cases",
  "bundle",
  "hours",
  "minutes",
  "seconds",
];































