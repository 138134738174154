import { camelCaseKeysForObjects } from '../../util';
import { DEFAULT_HEADERS } from '../../constants';

export const getCustomerQuery = (body) => ({
  url: ('/' + body.storeId + '/customers/' + body.customerId),
  headers: DEFAULT_HEADERS
});

export const getCustomerTransformResponse = (responseData) => {
  return camelCaseKeysForObjects(responseData.response);
};