import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  inventory: [],
  inventoryIsLoading: false,
  tutorialStatus: true,
  orderStatus: [],
  orderStatusIsLoading: false,

  // monthly sales overview
  monthlySalesOverview: {
    totalIncomes: {
      percent: 0,
      value: 0,
    },
    expenditures: {
      percent: 0,
      value: 0,
    },
    netIncomes: {
      percent: 0,
      value: 0,
    },
    lastNetIncomes: {
      percent: 0,
      value: 0,
    },
  },

  // paymentDue
  paymentDue: {
    data: [],
    tableData: [],
    sum: 0,
  },
  paymentDueIsLoading: false,

  // salesChart
  salesChart: {
    option: {
      orderType: 'online',
      time: 'month',
    },
  },
};

const slice = createSlice({
  name: 'commandCentral',
  initialState,
  reducers: {
    setInventory(state, action) {
      state.inventory = action.payload;
    },

    setInventoryIsLoading(state, action) {
      state.inventoryIsLoading = action.payload;
    },

    // Monthly Sales Overview
    setTotalIncomes(state, action) {
      state.monthlySalesOverview.totalIncomes = action.payload;
    },
    setExpenditures(state, action) {
      state.monthlySalesOverview.expenditures = action.payload;
    },
    setNetIncomes(state, action) {
      state.monthlySalesOverview.netIncomes = action.payload;
    },
    setLastNetIncomes(state, action) {
      state.monthlySalesOverview.lastNetIncomes = action.payload;
    },
    // paymentdue
    setPaymentDue(state, action) {
      state.paymentDue = action.payload;
    },
    setPaymentDueIsLoading(state, action) {
      state.paymentDueIsLoading = action.payload;
    },

    setOrderStatus(state, action) {
      state.orderStatus = action.payload;
    },
    setOrderStatusIsLoading(state, action) {
      state.orderStatusIsLoading = action.payload;
    },
    setSalesChartOption(state, action) {
      state.salesChart.option = action.payload;
    },
  },
});

export const {
  setInventory,
  setInventoryIsLoading,
  setTotalIncomes,
  setExpenditures,
  setNetIncomes,
  setLastNetIncomes,
  setPaymentDue,
  setPaymentDueIsLoading,
  setOrderStatus,
  setOrderStatusIsLoading,
  setSalesChartOption,
} = slice.actions;
export default slice.reducer;

export const selectInventory = (state) => state.commandCentral.inventory;
export const selectInventoryIsLoading = (state) => state.commandCentral.inventoryIsLoading;
export const selectMonthlySalesOverview = (state) => state.commandCentral.monthlySalesOverview;
export const selectPaymentDue = (state) => state.commandCentral.paymentDue;
export const selectPaymentDueIsLoading = (state) => state.commandCentral.paymentDueIsLoading;
export const selectOrderStatus = (state) => state.commandCentral.orderStatus;
export const selectOrderStatusIsLoading = (state) => state.commandCentral.orderStatusIsLoading;
export const selectSalesChartOption = (state) => state.commandCentral.salesChart.option;
