import { createSlice } from '@reduxjs/toolkit';
import { PREMIUM } from 'src/constants/subscription';
// import { initModalConfig } from 'src/modules';
// import sidebarConfig from 'src/layouts/dashboard/SidebarConfig';

export const initSubscriptionPayment = {
  duration: 'annual',
  planType: PREMIUM,
  paymentOption: 'naver',
};

export const initModalConfig = {
  isOpen: false,
  subscriptionId: '',
  selectedPermission: '',
  type: 'numberLimit',
  usedAmount: 0,
};

const initialState = {
  subscriptionPayment: initSubscriptionPayment,
  modalConfig: initModalConfig,
  selectedReasons: [],
};

const slice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setDuration(state, action) {
      state.subscriptionPayment.duration = action.payload;
    },
    setPlanType(state, action) {
      state.subscriptionPayment.planType = action.payload;
    },
    setPaymentOption(state, action) {
      state.subscriptionPayment.paymentOption = action.payload;
    },

    setSubscriptionPayment(state, action) {
      state.subscriptionPayment = action.payload;
    },

    /**  @param {{ payload: { isOpen: boolean, type: string, data: any, selectedPermission:string } }} action  */
    setModalConfig(state, action) {
      state.modalConfig = action.payload;
    },

    openModal(state, action) {
      state.modalConfig = {
        ...state.modalConfig,
        isOpen: true,
      };
    },

    openNumberLimitModal(state, action) {
      state.modalConfig = {
        ...state.modalConfig,
        isOpen: true,
        type: 'numberLimit',
        selectedPermission: action.payload.selectedPermission,
        usedAmount: action.payload.usedAmount,
      };
    },
    openPageLimitModal(state, action) {
      state.modalConfig = {
        ...state.modalConfig,
        isOpen: true,
        type: 'pageLimit',
        selectedPermission: action.payload,
      };
    },

    /** @param {{ payload: string }} action */
    setSelectPermission(state, action) {
      state.modalConfig.selectedPermission = action.payload;
    },

    toggleReason(state, action) {
      const value = action.payload;
      const index = state.selectedReasons.indexOf(value);
      if (index === -1) {
        state.selectedReasons.push(value);
      } else {
        state.selectedReasons.splice(index, 1);
      }
    },

    resetSelectedReasons(state) {
      state.selectedReasons = [];
    },

    closeModal(state) {
      state.modalConfig = {
        ...state.modalConfig,
        isOpen: false,
      };
    },
  },
});

export const {
  setDuration,
  setPlanType,
  setPaymentOption,
  setModalConfig,
  closeModal,
  openModal,
  setSubscriptionPayment,
  setSelectPermission,
  openNumberLimitModal: openNumberLimitModalAction,
  openPageLimitModal: openPageLimitModalAction,
  resetSelectedReasons,
  toggleReason,
} = slice.actions;

export default slice.reducer;

export const selectDuration = (state) => state.subscription.subscriptionPayment.duration;
export const selectPlanType = (state) => state.subscription.subscriptionPayment.planType;
export const selectPaymentOption = (state) => state.subscription.subscriptionPayment.paymentOption;

export const selectSubscriptionPayment = (state) => state.subscription.subscriptionPayment;

export const selectModalConfig = (state) => state.subscription.modalConfig;

export const selectSelectedReasons = (state) => state.subscription.selectedReasons;
