import { randomId } from '@mui/x-data-grid-generator';

//UTILITY FUNCTIONS TO HANDLE CSV ORDER PROCESSING
const isNaver = (jsonObj) => {
  //Method to check if a JSON obj represents a Naver csv file
  //console.log("jsonObj", jsonObj)
  const keys = Object.keys(jsonObj);
  const isSalesChannelValid = Object.entries(jsonObj).some(
    ([key, value]) => key === '판매채널' && value === '스마트스토어',
  );
  return keys.some((key) => key.includes('네이버페이')) || isSalesChannelValid;
};

const isKakao = (jsonObj) => {
  return '배송지/수신자정보 입력일' in jsonObj;
};

const isCoupang = (jsonObj) => {
  return '개인통관번호(PCCC)' in jsonObj;
};

const isGmarket = (jsonObj) => {
  return '덤 관리코드' in jsonObj;
};

const isTmon = (jsonObj) => {
  return '딜번호' in jsonObj;
};

const isTodaysHouse = (jsonObj) => {
  // Conditional below skips 2nd line of TodaysHouse is a guide line, not the actual data
  return '오늘의집상품코드' in jsonObj && jsonObj['주문번호'] !== '값을 변경하지마세요.';
};

const isCafe24 = (jsonObj) => {
  return '택배사**' in jsonObj;
};

const isManufacture = (jsonObj) => {
  return '공급단가' in jsonObj;
};

// ----------------------------------------------------------------------
const generateKakaoId = (jsonObj) => {
  //This method generates a unique partially-random ID for each Kakao order placed.
  return 'Kakao-' + jsonObj['주문일'] + '-' + randomId();
};

const generateGmarketId = (jsonObj) => {
  //This method generates a unique partially-random ID for each Gmarket order placed.
  return 'Gmarket-' + jsonObj['발송마감일'] + '-' + randomId();
};

const generateNaverId = (jsonObj) => {
  //This method generates a unique partially-random ID for each Naver order placed.
  return 'Naver-' + jsonObj['발송일'] + '-' + randomId();
};

const generateCoupangId = (jsonObj) => {
  //This method generates a unique partially-random ID for each Coupang order placed.
  return 'Coupang-' + jsonObj['주문일'] + '-' + randomId();
};

const generatePlatformUniqueId = (platformName, orderDate) => {
  return `${platformName}-${orderDate}-${randomId()}`;
};

// ----------------------------------------------------------------------
const safeParseInt = (str) => {
  if (str) {
    if (str === '') return 0;
    return Number(str.replace('₩', '').replace('\\', '').replace(',', ''));
  }
  return 0;
};

const safeConvertDate = (date) => {
  try {
    return new Date(date).toISOString();
  } catch {
    return new Date().toISOString();
  }
};

// ----------------------------------------------------------------------
const fitToNaver = (jsonObj) => {
    let streamlined = {}
    streamlined["source"] = "Naver"
    streamlined["datePlaced"] = safeConvertDate(jsonObj["발송일"])
    streamlined["orderNumber"] = jsonObj["상품주문번호"] // order number from Naver
    streamlined["storeProductCode"] = jsonObj["상품번호"] //seller's product code what they listed it on Naver
    streamlined["productDescription"] = jsonObj["상품명"] //product description
    streamlined["option"] = jsonObj["옵션정보"] //option information
    streamlined["quantity"] = safeParseInt(jsonObj["수량"]) //quantity
    streamlined["listedPrice"] = safeParseInt(jsonObj["상품가격"]) + safeParseInt(jsonObj["옵션가격"])
    streamlined["discounts"] = safeParseInt(jsonObj["판매자 부담 할인액"])
    streamlined["deliveryCost"] = safeParseInt(jsonObj["배송비 합계"]) || 0
    streamlined["platformFee"] = safeParseInt(jsonObj["네이버페이 주문관리 수수료"]) + safeParseInt(jsonObj["매출연동 수수료"])
    streamlined["expectedProfit"] = safeParseInt(jsonObj["정산예정금액"])
    streamlined["buyerName"] = jsonObj["구매자명"] //buyer name
    streamlined["buyerNumber"] = jsonObj["구매자연락처"]
    streamlined["receiverNumber"] = jsonObj["수취인연락처1"] //recipient contact 1
    streamlined["receiverName"] = jsonObj["수취인명"] //recipient name
    streamlined["receiverAddress"] = jsonObj["통합배송지"] ?? jsonObj["배송지"] //shipping address
    streamlined["zipcode"] = jsonObj["우편번호"] //zip code
    streamlined["customClearanceNumber"] = jsonObj["개인통관고유부호"] //custom clearance number
    streamlined["courier"] = jsonObj["택배사"] //courier
    streamlined["trackingNumber"] = jsonObj["송장번호"] //tracking #
    streamlined["memo"] = jsonObj["배송메세지"] //msg to logistics
    streamlined["id"] = generateNaverId(jsonObj)
    streamlined["status"] = "awaiting"
    streamlined["rawData"] = jsonObj
    return streamlined
}

const fitToCoupang = (jsonObj) => {
    let streamlined = {}
    streamlined["source"] = "Coupang"
    streamlined["datePlaced"] = safeConvertDate(jsonObj["주문일"])
    streamlined["purchaserId"] = jsonObj["구매자ID"] //purchaser's id
    streamlined["orderNumber"] = jsonObj["주문번호"] // order number from Naver
    streamlined["storeProductCode"] = jsonObj["노출상품ID"] //seller's product code what they listed it on Naver
    streamlined["productDescription"] = jsonObj["노출상품명(옵션명)"] //product description
    streamlined["option"] = jsonObj["등록옵션명"] //option information
    streamlined["quantity"] = safeParseInt(jsonObj["구매수(수량)"]) //quantity
    streamlined["listedPrice"] = safeParseInt(jsonObj["옵션판매가(판매단가)"])
    streamlined["discounts"] = 0
    streamlined["deliveryCost"] = safeParseInt(jsonObj["배송비"]) || 0
    streamlined["platformFee"] = ""
    streamlined["expectedProfit"] = ""
    streamlined["buyerName"] = jsonObj["구매자"] //buyer name
    streamlined["buyerNumber"] = jsonObj["구매자전화번호"] //buyer contact number
    streamlined["receiverNumber"] = jsonObj["수취인전화번호"] //recipient contact 1
    streamlined["receiverName"] = jsonObj["수취인이름"] //recipient name
    streamlined["receiverAddress"] = jsonObj["수취인 주소"] //shipping address
    streamlined["customClearanceNumber"] = jsonObj["개인통관번호(PCCC)"]//custom clearance number
    streamlined["zipcode"] = jsonObj["우편번호"] //zip code
    streamlined["courier"] = jsonObj["택배사"] //courier
    streamlined["trackingNumber"] = jsonObj["운송장번호"] //tracking #
    streamlined["memo"] = jsonObj["배송메세지"] //msg to logistics
    streamlined["id"] = generateCoupangId(jsonObj)
    streamlined["status"] = "awaiting"
    streamlined["rawData"] = jsonObj
    return streamlined
}

const fitToGmarket = (jsonObj) => {
    let streamlined = {}
    streamlined["source"] = "Gmarket"
    streamlined["datePlaced"] = safeConvertDate(jsonObj["주문일자(결제확인전)"])
    streamlined["orderNumber"] = jsonObj["주문번호"] // order number from Naver
    streamlined["storeProductCode"] = jsonObj["상품번호"] //seller's product code what they listed it on Naver
    streamlined["productDescription"] = jsonObj["상품명"] //product description
    streamlined["option"] = jsonObj["옵션ID"] //option information
    streamlined["quantity"] = safeParseInt(jsonObj["수량"]) //quantity
    streamlined["listedPrice"] = safeParseInt(jsonObj["판매단가"])
    streamlined["discounts"] = safeParseInt(jsonObj["판매자쿠폰할인"])
    streamlined["deliveryCost"] = safeParseInt(jsonObj["배송비 금액"]) || 0
    streamlined["platformFee"] = safeParseInt(jsonObj["서비스이용료"])
    streamlined["expectedProfit"] = safeParseInt(jsonObj["정산예정금액"])
    streamlined["buyerName"] = jsonObj["구매자명"] //buyer name
    streamlined["buyerNumber"] = jsonObj["구매자 전화번호"] //buyer contact number
    streamlined["receiverNumber"] = jsonObj["수령인 전화번호"] //recipient contact 1
    streamlined["receiverName"] = jsonObj["수령인명"] //recipient name
    streamlined["receiverAddress"] = jsonObj["주소"] //shipping address
    streamlined["customClearanceNumber"] = jsonObj["수령인 통관정보"]//custom clearance number
    streamlined["zipcode"] = jsonObj["우편번호"] //zip code
    streamlined["courier"] = jsonObj["택배사명(발송방법)"] //courier
    streamlined["trackingNumber"] = jsonObj["송장번호"] //tracking #
    streamlined["memo"] = jsonObj["배송메시지"] //msg to logistics
    streamlined["id"] = generateGmarketId(jsonObj)
    streamlined["status"] = "awaiting"
    streamlined["rawData"] = jsonObj
    return streamlined
}

const fitToKakao = (jsonObj) => {
    let streamlined = {}
    // console.log("fitToKakao jsonObj",jsonObj)
    streamlined["source"] = "Kakao"
    streamlined["datePlaced"] = safeConvertDate(jsonObj["배송지/수신자정보 입력일"])
    streamlined["orderNumber"] = jsonObj["주문번호"] // order number from 
    streamlined["storeProductCode"] = jsonObj["채널상품번호"] //seller's product code what they listed it on Naver
    streamlined["productDescription"] = jsonObj["상품명"] //product description
    streamlined["option"] = jsonObj["옵션"] //option information
    streamlined["quantity"] = safeParseInt(jsonObj["수량"]) //quantity
    streamlined["listedPrice"] = safeParseInt(jsonObj["상품금액"]) / safeParseInt(jsonObj["수량"])
    streamlined["discounts"] = safeParseInt(jsonObj["판매자할인금액"])
    streamlined["deliveryCost"] = safeParseInt(jsonObj["배송비금액"]) || 0
    streamlined["platformFee"] = safeParseInt(jsonObj["기본수수료"]) + safeParseInt(jsonObj["노출추가수수료"])
    streamlined["expectedProfit"] = safeParseInt(jsonObj["정산기준금액"])
    streamlined["buyerName"] = jsonObj["구매자명"] //buyer name
    streamlined["buyerNumber"] = jsonObj["구매자 전화번호"] //buyer contact number
    streamlined["receiverNumber"] = jsonObj["수령인연락처1"] //recipient contact 1
    streamlined["receiverName"] = jsonObj["수령인명"] //recipient name
    streamlined["receiverAddress"] = jsonObj["배송지주소"] //shipping address
    streamlined["customClearanceNumber"] = jsonObj["수령인 통관정보"]//custom clearance number
    streamlined["zipcode"] = jsonObj["우편번호"] //zip code
    streamlined["courier"] = jsonObj["택배사코드"]
    streamlined["trackingNumber"] = jsonObj["송장번호"] //tracking #
    streamlined["memo"] = jsonObj["배송메세지"] //msg to logistics
    streamlined["id"] = generateKakaoId(jsonObj)
    streamlined["status"] = "awaiting"
    streamlined["rawData"] = jsonObj
    return streamlined
}

const fitToTmon = (jsonObj) => {
  let streamlined = {}
  streamlined["source"] = "Tmon"
  streamlined["datePlaced"] = safeConvertDate(jsonObj["결제완료일"])
  streamlined["orderNumber"] = jsonObj["주문번호"] //order number
  streamlined["storeProductCode"] = jsonObj["딜번호"] //seller's product code 
  streamlined["productDescription"] = jsonObj["딜명"] //product description
  streamlined["option"] = jsonObj["옵션명"] //option information
  streamlined["quantity"] = safeParseInt(jsonObj["구매수량"]) //quantity
  streamlined["listedPrice"] = safeParseInt(jsonObj["옵션가"])
  streamlined["discounts"] = 0
  streamlined["deliveryCost"] = safeParseInt(jsonObj["고객부담배송비"]) || 0
  streamlined["platformFee"] = ""
  streamlined["expectedProfit"] = ""
  streamlined["buyerName"] = jsonObj["주문자명"] //buyer name
  streamlined["buyerNumber"] = jsonObj["주문자 연락처"] //buyer contact number
  streamlined["receiverName"] = jsonObj["수취인명"] //recipient name
  streamlined["receiverNumber"] = jsonObj["수취인연락처"] //recipient contact
  streamlined["receiverAddress"] = jsonObj["수취인주소"] //shipping address
  streamlined["customClearanceNumber"] = jsonObj["개인통관고유부호"]//custom clearance number
  streamlined["zipcode"] = jsonObj["수취인우편번호"] //zip code
  streamlined["courier"] = jsonObj["택배사"] //courier 
  streamlined["trackingNumber"] = jsonObj["운송장번호"] //tracking #
  streamlined["memo"] = jsonObj["배송요청메모"] //msg to logistics
  streamlined["id"] = generatePlatformUniqueId(streamlined["source"], streamlined["datePlaced"])
  streamlined["status"] = "awaiting"
  streamlined["rawData"] = jsonObj
  return streamlined
}

const fitToTodaysHouse = (jsonObj) => {
  let streamlined = {}
  streamlined["source"] = "TodaysHouse"
  streamlined["datePlaced"] = safeConvertDate(jsonObj["주문일자"])
  streamlined["orderNumber"] = jsonObj["주문번호"] //order number
  streamlined["storeProductCode"] = jsonObj["오늘의집상품코드"] //seller's product code 
  streamlined["productDescription"] = jsonObj["상품명"] //product description
  streamlined["option"] = jsonObj["선택옵션"] //option information
  streamlined["quantity"] = safeParseInt(jsonObj["수량"]) //quantity
  streamlined["listedPrice"] = safeParseInt(jsonObj["판매가"])
  streamlined["discounts"] = 0
  streamlined["deliveryCost"] = safeParseInt(jsonObj["선불배송비"]) || 0
  streamlined["platformFee"] = safeParseInt(jsonObj["판매가"]) - safeParseInt(jsonObj["공급가"])
  streamlined["expectedProfit"] = ""
  streamlined["buyerName"] = jsonObj["주문자"] //buyer name
  streamlined["buyerNumber"] = "" // X
  streamlined["receiverName"] = jsonObj["수취인명"] //recipient name
  streamlined["receiverNumber"] = jsonObj["수취인연락처"] //recipient contact
  streamlined["receiverAddress"] = jsonObj["수취인주소"] + " " + jsonObj["수취인주소상세"]  //shipping address
  streamlined["customClearanceNumber"] = jsonObj["통관부호"]//custom clearance number
  streamlined["zipcode"] = jsonObj["수취인우편번호"] //zip code
  streamlined["courier"] = jsonObj["택배사코드"] //courier 
  streamlined["trackingNumber"] = jsonObj["운송장번호"] //tracking #
  streamlined["memo"] = jsonObj["배송메세지"] //msg to logistics
  streamlined["id"] = generatePlatformUniqueId(streamlined["source"], streamlined["datePlaced"])
  streamlined["status"] = "awaiting"
  streamlined["rawData"] = jsonObj
  return streamlined
}

const fitToCafe24 = (jsonObj) => {
  let streamlined = {}
  streamlined["source"] = "Cafe24"
  streamlined["datePlaced"] = safeConvertDate(jsonObj["결제일시(입금확인일)"])
  streamlined["orderNumber"] = jsonObj["주문번호(원룸만들기)"] //order number
  streamlined["storeProductCode"] = jsonObj["자체품목코드"] //seller's product code 
  streamlined["productDescription"] = jsonObj["주문상품명"] //product description
  streamlined["option"] = jsonObj["상품옵션(기본)"] //option information
  streamlined["quantity"] = safeParseInt(jsonObj["주문수량"]) //quantity
  streamlined["listedPrice"] = safeParseInt(jsonObj["상품구매금액"]) / safeParseInt(jsonObj["주문수량"])
  streamlined["discounts"] = 0
  streamlined["deliveryCost"] = safeParseInt(jsonObj["공급사배송비"]) || 0
  streamlined["expectedProfit"] = ""
  streamlined["buyerName"] = jsonObj["주문자명"] //buyer name
  streamlined["buyerNumber"] = "" // X
  streamlined["receiverName"] = jsonObj["수령인"] //recipient name
  streamlined["receiverNumber"] = jsonObj["수령인연락처"] //recipient contact
  streamlined["receiverAddress"] = jsonObj["수령인주소(전체)"] //shipping address
  streamlined["customClearanceNumber"] = jsonObj["개인통관고유부호"]//custom clearance number
  streamlined["zipcode"] = jsonObj["수령인우편번호"] //zip code
  streamlined["courier"] = "" //courier
  streamlined["trackingNumber"] = jsonObj["배송번호"] //tracking #
  streamlined["memo"] = jsonObj["배송메시지"] //msg to logistics
  streamlined["id"] = generatePlatformUniqueId(streamlined["source"], streamlined["datePlaced"])
  streamlined["status"] = "awaiting"
  streamlined["rawData"] = jsonObj
  return streamlined
}

const cleanData = (dataArray) => {
  // Sanitises the data array, by replacing undefined and null values with the empty string.
  return dataArray.map((elem) => {
    for (const [key, value] of Object.entries(elem)) {
      if (value === null || value === undefined || value === '') {
        // shouldn't reassign 0 to ""
        elem[key] = '';
      }
    }
    elem['referenceProductCode'] = ''; //init ref prod code to ""
    return elem;
  });
};

export function streamlineObjects(jsonObjs) {
  // Utility method to take an array of unfilitered JSON objs and fit them to our needs
  return cleanData(
    // eslint-disable-next-line array-callback-return
    jsonObjs
      .map((elem) => {
        if (isNaver(elem)) {
          return fitToNaver(elem);
        } else if (isCoupang(elem)) {
          return fitToCoupang(elem);
        } else if (isGmarket(elem)) {
          return fitToGmarket(elem);
        } else if (isKakao(elem)) {
          return fitToKakao(elem);
        } else if (isTmon(elem)) {
          return fitToTmon(elem);
        } else if (isTodaysHouse(elem)) {
          return fitToTodaysHouse(elem);
        } else if (isCafe24(elem)) {
          return fitToCafe24(elem);
        } 
        // Further future checks for more stores go here
      })
      .filter((mapped) => mapped), // filters out 'undefined' elem
  );
}

export const getXlsxType = (jsonObj) => {
  switch (true) {
    case isNaver(jsonObj):
      return 'Naver';
    case isCoupang(jsonObj):
      return 'Coupang';
    case isGmarket(jsonObj):
      return 'Gmarket';
    case isKakao(jsonObj):
      return 'Kakao';
    case isTmon(jsonObj):
      return 'Tmon';
    case isTodaysHouse(jsonObj):
      return 'TodaysHouse';
    case isCafe24(jsonObj):
      return 'Cafe24';
    default:
      return 'Unknown';
  }
};

export const streamlineOfflineOrdersObjects = (jsonObjs, columns) => {
  return cleanData(
    jsonObjs
      .map((obj, index) => {
        let streamlined = {};
        streamlined = columns.reduce((acc, column) => {
          acc[column.field] = obj[column.headerName];
          return acc;
        }, {});
        streamlined['id'] = index;
        return streamlined;
      })
      .filter((mapped) => mapped),
  );
};

export function streamlineObjectsForTrackingUpdate(jsonObjs, fields) {
  // Utility method to take an array of unfilitered JSON objs and fit them to our needs
  if (fields.exportMapping && Object.keys(fields.exportMapping.fields).length !== 0) {
    return cleanData(
    jsonObjs.map((elem,index) => {
      let update = []
      fields.exportMapping.column.fieldIds.forEach((fieldId, index)=>{
        let fieldName = fields.exportMapping.fields[index].fieldValue === '' ? fields.exportMapping.fields[index].label : fields.exportMapping.fields[index].fieldValue;
        update[index] = {label: fieldId, name:fieldName, value: elem[fieldId]}
      })
      return update;
    }))


  }
  else {
    return cleanData(
    // jsonObjs.filter(mapped => mapped)
    // eslint-disable-next-line array-callback-return
    jsonObjs.map((elem, index) => {
      Object.entries(elem).reduce((accumulator, [key, value]) => {
        const transformedKey = key.replace(/\s+/g, '').toLowerCase();
        accumulator[transformedKey] = value;
        return accumulator;
      });
      // console.log(elem)
      return {
        id: index,
        orderId: getOrderId(elem),
        manufacturerProductName: getProductName(elem),
        receiverName: getRecipientName(elem),
        receiverNumber: getRecipientPhoneNumber(elem),
        zipcode: getZipcode(elem),
        trackingNumber: getTrackingNumber(elem),
        courier: getCourier(elem),
        // productName: getProductName(elem),
        // recipientName: getRecipientName(elem),
        // recipientPhoneNumber: getRecipientPhoneNumber(elem),
      };
    }),
  );
  }
}

const getOrderId = (elem) => {
  if (elem['주문id']) {
    return elem['주문id'];
  } else if (elem['주문Id']) {
    return elem['주문Id'];
  } else if (elem['orderid']) {
    return elem['orderid'];
  } else if (elem['orderId']) {
    return elem['orderId'];
  } else {
    return '';
  }
};

const getRecipientName = (elem) => {
  if (elem['받는분성명']) {
    return elem['받는분성명'];
  } else if (elem['recipientname']) {
    return elem['receipientname'];
  } else {
    return '';
  }
};

const getRecipientPhoneNumber = (elem) => {
  if (elem['받는분전화번호']) {
    return elem['받는분전화번호'];
  } else if (elem['recipientphonenumber']) {
    return elem['recipientphonenumber'];
  } else {
    return '';
  }
};

const getProductName = (elem) => {
  if (elem['상품명']) {
    return elem['상품명'];
  } else if (elem['productname']) {
    return elem['productname'];
  } else {
    return '';
  }
};

const getZipcode = (elem) => {
  if (elem['우편번호']) {
    return elem['우편번호'];
  } else if (elem['zipcode']) {
    return elem['zipcode'];
  } else {
    return '';
  }
};

const getTrackingNumber = (elem) => {
  if (elem['운송장번호']) {
    return elem['운송장번호'];
  } else if (elem['trackingnumber']) {
    return elem['trackingnumber'];
  } else {
    return '';
  }
};

const getCourier = (elem) => {
  if (elem['택배사']) {
    return elem['택배사'];
  } else if (elem['courier']) {
    return elem['courier'];
  } else {
    return '';
  }
};
