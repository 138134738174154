import { DEFAULT_HEADERS } from '../../constants';

export const editProductMutation = (body) => ({
  url: ('/' + body.storeId + '/products/' + body.updatedProduct.productId),
  headers: DEFAULT_HEADERS,
  method: 'PUT',
  // Include the entire post object as the body of the request
  body: {
    'storeID': body.storeId, 
    'product': body.updatedProduct,
    'productType': body.productType,
  }
});