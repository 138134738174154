export const initProductSchema = {
  manufacturerCode: '6a420133-dbf4-492f-ac93-bd4eed8ab0a6',
  manufactureCode: '',
  dateCreated: '2023-01-11',
  manufacturerPrice: '12',
  yarnDenier: '',
  stock: '0',
  weight: '0',
  basicColor: '',
  taxIncluded: true,
  option5: '',
  manufacturerName: 'Company 1',
  option4: '',
  option3: '',
  productCode: '123414',
  warehouse: '',
  option2: '',
  option1: '',
  color: '',
  productId: '6aaf48ac-355f-4775-ae73-4333cde547ef',
  quantity: '10',
  warehouseId: null,
  availableInOnlineStore: true,
  productType: 'RAW',
  colorClass: '',
  dropShipping: 'Yes',
  grade: '0',
  dynamicFormValues: {
    productColors: {
      fields: {
        NewField2: {
          defaultField: false,
          id: 'NewField2',
          fieldName: 'Gold',
          isMandatory: false,
        },
        NewField1: {
          defaultField: false,
          id: 'NewField1',
          fieldName: 'Black',
          isMandatory: false,
        },
      },
      column: {
        title: 'Add Product',
        fieldIds: ['NewField1', 'NewField2'],
        id: 'column-1',
      },
    },
    productOptions: {
      fields: {
        NewField2: {
          defaultField: false,
          id: 'NewField2',
          fieldName: 'New Option 2',
          isMandatory: false,
        },
        NewField3: {
          defaultField: false,
          id: 'NewField3',
          fieldName: 'New Option 3',
          isMandatory: false,
        },
        NewField1: {
          defaultField: false,
          id: 'NewField1',
          fieldName: 'New Option 1',
          isMandatory: false,
        },
      },
      column: {
        title: 'Add Product',
        fieldIds: ['NewField1', 'NewField2', 'NewField3'],
        id: 'column-1',
      },
    },
  },
  use: false,
  density: '',
  fabricClass: '',
  gender: 'na',
  categories: ['Clothing'],
  remaining: '-4',
  yarnBale: '',
  images: [],
  description: '<p>desc</p>',
  price: '12',
  material: '',
  productName: 'not defined',
  standard: '',
  status: 'in_stock',
  localizedStatus: 'In Stock',
};
