export const FREE = 'free';
export const TRIAL = 'trial';
export const ESSENTIALS = 'essentials';
export const INTERMEDIATE = 'intermediate';
export const PREMIUM = 'premium';
export const ENTERPRISE = 'enterprise';

export const SUBSCRIPTION_PLANS = [ESSENTIALS, INTERMEDIATE, PREMIUM, ENTERPRISE];

export const MONTHLY = 'monthly';
export const ANNUALLY = 'annually';

export const BILLING_CYCLE = [MONTHLY, ANNUALLY];

export const TRANSACTION = 'transaction';
export const USER = 'user';
export const WAREHOUSE = 'warehouse';
export const SYNCED_API_ACCOUNT = 'syncedApiAccount';
export const SELLGPT = 'sellGpt';

export const MODAL_TYPE = {
  pageLimit: 'pageLimit',
  numberLimit: 'numberLimit',
};

export const MODAL_TEXT_CONFIG = {
  [ESSENTIALS]: [
    {
      type: 'transaction',
      text: 'subscription.subscriptionPlans.essentials.description',
    },
    {
      type: 'user',
      text: 'landing.pricing.essentials.description2',
    },
    {
      type: 'warehouse',
      text: 'landing.pricing.essentials.description3',
    },
    // {
    //   type: 'syncedApiAccount',
    //   text: 'landing.pricing.essentials.description4',
    // },
    {
      type: 'sellGpt',
      text: 'landing.pricing.essentials.description5',
    },
  ],
  // [INTERMEDIATE]: [
  //   {
  //     type: 'transaction',
  //     text: 'subscription.subscriptionPlans.intermediate.description',
  //   },
  //   {
  //     type: 'user',
  //     text: 'landing.pricing.intermediate.description2',
  //   },
  //   {
  //     type: 'warehouse',
  //     text: 'landing.pricing.intermediate.description3',
  //   },
  //   {
  //     type: 'syncedApiAccount',
  //     text: 'landing.pricing.intermediate.description4',
  //   },
  //   {
  //     type: 'sellGpt',
  //     text: 'landing.pricing.intermediate.description5',
  //   },
  // ],
  [PREMIUM]: [
    {
      type: 'transaction',
      text: 'subscription.subscriptionPlans.premium.description',
    },
    {
      type: 'user',
      text: 'landing.pricing.premium.description2',
    },
    {
      type: 'warehouse',
      text: 'landing.pricing.premium.description3',
    },
    // {
    //   type: 'syncedApiAccount',
    //   text: 'landing.pricing.premium.description4',
    // },

    {
      type: 'etc',
      text: 'landing.pricing.premium.description5',
    },

    {
      type: 'sellGpt',
      text: 'landing.pricing.premium.description6',
    },
  ],
  [ENTERPRISE]: [
    {
      type: 'etc',
      text: 'landing.pricing.enterprise.description9',
    },
    {
      type: 'user',
      text: 'landing.pricing.enterprise.description2',
    },
    {
      type: 'warehouse',
      text: 'landing.pricing.enterprise.description3',
    },
    // {
    //   type: 'syncedApiAccount',
    //   text: 'landing.pricing.enterprise.description4',
    // },

    {
      type: 'etc',
      text: 'landing.pricing.enterprise.description5',
    },

    {
      type: 'sellGpt',
      text: 'landing.pricing.enterprise.description6',
    },
  ],
};

export const PAGE_ACCESS_CONFIG = [
  {
    type: 'onlineOrders',
    text: 'proModal.label.onlineOrders',
    path: '/dashboard/onlineorders',
  },
  {
    type: 'payment',
    text: 'proModal.label.payment',
    path: '/dashboard/payment',
  },
  {
    type: 'taxArchive',
    text: 'proModal.label.taxArchive',
    path: '/dashboard/taxes',
  },
  {
    type: 'marketingTools',
    text: 'proModal.label.marketingTools',
    path: '/dashboard/tools',
  },
  {
    type: 'procurement',
    text: 'proModal.label.procurement',
    path: 'procurement',
  },
  {
    type: 'manufacturedGoods',
    text: 'proModal.label.manufacturedGoods',
    path: 'manufactured-products',
  },
];

export const MODAL_PAGE_ACCESS_TEXT_CONFIG = {
  [ESSENTIALS]: PAGE_ACCESS_CONFIG,
  [PREMIUM]: PAGE_ACCESS_CONFIG,
  // [ENTERPRISE]: [],
};

// {
//   type: 'transaction',
//   text: 'subscription.subscriptionPlans.essentials.description',
// },
// {
//   type: 'user',
//   text: 'landing.pricing.essentials.description2',
// },
// {
//   type: 'warehouse',
//   text: 'landing.pricing.essentials.description3',
// },
// {
//   type: 'syncedApiAccount',
//   text: 'landing.pricing.essentials.description4',
// },
// {
//   type: 'sellGpt',
//   text: 'landing.pricing.essentials.description5',
// },

export const SUBSCRIPTION_NUMBER_LIMITS = {
  [FREE]: {
    user: 4,
    warehouse: 2,
    syncedApiAccount: 3,
    sellGpt: 5,
    transaction: 400,
  },
  [ESSENTIALS]: {
    user: 4,
    warehouse: 2,
    syncedApiAccount: 3,
    sellGpt: 5,
    transaction: 400,
  },
  [PREMIUM]: {
    user: 20,
    warehouse: 999999999,
    syncedApiAccount: 999999999,
    sellGpt: 75,
    transaction: 20000,
  },
  [ENTERPRISE]: {
    user: 999999999,
    warehouse: 999999999,
    syncedApiAccount: 999999999,
    sellGpt: 999999999,
    transaction: 999999999,
  },
};
