import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fCurrencyWon(number) {
  return "₩" + numeral(number).format('0,0');
}

export function fCurrencyWonText(number) {
  return numeral(number).format('0,0') + " 원";
}

export function fCurrencyWonSpaced(number) {
  return "₩ " + numeral(number).format('0,0');
}

export function stringToSafeFloat(string) {
  if (typeof string === 'string') {
    let result = parseFloat(string.replace(",", ""));
    if (isNaN(result)) result = 0;
    return result;
  } else if (typeof string === 'number') {
    return string;
  } else return 0.00001;
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}
