import { createSlice } from '@reduxjs/toolkit';
import { getTutorialStatusFromAWS, patchIndividualTutorialStatusFromAWS } from '../../../server/awsClient';
import { dispatch } from '../store';

// This is the Redux shared state that you will be updating. Source of truth via your reducers
// We are defaulting to false in the case of a server error, it just doesn't show by default
// Unsure if error handling is really worth it for a one time tutorial, would be weird to show a snackbar, just logged on console
const initialState = {
  storeId: "",
  error: {},
  tutorialStatuses: {
    dataMigrationAgreement: false,
    commandCentral: false,
    offlineOrders: false,
    onlineOrders: false,
    orderStatus: false,
    cashFlow: false,
    invoicing: false,
    payment: false,
    taxArchive: false,
    buzzwords: false,
    hotProducts: false,
    priceMatch: false,
    sellGpt: false,
    events: false,
    performance: false,
    store: false,
    inventoryCatalogue: false,
    procurement: false,
    inventoryManagement: false,
    customerDirectory: false,
    contactUs: false,
    userManagement: false,
    accountSettings: false
  }
};
  
//Creating a slice with action reducers that handle each slice of a state
const slice = createSlice({
  name: 'tutorialStatuses',
  initialState,
  reducers: {

    //Since this takes the error objects and unpacks it into our state, it requires both the state and action paramters
    hasError(state, action) {
      state.error = action.payload;
    },

    //In the case of an individual tutorial being completed
    //Takes in which tutorial and updates value to haveseen
    setIndividualTutorialStatus(state, action) {
      const { key, value } = action.payload;
      return {
        ...state,
        tutorialStatuses: {
          ...state.tutorialStatuses,
          [key]: value
        }
      };
    },
    
    //Gets all tutorials statuses from tutorial-status DB
    getTutorialStatuses(state, action) {
      state.tutorialStatuses = action.payload;
    },
  },
});

//Exporting redux constants reducer for tutorialStatuses
export const {
  hasError,
  setIndividualTutorialStatus,
  getTutorialStatus,
} = slice.actions;

export default slice.reducer;

//Creating an exported constant that can be accesses throughout components
export const currentTutorialStatuses = (state) => state.tutorialStatuses;

// So this is an action creator method that is exported out so that other components can call it
// What it does is that it calls in async api calls, and then passes the action as well as the state to trigger the reducers
export async function fetchCurrentTutorialStatuses() {
  const controller = new AbortController();
  return await getTutorialStatusFromAWS(controller)
    .then((response) => {
      dispatch(slice.actions.getTutorialStatuses(response));
    })
    .catch((error) => {
      dispatch(slice.actions.hasError(error.message));
    });
}

export async function patchIndividualTutorialStatus(tutorialKeyName, value) {
  try {
    dispatch(slice.actions.setIndividualTutorialStatus({ key: tutorialKeyName, value }));
    await patchIndividualTutorialStatusFromAWS(tutorialKeyName, value);
    fetchCurrentTutorialStatuses();
  } catch (error) {
    dispatch(slice.actions.hasError(error.message));
  }
}
