import { useContext, createContext, useCallback, useMemo, useState, useEffect } from 'react';
import {
  getStoreId,
  getEmail,
  setPermissionsInfo,
  setUserId as setLocalStorageUserId,
} from 'src/components/authentication/TempAuthService';
import { CURRENCY } from 'src/constants';
import { getStoreDetails, getUserPermissions } from 'src/server/awsClient';
import { currencyUtil } from 'src/utils/currency';
import { fCurrency, fCurrencyWon } from 'src/utils/formatNumber';
import { format, utcToZonedTime } from 'date-fns-tz';

const storeInfoInit = {
  storeName: '',
  storePhone: '',
  storeEmail: '',
  storeAddress: '',
  storeState: '',
  storeZip: '',
  storeCity: '',
  storeCountry: '',
  currency: 'KRW',
  timeZone: '',
  storeId: '',
};

//   storeInfo: storeInfoInit,
const AccountSettingContextInit = {
  /**
   *
   * @param {Array<Object>} data
   * @param {string} key
   * @returns {Array<Object>} converted data
   * @example
   * const data = [
   *  { amount: "$100" },
   *  { amount: "$200" },
   *  { amount: "$300" },
   * ];
   * const convertedData = convertAmount(data, "amount");
   * console.log(convertedData);
   *
   * @description
   * this function will convert the amount to the currency that is set in the store settings
   */
  getCurrencyLocalizedData: (data, key) => {},
  /**
   * @param {string} data
   * @returns {string} converted data
   * @example
   * const data = "$100";
   * const currency = "KRW";
   * const convertedData = getCurrencyLocalizedSingleData(data);
   * console.log(convertedData);
   * // output: 100 * 1400 = ₩140000
   *
   */
  getCurrencyLocalizedSingleData: (data) => {},
  getLocalizedTime: (utcDate) => {},
  fetchData: async () => {},
  updateData: (data) => {},
  /**
   * @type {"USD" | "KRW"}
   */
  currency: 'USD',
  storeName: '',
  storeInfo: storeInfoInit,
  loading: false,
  /**
   * @type {"$" | "₩"}
   */
  currencySymbol: CURRENCY.SYMBOL.KRW,
  /**
   * @param {number} amount
   * @returns {string} formatted currency
   */
  formatCurrency: (amount) => {},
  formatCurrencyByCurrency: (amount, currency) => {},
  userId: '',
  secondaryCurrency: null,
  setSecondaryCurrency: () => {},
};

const AccountSettingContext = createContext(AccountSettingContextInit);
export const useAccountSetting = () => useContext(AccountSettingContext);

export const AccountSettingProvider = ({ children }) => {
  const [storeInfo, setStoreInfo] = useState(storeInfoInit);
  const [userId, setUserId] = useState('');
  const [loading, setLoading] = useState(false);
  const [secondaryCurrency, setSecondaryCurrency] = useState(null);

  const updateData = (_storeInfo, permissions) => {
    const currencyProcessed = {
      ..._storeInfo,
      currency: currencyUtil.getDetectedCurrency(_storeInfo.currency),
    };
    setStoreInfo(currencyProcessed);
    if (!permissions) return;
    setPermissionsInfo(JSON.stringify(permissions));
  };

  const fetchData = async () => {
    setLoading(true);
    const storeId = getStoreId();
    const email = getEmail();

    if (storeId && email) {
      const { data } = await getStoreDetails(storeId, email);
      const permissions = await getUserPermissions(storeId, email);
      if (permissions.data && permissions.data.permissions) {
        updateData(data, permissions.data.permissions);
        setUserId(permissions?.data?.userId);
        setLocalStorageUserId(permissions?.data?.userId);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    const init = async () => {
      try {
        await fetchData();
      } catch (error) {
        console.error(error);
      }
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *
   * @param {Array<Object>} data
   * @param {string} key
   * @returns {Array<Object>} converted data
   * @example
   * const data = [
   *  { amount: "$100" },
   *  { amount: "$200" },
   *  { amount: "$300" },
   * ];
   * const convertedData = getCurrencyLocalizedData(data, "amount");
   * console.log(convertedData);
   *
   * @description
   * this function will convert the amount to the currency that is set in the store settings
   */
  const getCurrencyLocalizedData = (data, key) => {
    try {
      const { currency } = storeInfo;
      if (!currency === '') return data;
      return data.map((item) => {
        const { [key]: amount } = item;
        const convertedCurrency = currencyUtil.getConvertedAmount(amount, currency);
        return {
          ...item,
          [key]: currency === 'USD' ? fCurrency(convertedCurrency) : fCurrencyWon(convertedCurrency),
        };
      });
    } catch (error) {
      console.error(error);
      return data;
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const getCurrencyLocalizedSingleData = (data) => {
    const { currency } = storeInfo;
    if (currency === '') return data;

    // return currencyUtil.getConvertedAmount(data, currency);
    const convertedCurrency = currencyUtil.getConvertedAmount(data, currency);
    return currency === 'USD' ? fCurrency(convertedCurrency) : fCurrencyWon(convertedCurrency);
  };

  const getLocalizedTime = useCallback(
    (utcDate, pattern = 'yyyy/MM/dd HH:mm:ss') => {
      try {
        // Use the user's time zone from settings or a default if not available
        let { timeZone = 'UTC' } = storeInfo;
        if (timeZone === "kst") {
          timeZone = "Asia/Seoul";
        }
        // else if (timeZone === "pst") {
        //   timeZone = "America/Los_Angeles";
        // }
        // else if (timeZone === "cst") {
        //   timeZone = "America/Chicago";
        // }
        // else if (timeZone === "est") {
        //   timeZone = "America/New_York";
        // }

        const zonedDate = utcToZonedTime(utcDate, timeZone);
        const output = format(zonedDate, pattern, { timeZone });
  
        return output;
      } catch (error) {
        console.error(error);
        return utcDate;
      }
    },
    [storeInfo?.timeZone],
  );
  const currencySymbol = CURRENCY?.SYMBOL[storeInfo?.currency];

  const formatCurrency = (amount) => {
    let { currency } = storeInfo;
    if (secondaryCurrency) {
      currency = secondaryCurrency;
    }

    if (currency === undefined || currency === null || currency === '') {
      return amount;
    }
    if (CURRENCY?.CURRENCY_EXPRESSIONS?.USD?.includes(currency)) {
      return fCurrency(amount ?? 0);
    }
    if (CURRENCY?.CURRENCY_EXPRESSIONS?.KRW?.includes(currency)) {
      return fCurrencyWon(amount ?? 0);
    }
    if(currencySymbol){
      return `${currencySymbol}${amount ?? 0}`;
    }
  };

  const formatCurrencyByCurrency = (amount, currency) => {
    try {
      if (CURRENCY.CURRENCY_EXPRESSIONS.USD.includes(currency?.toUpperCase())) return fCurrency(amount ?? 0);
      if (CURRENCY.CURRENCY_EXPRESSIONS.KRW.includes(currency?.toUpperCase())) return fCurrencyWon(amount ?? 0);
      return `${currencySymbol}${amount ?? 0}`;
    } catch (error) {
      console.error(error);
      return 0;
    }
  };

  const values = useMemo(
    () => ({
      getCurrencyLocalizedData,
      getCurrencyLocalizedSingleData,
      fetchData,
      updateData,
      currency: storeInfo.currency,
      storeName: storeInfo.storeName,
      getLocalizedTime,
      storeInfo,
      loading,
      currencySymbol,
      formatCurrency,
      formatCurrencyByCurrency,
      userId,
      secondaryCurrency,
      setSecondaryCurrency,
    }),
    [
      getCurrencyLocalizedData,
      fetchData,
      updateData,
      storeInfo.currency,
      storeInfo.storeName,
      getCurrencyLocalizedSingleData,
      getLocalizedTime,
      storeInfo,
      loading,
      currencySymbol,
      formatCurrency,
      formatCurrencyByCurrency,
      userId,
      secondaryCurrency,
      setSecondaryCurrency,
    ],
  );

  return <AccountSettingContext.Provider value={values}>{children}</AccountSettingContext.Provider>;
};
