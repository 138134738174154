import { Card, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import shape from 'src/theme/shape';

export const FlatCard = styled(Card)(({ theme }) => ({
  borderRadius: shape.borderRadiusFlatten,
}));

export const FlatContainer = styled(Container)(({ theme }) => ({
  borderRadius: shape.borderRadiusFlatten,
}));
