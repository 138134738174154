import { createSlice } from '@reduxjs/toolkit';
import { initOfflineOrderMultiOrderRow } from 'src/components/offline-orders/multiline-orders/data';
// utils
// utils
import {
  getOfflineOrdersFromAWS,
  getOfflineOrderFromAWS,
  updateOfflineOrdersToAWS,
  getOfflineOrdersConfigFromAWS,
} from '../../../server/awsClient';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  offlineOrders: [],
  offlineOrdersConfig: null,
  offlineOrder: null,
  sortBy: null,
  columns: [],
  isColumnsInitialized: false,
  multiLineOrders: [],
  isMultiLineOrderInitialized: false,

  filterdMultiLineOrders: [],
  columnsIsLoading: true,
  products: [],
  manufacturedProducts: [],
  allProducts: [],
  customers: [],
  productsIsLoading: true,
  alertConfig: {
    open: false,
    message: '',
    severity: 'success',
  },
  filters: {
    status: 'All',
  },
  modalConfig: {
    isOpen: false,
    type: null,
    data: {},
  },
};

const slice = createSlice({
  name: 'offlineOrder',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    setFilterdMultiLineOrders(state, action) {
      state.filterdMultiLineOrders = action.payload;
    },

    setOfflineOrdersProducts(state, action) {
      state.products = action.payload;
      state.allProducts = state.manufacturedProducts.concat(action.payload)
    },

    setOfflineOrdersManufacturedProducts(state, action) {
      state.manufacturedProducts = action.payload;
      state.allProducts = state.products.concat(action.payload)
    },

    setOfflineOrdersProductsIsLoading(state, action) {
      state.productsIsLoading = action.payload;
    },

    setOfflineOrdersCustomers(state, action) {
      state.customers = action.payload;
    },

    addOfflineOrdersCustomer(state, action) {
      state.customers = [{ ...action.payload, isNew: true, dateCreated: new Date().toISOString() }, ...state.customers];
    },

    setOfflineOrdersCustomersIsLoading(state, action) {
      state.customersIsLoading = action.payload;
    },

    /**
     *  @param {{ payload: alertConfig }} action
     *  @typedef {{ open: boolean, message: string, severity: 'success' | 'info' | 'warning' | 'error' }} alertConfig
     */
    setOfflineOrdersAlertConfig(state, action) {
      state.alertConfig = action.payload;
    },

    setOfflineMultiLineOrders(state, action) {
      state.isMultiLineOrderInitialized = true;
      const mapOrders = (order) => ({
        ...order,
        customerId: order.customerId ?? '',
        deliveryOption: 'shipping',
        paymentStatus: 'unpaid',
      });
      state.multiLineOrders = action.payload?.map(mapOrders);
    },

    setOfflineMultiLineOrderIsInitialized(state, action) {
      state.isMultiLineOrderInitialized = action.payload;
    },

    /**  @param {{ payload: string }} action  */
    deleteOfflineMultiLineOrder(state, action) {
      state.multiLineOrders = state.multiLineOrders.filter((order) => order.id !== action.payload);
    },

    /**  @param {{ payload: string[] }} action  */
    deleteOfflineMultiLineOrders(state, action) {
      state.multiLineOrders = state.multiLineOrders.filter((order) => !action.payload.includes(order.id));
    },

    addOfflineEmptyMultiLineOrder(state, action) {
      state.multiLineOrders = [
        { id: action.payload, ...initOfflineOrderMultiOrderRow, datePlaced: new Date() },
        ...state.multiLineOrders,
      ];
    },

    updateOfflineMultiLineOrder(state, action) {
      state.multiLineOrders = state.multiLineOrders.map((order) => {
        if (order.id === action.payload.id) {
          return action.payload;
        }
        return order;
      });
    },

    setOfflineOrdersColumns(state, action) {
      state.columns = action.payload;
    },

    setOfflineOrdersColumnsInitialized(state, action) {
      state.isColumnsInitialized = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setOfflineOrders(state, action) {
      state.offlineOrders = action.payload;
    },

    setOfflineOrderConfig(state, action) {
      state.offlineOrdersConfig = action.payload;
    },

    setOfflineOrdersColumnIsLoading(state, action) {
      state.columnsIsLoading = action.payload;
    },

    // GET OFFLINE ORDERS
    getOfflineOrdersSuccess(state, action) {
      state.isLoading = false;
      state.offlineOrders = action.payload;
    },

    // GET OFFLINE ORDER
    getOfflineOrderSuccess(state, action) {
      state.isLoading = false;
      state.offlineOrder = action.payload;
    },

    // GET OFFLINE ORDERS CONFIG
    getOfflineOrdersConfigSuccess(state, action) {
      state.isLoading = false;
      state.offlineOrdersConfig = action.payload;
    },

    resetOfflineOrdersConfig(state) {
      state.offlineOrdersConfig = null;
    },

    //  SORT & FILTER OFFLINE ORDERS
    sortByOfflineOrders(state, action) {
      state.sortBy = action.payload;
    },

    filterOfflineOrders(state, action) {
      state.filters.status = action.payload.status;
    },

    updateOfflineOrdersSuccess(state, action) {
      var orders = action.payload.data.response;
      var a = [];
      state.offlineOrders.forEach((order) => {
        if (orders[order.offlineOrderId]) {
          a.push(orders[order.offlineOrderId]);
        } else {
          a.push(order);
        }
      });
      state.offlineOrders = a;
    },

    resetOfflineOrders(state) {
      state.offlineOrders = [];
      state.multiLineOrders = [];
      state.columnsIsLoading = true;
      state.alertConfig = {
        open: false,
        message: '',
        severity: 'success',
      };
    },

    /** @param { payload: { isOpen: boolean, type: 'createCustomer' | 'default', data: any } } */
    setOfflineOrderModalConfig(state, action) {
      state.modalConfig = action.payload;
    },

    closeOfflineOrderModal(state) {
      state.modalConfig = {
        ...state.modalConfig,
        isOpen: false,
      };
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  sortByOfflineOrders,
  filterOfflineOrders,
  setOfflineOrders,
  setOfflineOrderConfig,
  setOfflineOrdersColumns,
  setOfflineOrdersColumnIsLoading,
  setOfflineMultiLineOrders,
  setOfflineOrdersAlertConfig,
  deleteOfflineMultiLineOrder,
  deleteOfflineMultiLineOrders,
  updateOfflineMultiLineOrder,
  resetOfflineOrders,
  setOfflineOrdersProducts,
  setOfflineOrdersManufacturedProducts,
  setOfflineOrdersProductsIsLoading,
  setOfflineOrdersCustomers,
  addOfflineOrdersCustomer,
  setOfflineOrdersCustomersIsLoading,
  addOfflineEmptyMultiLineOrder,
  setFilterdMultiLineOrders,
  setOfflineMultiLineOrderIsInitialized,
  setOfflineOrdersColumnsInitialized,
  setOfflineOrderModalConfig,
  closeOfflineOrderModal,
  resetOfflineOrdersConfig
} = slice.actions;

// ----------------------------------------------------------------------
export function getOfflineOrdersFromStore(abortController) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getOfflineOrdersFromAWS(abortController);
      dispatch(slice.actions.getOfflineOrdersSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOfflineOrderFromStore(abortController, id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getOfflineOrderFromAWS(abortController, id);
      dispatch(slice.actions.getOfflineOrderSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOfflineOrdersConfigFromStore(abortController) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getOfflineOrdersConfigFromAWS(abortController);
      dispatch(slice.actions.getOfflineOrdersConfigSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateOfflineOrdersFromStore(orders) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await updateOfflineOrdersToAWS(orders);
      dispatch(slice.actions.updateOfflineOrdersSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export const selectOfflineOrdersColumns = (state) => state.offlineOrder.columns;
export const selectOfflineOrdersColumnIsLoading = (state) => state.offlineOrder.columnsIsLoading;
export const selectOfflineMultiLineOrders = (state) => state.offlineOrder.multiLineOrders;
export const selectOfflineFilterdMultiLineOrders = (state) => state.offlineOrder.filterdMultiLineOrders;
export const selectOfflineOrdersAlertConfig = (state) => state.offlineOrder.alertConfig;
export const selectOfflineOrderProducts = (state) => state.offlineOrder.products;
export const selectOfflineOrderManufacturedProducts = (state) => state.offlineOrder.manufacturedProducts;
export const selectOfflineOrderAllProducts = (state) => state.offlineOrder.allProducts;
export const selectOfflineOrderProductsIsLoading = (state) => state.offlineOrder.productsIsLoading;
export const selectOfflineOrderCustomers = (state) => state.offlineOrder.customers;
export const selectOfflineOrderCustomersIsLoading = (state) => state.offlineOrder.customersIsLoading;
// export const selectOfflineMultiLineOrdersIsLoading = (state) => state.offlineOrder.multiLineOrdersIsLoading;
export const selectOfflineMultiLineOrderIsInitialized = (state) => state.offlineOrder.isMultiLineOrderInitialized;
export const selectOfflineOrdersColumnsInitialized = (state) => state.offlineOrder.isColumnsInitialized;
export const selectOfflineOrdersConfig = (state) => state.offlineOrder.offlineOrdersConfig;

export const selectOfflineOrderModalConfig = (state) => state.offlineOrder.modalConfig;
