// import { camelCaseKeysForObjects } from '../../util';
import { DEFAULT_HEADERS } from '../../constants';

export const getInventoryConfigQuery = (body) => ({
  url: '/' + body.storeId + '/inventory-config',
  headers: DEFAULT_HEADERS,
});

export const getInventoryConfigTransformResponse = (responseData) => {
  return responseData?.config;
};
