import { Amplify, Auth } from 'aws-amplify';
import AMPLIFY_CONFIG from './amplifyconfiguration.js';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/LoadingScreen';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import ReactGA from "react-ga4";
import { useEffect } from 'react';
import { fetchCurrentTutorialStatuses } from 'src/components/redux/slices/tutorial-statuses';
import { getUserId } from './components/authentication/TempAuthService';

/**
 * This is a hack to fix the issue with the Amplify library treating all incoming urls as OAuth responses.
 * https://github.com/aws-amplify/amplify-js/issues/9208#issuecomment-1309890756*
 * This can probably be removed when upgrading to Amplfiy v6
 */
const _handleAuthResponse = Auth._handleAuthResponse.bind(Auth)
Auth._handleAuthResponse = (url) => {
  const configuration = Auth.configure()
  if (!url.includes(configuration.oauth.redirectSignIn)) return
  return _handleAuthResponse(url)
}

Amplify.configure(AMPLIFY_CONFIG);

//TODO: CREATE NEW TRACKING ID ON MAIN SELLPOINT ACCT
const TRACKING_ID = "G-4RHH3KFQ9E";
ReactGA.initialize(TRACKING_ID)


// ----------------------------------------------------------------------

export default function App() {

  //Not my first choice to place this. However, this needed to be called once at the start for all pages because tutorialStatus is shared 
  //Redux will catch and persist that initial following
  const localStorageUserId = getUserId()
  const userId = localStorageUserId;
  //Basically on load fetchTutorialStatuses and update the redux state for tutorials globally
  useEffect(() => {
    if(!userId) return;
    const fetchTutorialStatuses = async () => {
      let controller = new AbortController();
      try {
        await fetchCurrentTutorialStatuses(controller)
      } catch (error) {
        console.error(error);
      }
    };

    fetchTutorialStatuses();
  }, [userId]);

  useEffect(() => {
    //Temporary block on warns for peace of mind while we debug. 
    console.warn = () => {};
    //disable scroll gesture site-wide
    document.body.style.overscrollBehaviorX = "none"
  }, [])

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <RtlLayout>
          <GlobalStyles />
          <ProgressBarStyle />
          <ScrollToTop />
          <Router />
        </RtlLayout>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
