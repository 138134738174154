import { Container, Dialog, Grid, Typography, Box, FormControl, Input } from '@mui/material';
import { useEffect, useState } from 'react';
import { unlockExcel } from 'src/server/awsClient';
import CancelIcon from '@mui/icons-material/Close';
import * as XLSX from 'xlsx';
import i18next from 'i18next';
import { LoadingButton } from '@mui/lab';
import { motion } from 'framer-motion';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';

/**
 *
 * @note modal[0] : {false|true} , modal[1] : [data] , modal[2] : index
 */
export default function PasswordModal({ modal, setModal, csvPrep, setCsvPrep }) {
  const modalHandleClose = () => setModal((prev) => [false, prev[1]]);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [isWrong, setIsWrong] = useState(false);

  useEffect(() => {
    if (modal[0]) return;
    setPassword('');
    setIsUnlocked(false);
    setIsWrong(false);
  }, [modal[0]]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const res = await unlockExcel(modal[1], password);
      const dataBuffer = Buffer.from(res.data, 'base64');
      const blob = new Blob([dataBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      let csvFile;
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(blob);

      await new Promise((resolve) => {
        fileReader.onload = async (event) => {
          const workbook = XLSX.read(event.target.result, { type: 'array' });
          let firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          // this only works for naver excel
          Object.keys(firstSheet).forEach((key) => {
            if (firstSheet[key]?.w?.includes('+15')) firstSheet[key].w = firstSheet[key].v.toString();
          });
          csvFile = XLSX.utils.sheet_to_csv(firstSheet);
          resolve();
        };
      });

      // Naver Specific Checker
      if (csvFile.split('\n')[0].includes("다운로드 받은 파일로 '엑셀 일괄발송' 처리하는 방법")) {
        csvFile = csvFile.split('\n').slice(16).join('\n');
      }

      if (csvFile) {
        // console.log('🔓🔓🔓 unlocked')
        const _passwordExcel = [...csvPrep.passwordExcel];
        const _passwordExcelIndex = [...csvPrep.passwordExcelIndex];
        const convertedModalIndex = _passwordExcelIndex.indexOf(modal[2]);
        // console.log('🚀 ~ file: PasswordModal.js ~ line 66 ~ handleSubmit ~ convertedModalIndex', convertedModalIndex);
        setCsvPrep((prev) => ({
          ...prev,
          csv: [...csvPrep.csv, csvFile],
          passwordExcel: _passwordExcel.filter((_, index) => index !== convertedModalIndex),
          passwordExcelIndex: _passwordExcelIndex.filter((_, index) => index !== convertedModalIndex),
        }));
        setIsWrong(false);
        setIsUnlocked(true);
      }
    } catch {
      setIsWrong(true);
    }
    setLoading(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={modal[0]}
      onClose={modalHandleClose}
      keepMounted
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          px: 4,
          pt: 4,
          pb: 6,
        }}
      >
        <Box id="modal-modal-title" sx={{ fontSize: '1.125rem', fontWeight: 700 }}>
          {i18next.t('onlineOrderGrid.unlockModal.modalTitle')}
        </Box>
        <CancelIcon onClick={modalHandleClose} sx={{ cursor: 'pointer' }} />
      </Box>
      <Container id="modal-modal-description" maxWidth={false} disableGutters sx={{ width: '100%', px: 4 }}>
        {!isUnlocked && (
          <Box>
            <Typography color={isWrong && 'error'}>
              {!isWrong
                ? i18next.t('onlineOrderGrid.unlockModal.modalDescription1')
                : i18next.t('onlineOrderGrid.unlockModal.modalDescription2')}
            </Typography>
            <FormControl fullWidth sx={{ marginTop: 1.5 }}>
              <Input value={password} onChange={(e) => setPassword(e.target.value)} />
            </FormControl>
          </Box>
        )}
        {isUnlocked && (
          <Container
            sx={{
              width: '100%',
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                default: {
                  duration: 0.3,
                  ease: [0, 0.71, 0.2, 1.01],
                },
                scale: {
                  type: 'spring',
                  damping: 5,
                  stiffness: 100,
                  restDelta: 0.001,
                },
              }}
            >
              <CheckCircleOutlineRoundedIcon color="success" sx={{ fontSize: 100 }} />
            </motion.div>
            <Typography align="center" variant="h4">
              {i18next.t('onlineOrderGrid.unlockModal.modalUnlockSuccess')}
            </Typography>
          </Container>
        )}

        <Grid container spacing={1} sx={{ marginTop: 4, marginBottom: 5 }}>
          {!isUnlocked && (
            <Grid item xs={12} md={6} lg={6} sx={{ paddingBottom: 1 }}>
              <LoadingButton
                hidden={isUnlocked}
                loading={loading}
                size="large"
                variant="contained"
                sx={{ width: '100%' }}
                disabled={password.length === 0}
                onClick={handleSubmit}
              >
                {i18next.t('onlineOrderGrid.unlockModal.modalSubmitButton')}
              </LoadingButton>
            </Grid>
          )}
          <Grid item xs={12} md={!isUnlocked ? 6 : 12} lg={!isUnlocked ? 6 : 12}>
            <LoadingButton
              loading={loading}
              size="large"
              variant={!isUnlocked ? 'outlined' : 'contained'}
              sx={{ width: '100%' }}
              onClick={modalHandleClose}
              color="error"
            >
              {i18next.t('onlineOrderGrid.unlockModal.modalCloseButton')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  );
}
