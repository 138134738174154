import { DEFAULT_HEADERS } from '../../constants';

/**
 * Edits customers, given as a list, in the customer directory.
 *
 * @param {List<Object> updatedInventoryConfig} list of customers to update
 */
export const editInventoryConfigMutation = (body) => ({
  url: '/' + body.storeId + '/inventory-config',
  headers: DEFAULT_HEADERS,
  method: 'POST',
  // Include the entire post object as the body of the request
  body: { config: body.config },
});
