import { Box } from '@mui/material';

// ----------------------------------------------------------------------
export default function PageNotFoundIllustration({ ...other }) {

  return (
    <Box {...other}>
      <svg version="1.1" id="Layer_1"
        viewBox="0 0 620 800">
      <g>
        <path fill="#292E33" d="M308.913,476.648c-0.728,0.005-1.384-0.008-2.07-0.024l0.098-3.999c0.654,0.016,1.296,0.018,1.972,0.023
          c0.648,0,1.294-0.008,1.937-0.025l0.104,3.999C310.275,476.64,309.595,476.648,308.913,476.648z M302.738,476.418
          c-1.363-0.104-2.739-0.243-4.089-0.416l0.506-3.968c1.283,0.163,2.59,0.296,3.886,0.396L302.738,476.418z M315.056,476.412
          l-0.305-3.988c1.29-0.099,2.597-0.233,3.884-0.398l0.51,3.968C317.79,476.166,316.414,476.308,315.056,476.412z M294.586,475.377
          c-1.345-0.243-2.699-0.524-4.025-0.835l0.914-3.895c1.259,0.296,2.545,0.562,3.822,0.793L294.586,475.377z M323.208,475.363
          l-0.715-3.936c1.274-0.231,2.56-0.5,3.822-0.797l0.916,3.894C325.903,474.838,324.549,475.119,323.208,475.363z M286.583,473.5
          c-1.316-0.382-2.635-0.802-3.919-1.248l1.312-3.778c1.219,0.423,2.47,0.821,3.72,1.185L286.583,473.5z M331.21,473.479l-1.119-3.84
          c1.248-0.363,2.499-0.763,3.72-1.188l1.314,3.776C333.841,472.676,332.522,473.096,331.21,473.479z M278.809,470.801
          c-1.269-0.516-2.538-1.07-3.774-1.65l1.697-3.621c1.174,0.549,2.379,1.076,3.583,1.565L278.809,470.801z M338.972,470.775
          l-1.51-3.704c1.197-0.487,2.398-1.014,3.57-1.563l1.697,3.622C341.496,469.708,340.232,470.262,338.972,470.775z M271.353,467.31
          c-1.208-0.644-2.413-1.327-3.582-2.029l2.062-3.429c1.109,0.668,2.254,1.316,3.4,1.928L271.353,467.31z M346.397,467.291
          l-1.881-3.529c1.139-0.607,2.28-1.255,3.392-1.924l2.062,3.428C348.801,465.97,347.599,466.651,346.397,467.291z M264.3,463.07
          c-1.135-0.764-2.263-1.567-3.353-2.386l2.402-3.198c1.035,0.777,2.106,1.54,3.185,2.266L264.3,463.07z M353.436,463.06
          l-2.236-3.316c1.078-0.728,2.147-1.489,3.18-2.265l2.402,3.198C355.696,461.492,354.57,462.294,353.436,463.06z M257.725,458.131
          c-1.045-0.872-2.084-1.786-3.087-2.715l2.717-2.936c0.953,0.883,1.94,1.75,2.935,2.58L257.725,458.131z M360.002,458.125
          l-2.564-3.07c0.998-0.834,1.985-1.702,2.934-2.579l2.717,2.936C362.09,456.335,361.052,457.248,360.002,458.125z M251.692,452.549
          c-0.954-0.979-1.894-1.992-2.794-3.016l3.004-2.642c0.854,0.972,1.747,1.935,2.653,2.864L251.692,452.549z M366.031,452.544
          l-2.863-2.794c0.902-0.925,1.796-1.889,2.654-2.865l3.004,2.643C367.922,450.555,366.981,451.569,366.031,452.544z
          M246.264,446.381c-0.844-1.064-1.675-2.168-2.469-3.282l3.256-2.323c0.756,1.059,1.545,2.109,2.348,3.12L246.264,446.381z
          M371.463,446.372l-3.135-2.485c0.806-1.016,1.597-2.067,2.349-3.123l3.258,2.32C373.143,444.196,372.311,445.303,371.463,446.372z
          M241.497,439.693c-0.734-1.152-1.448-2.335-2.119-3.517l3.479-1.976c0.638,1.122,1.314,2.246,2.014,3.341L241.497,439.693z
          M376.235,439.673l-3.373-2.149c0.701-1.101,1.38-2.228,2.017-3.35l3.479,1.975C377.688,437.329,376.974,438.516,376.235,439.673z
          M237.443,432.555c-0.609-1.216-1.196-2.465-1.744-3.713l3.662-1.607c0.521,1.185,1.078,2.372,1.658,3.528L237.443,432.555z
          M380.294,432.516l-3.576-1.792c0.579-1.155,1.138-2.346,1.661-3.54l3.664,1.604C381.492,430.046,380.903,431.301,380.294,432.516z
          M234.149,425.043c-0.483-1.277-0.939-2.584-1.355-3.883l3.811-1.219c0.395,1.233,0.827,2.473,1.285,3.686L234.149,425.043z
          M383.597,424.971l-3.744-1.409c0.461-1.222,0.893-2.466,1.286-3.697l3.811,1.216C384.535,422.377,384.08,423.686,383.597,424.971z
          M231.644,417.213c-0.351-1.326-0.668-2.673-0.945-4.003l3.916-0.815c0.263,1.262,0.564,2.539,0.896,3.798L231.644,417.213z
          M386.098,417.132l-3.869-1.018c0.328-1.247,0.629-2.526,0.894-3.8l3.916,0.812C386.76,414.469,386.443,415.816,386.098,417.132z
          M229.961,409.166c-0.21-1.354-0.387-2.725-0.526-4.076l3.979-0.41c0.133,1.283,0.301,2.586,0.501,3.873L229.961,409.166z
          M387.77,409.084l-3.953-0.611c0.197-1.274,0.364-2.578,0.497-3.873l3.979,0.407C388.152,406.37,387.977,407.742,387.77,409.084z
          M229.118,400.993c-0.07-1.354-0.106-2.736-0.108-4.107l4-0.006c0.002,1.305,0.036,2.619,0.103,3.906L229.118,400.993z
          M388.604,400.908l-3.994-0.203c0.065-1.293,0.099-2.607,0.099-3.904l0.001-0.166h4l-0.001,0.201
          C388.709,398.166,388.674,399.547,388.604,400.908z M233.104,392.976l-3.994-0.198c0.067-1.363,0.171-2.742,0.308-4.098l3.98,0.402
          C233.267,390.369,233.168,391.68,233.104,392.976z M384.601,392.732c-0.068-1.296-0.171-2.605-0.305-3.893l3.979-0.414
          c0.142,1.355,0.249,2.733,0.32,4.096L384.601,392.732z M233.89,385.208l-3.953-0.606c0.208-1.36,0.453-2.723,0.729-4.047
          l3.916,0.812C234.32,382.624,234.088,383.916,233.89,385.208z M383.792,384.967c-0.199-1.274-0.437-2.566-0.703-3.84l3.914-0.822
          c0.282,1.341,0.531,2.702,0.74,4.045L383.792,384.967z M235.47,377.562l-3.869-1.011c0.346-1.326,0.73-2.655,1.142-3.95
          l3.812,1.211C236.164,375.042,235.799,376.304,235.47,377.562z M382.187,377.328c-0.329-1.246-0.698-2.506-1.097-3.746l3.809-1.223
          c0.419,1.306,0.808,2.633,1.155,3.946L382.187,377.328z M237.836,370.115l-3.744-1.407c0.479-1.275,1-2.56,1.549-3.817l3.666,1.6
          C238.785,367.686,238.291,368.904,237.836,370.115z M379.799,369.897c-0.46-1.213-0.957-2.426-1.476-3.606l3.662-1.609
          c0.546,1.242,1.068,2.52,1.554,3.796L379.799,369.897z M240.964,362.948l-3.578-1.787c0.609-1.22,1.26-2.443,1.935-3.634l3.48,1.97
          C242.161,360.628,241.543,361.79,240.964,362.948z M376.662,362.766c-0.583-1.161-1.203-2.317-1.841-3.437l3.477-1.979
          c0.671,1.18,1.323,2.397,1.938,3.619L376.662,362.766z M244.813,356.146l-3.375-2.147c0.731-1.148,1.504-2.297,2.296-3.413
          l3.262,2.316C246.242,353.963,245.508,355.055,244.813,356.146z M372.807,355.991c-0.701-1.096-1.437-2.183-2.187-3.233
          l3.254-2.325c0.79,1.105,1.564,2.249,2.302,3.401L372.807,355.991z M249.341,349.777l-3.137-2.482
          c0.843-1.064,1.728-2.126,2.632-3.158l3.008,2.638C250.983,347.755,250.142,348.766,249.341,349.777z M368.271,349.642
          c-0.811-1.02-1.654-2.027-2.507-2.994l3-2.646c0.897,1.018,1.784,2.077,2.638,3.149L368.271,349.642z M254.494,343.907
          l-2.867-2.789c0.951-0.978,1.94-1.943,2.94-2.872l2.721,2.933C256.338,342.061,255.397,342.979,254.494,343.907z M363.106,343.786
          c-0.91-0.931-1.853-1.846-2.802-2.723l2.715-2.938c0.998,0.922,1.989,1.886,2.946,2.865L363.106,343.786z M260.219,338.598
          l-2.566-3.067c1.047-0.876,2.129-1.736,3.218-2.555l2.406,3.195C262.242,336.949,261.214,337.766,260.219,338.598z
          M357.366,338.487c-0.996-0.83-2.027-1.645-3.064-2.422l2.398-3.201c1.092,0.818,2.178,1.676,3.227,2.55L357.366,338.487z
          M266.453,333.903l-2.24-3.313c1.13-0.764,2.294-1.508,3.462-2.212l2.064,3.426C268.631,332.472,267.525,333.179,266.453,333.903z
          M351.117,333.803c-1.078-0.725-2.188-1.43-3.3-2.097l2.057-3.431c1.17,0.702,2.339,1.444,3.474,2.207L351.117,333.803z
          M273.132,329.875l-1.889-3.526c1.202-0.644,2.437-1.263,3.669-1.843l1.701,3.62C275.443,328.676,274.272,329.264,273.132,329.875z
          M344.413,329.782c-1.149-0.611-2.326-1.198-3.5-1.746l1.693-3.624c1.234,0.577,2.475,1.195,3.684,1.838L344.413,329.782z
          M280.183,326.559l-1.514-3.702c1.264-0.517,2.558-1.007,3.846-1.456l1.318,3.776C282.61,325.603,281.383,326.068,280.183,326.559z
          M337.327,326.474c-1.201-0.487-2.434-0.949-3.663-1.375l1.309-3.78c1.294,0.448,2.592,0.935,3.856,1.448L337.327,326.474z
          M287.55,323.985l-1.121-3.84c1.307-0.381,2.645-0.734,3.976-1.049l0.92,3.893C290.061,323.286,288.791,323.622,287.55,323.985z
          M329.943,323.919c-1.242-0.358-2.513-0.69-3.777-0.985l0.908-3.896c1.332,0.311,2.671,0.661,3.979,1.039L329.943,323.919z
          M295.146,322.187l-0.719-3.935c1.338-0.244,2.704-0.458,4.061-0.634l0.516,3.967C297.715,321.752,296.417,321.955,295.146,322.187
          z M322.343,322.146c-1.273-0.229-2.572-0.427-3.859-0.59l0.502-3.969c1.355,0.172,2.723,0.381,4.064,0.621L322.343,322.146z
          M302.888,321.183l-0.311-3.988c1.356-0.105,2.737-0.177,4.104-0.214l0.107,3.999C305.489,321.014,304.176,321.082,302.888,321.183
          z M314.597,321.165c-1.291-0.097-2.604-0.161-3.899-0.191l0.094-4c1.364,0.033,2.745,0.1,4.104,0.202L314.597,321.165z"/>
      </g>
      <circle fill="#F7C450" cx="308.859" cy="396.8" r="37.85"/>
      <g>
        <g>
          <path fill="#FFFFFF" d="M292.649,423.161l7.643-7.642l28.955-28.956l7.643-7.642c5.064,1.527,10.783,0.294,14.785-3.708
            c4.217-4.218,5.35-10.339,3.426-15.594l-8.936,8.936l-6.947-1.861l-1.861-6.947l8.936-8.936
            c-5.256-1.924-11.377-0.792-15.594,3.425c-4.004,4.002-5.236,9.722-3.709,14.786l-7.643,7.642l-28.955,28.956l-7.641,7.642
            c-5.066-1.527-10.785-0.294-14.787,3.708c-4.217,4.218-5.35,10.339-3.424,15.594l8.936-8.936l6.945,1.862l1.863,6.946
            l-8.936,8.936c5.254,1.924,11.375,0.792,15.592-3.425C292.944,433.944,294.177,428.226,292.649,423.161z"/>
          <path fill="#292E33" d="M278.475,444.28C278.475,444.28,278.475,444.28,278.475,444.28c-1.993-0.001-3.948-0.347-5.813-1.03
            l-2.941-1.077l10.333-10.334l-1.265-4.716l-4.715-1.264l-10.333,10.333l-1.078-2.94c-2.249-6.139-0.724-13.084,3.888-17.696
            c3.178-3.178,7.403-4.928,11.898-4.928c1.248,0,2.487,0.138,3.699,0.409l42.616-42.618c-1.262-5.614,0.416-11.494,4.521-15.597
            c3.171-3.171,7.391-4.917,11.881-4.917c1.992,0,3.948,0.346,5.814,1.029l2.942,1.077l-10.335,10.334l1.264,4.717l4.717,1.264
            l10.334-10.334l1.077,2.941c2.247,6.138,0.721,13.084-3.89,17.695c-3.178,3.178-7.403,4.928-11.898,4.928
            c-1.247,0-2.486-0.138-3.697-0.409l-42.618,42.618c1.262,5.613-0.416,11.494-4.521,15.597
            C287.184,442.533,282.964,444.28,278.475,444.28z M277.32,440.229c0.383,0.034,0.768,0.052,1.153,0.052c0.001,0,0.001,0,0.001,0
            c3.421,0,6.636-1.331,9.052-3.747c3.35-3.349,4.578-8.251,3.208-12.794l-0.346-1.146l45.933-45.931l1.146,0.345
            c1.207,0.364,2.46,0.549,3.724,0.549c3.427,0,6.648-1.334,9.07-3.756c2.708-2.709,4.02-6.476,3.693-10.205l-7.191,7.191
            l-9.178-2.459l-2.459-9.178l7.194-7.194c-0.383-0.034-0.768-0.051-1.155-0.051c-3.422,0-6.637,1.33-9.053,3.746
            c-3.35,3.348-4.579,8.25-3.208,12.794l0.346,1.146l-45.931,45.931l-1.146-0.345c-1.207-0.364-2.461-0.549-3.726-0.549
            c-3.427,0-6.648,1.334-9.07,3.756c-2.708,2.71-4.02,6.478-3.692,10.206l7.192-7.193l9.176,2.461l2.462,9.177L277.32,440.229z"/>
        </g>
        <g>
          <path fill="#61B4E4" d="M319.458,393.306c-0.781-0.78-2.047-0.78-2.828,0l-1.207,1.207c-0.119-0.301-0.305-0.599-0.568-0.861
            l-2.844-2.844l-9.664,9.664l2.844,2.844c0.264,0.263,0.561,0.449,0.861,0.568l-1.207,1.207c-0.781,0.781-0.781,2.047,0,2.828
            l35.189,35.188c0.781,0.781,2.047,0.781,2.828,0l11.785-11.785c0.781-0.781,0.781-2.047,0-2.828L319.458,393.306z"/>
          <g>
            <path fill="#FFFFFF" d="M307.126,395.585l-35.031-35.031L307.126,395.585z"/>
            
              <rect x="264.84" y="376.07" transform="matrix(0.7071 0.7071 -0.7071 0.7071 352.1603 -94.0515)" fill="#292E33" width="49.542" height="4"/>
          </g>
          <g>
            <polygon fill="#FFFFFF" points="273.593,362.964 267.405,362.33 261.71,353.402 264.942,350.17 273.87,355.865 274.505,362.054 
                    "/>
            <path fill="#292E33" d="M274.333,365.051l-8.094-0.83l-7.079-11.097l5.504-5.504l11.097,7.079l0.831,8.098L274.333,365.051z
              M268.571,360.438l3.798,0.39l-0.39-3.797l-6.759-4.312l-0.961,0.961L268.571,360.438z"/>
          </g>
          <g>
            <path fill="#FFFFFF" d="M311.892,406.01l30.053,30.051L311.892,406.01z"/>
            
              <rect x="305.668" y="419.035" transform="matrix(0.7071 0.7071 -0.7071 0.7071 393.4517 -107.8497)" fill="#292E33" width="42.5" height="4"/>
          </g>
          <g>
            <path fill="#FFFFFF" d="M317.55,400.353l30.051,30.052L317.55,400.353z"/>
            
              <rect x="311.326" y="413.379" transform="matrix(0.7071 0.7071 -0.7071 0.7071 391.1345 -113.5037)" fill="#292E33" width="42.499" height="4"/>
          </g>
          <g>
            <path fill="#FFFFFF" d="M303.569,406.704l14.645-14.644L303.569,406.704z"/>
            
              <rect x="308.892" y="389.027" transform="matrix(0.7071 0.7071 -0.7071 0.7071 373.464 -102.8572)" fill="#292E33" width="4" height="20.71"/>
          </g>
        </g>
      </g>
      </svg>
    </Box>
  );
}
