import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { makeStyles } from "@mui/styles";

import { Link as RouterLink } from 'react-router-dom';

import CollapseButton from '../layouts/dashboard/CollapseButton';

// -------------------------------------------------------- SVG Padding Safair Adjustment ------------------------------------------------//
const navBarSVG = makeStyles(theme => ({
  svg: {
    "&::-webkit-transform": {
      translate: "0px, 0px"
    },
  }
}));

// -------------------------------------------------------- LogoDashboard Object Export ------------------------------------------------//
LogoDashboard.propTypes = {
  sx: PropTypes.object
};

// -------------------------------------------------------- Logo Side Nav Component ------------------------------------------------//
export default function LogoDashboard({ isMobile=true, onToggleCollapse, collapseClick, isCollapse }) {
  const svgClasses = navBarSVG();
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',  width: '100%' }}>

      { !isCollapse &&
        (<Box component={RouterLink} to="/">
          <Box component="img" src="/static/brand/logo_full.svg" className={svgClasses.svg} sx={{ width: 175, marginTop: 1 }} />
        </Box>)
      }
      {
        collapseClick && isCollapse &&
        (<Box sx={{ height: 61.69, marginTop: 1 }} >
          <Box component="img" src="/static/brand/logo_single.svg" className={svgClasses.svg} sx={{ height: 62, marginTop: 1 }} />
        </Box>)
      }

      { !isMobile && !isCollapse &&
        (
          <Box sx={{ pt: 3.5 }}>
            <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
          </Box>
        )
      }
    </Box>
  )
}
