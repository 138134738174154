import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, Container, Dialog, DialogTitle, Divider, IconButton, Stack, Typography } from '@mui/material';
import i18next from 'i18next';
import { useNavigate } from 'react-router';
import {
  ENTERPRISE,
  ESSENTIALS,
  FREE,
  MODAL_PAGE_ACCESS_TEXT_CONFIG,
  MODAL_TEXT_CONFIG,
  PREMIUM,
} from 'src/constants/subscription';
import useSubscription from 'src/hooks/useSubscription';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { getIcon, getSubscriptionLimit } from 'src/utils/common';

const ProModal = () => {
  const { modalConfig, closeModal, subscription } = useSubscription();
  const navigate = useNavigate();
  const subscriptionPlan = subscription?.subscriptionPlan;
  const subscriptionLimit = getSubscriptionLimit(subscriptionPlan)?.[modalConfig.selectedPermission];
  const getUpgradePlan = () => {
    const planCombination = {
      [FREE]: PREMIUM,
      [ESSENTIALS]: PREMIUM,
      [PREMIUM]: ENTERPRISE,
    };
    return planCombination[subscriptionPlan] || ESSENTIALS;
  };

  const upgradePlan = getUpgradePlan();
  const handleUpgrade = () => navigate(PATH_DASHBOARD.subscription.paymentPlan(upgradePlan));

  const getTitle = () => {
    if (modalConfig.type === 'pageLimit') return i18next.t(`proModal.title.essentials`);
    if (subscriptionPlan === 'free') return i18next.t(`proModal.title.essentials`);
    return i18next.t(`proModal.title.${subscriptionPlan}`);
  };

  const title = getTitle();

  const texts = {
    pageLimit: MODAL_PAGE_ACCESS_TEXT_CONFIG,
    numberLimit: MODAL_TEXT_CONFIG,
  }[modalConfig.type][upgradePlan];

  const topText = {
    numberLimit: (
      <div>
        {i18next.t('proModal.description.text1')} <HighlightedText>{subscriptionLimit}</HighlightedText>
        {i18next.t('proModal.description.text2')} <HighlightedText>{modalConfig.usedAmount}</HighlightedText>
        {i18next.t('proModal.description.text3')}
      </div>
    ),
    pageLimit: (
      <div>
        {i18next.t('proModal.description.text4')}{' '}
        <HighlightedText>{i18next.t('subscription.subscriptionPlans.premium.name')}</HighlightedText>
        {i18next.t('proModal.description.text5')}
      </div>
    ),
  }[modalConfig.type];

  return (
    <Dialog fullWidth maxWidth="xs" onClose={closeModal} open={modalConfig.isOpen} hideBackdrop={false}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: '1rem', px: '2rem' }}>
        <DialogTitle sx={{ p: 0, m: 0 }}>{title}</DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton title="Clear" aria-label="Clear" size="medium" onClick={closeModal}>
            <ClearIcon fontSize="medium" />
          </IconButton>
        </Box>
      </Box>
      <Divider />
      <Container
        maxWidth={false}
        disableGutters
        sx={{ width: '100%', px: 4, p: 4, gap: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        {topText}
      </Container>
      <Divider />

      <Container
        maxWidth={false}
        disableGutters
        sx={{ width: '100%', px: 4, p: 4, gap: 2, display: 'flex', flexDirection: 'column' }}
      >
        <Stack gap={2} pb={2}>
          {texts?.map((text) => (
            <Card key={text} text={text.text} highlight={text.type === modalConfig.selectedPermission} />
          ))}
        </Stack>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button variant="outlined" color="error" size="large" fullWidth onClick={closeModal}>
            {i18next.t('proModal.button2')}
          </Button>
          <Button variant="contained" color="primary" size="large" fullWidth onClick={handleUpgrade}>
            {i18next.t('proModal.button')}
          </Button>
        </Box>
      </Container>
    </Dialog>
  );
};

export default ProModal;

const Card = ({ text, highlight }) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#eaf8f5',
            borderRadius: '4px',
            p: 0.3,
          }}
        >
          {getIcon('Done', { width: '1.2rem', height: '1.2rem' })}
        </Box>
        <Typography
          variant="body2"
          color={highlight ? 'text.main' : 'text.secondary'}
          sx={{ fontWeight: highlight ? 'bold' : 'normal' }}
        >
          {i18next.t(text)}
        </Typography>
      </Box>
    </Box>
  );
};

const HighlightedText = ({ children }) => {
  return (
    <Typography component={'span'} variant="body1" color="text.main" sx={{ fontWeight: 'bold', width: 'fit-content' }}>
      {children}
    </Typography>
  );
};
