// ----------------------------------------------------------------------
function path(root, sublink) {
  return `${root}${sublink}`;
}

// Main paths
const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------
export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: {
    register: path(ROOTS_AUTH, '/register'),
    verify: (id) => path(ROOTS_AUTH, `/register/${id}/verify`),
    setup: path(ROOTS_AUTH, '/register/setup'),
  },
  auth: {
    signin: path(ROOTS_AUTH, '/signin'),
  },
  reset: path(ROOTS_AUTH, '/reset'),
  newPw: path(ROOTS_AUTH, '/setNewPassword'),
};

// General / Terminal features on main dashboard with no further layers
export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    offlineOrders: path(ROOTS_DASHBOARD, '/offlineorders'),
    newOfflineDynamic: path(ROOTS_DASHBOARD, '/offlineorders/new'),
    editOfflineDynamic: path(ROOTS_DASHBOARD, '/offlineorders/edit'),
    onlineOrders: path(ROOTS_DASHBOARD, '/onlineorders'),
    orderStatus: path(ROOTS_DASHBOARD, '/orderstatus'),
    orderArchive: path(ROOTS_DASHBOARD, '/orderarchive'),
    orderArchiveEdit: (id) => path(ROOTS_DASHBOARD, `/orderarchive/${id}/edit`),
    orderArchivePrint: (id) => path(ROOTS_DASHBOARD, `/orderarchive/${id}/print`),
    inbox: path(ROOTS_DASHBOARD, '/inbox'),
    userGuide: path(ROOTS_DASHBOARD, '/userguide'),
    settings: path(ROOTS_DASHBOARD, '/settings'),
  },

  // Tools related
  tools: {
    events: path(ROOTS_DASHBOARD, '/tools/events'),
    buzzwords: path(ROOTS_DASHBOARD, '/tools/buzzwords'),
    hotProducts: path(ROOTS_DASHBOARD, '/tools/hotproducts'),
    priceMatch: path(ROOTS_DASHBOARD, '/tools/pricematch'),
    sellgpt: path(ROOTS_DASHBOARD, '/tools/sellgpt'),
  },

  // Financials Related
  financials: {
    cashflow: path(ROOTS_DASHBOARD, '/financials/cashflow'),
  },

  // Invoicing Related
  invoice: {
    invoice: path(ROOTS_DASHBOARD, '/invoice'),
    createNewInvoice: path(ROOTS_DASHBOARD, '/invoice/new'),
    invoiceView: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/invoiceView`),
    onlineView: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/onlineView`),
    offlineView: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/offlineView`),
    offlineBatchView: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/offlineBatchView`),
    procurementView: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/procurementView`),
    invoiceEdit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/invoiceEdit`),
    onlineEdit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/onlineEdit`),
    offlineEdit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/offlineEdit`),
    offlineBatchEdit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/offlineBatchEdit`),
    procurementEdit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/procurementEdit`),
  },

  // Payment Related
  payment: {
    payment: path(ROOTS_DASHBOARD, '/payment'),
    paymentView: (id) => path(ROOTS_DASHBOARD, `/payment/${id}/paymentView`),
  },

  taxArchive: {
    taxArchive: path(ROOTS_DASHBOARD, '/taxes'),
    taxArchiveDetail: path(ROOTS_DASHBOARD, '/taxes/:id'),
    taxArchiveQuarter: path(ROOTS_DASHBOARD, '/taxes/:id/detail'),
    taxArchiveWithdraw: path(ROOTS_DASHBOARD, '/taxes/:id/detail/withdraw'),
    taxArchiveDeposit: path(ROOTS_DASHBOARD, '/taxes/:id/detail/deposit'),
    taxInvoice: path(ROOTS_DASHBOARD, '/taxes/invoice/auth'),
  },

  // Payment Related
  centralCommand: {
    centralCommand: path(ROOTS_DASHBOARD, '/commandcentral'),
  },

  // Commerce Related
  operations: {
    performance: path(ROOTS_DASHBOARD, '/operations/performance'),
    store: path(ROOTS_DASHBOARD, '/operations/store'),
    catalogue: path(ROOTS_DASHBOARD, '/operations/catalogue'),
    editCatalogue: path(ROOTS_DASHBOARD, '/operations/catalogue/edit'),
    createNewProduct: path(ROOTS_DASHBOARD, '/operations/catalogue/new'),
    duplicateNewProduct: (id) => path(ROOTS_DASHBOARD, `/operations/catalogue/new/${id}`),
    procurement: path(ROOTS_DASHBOARD, '/operations/procurement'),
    createProcurement: path(ROOTS_DASHBOARD, '/operations/procurement/new'),
    editProcurement: (id) => path(ROOTS_DASHBOARD, `/operations/procurement/edit/${id}`),
    inventory: path(ROOTS_DASHBOARD, '/operations/inventory'),
    inventoryDetail: (id) => path(ROOTS_DASHBOARD, `/operations/inventory/${id}`),
    createNewWarehouse: path(ROOTS_DASHBOARD, '/operations/inventory/new'),
    editWarehouse: (id) => path(ROOTS_DASHBOARD, `/operations/inventory/edit/${id}`),
    customerDirectory: path(ROOTS_DASHBOARD, '/operations/customerdirectory'),
    createNewCustomer: path(ROOTS_DASHBOARD, '/operations/customerdirectory/new'),
    customerEdit: (id) => path(ROOTS_DASHBOARD, `/operations/${id}/customerEdit`),
    productView: (id) => path(ROOTS_DASHBOARD, `/operations/${id}/productView`),
    productEdit: (id) => path(ROOTS_DASHBOARD, `/operations/${id}/productEdit`),
    cartCheckout: path(ROOTS_DASHBOARD, '/operations/checkout'),
    editCreateRawMaterials: (id) => path(ROOTS_DASHBOARD, `/operations/catalogue/create/raw-material/${id}`),
    duplicateRawMaterials: (id) => path(ROOTS_DASHBOARD, `/operations/catalogue/create/raw-material/${id}/duplicate`),
    createRawMaterials: path(ROOTS_DASHBOARD, '/operations/catalogue/create/raw-material'),
    configRawMaterials: path(ROOTS_DASHBOARD, '/operations/catalogue/config/raw-material'),
    editCreateManufacturedProducts: (id) =>
      path(ROOTS_DASHBOARD, `/operations/catalogue/create/manufactured-products/${id}/productEdit`),
    duplicateManufacturedProducts: (id) =>
      path(ROOTS_DASHBOARD, `/operations/catalogue/create/manufactured-products/${id}/duplicate`),
    createManufacturedProducts: path(ROOTS_DASHBOARD, '/operations/catalogue/create/manufactured-products'),
    configManufacturedProducts: path(ROOTS_DASHBOARD, '/operations/catalogue/config/manufactured-products'),
  },
  admin: {
    userManagement: path(ROOTS_DASHBOARD, '/admin/usermanagement'),
    userInformationManagement: (id) => path(ROOTS_DASHBOARD, `/admin/usermanagement/${id}/information`),
    roleManagement: path(ROOTS_DASHBOARD, '/admin/rolemanagement'),
    roleCreationManagement: path(ROOTS_DASHBOARD, '/admin/rolemanagement/new'),
    roleEditManagement: (id) => path(ROOTS_DASHBOARD, `/admin/rolemanagement/${id}/edit`),
  },
  subscription: {
    payment: path(ROOTS_DASHBOARD, '/subscription/payment'),
    paymentPlan: (plan) => path(ROOTS_DASHBOARD, `/subscription/payment?plan=${plan}`),
    paymentMethod: ({ billingCycle, plan }) =>
    path(ROOTS_DASHBOARD, `/subscription/payment/method?billingCycle=${billingCycle}&plan=${plan}`),
    cancel: path(ROOTS_DASHBOARD, '/subscription/payment/cancel'),
    cancelConfirmation: path(ROOTS_DASHBOARD, '/subscription/payment/cancel/confirmation'),
  },
};
