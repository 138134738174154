import { Icon } from '@iconify/react';
import { useRef, useState, useEffect } from 'react';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import person from '@iconify/icons-eva/person-fill';
import roundPerson from '@iconify/icons-ic/round-person';
import sharpPerson from '@iconify/icons-ic/sharp-person';

import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { getStoreId,  getEmail, getPermissionsInfo } from 'src/components/authentication/TempAuthService.js';
import { getStoreDetails, getStoreImage } from 'src/server/awsClient.js';

import { alpha } from '@mui/material/styles';
import { Avatar, Button, Box, Divider, MenuItem, Typography } from '@mui/material';
import { MIconButton } from '../../components/@material-extend';
import MenuPopover from '../../components/MenuPopover';
import i18next from 'i18next';
import { Auth } from 'aws-amplify';
import { useQuery } from 'react-query';
import useReactQueryManager from 'src/hooks/useReactQueryManager';

const signOut = async () => {
  try {
    await Auth.signOut();
  } catch (error) {
    console.error('Error signing out: ', error);
  }
}

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: i18next.t('dashboard.accountPopover.text2'),
    icon: sharpPerson,
    linkTo: '/dashboard/admin/usermanagement',
    isAuth: true,
  },
  { label: i18next.t('dashboard.accountPopover.text1'), icon: settings2Fill, linkTo: '/dashboard/settings' },
];

// ----------------------------------------------------------------------
export default function AccountPopover() {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [accName, setAccName] = useState('Account Name');
  // const [isAdminUser, setIsAdminUser] = useState(true);
  const [accEmail, setAccEmail] = useState('Account Email');
  const [src, setSrc] = useState('/static/mock-images/avatars/avatar_default.jpg');
  const storeId = getStoreId();
  const emailId = getEmail();
  const { getIsLoading } = useReactQueryManager()

  const { data: storeDetailsResponse, refetch, remove } = useQuery(["storeDetails"], ()=> getStoreDetails(getStoreId(),getEmail()),
  { refetchOnWindowFocus: false, enabled:false })

  const isLoading = getIsLoading(["storeDetails"]);

  useEffect(()=>{
    if(storeDetailsResponse)return console.log("STORE DETAILS",storeDetailsResponse)
    if(!storeId || !emailId) return;
    console.log("REFETCHING STORE DETAILS")
    refetch();
  },[storeId, emailId])

  useEffect(()=>{
    if(!storeDetailsResponse)return;
    setSrc(storeDetailsResponse.data?.photoURL ?? '/static/mock-images/avatars/avatar_default.jpg');
  },[storeDetailsResponse, isLoading])


  const isAdminUser = JSON.parse(getPermissionsInfo())?.isAdmin ?? false;

  const logout = async () => {
    remove();
    await signOut();
    navigate('/auth/login/', { replace: false });
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // getStoreImage(getStoreId())
    //   .then((response) => {
    //     setSrc(response.config.url);
    //   })
    //   .catch((err) => {
    //     // console.log(err);
    //   });
    try {
      getStoreDetails(getStoreId(), getEmail()).then((response) => {
        if (response.status === 200) {
          setAccName(response.data.storeName);
          setAccEmail(response.data.storeEmail);
          // setSrc(response.data?.photoURL ?? '/static/mock-images/avatars/avatar_default.jpg');
        } else {
          alert('There was an error in fetching your data.');
        }
      });
    } catch (error) {
      alert('An error occured while fetching your data.');
    }
  }, []);

  const filterByAdmin = (item) => {
    if (!item?.isAuth) return true;
    if (item?.isAuth && isAdminUser) return true;
    return false;
  };
  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 1,
          marginTop: 2,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        <Avatar alt="My Company Profile Image" src={src} />
      </MIconButton>
      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 220 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {accName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {emailId}
          </Typography>
        </Box>
        <Divider sx={{ my: 1 }} />
        {MENU_OPTIONS.filter(filterByAdmin).map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />
            {option.label}
          </MenuItem>
        ))}
        <Box sx={{ p: 2, pt: 1.5 }}>
          {/* <GoogleLogout
            clientId={authConfiguration.api.clientId}
            render={renderProps => (
              <Button fullWidth size="large" color="inherit" variant="outlined" onClick={(event) => {renderProps.onClick();}} disabled={renderProps.disabled}>
                {i18next.t('dashboard.accountPopover.button1')}
              </Button>
            )}
            onLogoutSuccess={logout}
            onFailure={onFailure}
            >
          </GoogleLogout> */}
          <Button fullWidth size="large" color="inherit" variant="outlined" onClick={logout}>
            {i18next.t('dashboard.accountPopover.button1')}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
