import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import MenuItem from '@mui/material/MenuItem';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Stack, Link, Typography, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { createStoreInAWS } from '../../../server/awsClient';
import i18next from 'i18next';
import { PATH_AUTH } from '../../../routes/paths';
import { v4 as uuidv4 } from 'uuid';
import {
  selectUser,
  getUserFromStore
} from 'src/components/redux/slices/admin-panel';
import { useCreateCustomerMutation } from 'src/components/redux/rtkq/apiSlice';

import { Auth } from 'aws-amplify';

// ----------------------------------------------------------------------
//  This is the example given within the documentation for using a multi select field drop for this library
//  Apparently you can't just embed and have to create a const, I'm sure you there is a way, but will investigate later
const accounts = [
  // {
  //   value: 'Seller',
  //   label: i18next.t('registerForm.account.seller')
  // },
  {
    value: 'Distributor',
    label: i18next.t('registerForm.account.distributor')
  }
  // {
  //   value: 'Manufacture',
  //   label: i18next.t('registerForm.account.manufacture')
  // }
];


function createAttributes(formData) {
  return {
    'email': formData.email.toLowerCase(),
    'given_name': formData.firstName,
    'family_name': formData.lastName,
    'custom:store_name': formData.storeName,
    'custom:store_id': formData.storeId,
    'custom:account_type': formData.accountType
  };
}

async function createCognitoUser(usernameUuid, password, attributes) {
  try {
    const { user } = await Auth.signUp({
      username: usernameUuid,
      password,
      attributes
    });
    return user;
  } catch (error) {
    throw error;
  }
}

async function registerUser(formData) {
  var usernameUuid = uuidv4();
  
  const attributes = createAttributes(formData);

  // First two arguments are username & password
  // Retry in case uuid is not unique, since we want this operation to be
  // "transactional" since the dynamoDb tables have already been created
  var retriesLeft = 3;
  var signupPending = true;
  while (signupPending && retriesLeft > 0) {
    try {
      return createCognitoUser(usernameUuid, formData.password, attributes)
    } catch (error) {
      console.error(error.message);
      usernameUuid = uuidv4();
      retriesLeft -= 1;
    }
  }
}

// Registration form set up with formik validation
export default function RegisterForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const inviteId = queryParams.get('inviteId');
  const [showPassword, setShowPassword] = useState(false);
  //  React Hooks first param is the variable that houses the state and the second is the method that makes the state change
  const strongPasswordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

  // if theres an inviteId, get the associated user
  useEffect(() => {
    if (inviteId == null) return;

    dispatch(getUserFromStore(inviteId));
  }, [inviteId]);

  const user = useSelector(selectUser);


  // This is your yum validator to check if all the inputs have been filed in prior to allowing submission.
  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().min(1, i18next.t('registerForm.message.verification1')).max(50, i18next.t('registerForm.message.verification2')).required(i18next.t('registerForm.message.verification3')),
    lastName: Yup.string().min(1, i18next.t('registerForm.message.verification1')).max(50, i18next.t('registerForm.message.verification2')).required(i18next.t('registerForm.message.verification4')),
    storeName: Yup.string()
      // .matches(/^[a-zA-Z0-9]+$/, i18next.t('registerForm.message.verification14'))
      .required(i18next.t('registerForm.message.verification5')),
    email: Yup.string().email(i18next.t('registerForm.message.verification6')).required(i18next.t('registerForm.message.verification7')),
    password: Yup.string().matches(strongPasswordRegex, i18next.t('registerForm.message.verification8')).required(i18next.t('registerForm.message.verification9')),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], i18next.t('registerForm.message.verification10')).required(i18next.t('registerForm.message.verification11')),
    accountType: Yup.string().min(1, i18next.t('registerForm.message.verification12')).required(i18next.t('registerForm.message.verification13'))
  });
  //  This formik object contains the fields and their values that you can reference in the json payload to the lambda
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      storeName: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      accountType: ''
    },
    validationSchema: RegisterSchema,

    // Following which data is sent through an axios call to be authenticated via google or whichever. 
    onSubmit: async (values, { setSubmitting }) => {
      try {
        values.storeId = uuidv4();
        if (Object.keys(user).length != 0) {
          values.storeId = user.storeId
          values.inviteId = user.inviteId
          values.roleId = user.roleId
          values.userId = user.userId
        }
        await registerUser(values)
          .then((response) => {
            console.log(response);
             if (response && response.username) {
                var username = response.username;
                values.userId = username
                if (Object.keys(user).length != 0) {
                  values.storeId = user.storeId
                  values.userId = user.userId
                }
                createStoreInAWS(values)
                  .then((response) => {
                    if (response.data.statusCode === 200) {
                      navigate(PATH_AUTH.register.verify(username));
                    } else if (response.data.statusCode === 400) {
                      alert(response.data.body);
                      setSubmitting(false);
                    } else {
                      alert('Server is unresponsive, please try again in a few minutes' +
                        'or contact a Sellpoint employee!');
                      setSubmitting(false);
                    }
                  })
                  .catch((e) => {
                    console.log(e);
                    alert('Something went wrong. Please contact a Sellpoint employee.');
                    setSubmitting(false);
                  });
             } else {
                alert('Something went wrong. Please contact a Sellpoint employee.');
                setSubmitting(false);
             }
          })
      } catch (error) {
        console.log(error)
        alert(error);
        setSubmitting(false);
      }
    }
  });
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  useEffect(() => {
    if (user == null) return;

    setFieldValue('email', user.email)
    setFieldValue('storeName', user.storeName)
    setFieldValue('accountType', user.accountType)
  }, [user]);


  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              // The label is the name of the field
              label={i18next.t('registerForm.label.firstName')}
              //  FieldProps is the setter method that updates the firstname property upon value change in the field
              {...getFieldProps('firstName')}
              //  This is where the error validation messages come into play based on the scheme you defined earlier
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />
            <TextField
              fullWidth
              label={i18next.t('registerForm.label.lastName')}
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label={i18next.t('registerForm.label.storeName')}
              {...getFieldProps('storeName')}
              error={Boolean(touched.storeName && errors.storeName)}
              helperText={touched.storeName && errors.storeName}
              disabled={inviteId != null}
            />
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label={i18next.t('registerForm.label.emailAddress')}
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              disabled={inviteId != null}
            />
          </Stack>
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label={i18next.t('registerForm.label.password')}
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          <TextField
            fullWidth
            autoComplete="password-confirmation"
            type={showPassword ? 'text' : 'password'}
            label={i18next.t('registerForm.label.passwordConfirmation')}
            {...getFieldProps('passwordConfirmation')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.passwordConfirmation && errors.passwordConfirmation)}
            helperText={touched.passwordConfirmation && errors.passwordConfirmation}
          />
          <TextField
            fullWidth
            select
            label={i18next.t('registerForm.label.accountType')}
            {...getFieldProps('accountType')}
            value={formik.values['accountType']}
            error={Boolean(touched.accountType && errors.accountType)}
            helperText={touched.accountType && errors.accountType}
            disabled={inviteId != null}
          >
            {accounts.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            {i18next.t('registerForm.button')}
          </LoadingButton>
        </Stack>
      </Form>
      <Typography variant="subtitle2" sx={{ mt: 3, textAlign: 'center' }}>
        {i18next.t('registerForm.text1')}&nbsp;
        <Link to="/auth/login" component={RouterLink}>
          {i18next.t('registerForm.text2')}
        </Link>
      </Typography>
    </FormikProvider>
  );
}