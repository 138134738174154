import { GridActionsCellItem } from '@mui/x-data-grid';
import i18next from 'i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

const options = {
  deliveryOption: [
    { value: 'shipping', label: 'offlineOrderStatusDate.label.shipping' },
    { value: 'inStorePickUp', label: 'offlineOrderStatusDate.label.pickup' },
    { value: 'preOrder', label: 'offlineOrderStatusDate.label.delivery' },
  ],
  paymentStatusOption: [
    { value: 'unpaid', label: 'offlineOrderStatusDate.label.unpaid' },
    { value: 'paid', label: 'offlineOrderStatusDate.label.paid' },
    { value: 'returned', label: 'offlineOrderStatusDate.label.returned' },
    { value: 'cancelled', label: 'offlineOrderStatusDate.label.cancelled' },
  ],
};

const mapLocalizeOption = (option) => ({ ...option, label: i18next.t(option.label) });

const optionValueFormatter = ({ value, options }) => {
  const option = options.find((option) => option.value === value);
  return option ? i18next.t(option.label) : '';
};

export const columns = ({ handleSave, handleCancel, handleEdit, handleDelete, getRowMode }) => [
  {
    field: 'productId',
    editable: true,
    hide: true,
    headerName: 'Product Id',
  },
  {
    field: 'customerId',
    editable: true,
    hide: true,
    headerName: 'Customer Id',
  },
  {
    field: 'complimentaryProductId',
    editable: true,
    hide: true,
  },
  {
    field: 'productCode',
    headerName: i18next.t('offlineOrderGrid.headerName.productCode'),
    editable: true,
    hide: true,
  },
  {
    field: 'productName',
    headerName: i18next.t('offlineOrderGrid.headerName.productName'),
    editable: true,
    width: 200,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
  },

  {
    field: 'option',
    headerName: i18next.t('offlineOrderGrid.headerName.option'),
    editable: true,
    width: 150,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
  },
  {
    field: 'quantity',
    headerName: i18next.t('offlineOrderGrid.headerName.quantity'),
    editable: true,
    width: 150,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    type: 'number',
  },
  {
    field: 'price',
    headerName: i18next.t('offlineOrderGrid.headerName.price'),
    editable: true,
    width: 150,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    type: 'number',
  },
  {
    field: 'vat',
    headerName: i18next.t('offlineOrderGrid.headerName.vat'),
    editable: true,
    width: 150,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    type: 'number',
  },
  {
    field: 'deliveryCost',
    headerName: i18next.t('offlineOrderGrid.headerName.deliveryCost'),
    editable: true,
    width: 150,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    type: 'number',
  },
  {
    field: 'customsNumber',
    headerName: i18next.t('offlineOrderGrid.headerName.customsNumber'),
    editable: true,
    width: 150,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
  },
  // {
  //   field: 'includeComplimentaryItem',
  //   headerName: i18next.t('offlineOrderGrid.headerName.includeComplimentaryItem'),
  //   editable: true,
  //   width: 150,
  //   sortable: false,
  //   filterable: false,
  //   disableColumnMenu: true,
  // },
  {
    field: 'company',
    headerName: i18next.t('offlineOrderGrid.headerName.company'),
    editable: true,
    width: 150,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
  },
  {
    field: 'shippingAddress',
    headerName: i18next.t('offlineOrderGrid.headerName.shippingAddress'),
    editable: true,
    width: 300,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
  },
  {
    field: 'zipcode',
    headerName: i18next.t('offlineOrderGrid.headerName.zipcode'),
    editable: true,
    width: 150,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
  },
  {
    field: 'phoneNumber',
    headerName: i18next.t('offlineOrderGrid.headerName.phoneNumber'),
    editable: true,
    width: 150,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
  },

  {
    field: 'datePlaced',
    headerName: i18next.t('offlineOrderGrid.headerName.datePlaced'),
    editable: true,
    width: 150,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    type: 'date',
    valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return new Date(params.value).toLocaleDateString('en-US');
    },
  },

  {
    field: 'memo',
    headerName: i18next.t('offlineOrderGrid.headerName.memo'),
    editable: true,
    width: 150,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
  },

  {
    field: 'deliveryOption',
    headerName: i18next.t('offlineOrderGrid.headerName.deliveryOption'),
    editable: true,
    type: 'singleSelect',
    width: 150,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    valueOptions: options.deliveryOption.map(mapLocalizeOption),
    valueFormatter: ({ value }) => optionValueFormatter({ value, options: options.deliveryOption }),
  },

  {
    field: 'paymentStatus',
    headerName: i18next.t('offlineOrderStatusDate.label.status'),
    type: 'singleSelect',
    editable: true,
    width: 150,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    valueOptions: options.paymentStatusOption.map(mapLocalizeOption),
    valueFormatter: ({ value }) => optionValueFormatter({ value, options: options.paymentStatusOption }),
  },
  {
    field: 'actions',
    type: 'actions',
    editable: false,
    headerName: i18next.t('offlineOrderGrid.headerName.actions'),
    cellClassName: 'actions',
    getActions: ({ id }) => {
      const component = {
        edit: [
          <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={() => handleSave(id)} color="primary" />,
          <GridActionsCellItem
            icon={<CancelIcon />}
            label="Cancel"
            className="textPrimary"
            onClick={() => handleCancel(id)}
            color="inherit"
          />,
        ],
        view: [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => handleEdit(id)}
            color="inherit"
          />,
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={() => handleDelete(id)} color="inherit" />,
        ],
      };
      return component[getRowMode(id)];
    },
  },
];

export const getColumnsRef = () => {
  return columns({
    handleSave: () => {},
    handleCancel: () => {},
    handleEdit: () => {},
    handleDelete: () => {},
    getRowMode: () => {},
  });
};

export const initOfflineOrderMultiOrderRow = getColumnsRef()
  .filter((col) => col !== 'actions')
  .reduce((acc, col) => {
    acc[col.field] = '';
    return acc;
  }, {});

export const getColumn = ({ field, headerName }) => ({
  field,
  headerName,
  editable: true,
  width: 150,
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
});
