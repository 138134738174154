import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';

import { getOnlineOrdersFromAWS } from '../../../server/awsClient';

// A new slice to keep track of an array of online orders, passing it in between
// the uploadMultigeneric component and the OnlineOrderGrid Component.
const initialState = {
    orderStatuses: [],
    isLoading: true
};

const slice = createSlice({
    name: 'orderStatus',
    initialState,
    reducers: {
    //Only one reducer: to update the array of online orders.
    updateOrderStatus: (state, action) => {
        state.orderStatuses = action.payload
    },
     // START LOADING
     startLoading(state) {
       state.isLoading = true;
       state.orderStatuses = [];
      },
  
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },
  
      // GET ORDERS
      getOrderStatusSuccess(state, action) {
        state.isLoading = false;
        state.orderStatuses = action.payload;
      },
    },
});

// Reducer
export default slice.reducer;
export const { updateOrderStatus } = slice.actions;

// ----------------------------------------------------------------------
export function getOrderStatusFromStore(abortController) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await getOnlineOrdersFromAWS(abortController);
        dispatch(slice.actions.getOrderStatusSuccess(response));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }
