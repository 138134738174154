import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';

import { getOnlineOrdersFromAWS, getOnlineOrderFromAWS } from '../../../server/awsClient';

// A new slice to keep track of an array of online orders, passing it in between
// the uploadMultigeneric component and the OnlineOrderGrid Component.
const initialState = {
    onlineOrders: [],
    isLoading: false,
};

const slice = createSlice({
    name: 'onlineOrder',
    initialState,
    reducers: {
    //Only one reducer: to update the array of online orders.
    updateOnlineOrders: (state, action) => {
        state.onlineOrders = action.payload
    },
    setOnlineOrders: (state, action) => {
      state.onlineOrders = action.payload;
    },
     // START LOADING
     startLoading(state) {
        state.isLoading = true;
      },
  
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },
  
      // GET ONLINE ORDERS
      getOnlineOrdersSuccess(state, action) {
        state.isLoading = false;
        state.onlineOrders = action.payload;
      },
  
      // GET ONLINE ORDER
      getOnlineOrderSuccess(state, action) {
        state.isLoading = false;
        state.onlineOrder = action.payload;
      },
    },
});

// Reducer
export default slice.reducer;
export const { updateOnlineOrders, setOnlineOrders } = slice.actions;

// ----------------------------------------------------------------------
export function getOnlineOrdersFromStore(abortController) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await getOnlineOrdersFromAWS(abortController);
        dispatch(slice.actions.getOnlineOrdersSuccess(response));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }
  
  export function getOnlineOrderFromStore(abortController, id) {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await getOnlineOrderFromAWS(abortController, id);
        dispatch(slice.actions.getOnlineOrderSuccess(response));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }