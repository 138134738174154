import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import {
  Box,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  IconButton,
  TableContainer,
} from '@mui/material';
// utils
import { fCurrency } from '../../../utils/formatNumber';
// components
import Image from '../../Image';
import Iconify from '../../Iconify';

// ----------------------------------------------------------------------
const IncrementerStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(0.5),
  padding: theme.spacing(0.5, 0.75),
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${theme.palette.grey[500_32]}`,
}));

// ----------------------------------------------------------------------
CheckoutProductList.propTypes = {
  cart: PropTypes.array.isRequired,
  onDelete: PropTypes.func,
  onDecreaseQuantity: PropTypes.func,
  onIncreaseQuantity: PropTypes.func,
};

export default function CheckoutProductList({ cart, onDelete, onIncreaseQuantity, onDecreaseQuantity }) {
  
  // Storing for historical purposes, in case we reuse this logic
  // Add after productName Box
  // Remove double quotations and + when reusing
  // const sizeAndColorName = "<Box" +
  //                       "sx={{" +
  //                         "display: 'flex'," +
  //                         "alignItems: 'center'," +
  //                       "}}" +
  //                     ">" +
  //                       "<Typography variant='body2'>" +
  //                         "<Typography component='span' variant='body2' sx={{ color: 'text.secondary' }}>" +
  //                           "size:&nbsp;" +
  //                         "</Typography>" +
  //                         "{size}" +
  //                       "</Typography>" +
  //                       "<Divider orientation='vertical' sx={{ mx: 1, height: 16 }} />" +
  //                       "<Typography variant='body2'>" +
  //                         "<Typography component='span' variant='body2' sx={{ color: 'text.secondary' }}>" +
  //                           "color:&nbsp;" +
  //                         "</Typography>" +
  //                         "{getColorName(color)}" +
  //                       "</Typography>" +
  //                     "</Box>";
  
  return (
    <TableContainer sx={{ minWidth: 720 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Product</TableCell>
            <TableCell align="left">Price</TableCell>
            <TableCell align="left">Quantity</TableCell>
            <TableCell align="right">Total Price</TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {cart.map((product) => {
            const { key, productId, productName, price, coverImage, quantity, available } = product;
            return (
              <TableRow key={key}>
                <TableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Image alt="product image" src={coverImage} sx={{ width: 64, height: 64, borderRadius: 1.5, mr: 2 }} />
                    <Box>
                      <Typography noWrap variant="subtitle2" sx={{ maxWidth: 240 }}>
                        {productName}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell align="left">{fCurrency(price)}</TableCell>
                <TableCell align="left">
                  <Incrementer
                    quantity={quantity}
                    available={available}
                    onDecrease={() => onDecreaseQuantity(productId)}
                    onIncrease={() => onIncreaseQuantity(productId)}
                  />
                </TableCell>
                <TableCell align="right">{fCurrency(price * quantity)}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => onDelete(productId)}>
                    <Iconify icon={'eva:trash-2-outline'} width={20} height={20} />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

// ----------------------------------------------------------------------
Incrementer.propTypes = {
  // available: PropTypes.number,
  available: PropTypes.bool,
  quantity: PropTypes.number,
  onIncrease: PropTypes.func,
  onDecrease: PropTypes.func,
};

function Incrementer({ available, quantity, onIncrease, onDecrease }) {
  // const disableIncrement = quantity >= available;
  const disableIncrement = !available;
  
  // Storing for historical purposes, in case we reuse this logic
  // Add after IncrementerStyle section
  // Remove double quotations and + when reusing
  // const availableStatus = "<Typography variant='caption' sx={{ color: 'text.secondary' }}>" +
  //       "available: {available}" +
  //     "</Typography>";
  
  return (
    <Box sx={{ width: 96, textAlign: 'right' }}>
      <IncrementerStyle>
        <IconButton size="small" color="inherit" onClick={onDecrease} disabled={quantity <= 1}>
          <Iconify icon={'eva:minus-fill'} width={16} height={16} />
        </IconButton>
        {quantity}
        <IconButton size="small" color="inherit" onClick={onIncrease} disabled={disableIncrement}>
          <Iconify icon={'eva:plus-fill'} width={16} height={16} />
        </IconButton>
      </IncrementerStyle>
    </Box>
  );
}
