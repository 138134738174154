import { DEFAULT_HEADERS } from '../../constants';

/**
 * Deletes customers, given as a list, in the customer directory.
 *
 * @param {List<Object> customers} list of customers to delete
 */
export const deleteCustomersMutation = (body) => ({
  url: ('/' + body.storeId + '/customers'),
  headers: DEFAULT_HEADERS,
  method: 'DELETE',
  body: JSON.stringify({
    'storeID': body.storeId,
    'customers': body.customers,
  })
});