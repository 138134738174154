import { useEffect, useState } from 'react';
import { ENTERPRISE, PAGE_ACCESS_CONFIG, PREMIUM, TRIAL } from 'src/constants/subscription';
import useSubscription from 'src/hooks/useSubscription';
import DashboardContainer from './DashboardContainer';
import { Alert, AlertTitle, Box } from '@mui/material';
import i18next from 'i18next';
import ErrorIcon from '@mui/icons-material/Error';
import { ProModal } from './modal';
import LoadingContainer from './LoadingContainer';

const SubscriptionBasedGuard = ({ children }) => {
  const { subscription, openPageLimitModal, isLoading } = useSubscription();
  const [ isAccessible, setIsAccessible ] = useState(false);
  const pathName = window.location.pathname;

  const currentPage = PAGE_ACCESS_CONFIG.find((page) => pathName.includes(page.path));
  const fullAccessSubscriptions = [TRIAL, PREMIUM, ENTERPRISE];

  useEffect(() => {
    setIsAccessible(fullAccessSubscriptions.includes(subscription?.subscriptionPlan));
    if (isAccessible || !currentPage || isLoading) return;
    openPageLimitModal(currentPage.type);
  }, [isLoading, pathName]);
  if (!currentPage) return <>{children}</>;
  if (isAccessible) return <>{children}</>;

  return (
    <DashboardContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <LoadingContainer isLoading={isLoading} isSellpointLoader>
          <Alert severity="error" iconMapping={{ error: <ErrorIcon fontSize="inherit" /> }}>
            <AlertTitle>{i18next.t('authGuard.alertTitle')}</AlertTitle>
            {i18next.t('authGuard.messageBody')}
          </Alert>
        </LoadingContainer>
      </Box>
      <ProModal />
    </DashboardContainer>
  );
};

export default SubscriptionBasedGuard;
