import { createSlice } from '@reduxjs/toolkit';
import { GPT_MESSAGE_ENTRY, USER_MESSAGE_ENTRY } from 'src/constants';
import { getGptQueryFromAWS, postSellGptQueryToAWS, patchSellGptDatabaseFromAWS } from '../../../server/awsClient';
import { dispatch } from '../store';

//This is the redux shared state that you will be updating. Source of truth via your reducers
const initialState = {
  error: {},
  isLoadingChat: false,
  sendMessageIsLoading: false,
  databaseSelection: {
    primaryDatabase: null,
    secondaryDatabase: null,
  },
  conversation: {
    queriesLeft: null,
    queryHistories: [],
  },
};

//Creating a slice with action reducers that handle each slice of a state
const slice = createSlice({
  name: 'sellGpt',
  initialState,
  reducers: {
    //Since this takes the error objects and unpacks it into our state, it requires both the state and action paramters
    hasError(state, action) {
      state.error = action.payload;
    },

    setIsLoadingChat(state, action) {
      state.isLoadingChat = action.payload;
    },

    setPrimaryDatabase(state, action) {
      state.databaseSelection.primaryDatabase = action.payload;
    },

    setSecondaryDatabase(state, action) {
      state.databaseSelection.secondaryDatabase = action.payload;
    },

    getIsLoadingChat(state, action) {
      state.isLoadingChat = action.payload;
    },

    //Getting sellGPT chat log history.
    getQueriesRemaining(state, action) {
      state.conversation.queriesLeft = action.payload;
    },

    //Getting sellGPT chat log history
    getSellGptConversationQueryHistories(state, action) {
      state.conversation.queryHistories = action.payload;
    },

    //Getting sellGPT chat log history
    getPrimaryDatabase(state, action) {
      state.databaseSelection.primaryDatabase = action.payload;
    },

    //Getting sellGPT chat log history
    getSecondaryDatabase(state, action) {
      state.databaseSelection.secondaryDatabase = action.payload;
    },

    setSendMessageIsLoading(state, action) {
      state.sendMessageIsLoading = action.payload;
    },

    /** @param {{ payload: string }} action */
    addUserMessage(state, action) {
      state.conversation.queryHistories.push(USER_MESSAGE_ENTRY(action.payload));
    },

    addGptMessage(state, action) {
      state.conversation.queryHistories.push(GPT_MESSAGE_ENTRY(action.payload));
    },
  },
});

// Exporting the reducer
export default slice.reducer;

export const { addGptMessage, addUserMessage } = slice.actions;

//Creating an exported constant that can be accesses throughout components
export const queriesConstant = (state) => state.sellGpt.conversation.queriesLeft;
export const isLoadingChatConstant = (state) => state.sellGpt.isLoadingChat;
export const primaryDatabaseConstant = (state) => state.sellGpt.databaseSelection.primaryDatabase;
export const secondaryDatabaseConstant = (state) => state.sellGpt.databaseSelection.secondaryDatabase;
export const conversationConstant = (state) => state.sellGpt.conversation.queryHistories;
export const errorConstant = (state) => state.sellGpt.error;
export const selectSendMessageIsLoading = (state) => state.sellGpt.sendMessageIsLoading;

// So this is an action creator method that is exported out so that other components can call it
// What it does is that it calls in async API calls, and then passes the action as well as the state to trigger the reducers
export async function fetchQueries(controller) {
  // dispatch(slice.actions.setIsLoadingChat(true));
  // return async (dispatch) => {
  await getGptQueryFromAWS(controller)
    .then((response) => {
      dispatch(slice.actions.getIsLoadingChat(false));
      dispatch(slice.actions.getSellGptConversationQueryHistories(response.query_histories));
      dispatch(slice.actions.getPrimaryDatabase(response.primary_database_selection));
      dispatch(slice.actions.getSecondaryDatabase(response.secondary_database_selection));
      dispatch(slice.actions.getQueriesRemaining(response.queries_left));
    })
    .catch((error) => {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.getIsLoadingChat(false));
    });
  // };
}

//On send message to the AWS post request
export async function onSendMessage(value) {
  // return async () => {
  const abortController = new AbortController();
  try {
    await postSellGptQueryToAWS(value);
    fetchQueries(abortController);
  } catch (error) {
    console.error(error?.message);
    dispatch(slice.actions.hasError(JSON.stringify(error)));
  }
  // };
}

// Setter methods for primary and secondary databased because they are set asynchronously
export async function setPrimaryDatabaseSelection(primaryDatabase) {
  dispatch(slice.actions.setPrimaryDatabase(primaryDatabase));
}

export async function setSecondaryDatabaseSelection(secondaryDatabase) {
  dispatch(slice.actions.setSecondaryDatabase(secondaryDatabase));
}

export async function patchDatabaseSelection(primaryDatabase, secondaryDatabase) {
  try {
    await patchSellGptDatabaseFromAWS(primaryDatabase, secondaryDatabase);
  } catch (error) {
    console.error(error);
    dispatch(slice.actions.hasError(error));
  }
}

/** @param {boolean} value */
export const setMessageIsLoading = (value) => {
  dispatch(slice.actions.setSendMessageIsLoading(value));
};

/** @param {boolean} value */
export const setIsLoadingChat = (value) => {
  dispatch(slice.actions.setIsLoadingChat(value));
};
