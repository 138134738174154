import * as Yup from 'yup';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import MenuItem from '@mui/material/MenuItem';
import { Stack, Link, Typography, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { createStoreInAWS } from '../../../server/awsClient';
import i18next from 'i18next';
import { getEmail, setUserSession, setPermissionsInfo } from '../TempAuthService';
import { useCreateCustomerMutation } from 'src/components/redux/rtkq/apiSlice';
import { Auth } from 'aws-amplify';

import { v4 as uuidv4 } from 'uuid';

// ----------------------------------------------------------------------
//  This is the example given within the documentation for using a multi select field drop for this library
//  Apparently you can't just embed and have to create a const, I'm sure you there is a way, but will investigate later
const accounts = [
  // {
  //   value: 'Seller',
  //   label: i18next.t('registerForm.account.seller')
  // },
  {
    value: 'Distributor',
    label: i18next.t('registerForm.account.distributor')
  }
  // {
  //   value: 'Manufacture',
  //   label: i18next.t('registerForm.account.manufacture')
  // }
];

// Registration form set up with formik validation
export default function SetupForm() {
  const navigate = useNavigate();

  // This is your yum validator to check if all the inputs have been filed in prior to allowing submission.
  const SetupSchema = Yup.object().shape({
    storeName: Yup.string().required(i18next.t('registerForm.message.verification5')),
    accountType: Yup.string().min(1, i18next.t('registerForm.message.verification12')).required(i18next.t('registerForm.message.verification13'))
  });


  //  This formik object contains the fields and their values that you can reference in the json payload to the lambda
  const formik = useFormik({
    initialValues: {
      storeName: '',
      accountType: ''
    },
    validationSchema: SetupSchema,

    // Following which data is sent through an axios call to be authenticated via google or whichever. 
    onSubmit: async (values, { setSubmitting }) => {
      if (getEmail() == null) {
        navigate('/auth/login', { replace: true });
      }

      try {
        const user = await Auth.currentAuthenticatedUser();
        const { attributes, username } = user;

        const email = attributes["email"];
        const storeId = uuidv4();
        values.email = email;
        values.storeId = storeId;
        values.userId = username;

        const storeName = values.storeName;
        const accountType = values.accountType;

        // TODO: Read bug fixes needed in https://docs.google.com/document/d/1JzTW-4vwUAUXFzkV6Y0YIiIszVj0yBN-ACUufnj1TfI/edit?usp=sharing
        await createStoreInAWS(values)
          .then(async (response) => {
            if (response.data.statusCode === 200) {
              await Auth.updateUserAttributes(user, {
                "custom:store_id": storeId,
                "custom:store_name": storeName,
                "custom:account_type": accountType
              });

              setUserSession(storeId, accountType, email);
              setPermissionsInfo(JSON.stringify({'isAdmin': true}));

              navigate('/dashboard/commandcentral', { replace: true })
              
            } else if (response.data.statusCode === 400) {
              if (response.data.body.includes("Table already exists")) {
                // TODO: Add a button next to Store Name field to "Check
                // Availability" for store name instead of waiting on submit,
                // and mark the box and text red (like you see in similar forms)
                alert("Store name already exists, please pick a new name.");
              } else {
                alert(response.data.body);
              }
              setSubmitting(false);
            } else {
              alert('Server is unresponsive, please try again in a few minutes' +
                'or contact a Sellpoint employee!');
              setSubmitting(false);
            }
          })
          .catch((e) => {
            setSubmitting(false);
          });
      } catch (error) {
        alert('Unexpected error, please contact a Sellpoint employee!');
        setSubmitting(false);
      }
    }
  });
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            label={i18next.t('registerForm.label.storeName')}
            {...getFieldProps('storeName')}
            error={Boolean(touched.storeName && errors.storeName)}
            helperText={touched.storeName && errors.storeName}
          />
          <TextField
            fullWidth
            select
            label={i18next.t('registerForm.label.accountType')}
            {...getFieldProps('accountType')}
            value={formik.values['accountType']}
            error={Boolean(touched.accountType && errors.accountType)}
            helperText={touched.accountType && errors.accountType}
          >
            {accounts.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            {i18next.t('setupForm.button')}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
