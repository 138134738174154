import { Auth, Hub } from 'aws-amplify';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import i18next from 'i18next'
import { resetUserSession } from 'src/components/authentication/TempAuthService.js';
export const useCognitoContextProvider = () => {
  const navigate = useNavigate();
  const [context, setContext] = useState({});

  useEffect(() => {
    const signOut = async () => {
      try {
        await Auth.signOut();
        resetUserSession();
      } catch (error) {
        console.log('Error signing out: ', error);
      }
    };

    const hubListenerCancelToken = Hub.listen('auth', ({ channel, payload: { event } }) => {
      if (channel === 'auth') {
        switch (event) {
          case 'tokenRefresh_failure':
            alert(i18next.t('cognitoContext.expiredSessionAlert'));
            signOut();
            navigate('/auth/login');
            break;
          case 'oAuthSignOut':  
          case 'signOut':
            resetUserSession();
            break;
        }
      }
    });

    return () => {
      hubListenerCancelToken();
    };
  }, []);

  return context;
};
