import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------
Emblem.propTypes = {
  sx: PropTypes.object
};

export default function Emblem({ sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box sx={{ width: 200, height: 200, marginTop: 9, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 700 600">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
          <path
            fill="url(#BG2)"
            d="M131.094,27.401l18.331,16.726l3.319,2.918
            c3.154-2.39,5.891-4.87,7.879-7.952l-2.9-2.827l-13.277-11.674c9.812-2.698,18.805-1.622,21.996,3.904
            c1.879,3.483,1.203,8.608-1.568,13.277l-0.183,0.84c-3.886,5.745-8.937,10.797-14.975,14.316l-1.532,0.986
            c-6.456,3.428-13.15,5.927-19.479,6.747l-0.092,0.42c-0.365,1.679-1.148,3.265-2.262,4.341l-8.572,9.12l-3.593-11.766
            c-0.565-1.44-0.62-3.209-0.256-4.887c0.912-4.195,4.997-6.822,8.772-6.002c1.679,0.365,3.174,1.569,4.25,2.683
            c3.211-0.621,6.001-1.332,9.303-2.371c1.44-0.565,2.972-1.551,3.993-2.207l2.464-1.223l-2.992-2.407l-13.187-12.093L78.273,54.139
            l-0.547,2.517l-10.67,49.081l-0.273,1.259l65.696,59.532l-2.735,12.585l-73.74-66.992c-1.167-0.692-1.313-2.042-1.04-3.302
            l13.315-61.246c0.365-1.678,0.966-2.426,2.408-2.992l56.632-18.001C128.76,26.014,130.018,26.289,131.094,27.401 M84.438,78.324
            l28.617,25.991l40.729,36.971l4.395,4.03c0.748,0.603,1.312,2.044,1.039,3.302l-14.5,66.7c-0.456,2.097-2.498,3.411-4.596,2.955
            l-100.68-21.888c-1.678-0.364-2.991-2.408-2.535-4.505l12.129-55.793l-9.539-8.664l-13.588,62.506
            c-1.732,7.97,3.192,15.63,11.163,17.362l101.1,21.979c7.97,1.732,15.631-3.192,17.363-11.162l14.592-67.12
            c1.094-5.034-0.419-10.196-4.487-13.716l-9.119-8.572l-3.647-3.429l10.852-49.921l0.548-2.517l-11.034-9.868
            c3.064-1.969,5.708-4.03,8.354-6.091l14.682,13.296l-0.183,0.839l-10.944,50.34l4.396,4.031l5.144,4.633l0.364-1.679l12.038-55.373
            c0.912-4.195,0.056-8.336-2.662-12.002l-14.683-13.297l0.693-1.167c3.976-6.164,6.22-14.463,2.043-21.521
            c-4.176-7.059-12.477-9.302-19.972-9.175c-4.47,0.347-9.539,1.441-14.281,3.046l-0.328-0.511l-1.496-1.203
            c-2.572-2.316-6.347-3.138-10.067-2.188L67.239,34.167c-5.162,1.514-8.682,5.582-9.777,10.616l-13.406,61.665
            c-1.186,5.454,0.419,10.196,4.396,14.136l9.21,8.152l3.648,3.43L49.364,187.12l85.998,18.696l9.85-45.307l2.188-10.068
            L81.703,90.909l0.912-4.195L84.438,78.324z"
          />
        </g>
      </svg>
    </Box>
  );
}
