import { DEFAULT_HEADERS } from '../../constants';

export const deleteImageMutation = (body) => {
    return {
      url: ('/images/' + body.imageId),
      headers: {
        ...DEFAULT_HEADERS,
        'Content-Type': 'text/plain'
      },
      method: 'DELETE'
    };
};
