export const STATUS_COLOR = {
  pending: 'warning',
  accepted: 'success',
  rejected: 'error',
  cancelled: 'default',
  returned: 'error',
  fulfilled: 'success',
  shipped: 'success',
  declined: 'error',
  lowStock: 'warning',
  outOfStock: 'error',
  mediumStock: 'success',
  inStock: 'success',
  refunded: 'error',
  awaiting: 'warning',
  paid: 'success',
  unpaid: 'warning',
  partialPaid: 'warning',
  active: 'success',
  inactive: 'default',
  client: 'success',
  vendor: 'error',
  banned: 'default',
  flagged: 'warning',
  inProgress:'warning',
  authorized: 'success',
  unauthorized: 'default',
  completed: 'success',
  partiallyCompleted: 'primary',
  na:'success'
};
