import { CURRENCY_EXPRESSIONS, DAY, HOUR, MINUTE, SECOND } from 'src/constants';
import { EMPTY_IMAGE_URL, MUI_LICENCE } from 'src/constants/common';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import i18next from 'i18next';
import { getWeek } from 'date-fns';
import Image from 'src/components/Image';
import { SUBSCRIPTION_NUMBER_LIMITS } from 'src/constants/subscription';
// import JSZip from 'jszip';
import { getFileSizeByByte } from './tax-archive';
import Resizer from 'react-image-file-resizer';

export const makeCammelCase = (str) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};

/**
 *
 * @param {Object} obj
 * @returns {Object}
 * @description
 * This function is used to convertToBoolean
 *  } the object keys to camelCase
 * @example
 * const obj = {
 * "first_name": "John",
 * "last_name": "Doe",
 * "age": 25
 * }
 *
 * const result = convertKeysToCamelCase(obj)
 *
 * console.log(result)
 *
 * // Output
 * {
 * "firstName": "John",
 * "lastName": "Doe",
 * "age": 25
 * }
 *
 */
export const convertKeysToCamelCase = (obj) => {
  let newObj = {};
  for (let key in obj) {
    newObj[makeCammelCase(key)] = obj[key];
  }
  return newObj;
};

export const convertStringToBoolean = (str) => {
  if (typeof str === 'boolean') return str;
  if (typeof str === 'string') {
    return str.toLowerCase() === 'true' || str.toLowerCase() === 'yes';
  }
  return false;
};

export const getFilterdWithin7Days = (data, dateKey) => {
  try {
    return data.filter((item) => {
      const today = new Date();
      const datePlaced = new Date(item[dateKey]);
      const diffTime = Math.abs(today - datePlaced);
      const diffDays = Math.ceil(diffTime / (SECOND * MINUTE * HOUR * DAY));
      return diffDays <= 7;
    });
  } catch (error) {
    console.error(error);
    return data;
  }
};

export const phoneNumberFormatter = (value) => {
  const newValue = value.replace(/\D/g, '');
  // like this form 010-1234-5678
  const phone = newValue.toString();
  const first = phone.substr(0, 3);
  let second = phone.substr(3, 4);
  let third = phone.substr(7, 4);
  // if the number is 10 digit numbers, it should be 010-123-5678
  if (phone.length === 10) {
    second = phone.substr(3, 3);
    third = phone.substr(6, 4);
    return `${first}-${second}-${third}`;
  }
  return `${first}${second && '-'}${second}${third && '-'}${third}`;
};
export const isNotEmpty = (value) => value !== '' && typeof value !== 'undefined' && value !== null;
export const isNull = (value) => value === '' || typeof value === 'undefined' || value === null;
export const isZero = (value) => value === 0;
export const safeIsNotEmpty = (value) => {
  if (typeof value === 'string' || typeof value === 'number') return isNotEmpty(value);
  if (Array.isArray(value)) return value.length > 0;
  return false;
};

/**
 * 
 * @param {*} e
 * @example
 * <RHFTextField
    onChange={(event) => setValue(`items[${index}].price`, Number(event.target.value))}
    // block for negative value
    onInput={blockNegativeNumber}
  /> 
 */
export const strictBlockNegativeNumber = (e) => {
  const newValue = Math.max(0, parseFloat(e.target.value)).toString().slice(0, 10);
  if (newValue !== e.target.value) {
    e.target.value = newValue;
  }
};

export const mapLocalizeOption = (option) => ({ ...option, label: i18next.t(option.label) });

export const optionValueFormatter = ({ value, options }) => {
  const option = options.find((option) => option.value === value);
  return option ? i18next.t(option.label) : '';
};

export const blockNegativeNumber = (e) => {
  let newValue = e.target.value;

  // Remove leading minus sign
  newValue = newValue.replace('-', '');

  // Remove leading zeros unless followed by a decimal point
  if (newValue.length > 1 && newValue[0] === '0' && newValue[1] !== '.') {
    newValue = newValue.slice(1);
  }

  // Remove non-numeric characters except decimal point
  newValue = newValue.replace(/[^0-9.]/g, '');

  if (newValue !== e.target.value) {
    e.target.value = newValue;
  }
};

/**
 *
 * @param {string | number} value
 * @returns
 */
export const getPositiveNumber = (value) => {
  return Math.max(0, parseFloat(value)).toString().slice(0, 10);
};

export const convertToBoolean = (value) => {
  if (typeof value === 'boolean') return value;
  if (typeof value === 'string') {
    return value.toLowerCase() === 'true' || value.toLowerCase() === 'yes' || value.toLowerCase() === 'y';
  }
  return false;
};

export const setMUILicense = () => LicenseInfo.setLicenseKey(`${MUI_LICENCE.mui.licenseKey}`);

export const convertImgToS3 = (products) =>
  products.map((product) => ({
    ...product,
    image: product.images && product.images.length > 0 ? product.images[0].payloadS3 : EMPTY_IMAGE_URL,
  }));

export const validateEmail = (email) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

export const getMetaTitle = (title) => `${title} | ${i18next.t('common.sellpoint')}`;

export const getRecent12Months = () => {
  const today = new Date();
  const recent12Months = Array.from({ length: 12 }, (_, i) => {
    const month = today.getMonth() + 1 - i;
    if (month < 1) return { year: today.getFullYear() - 1, month: 12 + month };
    return { year: today.getFullYear(), month };
  });
  return recent12Months.reverse();
};

// export const getRecent4Weeks = () => {
//   const today = new Date();
//   const recent4Weeks = Array.from({ length: 4 }, (_, i) => {
//     const date = today.getDate() - i * 7;
//     const week = Math.ceil((date + 6 - new Date(today.getFullYear(), today.getMonth(), date).getDay()) / 7);
//     return { year: today.getFullYear(), month: today.getMonth() + 1, week };
//   });
//   return recent4Weeks;
// };

export const getRecent4Weeks = () => {
  const today = new Date();
  const recent4Weeks = Array.from({ length: 4 }, (_, i) => {
    const date = today.getDate() - i * 7;
    const week = getWeek(new Date(today.getFullYear(), today.getMonth(), date));
    return { year: today.getFullYear(), month: today.getMonth() + 1, week };
  });
  return recent4Weeks.reverse();
};

export const getRecent14Days = () => {
  const today = new Date();
  const recent14Days = Array.from({ length: 14 }, (_, i) => {
    let date = today.getDate() - i;
    const lastMonthDate = new Date(today.getFullYear(), today.getMonth(), 0).getDate();
    if (date < 1) return { year: today.getFullYear(), month: today.getMonth(), date: lastMonthDate + date };
    return { year: today.getFullYear(), month: today.getMonth() + 1, date };
  });

  return recent14Days.reverse();
};

// export const getTodayInfo = (today = new Date()) => {
//   const year = today.getFullYear();
//   const month = today.getMonth() + 1;
//   const date = today.getDate();
//   // const week = Math.ceil((date + 6 - new Date(year, month - 1, date).getDay()) / 7);
//   const week = getWeek(today);
//   return { year, month, date, week };
// };

export const getDateInfo = (today = new Date()) => {
  const year = today?.getFullYear();
  const month = today?.getMonth() + 1;
  const date = today?.getDate();
  // const week = Math.ceil((date + 6 - new Date(year, month - 1, date).getDay()) / 7);
  const week = getWeek(today);
  return { year, month, date, week };
};

export const removeDuplicate = (array) => {
  if (!array) return [];
  return [...new Set(array)];
};
export const removeDuplicated = (array) => [...new Set(array)];

export const localize = (key) => {
  return i18next.t(key);
};

export const getCurrencySign = (currency) => {
  if (!currency) return null;
  if (CURRENCY_EXPRESSIONS.USD.includes(currency?.toUpperCase())) return '$';
  if (CURRENCY_EXPRESSIONS.KRW.includes(currency?.toUpperCase())) return '₩';
  return null;
};

export const initializeFormValues = (object, setValue) => {
  Object.entries(object).forEach(([key, value]) => {
    setValue(key, value);
  });
};

export const getIcon = (name, sx) => (
  <Image
    src={`/static/icons/${name}.svg`}
    sx={{
      height: '100%',
      objectFit: 'contain',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...sx,
    }}
  />
);

/**  @param {"free" | "essentials" | "premium" | "enterprise"} subscriptionPlan */
export const getSubscriptionLimit = (subscriptionPlan) => {
  return SUBSCRIPTION_NUMBER_LIMITS[subscriptionPlan];
};

export const getTodayInfo = (today = new Date()) => {
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const date = today.getDate();
  // const week = Math.ceil((date + 6 - new Date(year, month - 1, date).getDay()) / 7);
  const week = getWeek(today);
  return { year, month, date, week };
};

export const getQuarter = (month) => {
  if (month >= 1 && month <= 3) return 1;
  if (month >= 4 && month <= 6) return 2;
  if (month >= 7 && month <= 9) return 3;
  if (month >= 10 && month <= 12) return 4;
};

export const getMonthsFromQuarter = (quarter) => {
  if (quarter === 1) return [1, 2, 3];
  if (quarter === 2) return [4, 5, 6];
  if (quarter === 3) return [7, 8, 9];
  if (quarter === 4) return [10, 11, 12];
};

export const getFileSize = (arr) => {
  try {
    return getFileSizeByByte(arr.length * 0.9 * 1000);
  } catch (error) {
    return '0MB';
  }
};

export const splitArray = ({ array, size }) => {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    const chunk = array.slice(i, i + size);
    result.push(chunk);
  }
  return result;
};

export const getMonthByNumber = (monthNumber) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  if (monthNumber >= 1 && monthNumber <= 12) {
    return months[monthNumber - 1];
  } else {
    return 'Invalid month number';
  }
};

export const formatDateInfo = (invoice) => {
  const { year, month, date } = getTodayInfo(new Date(invoice.dateIssued));
  return {
    ...invoice,
    dateIssued: `${year}-${month}-${date}`,
  };
};

export const downloadFile = ({ url, filename }) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();
};
export const resizeFile = async (image) => {
  const resizePayload = new Promise((resolve) => {
    Resizer.imageFileResizer(
      image,
      300,
      300,
      'JPEG',
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'base64',
    );
  });
  // convert to image File
  const resizedImage = await resizePayload;
  const imageFile = await fetch(resizedImage);
  const imageBlob = await imageFile.blob();
  const file = new File([imageBlob], image.name, { type: image.type });
  return file;
};

export const getUniqueArr = (array) => [...new Set(array)];

export const getProductType = (productType) => {
  const typeMap = {
    manufacturedProduct: 'manufacturedProduct',
    rawMaterial: 'rawMaterial',
    RAW: 'vendorProduct',
  };

  return typeMap[productType] ?? 'vendorProduct';
};

export const getProductId = (product) => {
  console.log(product);
  const productIdMap = {
    vendorProduct: product?.productId,
    manufacturedProduct: product?.productId,
    rawMaterial: product?.materialId,
  }[getProductType(product?.productType)];

  return productIdMap;
};

export const getQueryString = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams;
};

export function formatLargeNumber(number) {
  try {
    // allow only numbers, decimal points, and minus sign for negative numbers
    const stringNumber = String(number);
    const onlyNumbers = stringNumber?.replace(/[^0-9.-]/g, '');
    if (parseFloat(onlyNumbers) < 1000) return onlyNumbers;

    // Split the number into integer and decimal parts
    const parts = onlyNumbers.split('.');
    let integerPart = parts[0];
    const decimalPart = parts.length > 1 ? '.' + parts[1] : '';

    // Add commas to the integer part
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Combine the integer and decimal parts and return
    return integerPart + decimalPart;
  } catch (error) {
    console.log('ERROR: formatLargeNumber', error?.message || error);
    return number;
  }
}
export const getFullKoreanName = ({ firstName, lastName }) => {
  try {
    return `${lastName}${firstName}`;
  } catch (error) {
    return '';
  }
};

export const getOnlyNumbers = (value) => {
  try {
    return value.replace(/[^0-9]/g, '');
  } catch (error) {
    console.log('Fail to get only numbers');
    return value;
  }
};

export const onlyNumbers = (value) => {
  try {
    const strValue = String(value);
    // allow only numbers, decimal points, and minus sign for negative numbers
    const onlyNumbers = strValue.replace(/[^0-9.-]/g, '');
    return Number(onlyNumbers);
  } catch (error) {
    console.log('ERROR: onlyNumbers', error?.message || error);
    return value;
  }
};

export const floated = (input) => {
  // Regular expression to match a number that doesn't start with 0 unless it's a decimal
  if (!isNaN(input)) {
    // Convert the input to a number and back to a string
    const num = parseFloat(input);
    return num.toString();
  }
  return input; // If it's not a number, return it as is
};

export function convertNumbersToStrings(obj) {
  const newObj = Array.isArray(obj) ? [] : {};

  for (let key in obj) {
    if (typeof obj[key] === 'number') {
      // Convert number to string and assign to new object
      newObj[key] = obj[key].toString();
    } else if (obj[key] instanceof Date) {
      // Convert date to ISO string format
      newObj[key] = obj[key].toISOString();
    } else if (Array.isArray(obj[key])) {
      // Recursively handle arrays
      newObj[key] = obj[key].map((item) => convertNumbersToStrings(item));
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      // Recursively handle objects
      newObj[key] = convertNumbersToStrings(obj[key]);
    } else {
      // Copy other values as is
      newObj[key] = obj[key];
    }
  }

  return newObj;
}

export const commonUtil = {
  makeCammelCase,
  convertKeysToCamelCase,
  removeDuplicate,
  removeDuplicated,
};
