import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getStoreId } from 'src/components/authentication/TempAuthService';
import { Auth } from 'aws-amplify';

const API_ROOT = process.env.REACT_APP_API_ROOT + '/';

const REQUEST_CONFIG = {
    headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
    },
};

async function getRequestHeaders() {
    var user;
    try {
      // currentSession() checks if id token is expired and refreshes with Cognito if needed automatically
      user = await Auth.currentSession();
    } catch (err) {
      console.log(err);
      throw err;
    }
    return {
      headers: {
        'Authorization': user.getAccessToken().getJwtToken()
      }
    };
  }

function getReqConfWithAbort(abortController) {
    return { ...REQUEST_CONFIG, signal: abortController.signal };
}

const initialState = {
    isLoading: false,
    salesByVolumeData: null,
    salesByRegionData: null,
    topProductsData: null,
    topCustomersData: null,
    topGendersData: null,
    sourceBreakdownData: null,
    salesByCategoryData: null,
    annualYieldEfficiencyChartData: null,
    inventoryTurnOverRateData: null,
    error: null,
};

const performanceSlice = createSlice({
    name: 'perfomance',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        setSalesByVolume(state, action) {
            state.salesByVolumeData = action.payload;
            state.isLoading = false;
        },
        setSalesByRegion(state, action) {
            state.salesByRegionData = action.payload;
            state.isLoading = false;
        },
        setTopProducts(state, action) {
            state.topProductsData = action.payload;
            state.isLoading = false;
        },
        setTopCustomers(state, action) {
            state.topCustomersData = action.payload;
            state.isLoading = false;
        },
        setTopGenders(state, action) {
            state.topGendersData = action.payload;
            state.isLoading = false;
        },
        setSourceBreakdown(state, action) {
            state.sourceBreakdownData = action.payload;
            state.isLoading = false;
        },
        setSalesByCategory(state, action) {
            state.salesByCategoryData = action.payload;
            state.isLoading = false;
        },
        setAnnualYieldEfficiencyChartData(state, action) {
            state.annualYieldEfficiencyChartData = action.payload;
            state.isLoading = false;
        },
        setInventoryTurnOverRateData(state, action) {
            state.inventoryTurnOverRateData = action.payload;
            state.isLoading = false;
        },
        setError(state, action) {
            state.error = action.payload;
            state.isLoading = false;
        },
    },
});

export const {
    startLoading,
    setSalesByVolume,
    setSalesByRegion,
    setAnnualYieldEfficiencyChartData,
    setTopProducts,
    setTopCustomers,
    setTopGenders,
    setSourceBreakdown,
    setSalesByCategory,
    setInventoryTurnOverRateData,
    setError,
} = performanceSlice.actions;

export function fetchPerformanceSalesByVolume(abortController) {
    return async (dispatch) => {
        dispatch(startLoading());
        try {
            const response = await getSalesFromAWS(abortController, 'SalesByVolume')
            dispatch(setSalesByVolume(response))
        } catch (error) {
            dispatch(setError(error));
        }
    }
}

export function fetchPerformanceSalesByRegion(abortController) {
    return async (dispatch) => {
        dispatch(startLoading());
        try {
            const response = await getSalesFromAWS(abortController, 'SalesByRegion')
            dispatch(setSalesByRegion(response))
        } catch (error) {
            dispatch(setError(error));
        }
    }
}

export function fetchPerformanceTopProducts(abortController) {
    return async (dispatch) => {
        dispatch(startLoading());
        try {
            const response = await getSalesFromAWS(abortController, 'TopSellingProducts')
            dispatch(setTopProducts(response))
        } catch (error) {
            dispatch(setError(error));
        }
    }
}
export function fetchPerformanceSalesByCustomer(abortController) {
    return async (dispatch) => {
        dispatch(startLoading());
        try {
            const response = await getSalesFromAWS(abortController, 'TopCustomers')
            dispatch(setTopCustomers(response))
        } catch (error) {
            dispatch(setError(error));
        }
    }
}
export function fetchPerformanceGenderBreakdown(abortController) {
    return async (dispatch) => {
        dispatch(startLoading());
        try {
            const response = await getSalesFromAWS(abortController, 'GenderBreakdown')
            dispatch(setTopGenders(response))
        } catch (error) {
            dispatch(setError(error));
        }
    }
}
export function fetchPerformanceSourceBreakdown(abortController) {
    return async (dispatch) => {
        dispatch(startLoading());
        try {
            const response = await getSalesFromAWS(abortController, 'SourceBreakdown')
            dispatch(setSourceBreakdown(response))
        } catch (error) {
            dispatch(setError(error));
        }
    }
}
export function fetchPerformanceSalesByCategory(abortController) {
    return async (dispatch) => {
        dispatch(startLoading());
        try {
            const response = await getSalesFromAWS(abortController, 'SalesByCategory')
            dispatch(setSalesByCategory(response))
        } catch (error) {
            dispatch(setError(error));
        }
    }
}

export function fetchAnnualYieldEfficiency(abortController, year) {
    return async (dispatch) => {
        dispatch(startLoading());
        try {
            const response = await getAnnualYeildFromAWS(abortController, year)
            dispatch(setAnnualYieldEfficiencyChartData(response));
        } catch (error) {
            dispatch(setError(error));
        }
    }
}

export function fetchInventoryTurnOverRate(abortController, year) {
    return async (dispatch) => {
        dispatch(startLoading());
        try {
            const response = await getTurnOverFromAWS(abortController, year)
            dispatch(setInventoryTurnOverRateData(response));
        } catch (error) {
            dispatch(setError(error));
        }
    }
}

//selectors
export const selectIsLoading = (state) => state.performance.isLoading;
export const selectSalesByVolumeData = (state) => state.performance.salesByVolumeData;
export const selectSalesByRegionData = (state) => state.performance.salesByRegionData;
export const selectTopProductsData = (state) => state.performance.topProductsData;
export const selectTopCustomersData = (state) => state.performance.topCustomersData;
export const selectTopGendersData = (state) => state.performance.topGendersData;
export const selectSourceBreakdownData = (state) => state.performance.sourceBreakdownData;
export const selectSalesByCategoryData = (state) => state.performance.salesByCategoryData;
export const selectAnnualYieldEfficiencyChartData = (state) => state.performance.annualYieldEfficiencyChartData;
export const selectInventoryTurnOverRateData = (state) => state.performance.inventoryTurnOverRateData;
export const selectError = (state) => state.performance.error;

// Reducer
export default performanceSlice.reducer;


export async function getSalesFromAWS(abortController, body) {
    const requestHeaders = await getRequestHeaders();
    const fetchSalesPromise = new Promise((resolve, reject) => {
        const requestUrl = API_ROOT + getStoreId() + '/performance-data';
        const requestBody = JSON.stringify({
            dataType: body,
        });
        axios
            .post(requestUrl, requestBody, requestHeaders)
            .then(async (response) => {
                resolve(response.data);
            })
            .catch((e) => {
                console.log(e);
                resolve([]);
            });
    });

    const fetchedSales = await Promise.all([fetchSalesPromise]);
    return fetchedSales[0];
}
export async function getAnnualYeildFromAWS(abortController, year) {
    const fetchYeildPromise = new Promise((resolve, reject) => {
        const requestUrl = API_ROOT + getStoreId() + `/yieldEfficiency/${year}`;
        axios
            .get(requestUrl, getReqConfWithAbort(abortController))
            .then(async (response) => {
                resolve(response.data);
            })
            .catch((e) => {
                console.log(e);
                resolve([]);
            });
    });

    const fetchedYeild = await Promise.all([fetchYeildPromise]);
    return fetchedYeild[0];
}
export async function getTurnOverFromAWS(abortController, year) {
    const fetchYeildPromise = new Promise((resolve, reject) => {
        const requestUrl = API_ROOT + getStoreId() + `/inventoryTurnOver/${year}`;
        axios
            .get(requestUrl, getReqConfWithAbort(abortController))
            .then(async (response) => {
                resolve(response.data);
            })
            .catch((e) => {
                console.log(e);
                resolve([]);
            });
    });

    const fetchedYeild = await Promise.all([fetchYeildPromise]);
    return fetchedYeild[0];
}
