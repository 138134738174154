import { camelCase } from 'lodash';

export function camelCaseKeys(object) {  
  return Object
    .entries(object)
    .reduce((newObj, [key, value]) => {
      newObj[camelCase(key)] = value;

      return newObj;
    }, {})
}

/**
 * Translate all top-level keys of the given objects in objectList to camelCase.
 *
 * @param List<Object> objectList
 * @returns List<Object>
 */
export function camelCaseKeysForObjects(objectList) {
  if (!objectList) return [];
  return objectList.map(object => camelCaseKeys(object));
}

/**
 * Wraps ids in an {'id': id} object and return a list of those objects.
 * Useful for bulk edit/delete operations.
 *
 * @param List<string> ids
 * @returns List<Object>
 */
export function wrapIdsInIdObject(ids) {
  if (!ids) return [];
  return ids.map(id => ({'id': id}));
}