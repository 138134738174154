import PropTypes from 'prop-types';
import { createContext, useState } from 'react';

// ----------------------------------------------------------------------
const initialState = {
  isDropStatus: {
    orderManagement: true,
    financials: true,
    marketingTools: true,
    logistics: true,
    commerce: true,
    support: true
  },
  onDropStatus: () => {}
};

const SidebarDropContext = createContext(initialState);

SidebarDropProvider.propTypes = {
  children: PropTypes.node
};

function SidebarDropProvider({ children }) {
  const [dropStatus, setDropStatus] = useState({
    orderManagement: true,
    financials: true,
    marketingTools: true,
    logistics: true,
    commerce: true,
    support: true
  });

  const onDropStatus = ( status ) => {
    setDropStatus({ ...dropStatus, [status]: !dropStatus[status] });
  };

  return (
    <SidebarDropContext.Provider
      value={{
        isDropStatus: dropStatus,
        onDropStatus
      }}
    >
      {children}
    </SidebarDropContext.Provider>
  );
}

export { SidebarDropProvider, SidebarDropContext };
