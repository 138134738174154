import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { withTranslation } from "react-i18next";
import enLocales from './en.json';
import koLocales from './koKR.json';
import cnLocales from './zhCN.json';
import jaLocales from './jaJP.json';
import viLocales from './viVN.json';

// ----------------------------------------------------------------------

let lng = 'en';
let detectedLng = 'en';

if (window.navigator.language==='ko') { detectedLng = 'kr'; }
    else if (window.navigator.language==='vi') { detectedLng = 'vi'; }
    else if (window.navigator.language==='ja') { detectedLng = 'ja'; }
    else if (window.navigator.language==='zh-CN') { detectedLng = 'cn'; }

if (typeof localStorage !== 'undefined') {
  lng = localStorage.getItem('i18nextLng') || detectedLng;
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      kr: { translations: koLocales },
      en: { translations: enLocales },
      cn: { translations: cnLocales },
      ja: { translations: jaLocales },
      vi: { translations: viLocales }
    },
    lng,
    fallbackLng: 'en',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false
    }
  });

export default withTranslation()(i18n); 