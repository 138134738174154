export const MUI_LICENCE = require('src/components/licenseKeys.json');

export const EMPTY_IMAGE_URL = 'https://spbeta.s3.amazonaws.com/static/placeholder.svg';

export const ALERT_CONFIG = { open: false, message: '', severity: 'success' };

export const COUNRTY_CODE = {
  kr: 'ko-KR',
  en: 'en-US',
  cn: 'zh-CN',
  ja: 'ja-JP',
  vi: 'vi-VN',
};
